@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

/* On Click Cart section open */
.cart-list {
    position: fixed;
    height: 100vh;
    width: 0vw;
    max-width: 560px;
    background-color: #FFF;
    z-index: 1000;
    right: 0;
    top: 0;
    transition: width 1s;

    &.show {
        width: 50vw;
        transition: width 1s;
        z-index: 9999;
        .btn-close {
            display: block !important;
        }
    }

    .topheader {
        background-color: $maincolor;
        height: 100px;
        color: $whitecolor;
        @include copyright(1.875rem, flex, space-between);
        align-items: center;

        h2 {
            color: $whitecolor;
            margin-bottom: 0;

            span {
                font-weight: 300;
            }
        }

        .btn-close {
            display: none;
            background-image: none;
            opacity: 1;
            color:#FFF;font-size:35px;
            top:10px;position: absolute;right:1.5rem;
            cursor: pointer;
            &:focus {
                box-shadow: none;
            }

            i.fa {
                color: #FFF;
                font-size: 1.875rem;
            }
        }

    }

    .cart-body {
        height: 100%;

        .product-price {
            padding: 1.375rem 1.875rem 0rem;
            border-bottom: 1px solid $cardcolor;
            margin-bottom: 0rem;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style-type: none;
                margin-bottom: 1.25rem;

                h5 {
                    font-size: 1.125rem;
                    font-weight: bold;
                }
            }

        }

        .product-details {
            padding: 0.8rem 0rem 1.375rem;
            margin: 0;
            height: 765px;
            overflow-y: auto;

            >li {
                list-style-type: none;
                padding: 0 1.5rem 1rem;margin-bottom: 1rem; border-bottom: 1px solid $cardcolor;
            }

            &::-webkit-scrollbar {
                width: 2px;
            }
             
            &::-webkit-scrollbar-track {
                width: 5px;background-color: #F5F5F5;border-radius: 10px;
            }
             
            &::-webkit-scrollbar-thumb {
              width: 2px;background-color: $linkcolor;height: 62px;border-radius: 10px;
            }
        }

        .cart-footer {
            height: 100px;
            @include copyright(1.375rem 1.875rem 1.375rem, flex, space-between);
            align-items: center;
            position: absolute;
            bottom: 0;
            width: 100%;
            box-shadow: 0 -3px 6px -5px #000;
            background-color: #fff;
            .cart-button {display: flex;justify-content: flex-start;align-items: center;}
            .total-prize {
                font-size: 1.5rem;
                font-weight: bold;
            }

            .btn-secondary {
                width: 130px;
                margin-right: 1rem;
                font-size: 13px;
                height: 40px;
                font-weight: 600;
            }
            .btn-primary{
                font-size: 13px;
                font-weight: 600;
                
            }
        }
        .product-label-offer h5.offer {
            padding-left: 0;font-weight: 400;font-size: 13px;
        }
        .product-price-counter > li:nth-child(2) {
            width: 100%;padding-left: 1rem;
        }
        .product-price-counter {
            align-items: flex-start;
        }
    }
}

