.empty {
  text-align: center;
  margin-top: 2rem;
}
.empty img {
  margin-bottom: 40px;
}
.empty p {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 20px;
}
.empty .btn.btn-primary {
  border-radius: 5px;
  text-transform: uppercase;
  width: auto;
  border: 1px solid #5B005D;
}
.empty .btn.btn-primary:hover {
  background-color: #fff;
  border: 1px solid #5B005D;
  color: #5B005D;
}/*# sourceMappingURL=empty.css.map */