@import '../../assets/Scss/variable';
@import '../../assets/Scss/_mixing.scss';

.about {
    margin-top: 1.8rem;
    position: relative;
    height: 450px;

    figure {
        height: 450px;
        background-size: cover;
        position: absolute;
        background-position: center;
        width: 100%;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-image: url(../../assets/images/banner-about.jpg);
    }

    section {
        left: 50%;
        width: 50%;
        height: 450px;
        position: relative;
        text-align: center;
        flex-direction: column;
        @include copyright(0 4rem, flex, center);

        p {
            color: $whitecolor;
            margin-bottom: 2rem;text-align: justify;
        }

        h2 {
            text-align: center;text-transform: uppercase;
            @include font(32px, 600, $whitecolor);
            margin-bottom: 1rem;
        }
    }
}