@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

//Gifts CSS

.gifts{
    p{@include font(15px,'',$textcolor,25px);}
    h2{text-align: center;}

    @include banner-background("../../assets/images/gifts/gifts-banner.png");
    @include banner-div;

    .banner-div {
        .container {@include flexitem;}
    }

    .steps{@include flexitem;margin-bottom: 3rem;padding-left: 0;
        li{list-style: none;text-align: center;position: relative;width: 215px;
            h5{@include font(20px,600,$grayColor,30px);margin-bottom: 0;}
            &:first-child,&:nth-child(2){
                &:after {
                    position: absolute;content: "";width: 142px;height: 1px;background-repeat: no-repeat;background-size: 100%;background-image: url(../../assets/images/gifts/icons/hr.svg);top: 30%;right: 0;transform: translateX(50%);
                }
            }
        }
    }
    @include tabs;
}


.buy-a-card{
    @include banner-background("../../assets/images/gifts/gifts-banner.png");
    @include banner-div;

    .banner-div {
        .container {@include flexitem;}
    }
    .gap0{
        margin-bottom: 5rem;
        .card-benefits{width: 40%;
            padding-right: 0;margin-top: 40px;
            h2{margin-top: 2rem;}
            .orangeBox {
                background: #FFF7EB;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                padding:50px 50px 100px;
                .bgImg{position: relative;
                    img{width: 100%;}
                    .pric {@include font(30px,700,$offwhitecolor,38px);position: absolute;right: 2rem;top: 1.25rem;}
                }
                ul {
                    margin:0;padding:0;list-style-type: none;
                    li {
                        padding-left: 80px;position: relative;margin-bottom:3rem;
                        color:#5D5D5D;
                        .icon{
                            position: absolute;
                            top:0;
                            left:0;
                            width: 60px;
                            height: 60px;
                            border-radius: 100%;
                            background: $whitecolor;
                            img{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);}
                        }
                        h5{font-size: 20px;margin:0;line-height: normal;}
                    }
                }
            }
        }

        .form{width: 60%;
            padding-left: 0;
            .shadow-box-rounded {
                border-radius: 10px;
                padding:30px 30px 40px;
                height: 100%;
                box-shadow: 0 0 30px rgba(0,0,0,0.10);

                .topheading{display: flex;justify-content: space-between;margin-bottom:.5rem;
                    h5{@include font(20px,600,$textcolor,30px);margin-bottom: 0;}
                    span{@include font(15px,400,$grayColor,21px);}
                }

                @include gift-form;
                .form-group {
                    .txtbox{
                        &::placeholder {
                            color: #C8C7C7;
                        }
                    }
                    .formMargin {
                        .formRe{line-height: normal;}
                    }
                    &.radio{display: flex;
                        input[type='radio'] {
                            accent-color: $linkcolor;margin-right: .5rem;
                        }
                    }
                    .ammount{@include font(15px,700 !important,$textcolor !important,21px);}
                    .amount{
                        .formMargin{
                            .lbl{display: none;}
                            .btn.btn-primary{width: 74px !important;}
                        }
                        .error-message{position: relative;width: 100%;}
                    }
                    &.card-name{width: 40%;
                        .message{@include font(13px,400,$grayColor,23px);}
                        .error-message{bottom: -35px;}
                    }
                    &.name{
                        display: flex;justify-content: space-between;
                        .formMargin{
                            width: 43%;
                            &:first-child{width: 68px;}
                            .formRe{
                                .txtbox{background-position: right 0.25rem center;
                                    padding: 5px;
                                }
                                .error-message{line-height: normal;}
                            }
                        }
                    }
                    &.quantity{
                        .formMargin{
                            .input-lable{@include font(15px,700 !important,$textcolor !important,21px);margin-bottom: .5rem;}
                        }
                    }
                    &.mobile{
                        .cust-select{justify-content: space-between;
                            .form-select{background-position: right 0.25rem center;
                                width: 68px !important;
                            }
                            .formMargin{
                                &:first-child{width: 70px;}
                                &:nth-child(2){.formRe{margin-top: 3px;}}
                                &:last-child{.formRe{margin-top: 0;}}
                                width: 43%;
                            }
                        }
                    }
                }
                h4{@include font(15px,700,$textcolor,21px);}
                .make-info{text-align: center;
                    .checkbox {
                        .styled-checkbox + label{
                            @include font(15px,400,$grayColor,21px);letter-spacing: 0.02em;
                            a{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

.buy-a-card-customised{
    .gap0 .form .shadow-box-rounded .card-div{margin-bottom: 3rem;
        .mb-3 input{height: 262px;width: 422px;border: 0;
            &:focus{border: 0;}
            &[type="file"] {
                padding: 0;
                background: url('../../assets/images/gifts/customised-upload.png') center center no-repeat;
                color: rgba(0, 0, 0, 0);
                &::file-selector-button{display: none}
                &.edit{background: url('../../assets/images/gifts/icons/edit.svg') center center no-repeat;height: 20px;width: 20px;}
            }
        }
        .card-details {position: relative;width: 630px;max-width: 100%;margin: 0;width: 420px;height: 260px;
            .card-img{width: auto;max-width: 100%;}
            .card-type {@include font(20px,600,$whitecolor,25px);position: absolute;left: 1.5rem;top: 1.75rem;text-shadow: 4.49286px 5.99048px 5.99048px rgba(0, 0, 0, 0.25);}
            .pric {@include font(30px,700,$offwhitecolor,38px);position: absolute;right: 2rem;top: 1.5rem;}
            .card-number{@include font(20px,400,$whitecolor,32px);font-family: 'Credit Card';position: absolute;right: 0;top: 7rem;left: 0;letter-spacing: 1.95643px;text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);left: 10%;transform: translateX(0%);}
            .valid-from{@include font(8px,600,$whitecolor,10px);position: absolute;left: 2.5rem;bottom: 3rem;text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
                span{@include font(13px,700,$whitecolor,16px);}
            }
            .valid-thru{@include font(8px,600,$whitecolor,10px);position: absolute;left: 0;right: 0;bottom: 3rem;text-align: center;text-shadow: 1.8268px 2.43573px 2.43573px rgba(0, 0, 0, 0.25);
                span{@include font(13px,700,$whitecolor,16px);}
            }
            .name{@include font(15px,700,$whitecolor,19px);position: absolute;left: 2.5rem;bottom: .75rem;text-shadow: 1.8268px 2.43573px 2.43573px rgba(0, 0, 0, 0.25);}
        }
        .update{position: relative;display:flex;justify-content:flex-end;align-items:center;width: 422px;
            .edit{margin: 10px;cursor: pointer;
                &[type="file"]{
                    padding: 0;
                    color: rgba(0, 0, 0, 0);
                    &::file-selector-button{display: none}
                    background: url('../../assets/images/gifts/icons/edit.svg') center center no-repeat;height: 20px;width: 20px;
                }
            }
            .delete{margin: 10px;cursor: pointer;}
        }
    }
}

.gift-a-card{
    @include banner-background("../../assets/images/gifts/gifts-banner.png");
    @include banner-div;

    .banner-div {
        .container {@include flexitem;}
    }
    .gap0{
        margin-bottom: 5rem;
        .card-benefits{width: 40%;
            padding-right: 0;margin-top: 40px;
            h2{margin-top: 2rem;}
            .orangeBox {
                background: #FFF7EB;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                padding:50px 50px 100px;
                position: relative;
                .pric {@include font(30px,700,$offwhitecolor,38px);position: absolute;right: 2rem;top: 1.25rem;}
                ul {
                    margin:0;padding:0;list-style-type: none;
                    li {
                        padding-left: 80px;position: relative;margin-bottom:3rem;
                        color:#5D5D5D;
                        .icon{
                            position: absolute;
                            top:0;
                            left:0;
                            width: 60px;
                            height: 60px;
                            border-radius: 100%;
                            background: $whitecolor;
                            img{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);}
                        }
                        h5{font-size: 20px;margin:0;line-height: normal;}
                    }
                }
            }
        }

        .form{width: 60%;
            padding-left: 0;
            .shadow-box-rounded {
                border-radius: 10px;
                padding:30px 30px 40px;
                box-shadow: 0 0 30px rgba(0,0,0,0.10);

                .topheading{display: flex;justify-content: space-between;margin-bottom:.5rem;
                    h5{@include font(20px,600,$textcolor,30px);margin-bottom: 0;}
                    span{@include font(15px,400,$grayColor,21px);}
                }

                @include gift-form;
                .form-group {
                    .txtbox{
                        &::placeholder {
                            color: #C8C7C7;
                        }
                    }
                    &.radio{display: flex;
                        input[type='radio'] {
                            accent-color: $linkcolor;margin-right: .5rem;
                        }
                    }
                    .ammount{@include font(15px,700 !important,$textcolor !important,21px);}
                    .amount{
                        .formMargin{
                            .lbl{display: none;}
                        }
                    .error-message{position: relative;width: 100%;}
                    }
                    &.name{
                        display: flex;justify-content: space-between;
                        .formMargin{
                            width: 43%;
                            &:first-child{width: 68px;}
                            .formRe{
                                .txtbox{background-position: right 0.25rem center;
                                    padding: 5px;
                                }
                                .error-message{line-height: normal;}
                            }
                        }
                    }
                    &.quantity{margin-bottom: 2rem;
                        .formMargin{
                            .input-lable{@include font(15px,700 !important,$textcolor !important,21px);margin-bottom: .5rem;}
                        }
                    }
                    &.mobile{margin-bottom: 2rem;
                        .cust-select{justify-content: space-between;
                            .form-select{background-position: right 0.25rem center;
                                width: 68px !important;
                            }
                            .formMargin{
                                &:first-child{width: 70px;}
                                &:nth-child(2){.formRe{margin-top: 3px;}}
                                &:last-child{.formRe{margin-top: 0;}}
                                width: 43%;
                            }
                        }
                    }
                }
                h3{@include font(20px,700,$maincolor,25px);margin-top: 1.5rem;}
                h4{@include font(15px,700,$textcolor,21px);}
                .make-info{text-align: center;
                    .checkbox {
                        .styled-checkbox + label{
                            @include font(15px,400,$grayColor,21px);letter-spacing: 0.02em;
                            a{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

.giftsreview,.giftreview,.customisegiftsreview{
    .gifts-review,.gift-review,.customise-gifts-review{
        margin-top: 11rem;

        .checkout-details{
            .contact{
                .left{
                    h4{@include font(20px,600,$textcolor,30px);}
                    h5{@include font(15px,600,$textcolor,25px);margin-bottom: .5rem;}
                    .review-add-details{
                        h6{@include font(13px,600,#3F3F3F,23px);margin-bottom: 0;}
                        ul{
                            li{
                                padding-right: 3rem;
                                &:last-child{padding-right: 0;}
                            }
                            &:last-child{margin-bottom: 0;}
                        }
                    }
                    .contact-details{padding: .5rem 1rem 0rem 1rem;}
                }

                // .right{
                //     .checkout-summary{

                //     }
                // }
            }
        }
    }
}

.giftreview{
    .gift-review{
        h3{@include font(20px,700,$linkcolor,25px);}
        .checkout-details .contact .left .contact-details .review-add-details ul{margin-bottom: 0;}
    }
}

.thanks{
    .heading {
        h4{@include font(15px,400,$grayColor,25px);}
        h5{@include font(15px,700,$grayColor,25px);margin-top: 1rem;margin-bottom: 1rem;}
    }

    .order-info{
        .track-order {
            .order-details {
                ul {display: flex;}
            }
            .track-order-details {
                .btn-secondary {
                    svg {font-size: 1.25rem;}
                }
            }
        }
    }

    .thank-order-summary {text-align: center;margin-bottom: 4rem;
        h4 {@include font(20px,400,$textcolor,25px);}

        @include card-details;

        .get-card{
            ul{@include flexitem;margin-bottom: 4rem;padding-left: 0;
                li{list-style-type: none;margin: 0px 15px;
                    span{@include flexitem;
                        svg {font-size: 1.25rem;margin-right: 5px;}
                    }
                }
            }
            .btn.btn-primary{width: 223px;}
        }
    }
}