.checkout-details h2 {
  text-align: left;
  padding-bottom: 14px;
}
.checkout-details .shipping-pickup {
  margin-top: 2.2rem;
}
.checkout-details .contact {
  display: flex;
  padding-bottom: 3.125rem;
}
.checkout-details .contact h4 {
  font-size: 15px;
  font-weight: 600;
  position: relative;
}
.checkout-details .contact h4 span {
  font-weight: 300;
}
.checkout-details .contact h4 svg {
  margin-right: 6px;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 0;
}
.checkout-details .contact .left {
  width: 100%;
  max-width: 990px;
}
.checkout-details .contact .left .contact-details {
  border: 1px solid #E6E6E6;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 5px;
}
.checkout-details .contact .left .contact-details .spacer {
  margin-top: 2.2rem;
}
.checkout-details .contact .left .contact-details .review-add-details {
  font-size: 14px;
  font-weight: 400;
}
.checkout-details .contact .left .contact-details .review-add-details p {
  font-size: 14px;
}
.checkout-details .contact .left .contact-details .review-add-details h6 {
  font-size: 12px;
  font-weight: 700;
}
.checkout-details .contact .left .contact-details .review-add-details ul {
  padding: 0;
  display: flex;
}
.checkout-details .contact .left .contact-details .review-add-details ul li {
  list-style-type: none;
  width: 225px;
}
.checkout-details .contact .left .contact-details .review-add-details ul li a {
  color: inherit;
}
.checkout-details .contact .left .contact-details .review-add-details address {
  font-size: 14px;
  margin-bottom: 2rem;
}
.checkout-details .contact .left .contact-details .review-add-details address span {
  display: block;
}
.checkout-details .contact .left .contact-details .topheading {
  display: flex;
  justify-content: space-between;
}
.checkout-details .contact .left .contact-details .topheading h6 {
  font-size: 0.75rem;
}
.checkout-details .contact .left .contact-details .form-row {
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: flex-start;
}
.checkout-details .contact .left .contact-details .form-row.billpincode {
  justify-content: flex-start;
}
.checkout-details .contact .left .contact-details .form-row.billpincode li {
  margin-right: 0.8rem;
}
.checkout-details .contact .left .contact-details .form-row li + li {
  padding-left: 10px;
}
.checkout-details .contact .left .contact-details .form-row .form-group {
  width: 32.5%;
}
.checkout-details .contact .left .contact-details .form-row .form-group label {
  font-size: 13px;
  font-weight: 400;
  color: #3F3F3F;
}
.checkout-details .contact .left .contact-details .form-row .form-group .form-control {
  border-radius: 5px;
  border: 1px solid #B6B5B5;
  height: 40px;
  font-family: "Source Sans Pro", sans-serif;
}
.checkout-details .contact .left .contact-details .form-row .form-group .form-control::-moz-placeholder {
  font-size: 0.875rem;
  color: #7F7F7F;
}
.checkout-details .contact .left .contact-details .form-row .form-group .form-control::placeholder {
  font-size: 0.875rem;
  color: #7F7F7F;
}
.checkout-details .contact .left .contact-details .form-row .form-group .form-control:disabled {
  background-color: #F5F1F1;
}
.checkout-details .contact .left .contact-details .form-row .form-group .form-control:focus {
  box-shadow: none;
}
.checkout-details .contact .left .contact-details .form-row .form-group .form-select {
  border-radius: 5px;
  border: 1px solid #B6B5B5;
  height: 40px;
  font-size: 0.875rem;
  color: #7F7F7F;
  width: 300px;
  background-image: url(../../assets/images/icons/sort-down-solid.svg);
  font-family: "Source Sans Pro", sans-serif;
}
.checkout-details .contact .left .contact-details .form-row .form-group.mobile .cust-select {
  display: flex;
}
.checkout-details .contact .left .contact-details .form-row .form-group.mobile .cust-select .form-select {
  width: 75px;
}
.checkout-details .contact .left .contact-details .form-row .form-group.mobile .cust-select .form-control {
  margin-right: 6px;
}
.checkout-details .contact .left .contact-details .form-row.custom-store {
  justify-content: flex-start !important;
  position: relative;
  margin-top: 1rem;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a {
  width: 300px;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary {
  border: 1px solid #3B003A;
  height: 40px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
  padding: 0;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  position: relative;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary svg {
  font-size: 20px;
  margin-right: 6px;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary.custom-active {
  color: #FFF;
  background-color: #5B005D;
  border: 1px solid #5B005D;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary.custom-active::after {
  content: "";
  position: absolute;
  bottom: -10px;
  font-size: 1.5rem;
  left: 49%;
  border-color: transparent;
  border-top-color: #5B005D;
  border-style: solid;
  border-width: 0.45em 0.39em 0;
  width: 0;
  height: 0;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary.custom-active:focus {
  box-shadow: none;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary.delivery:active {
  color: #FFF;
  background-color: #5B005D;
  border: 1px solid #B6B5B5;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary.pickup-store:active {
  color: #FFF;
  background-color: #043F7D;
  border: 1px solid #B6B5B5;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary.pickup-store.custom-active-btn {
  color: #FFF;
  background-color: #043F7D;
  border: 1px solid #B6B5B5;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary.pickup-store.custom-active-btn::after {
  content: "\f0d7";
  position: absolute;
  bottom: -19px;
  font-size: 1.5rem;
  left: 49%;
  color: #043F7D;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a.btn-outline-primary.pickup-store.custom-active-btn:focus {
  box-shadow: none;
}
.checkout-details .contact .left .contact-details .form-row.custom-store li a i {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.checkout-details .contact .left .contact-details .btn-secondary.add-address {
  text-align: center;
  margin-top: 1rem;
}
.checkout-details .contact .left .pickup-address {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.checkout-details .contact .left .pickup-address span {
  margin-left: 1.5rem;
  font-size: 15px;
  font-weight: 400;
}
.checkout-details .contact .left .pickup-address .address-details {
  width: 100%;
  max-width: 500px;
}
.checkout-details .contact .left .pickup-address .address-details h4 {
  padding-left: 1.5rem;
}
.checkout-details .contact .left .pickup-address .address-details .address ul {
  padding-left: 1.5rem;
}
.checkout-details .contact .left .pickup-address .address-details .address ul li {
  list-style: none;
  font-weight: 400;
  font-size: 15px;
}
.checkout-details .contact .left .pickup-address .address-details .call {
  margin-top: 1rem;
}
.checkout-details .contact .left .pickup-address .map {
  width: 100%;
  max-width: 500px;
}
.checkout-details .contact .left .make-info {
  padding-top: 2.2rem;
  text-align: center;
}
.checkout-details .contact .left .make-info h4 {
  font-size: 0.875rem;
  font-weight: 400;
  padding-bottom: 1.313rem;
}
.checkout-details .contact .left .make-info h4 i {
  color: #0B2138;
  font-size: 1.25rem;
}
.checkout-details .contact .left .make-info h4 a {
  color: #0B2138;
}
.checkout-details .contact .left .make-info .btn-primary {
  background-color: #5B005D;
  width: 286px;
  height: 40px;
  color: #FFFFFF;
  line-height: 40px;
}
.checkout-details .contact .left .make-info .btn-primary.btnmakepayment {
  background-color: #0B2138;
  color: #FFFFFF;
}
.checkout-details .contact .left .make-info .btn-primary:disabled {
  opacity: 40%;
}
.checkout-details .contact .right {
  width: 100%;
  max-width: 310px;
  background-color: #F6F6F6;
  padding: 1rem;
  margin-left: 1.625rem;
  height: 100%;
}
.checkout-details .contact .right.open {
  height: 100vh;
  transition: all 0.5s ease-in-out;
  margin-left: 0;
}
.checkout-details .contact .right.open .summary-data {
  display: inline-block;
}
.checkout-details .contact .right.open .summary-cancle {
  text-align: center;
}
.checkout-details .contact .right h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #5B005D;
}
.checkout-details .contact .right .summary-data .close {
  cursor: pointer;
  position: absolute;
  top: 1.6rem;
  right: 0;
  padding: 12px 16px;
  transform: translate(0%, -50%);
  font-size: 30px;
  display: none;
}
.checkout-details .contact .right .summary-data ._border_bottom {
  padding-bottom: 0.425rem;
  padding-top: 0.5125rem;
}
.checkout-details .contact .right .summary-data ._border_bottom:first-child {
  padding-top: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom:last-child {
  border-bottom: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom h6 {
  font-size: 14px;
  color: #231535;
  font-weight: 400;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex .product_info {
  display: inline-block;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex .product_info h5 {
  font-size: 15px;
  font-weight: 600;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex .product_info h4 {
  font-size: 13px;
  text-transform: capitalize;
  color: #0B2138;
  font-weight: 400;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex .product_info h4 span {
  font-weight: 600;
}
.checkout-details .contact .right .summary-data ._border_bottom .view_all {
  display: inline-block;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #5B005D;
}
.checkout-details .contact .right .summary-data ._border_bottom .view_all span {
  margin-right: 5px;
  font-weight: 600;
  font-size: 0.875rem;
  color: #5B005D;
}
.checkout-details .contact .right .summary-data ._border_bottom .view_all .caret {
  color: #5B005D;
  width: 6px;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body {
  padding: 0.8rem 1.375rem;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details {
  padding: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li {
  border-bottom: 0;
  padding-left: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card {
  width: 100%;
  height: auto;
  min-height: 100px;
  border: 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1215686275);
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body {
  height: auto;
  padding: 1.25rem 1rem;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body .coupon {
  position: relative;
  margin-bottom: 1.5rem;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body .coupon .checkbox {
  display: inline-flex;
  margin-bottom: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body .coupon .checkbox label {
  font-size: 14px;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body .coupon .checkbox label .promo-btn {
  background: #fff;
  border: 1px dashed #5B005D;
  border-radius: 25px;
  color: #231535;
  padding: 0.5rem 1.8rem;
  letter-spacing: 0.5px;
  font-size: 14px;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body .coupon .save {
  position: absolute;
  right: 0;
  top: 6px;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body .coupon .save h6 {
  font-weight: 600;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body p {
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 0;
  margin-left: 1rem;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li .card .card-body p span {
  font-weight: 700;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .product-details li.active .card-body {
  background-color: rgba(59, 0, 58, 0.2588235294);
  border: 1px dashed #5B005D;
  border-radius: 10px;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .cart-footer {
  left: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .cart-footer .total-prize p {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom .cart-list .cart-body .cart-footer .total-prize h5 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex a {
  border-bottom: 1px solid #5B005D;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex .cust-total {
  color: #231535;
  font-weight: 600;
  margin-bottom: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex.save-promo .savepromocode {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  width: 100%;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex.save-promo .savepromocode .withpromo {
  text-decoration: none;
}
.checkout-details .contact .right .summary-data ._border_bottom .custom_flex.save-promo .savepromocode .remove {
  text-decoration: underline;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  top: 1.4rem;
  color: #5B005D;
  cursor: pointer;
}
.checkout-details .contact .right .summary-data ._border_bottom .apply-promo {
  margin-top: 0.875rem;
  display: flex;
}
.checkout-details .contact .right .summary-data ._border_bottom .apply-promo .code {
  border: 1px solid #B6B5B5;
  padding: 11px 13px;
  color: #0B2138;
  width: 210px;
  height: 40px;
  position: relative;
  font-size: 0.875rem;
}
.checkout-details .contact .right .summary-data ._border_bottom .apply-promo .code::-moz-placeholder {
  color: #0B2138;
  font-size: 0.875rem;
}
.checkout-details .contact .right .summary-data ._border_bottom .apply-promo .code::placeholder {
  color: #0B2138;
  font-size: 0.875rem;
}
.checkout-details .contact .right .summary-data ._border_bottom .apply-promo .code:focus-visible {
  outline: none;
}
.checkout-details .contact .right .summary-data ._border_bottom .apply-promo .formMargin {
  width: 75%;
}
.checkout-details .contact .right .summary-data ._border_bottom .apply-promo .code-btn {
  width: 25%;
  background-color: #fff;
  border: 1px solid #B6B5B5;
  color: #5B005D;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 0em 0.7em;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0;
}
.checkout-details .contact .right .summary-data ._border_bottom .apply-promo .msg {
  font-size: 0.8rem;
  font-weight: 700;
  padding-top: 0.6rem;
}
.checkout-details .contact .right .summary-cancle {
  display: none;
}

.checkbox {
  margin-bottom: 1rem;
}
.checkbox.extra-text {
  padding-bottom: 15px;
}
.checkbox .styled-checkbox {
  position: absolute;
  opacity: 0;
}
.checkbox .styled-checkbox + label {
  position: relative;
  cursor: pointer;
  color: #231535;
  font-size: 13px;
  font-weight: 400;
}
.checkbox .styled-checkbox + label p {
  position: absolute;
  top: 0;
  left: 29px;
}
.checkbox .styled-checkbox + label span {
  font-size: 12px;
}
.checkbox .styled-checkbox + label a {
  color: #5B005D;
}
.checkbox .styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid #B6B5B5;
}
.checkbox .styled-checkbox:checked + label:before {
  background: #5B005D;
}
.checkbox .styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #FFFFFF;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #FFFFFF, 4px 0 0 #FFFFFF, 4px -2px 0 #FFFFFF, 4px -4px 0 #FFFFFF, 4px -6px 0 #FFFFFF, 4px -8px 0 #FFFFFF;
  transform: rotate(45deg);
}

.delivery-details h4 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.delivery-details h3 {
  font-size: 20px;
  color: #3B003A;
  font-weight: 800;
}
.delivery-details .form-row {
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: space-between;
}
.delivery-details .form-group {
  margin-bottom: 1rem;
}
.delivery-details .form-group label {
  font-size: 13px;
  font-weight: 400;
}
.delivery-details .form-group .form-control {
  border-radius: 5px;
  border: 1px solid #B6B5B5;
  height: 40px;
  padding-left: 8px;
}
.delivery-details .form-group .form-control::-moz-placeholder {
  font-size: 0.875rem;
  color: #7F7F7F;
}
.delivery-details .form-group .form-control::placeholder {
  font-size: 0.875rem;
  color: #7F7F7F;
}
.delivery-details .form-group .form-control:disabled {
  background-color: #E7E7E7;
}
.delivery-details .form-group .form-select {
  border-radius: 0;
  border: 1px solid #B6B5B5;
  height: 40px;
  font-size: 0.875rem;
  color: #7F7F7F;
  width: 300px;
  background-image: url(../../assets/images/icons/sort-down-solid.svg);
}
.delivery-details .form-group.mobile .cust-select {
  display: flex;
  width: 300px;
}
.delivery-details .form-group.mobile .cust-select .form-select {
  width: 84px;
}
.delivery-details .form-group.mobile .cust-select .form-control {
  width: 210px;
  margin-left: 6px;
}
.delivery-details ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}
.delivery-details ul li {
  font-size: 1rem;
  font-weight: 700;
  color: #2E2E2E;
}
.delivery-details ul li .form-group.other-field .form-control {
  width: 300px !important;
}
.delivery-details ul li h4 {
  margin-right: 1.425rem;
}
.delivery-details ul li .rdio {
  margin-right: 3rem;
}
.delivery-details ul li .rdio.other {
  margin-right: 10px;
}
.delivery-details ul li .rdio label::before {
  border: 1px solid #B3B5B7;
}
.delivery-details.edit-details {
  display: block;
}
.delivery-details .btn {
  text-transform: uppercase;
}
.delivery-details span.md-text {
  float: right;
  margin-top: -3rem;
  color: #847F7F;
  font-size: 1rem;
}
.delivery-details .rdio label:before {
  width: 25px;
  height: 25px;
}
.delivery-details .rdio input[type=radio]:checked + label::after {
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
}
.delivery-details .unsubscribe-mail .form-group {
  width: 100%;
  max-width: 380px;
}

.custompayment {
  padding: 25rem 0 10rem;
  text-align: center;
}

.gst-details {
  margin-top: 2rem;
}
.gst-details h4 span {
  font-weight: 400;
}/*# sourceMappingURL=index.css.map */