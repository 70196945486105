@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';
.checkout-details {    

    h2 {
        text-align: left;
        padding-bottom: 14px;
    }
    .shipping-pickup{
        margin-top: 2.2rem;
    }

    .contact {
        display: flex;
        padding-bottom: 3.125rem;

        h4 {
            font-size: 15px;
            font-weight: 600;
            position: relative;
            span{font-weight: 300;}

            svg{
                margin-right: 6px;font-size: 15px;position: absolute;left: 0;top:0;
            }
        }

        .left {
            width: 100%;
            max-width: 990px;

            .contact-details {
                border: 1px solid $cardcolor;
                padding: 1rem 1rem 1.5rem 1rem;
                border-radius: 5px;

                .spacer {
                    margin-top: 2.2rem;
                }

                .review-add-details {
                    font-size: 14px;font-weight:400;
                    p {font-size: 14px;}
                    h6 {font-size: 12px;font-weight: 700;}
                    ul{
                        padding:0;display:flex;
                        li{
                            list-style-type: none;
                            // padding-right:4rem;
                            width: 225px;
                            a {color:inherit}
                        }
                    }
                    address {font-size: 14px;margin-bottom: 2rem;
                    span{display: block;}
                    }
                }

                .topheading {
                    display: flex;
                    justify-content: space-between;

                    h6 {
                        font-size: 0.75rem;
                    }
                }
                    .form-row {
                        list-style: none;
                        display: flex;
                        padding: 0;
                        justify-content: flex-start;

                        &.billpincode {
                            justify-content: flex-start;

                            li {
                                margin-right: 0.8rem;
                            }
                        }
                        li + li {
                            padding-left: 10px;
                        }

                        .form-group {
                            width: 32.5%;
                            label {
                                font-size: 13px;
                                font-weight: 400;
                                color:#3F3F3F;
                            }

                            .form-control {
                                border-radius: 5px;
                                border: 1px solid #B6B5B5;
                                height: 40px;
                                font-family: "Source Sans Pro", sans-serif;

                                &::placeholder {
                                    font-size: 0.875rem;
                                    color: #7F7F7F;
                                }

                                &:disabled {
                                    // background-color: #E7E7E7;
                                    background-color: #F5F1F1;
                                }
                                &:focus{
                                    box-shadow: none;
                                }
                            }

                            .form-select {
                                border-radius: 5px;
                                border: 1px solid #B6B5B5;
                                height: 40px;
                                font-size: 0.875rem;
                                color: #7F7F7F;
                                width: 300px;
                                background-image: url(../../assets/images/icons/sort-down-solid.svg);
                                font-family: "Source Sans Pro", sans-serif;
                            }

                            &.mobile {
                                .cust-select {
                                    display: flex;

                                    .form-select {
                                        // width: 84px;
                                        width: 75px;
                                    }

                                    .form-control {
                                        margin-right: 6px;
                                    }
                                }

                            }
                        }

                        &.custom-store {
                            justify-content: flex-start !important;
                            position: relative;
                            margin-top: 1rem;

                            li {
                                // margin-right: 0.8rem;

                                a {
                                    width: 300px;

                                    &.btn-outline-primary {
                                        border: 1px solid $maincolor;
                                        height: 40px;
                                        font-weight: 600;
                                        display: flex;justify-content: center;align-items: center;
                                        font-size: 13px;
                                        text-transform: uppercase;
                                        padding: 0;
                                        border-radius: 5px;
                                        font-family: 'Montserrat', sans-serif;
                                        position: relative;
                                        svg{
                                            font-size: 20px;
                                            margin-right: 6px;
                                        }

                                        &.custom-active {
                                            color: #FFF;
                                            background-color: $linkcolor;
                                            border: 1px solid $linkcolor;

                                            &::after {
                                                content: '';
                                                position: absolute;
                                                bottom: -10px;
                                                font-size: 1.5rem;
                                                left: 49%;
                                                border-color: transparent;
                                                border-top-color: $linkcolor;
                                                border-style: solid;
                                                border-width: .45em .39em 0;
                                                width: 0;
                                                height: 0;
                                            }

                                            &:focus {
                                                box-shadow: none;
                                            }
                                        }

                                        &.delivery {
                                            &:active {
                                                color: #FFF;
                                                background-color: $linkcolor;
                                                border: 1px solid #B6B5B5;
                                            }
                                        }

                                        &.pickup-store {
                                            &:active {
                                                color: #FFF;
                                                background-color: $bluecolor;
                                                border: 1px solid #B6B5B5;
                                            }

                                            &.custom-active-btn {
                                                color: #FFF;
                                                background-color: $bluecolor;
                                                border: 1px solid #B6B5B5;

                                                &::after {
                                                    content: '\f0d7';
                                                    position: absolute;
                                                    bottom: -19px;
                                                    font-size: 1.5rem;
                                                    left: 49%;
                                                    color: $bluecolor;
                                                }

                                                &:focus {
                                                    box-shadow: none;
                                                }
                                            }
                                        }
                                    }

                                    i {
                                        font-size: 1rem;
                                        padding-right: 0.5rem;
                                    }
                                }
                            }

                        }

                    }
                
                .btn-secondary.add-address{
                    text-align: center;margin-top: 1rem;
                }
            }

            .pickup-address {
                display: flex;margin-top: 2rem;
                justify-content: space-between;
                margin-bottom: 2rem;
                span {
                    margin-left: 1.5rem;font-size: 15px;
                    font-weight: 400;
                }

                .address-details {
                    width: 100%;
                    max-width: 500px;
                    h4{
                        padding-left: 1.5rem;
                    }
                    .address {
                        ul {
                            padding-left: 1.5rem;

                            li {
                                list-style: none;
                                font-weight: 400;
                                font-size: 15px;
                            }
                        }
                        
                    }

                    .call {
                        margin-top: 1rem;
                    }
                }

                .map {
                    width: 100%;
                    max-width: 500px;
                }

            }
        

            .make-info {
                padding-top: 2.2rem;
                text-align: center;

                h4 {
                    font-size: 0.875rem;
                    font-weight: 400;
                    padding-bottom: 1.313rem;

                    i {
                        color: #0B2138;
                        font-size: 1.25rem;
                    }

                    a {
                        color: #0B2138;
                        // border-bottom: 1px solid #0B2138;
                    }
                }

                .btn-primary {
                    background-color: $linkcolor;
                    width: 286px;
                    height: 40px;
                    color: #FFFFFF;
                    line-height: 40px;

                    &.btnmakepayment {
                        background-color: #0B2138;
                        color: #FFFFFF;
                    }
                    &:disabled{
                        opacity: 40%;
                    }
                }

            }
        }

        .right {
            width: 100%;
            max-width: 310px;
            background-color: #F6F6F6;
            padding: 1rem;
            margin-left: 1.625rem;
            height: 100%;

            &.open {
                height: 100vh;
                transition: $DefaultTransition;
                margin-left: 0;

                .summary-data {
                    display: inline-block;
                }

                .summary-cancle {
                    text-align: center;
                }
            }

            h4 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;
                color:$linkcolor;
            }

            .summary-data {
                .close {
                    cursor: pointer;
                    position: absolute;
                    top: 1.6rem;
                    right: 0;
                    padding: 12px 16px;
                    transform: translate(0%, -50%);
                    font-size: 30px;
                    display: none;
                }

                ._border_bottom {
                    padding-bottom: 0.425rem;
                    padding-top: 0.5125rem;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        border-bottom: 0;
                    }

                    h6 {
                        font-size: 14px;
                        color: #231535;
                        font-weight: 400;
                    }


                    .custom_flex {
                        display: flex;
                        justify-content: space-between;
                        position: relative;

                        .product_info {
                            display: inline-block;
                            h5{
                                font-size: 15px;font-weight: 600;
                            }
                            h4 {
                                font-size: 13px;
                                text-transform: capitalize;
                                color: #0B2138;
                                font-weight: 400;

                                span {
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    .view_all {
                        display: inline-block;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        border-bottom: 1px solid $linkcolor;

                        span{margin-right: 5px;font-weight: 600;font-size: 0.875rem;color: $linkcolor;}

                        .caret {
                            color: $linkcolor;
                            width: 6px;
                        }
                    }

                    .cart-list{
                        .cart-body{
                            padding: 0.8rem 1.375rem;
                            .product-details{
                                padding: 0;
                                li{
                                    border-bottom: 0;
                                    padding-left: 0;
                                    .card{
                                        width: 100%;
                                        height: auto;
                                        min-height: 100px;
                                        border: 0;
                                        box-shadow: 2px 2px 10px #0000001f;

                                        .card-body{
                                            height: auto;
                                            padding: 1.25rem 1rem;
                                            .coupon{
                                                position: relative;
                                                margin-bottom: 1.5rem;
                                                .checkbox{
                                                    display: inline-flex;
                                                    margin-bottom: 0;
                                                    label{font-size: 14px;
                                                        .promo-btn{
                                                            background: #fff;
                                                            border: 1px dashed $linkcolor;
                                                            border-radius: 25px;
                                                            color: #231535;
                                                            padding: .5rem 1.8rem;
                                                            letter-spacing: 0.5px;
                                                            font-size: 14px;
                                                        }
                                                    }
                                                }
                                                .save{
                                                    position: absolute;
                                                    right: 0;
                                                    top: 6px;
                                                    h6{font-weight: 600;}
                                                }
                                            }
                                            p{font-size: 12px;line-height: 17px;margin-bottom: 0;margin-left: 1rem;
                                            span{font-weight: 700;}
                                            }
                                        }
                                        
                                    }
                                    &.active{
                                        .card-body{background-color: #3b003a42;border: 1px dashed #5B005D;border-radius: 10px;}
                                    }
                                }
                            }
                            .cart-footer{
                                left: 0;
                                .total-prize{
                                    p{font-size: 15px;font-weight: 600;margin-bottom: 0;}
                                    h5{font-size: 1.25rem;font-weight: 600;line-height: 28px;margin-bottom: 0;}
                                }
                            }
                        }
                    }

                    // .down-arrow {
                    //     display: none;
                    //     font-size: 0.875rem;
                    //     color: $textcolor;
                        // display: inline-block;

                    //     .caret {
                    //         color: $linkcolor;
                    //     }
                    // }

                    .custom_flex {
                        a {
                            // color: #0B2138;
                            // font-weight: 700;
                            // text-transform: uppercase;
                            // cursor: pointer;
                            // font-size: 14px;
                            border-bottom: 1px solid $linkcolor;
                        }

                        .cust-total {
                            color: $textcolor;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        &.save-promo {
                            // display: none;

                            .savepromocode {
                                display: flex;
                                justify-content: space-between;
                                padding-bottom: 1rem;
                                width: 100%;

                                .withpromo {
                                    text-decoration: none;
                                }

                                .remove {
                                    text-decoration: underline;
                                    text-transform: capitalize;
                                    font-size: 14px;
                                    font-weight: 600;
                                    position: absolute;
                                    bottom: 0;
                                    top: 1.4rem;
                                    color: $linkcolor;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .apply-promo {
                        // display: none;
                        margin-top: 0.875rem;
                        display: flex;

                        .code {
                            border: 1px solid #B6B5B5;
                            padding: 11px 13px;
                            color: #0B2138;
                            width: 210px;
                            height: 40px;
                            position: relative;
                            font-size: 0.875rem;

                            &::placeholder {
                                color: #0B2138;
                                font-size: 0.875rem;
                            }

                            &:focus-visible {
                                outline: none;
                            }
                        }
                        
                        .formMargin{width: 75%;}

                        .code-btn {
                            // height: 40px;
                            // background-color: #ffff;
                            // border: 1px solid $textcolor;
                            // color: $textcolor;
                            // font-weight: 600;
                            // position: absolute;
                            // right: 8%;
                            // text-transform: uppercase;
                            // font-size: 0.875rem;
                            // padding: 0em 0.7em;
                            // width: 70px;
                            width: 25%;
                            background-color: #fff;
                            border: 1px solid #B6B5B5;
                            color: $linkcolor;
                            font-weight: 600;
                            text-transform: uppercase;
                            font-size: 0.875rem;
                            padding: 0em 0.7em;
                            border-radius: 0;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px;
                            border-left: 0;
                        }

                        .msg {
                            font-size: .8rem;
                            font-weight: 700;
                            padding-top: 0.6rem;
                        }
                    }
                }
            }

            .summary-cancle {
                display: none;
            }
        }
    }
}

.checkbox {
    margin-bottom: 1rem;
    &.extra-text {
        padding-bottom: 15px;
    }

    .styled-checkbox {
        position: absolute;
        opacity: 0;

        &+label {
            position: relative;
            cursor: pointer;
            color: $textcolor;
            font-size: 13px;
            font-weight: 400;

            p {
                position: absolute;
                top: 0;
                left: 29px;
            }

            span {
                font-size: 12px;
            }

            a {
                color: $linkcolor;
            }

           
        }

        // Box.
        &+label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: transparent;
            border: 1px solid #B6B5B5;
        }

         &:checked+label:before {
                background: $linkcolor;
            }
    
            // Checkmark. Could be replaced with an image
            &:checked+label:after {
                content: '';
                position: absolute;
                left: 5px;
                top: 9px;
                background: #FFFFFF;
                width: 2px;
                height: 2px;
                box-shadow: 2px 0 0 #FFFFFF, 4px 0 0 #FFFFFF, 4px -2px 0 #FFFFFF, 4px -4px 0 #FFFFFF, 4px -6px 0 #FFFFFF, 4px -8px 0 #FFFFFF;
                transform: rotate(45deg);
            }
    }

        

        

        // Box hover
        // &:hover+label:before {
        //     background: #0B2138;
        // }

        // Box checked
    
}

// Delivery Details
.delivery-details {
    

    h4 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: 20px;
        color: $maincolor;
        font-weight: 800;
    }

    .form-row {
        list-style: none;
        display: flex;
        padding: 0;
        justify-content: space-between;
    }

    .form-group {
        margin-bottom: 1rem;
        label {
            font-size: 13px;
            font-weight: 400;
        }

        .form-control {
            border-radius: 5px;
            border: 1px solid #B6B5B5;
            height: 40px;
            padding-left: 8px;
            &::placeholder {
                font-size: 0.875rem;
                color: #7F7F7F;
            }

            &:disabled {
                background-color: #E7E7E7;
            }
        }

        .form-select {
            border-radius: 0;
            border: 1px solid #B6B5B5;
            height: 40px;
            font-size: 0.875rem;
            color: #7F7F7F;
            width: 300px;
            background-image: url(../../assets/images/icons/sort-down-solid.svg);
        }

        &.mobile {
            .cust-select {
                display: flex;
                width: 300px;

                .form-select {
                    width: 84px;
                }

                .form-control {
                    width: 210px;
                    margin-left: 6px;
                }
            }

        }
    }

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;

        li {
            font-size: 1rem;
            font-weight: 700;
            color: #2E2E2E;

            .form-group.other-field {
               

                .form-control {
                    width: 300px !important;
                }
            }

            h4 {
                margin-right: 1.425rem;
            }

            .rdio {
                margin-right: 3rem;
            &.other{
                margin-right: 10px;
            }
                label {
                    &::before {
                        border: 1px solid #B3B5B7;
                    }
                }
            }
           
        }
    }

    &.edit-details {
        display: block;
    }

    .btn {
        // width: 112px;
        // margin: 1rem 1rem 0 0;
        text-transform: uppercase;
    }

    span.md-text {
        float: right;
        margin-top: -3rem;
        color: #847F7F;
        font-size: 1rem;
    }

    .rdio label:before {
        width: 25px;
        height: 25px;
    }

    .rdio input[type=radio]:checked+label::after {
        top: 5px;
        left: 5px;
        width: 15px;
        height: 15px;
    }

    .unsubscribe-mail {
        .form-group{width: 100%;max-width: 380px;}
    }
}

.custompayment{
    padding:25rem 0 10rem;
    text-align: center;
}
// .payment header {
//     display: none;
// }
// .payment footer {
//     display: none;
// }

.gst-details {
    margin-top: 2rem;
    h4{
        span{
            font-weight: 400;
        }
    }
}

