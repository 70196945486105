@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

.testimonial {
    margin: 0rem 0 5rem;
    .item{
        display: flex;justify-content: center;align-items: center;
    }

    .testi-icon {
        svg {
            color: $maincolor;
            font-size: 26px;
            margin:1.5rem 0;
        }
    }

    p {
        @include font(20px, 600);
        margin-bottom: 0;align-items:center;
        &.content {
            @include font(20px, 300);
            width: 668px;
        }

        &.title {
            @include font(16px, 600);
        }
        &.client-location{
            @include font(14px, 400);
        }
    }

    .testimonial-carousel {
        padding-bottom: 2.5rem;
    }
    .img-wrapper {
        position: relative;margin-top: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        figure{
            height: 90px;width: 90px;
            img{
                width: 100%;height: 100%;border-radius: 50px;
            }
        }
        figcaption{
            text-align: left;padding-left: 10px;
        }
       
    }

    .owl-carousel button.owl-dot {
        background: $maincolor;
        border: 2px solid $maincolor;
        @include owl-dot;
    }

    .owl-dots {
        bottom: -16px;
    }

    .inner-wrapper {
        padding: 0 5rem;
    }
    .slick-dots li button:before { content:none;}
    .slick-dots li button {
        content:none;width: 16px;height: 16px;border-radius: 50px;
        color:$linkcolor;opacity: 1;border:2px solid $linkcolor;background: $linkcolor;
    }
    .slick-dots li.slick-active button {
        background: transparent;
    }
    .slick-dots {
        bottom: -40px;
        li{
            margin:0 2px;
        }
    }
}