@import '../../../assets/Scss/variable';

.slick-arrow {
    position: absolute;
    z-index: 8;
    width:45px;height: 45px;border-radius: 30px;background: #fff;box-shadow: 0 0px 20px rgba(0, 0, 0, 0.15);
    &::before {content:'';
        background: url('/src/assets/images/icons/prevArrow.png');
        width: 20px;
        height: 18px;
        position: absolute;
        top:50%;
        left:50%;transform: translate(-50%,-50%);
    
    }
    &.slick-prev {
       
        left:0;
        &::before {content:'';
            background: url('/src/assets/images/icons/prevArrow.png');
        }
    }
    &.slick-next {
        right:0;
        &::before {content:'';
            background: url('/src/assets/images/icons/nextArrow.png');
        }
    }
    &:hover {background: $maincolor;
    &:before{
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(142deg) brightness(110%) contrast(101%);
    }
    }
}
.slick-slide {height:auto;
    & figure {margin:0;
        & img {
            margin: auto;
            width: 100%;
            height: 500px;
            object-fit: cover;
        }
    }
    
}
.bannerTxt {position: absolute;z-index: 9;top:50%;transform: translateY(-50%);
    h3 {font-family: 'Bahnschrift';color:#FFC46E;text-transform: uppercase;font-size: $font20;font-weight: 200;margin-bottom: 1rem;}
    h2{font-family: 'Bahnschrift';color:$whitecolor;font-size: 40px;font-weight: 200;letter-spacing: -1px;text-transform: uppercase;
        margin-bottom: 1rem;}
    .btn-primary {
        span {font-weight: 800;}
    }
}

.typ2 {
    .slick-slider {
        margin-left:-0px;
        .slick-slide {
            margin-left:0px;
          }
        .slick-arrow  {
            box-shadow: 0px 0px 20px rgba(0,0,0,0.20);
            &.slick-prev {
                left:-22px;top:45%;
            }
            &.slick-next {
                right:-10px;top:45%;
            }
        }
        .slick-slide {height:auto;
            figure {
                margin:0;height:242px;width:242px !important;cursor: pointer;overflow:hidden;border-radius: 10px;
                img {width: 100%;height: 100%;border-radius:10px;transition: all .5s ease; &:hover{transform: scale(1.1);}}
                h3 {font-size: 1.25rem;font-weight: 400;margin-top:10px;}
            }
        }
    }
}

.home {
    .bannerTxt h2 {margin-bottom: 1rem;}
    .imgslider{.slick-slider{.slick-dots{bottom: 35px;
        li{
                button{&::before{    font-size: 12px;
                    border: 1px solid #5B005D;
                    border-radius: 10px;
                    color: #5b005d5c;
                    opacity: 1;
                    background: #fff;}}
                    &.slick-active{
                        button{
                            &::before{opacity: 1; color: #5B005D;}
                        }
                    }
        }
    }}}

}

.singleimgslider{
    figure{
        position: relative;
        h3{
            position: absolute;
            bottom: 50px;
            font-size: 40px;
            left:50%;
            transform: translateX(-50%);
            color:#FFF;
            font-family: 'Bahnschrift';
        }
    }
}