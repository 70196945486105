.backBtnToTab {
  position: absolute !important;
  top: 0;
  right: 0;
}

table {
  border: 1px solid #ccc;
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}
table tr {
  padding: 5px;
}
table th,
table td {
  padding: 10px;
  text-align: center;
}
table th {
  border: 1px solid #DBDBDB;
  background-color: #EFEFEF;
  color: #0B2138;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 500;
}
table td {
  border: 1px solid #DBDBDB;
  padding: 10px 0 0;
}

.mainWapper .breadcrumbs {
  margin-top: 20px;
}
.mainWapper .breadcrumbs .breadcrumb {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
}
.mainWapper ul {
  display: flex;
  list-style: none;
  padding-left: 0;
}
.mainWapper .userdetails {
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  margin: 1.5rem 0rem;
}
.mainWapper .userdetails .uploadImg {
  position: relative;
  border-radius: 50%;
}
.mainWapper .userdetails .uploadImg img {
  border-radius: 50px;
}
.mainWapper .userdetails .uploadImg .editImg {
  width: 28px;
  height: 28px;
  border-radius: 50px;
  position: absolute;
  bottom: -4px;
  left: 23px;
  /* opacity: 0; */
  z-index: 2;
  cursor: pointer;
}
.mainWapper .userdetails .uploadImg .editImg::after {
  content: "";
  background: url("../../../assets/images/myaccount/user-edit.svg") no-repeat;
  background-size: 100%;
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.mainWapper .userdetails .uploadImg .dltImg {
  content: "";
  background: url("../../../assets/images/myaccount/userdelete.svg") no-repeat;
  background-size: 100%;
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: -4px;
  right: 15px;
  cursor: pointer;
}
.mainWapper .userdetails .userName {
  padding-left: 25px;
  font-weight: 600;
  color: #5B005D;
  font-size: 1.875rem;
}
.mainWapper .userdetails .userName span {
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #5d5d5d;
}
.mainWapper .verticalTab {
  position: relative;
}
.mainWapper .verticalTab .tabs {
  position: absolute;
  left: 0;
  background: white;
}
.mainWapper .verticalTab .contentWapper {
  margin-left: 60px;
  width: 100%;
  position: relative;
}
.mainWapper .verticalTab .contentWapper .activeContent {
  display: block;
  width: 90%;
}
.mainWapper .RRT__container {
  margin: 15px 0 40px;
  box-shadow: 1px 1px 20px -16px #231535;
  background: #FFFFFF;
  height: 100%;
  padding: 20px;
  min-height: 600px;
}
.mainWapper .RRT__container .RRT__tabs {
  flex-direction: column;
  height: 97%;
  border-right: 2px solid #5B005D;
  width: 57px;
  background: #fff;
  z-index: 9;
  position: absolute;
  transition: all 0.3s ease-in;
  overflow: hidden;
}
.mainWapper .RRT__container .RRT__tabs .RRT__tab--first div img {
  filter: none !important;
}
.mainWapper .RRT__container .RRT__tabs .RRT__tab {
  width: 100%;
  padding: 0;
  border: none;
  padding-right: 10px;
  background: none;
  cursor: pointer;
}
.mainWapper .RRT__container .RRT__tabs .RRT__tab div {
  padding: 0.7em 0.78em;
  border: 1px solid #DCDCDC;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.mainWapper .RRT__container .RRT__tabs .RRT__tab div span {
  font-weight: 600;
  text-transform: capitalize;
  padding-left: 40px;
  position: absolute;
  width: 160px;
  display: block;
  transition: all 0.5s ease;
  left: -100px;
  font-size: 15px;
  opacity: 0;
  color: #5B005D;
}
.mainWapper .RRT__container .RRT__tabs .RRT__tab--selected {
  padding: 0;
  color: #fff;
}
.mainWapper .RRT__container .RRT__tabs .RRT__tab--selected div {
  color: #fff;
  border-right: none;
  background-color: #3B003A;
  border: 1px solid #3B003A;
}
.mainWapper .RRT__container .RRT__tabs .RRT__tab--selected div span {
  color: #fff;
  font-weight: 700;
}
.mainWapper .RRT__container .RRT__tabs .RRT__tab--selected div img {
  filter: invert(89%) sepia(89%) saturate(1%) hue-rotate(188deg) brightness(4) contrast(97%);
}
.mainWapper .RRT__container .RRT__tabs:hover {
  width: 218px;
  transition: all 0.5s ease-in;
}
.mainWapper .RRT__container .RRT__tabs:hover div span {
  opacity: 1;
  left: 0px;
}
.mainWapper .RRT__container .RRT__panel {
  margin-left: 60px;
  position: relative;
  top: 0;
  width: 95%;
  border: none;
  padding-top: 0.5rem;
}
.mainWapper .RRT__container .RRT__panel h5 {
  font-size: 2rem;
  font-weight: 600;
  color: #5B005D;
  margin-bottom: 1rem;
}
.mainWapper .RRT__container .RRT__panel .btn-cancel {
  width: 92px;
  padding: 0;
}

.myOrderTab .tab-list,
.orderDetailSec .tab-list {
  height: 60px;
  display: flex;
  justify-content: flex-start;
  list-style: none;
  font-size: 18px;
  margin: 0;
  border-bottom: 1px solid #DBDBDB;
  padding-left: 0;
}
.myOrderTab .tab-list .tabs,
.orderDetailSec .tab-list .tabs {
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #231535;
}
.myOrderTab .tab-list .tabs .tabs:not(:last-child),
.orderDetailSec .tab-list .tabs .tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.myOrderTab .tab-list .active-tabs,
.orderDetailSec .tab-list .active-tabs {
  width: 175px;
  justify-content: center;
  display: flex;
  font-size: 1rem;
  height: 100%;
  align-items: center;
  color: #5B005D;
  position: relative;
  font-weight: 600;
}
.myOrderTab .tab-list .active-tabs::before,
.orderDetailSec .tab-list .active-tabs::before {
  content: "";
  border-bottom: 3px solid;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.myOrderTab .content-container .content,
.orderDetailSec .content-container .content {
  background-color: #eeeded;
  display: none;
  padding: 10px;
}
.myOrderTab .content-container .active-content .filterMonth,
.orderDetailSec .content-container .active-content .filterMonth {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
}
.myOrderTab .content-container .active-content .filterMonth label,
.orderDetailSec .content-container .active-content .filterMonth label {
  font-size: 14px;
  font-weight: 400;
  color: #231535;
  line-height: 24px;
}
.myOrderTab .content-container .active-content .filterMonth label span,
.orderDetailSec .content-container .active-content .filterMonth label span {
  font-weight: 700;
}
.myOrderTab .content-container .filterSection,
.orderDetailSec .content-container .filterSection {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
}
.myOrderTab .content-container .filterSection ul li,
.orderDetailSec .content-container .filterSection ul li {
  width: 226px;
  background: #FFFFFF;
  list-style: none;
  margin: 0rem 1rem;
  border: 1px solid #D8D8D8;
  position: relative;
  display: flex;
  align-items: center;
}
.myOrderTab .content-container .filterSection ul li input,
.orderDetailSec .content-container .filterSection ul li input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: #232A35;
  padding: 10px;
  border-color: #D8D8D8;
}
.myOrderTab .content-container .filterSection ul li input ::-moz-placeholder, .orderDetailSec .content-container .filterSection ul li input ::-moz-placeholder {
  font-size: 14px;
}
.myOrderTab .content-container .filterSection ul li input ::placeholder,
.orderDetailSec .content-container .filterSection ul li input ::placeholder {
  font-size: 14px;
}
.myOrderTab .content-container .filterSection ul li:first-child,
.orderDetailSec .content-container .filterSection ul li:first-child {
  width: auto;
  border: none;
}
.myOrderTab .content-container .filterSection ul li:last-child,
.orderDetailSec .content-container .filterSection ul li:last-child {
  width: 287px;
}
.myOrderTab .content-container .filterSection ul li svg,
.orderDetailSec .content-container .filterSection ul li svg {
  width: 30px;
}
.myOrderTab .tableSec table thead tr th:first-child,
.orderDetailSec .tableSec table thead tr th:first-child {
  text-align: left;
}
.myOrderTab .tableSec table tbody tr td svg,
.orderDetailSec .tableSec table tbody tr td svg {
  color: #5B005D;
}
.myOrderTab .tableSec table tbody tr td p,
.orderDetailSec .tableSec table tbody tr td p {
  font-size: 1rem;
  color: #231535;
  font-weight: 400;
  text-align: left;
  padding-left: 10px;
  padding-top: 15px;
}
.myOrderTab .tableSec table tbody tr td p span,
.orderDetailSec .tableSec table tbody tr td p span {
  font-size: 0.7rem;
  color: #847F7F;
  padding-top: 5px;
  font-weight: 500;
}
.myOrderTab .tableSec table tbody tr td ul,
.orderDetailSec .tableSec table tbody tr td ul {
  flex-direction: column;
  padding-left: 10px;
}
.myOrderTab .tableSec table tbody tr td ul li,
.orderDetailSec .tableSec table tbody tr td ul li {
  display: flex;
  align-items: center;
}
.myOrderTab .tableSec table tbody tr td ul li span,
.orderDetailSec .tableSec table tbody tr td ul li span {
  color: #FF0000;
  font-size: 0.75rem;
  font-weight: 600;
  position: relative;
  top: 6px;
}
.myOrderTab .tableSec table tbody tr td ul li img,
.orderDetailSec .tableSec table tbody tr td ul li img {
  width: 60px;
  height: 60px;
}
.myOrderTab .tableSec table tbody tr td ul li p span,
.orderDetailSec .tableSec table tbody tr td ul li p span {
  font-size: 0.7rem;
  color: #847F7F;
  text-transform: none;
  font-weight: 500;
  padding-top: 0;
}
.myOrderTab .tableSec table tbody tr td ul li button,
.orderDetailSec .tableSec table tbody tr td ul li button {
  border: 1px solid #5B005D;
  padding: 7px 5px;
  width: 115px;
  background: transparent;
  color: #5B005D;
  font-size: 0.75rem;
  margin: 5px 0;
  font-weight: 500;
  outline: none;
}
.myOrderTab .tableSec table tbody tr td:first-child ul li:first-child p,
.orderDetailSec .tableSec table tbody tr td:first-child ul li:first-child p {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}
.myOrderTab .tableSec table tbody tr td:nth-child(2) p, .myOrderTab .tableSec table tbody tr td:last-child p,
.orderDetailSec .tableSec table tbody tr td:nth-child(2) p,
.orderDetailSec .tableSec table tbody tr td:last-child p {
  display: flex;
  flex-direction: column;
}
.myOrderTab .tableSec table tbody tr td:first-child,
.orderDetailSec .tableSec table tbody tr td:first-child {
  width: 55%;
}
.myOrderTab .tableSec table tbody tr:last-child td:last-child ul li span,
.orderDetailSec .tableSec table tbody tr:last-child td:last-child ul li span {
  padding-bottom: 10px;
}

.orderDetailSec {
  position: relative;
}
.orderDetailSec .orderlabel {
  padding: 9px 15px;
  border: 1px solid rgba(159, 159, 159, 0.431372549);
  border-radius: 5px;
  background: #fefcff;
  margin-top: 1.5rem;
}
.orderDetailSec .orderlabel table {
  padding: 10px;
  border: none;
  background: none;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  text-align: left;
}
.orderDetailSec .orderlabel table th {
  width: 120px;
  font-weight: 500;
  border: none;
  background: none;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  text-align: left;
}
.orderDetailSec .orderlabel table th:nth-child(4) {
  width: 150px;
}
.orderDetailSec .orderlabel table th:last-child {
  text-align: right;
}
.orderDetailSec .orderlabel table th b {
  font-weight: 600;
}
.orderDetailSec .orderlabel table td {
  padding-top: 5px;
  border: none;
  background: none;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  text-align: left;
}
.orderDetailSec .orderlabel table td:last-child ul {
  justify-content: end;
  margin-bottom: 0;
}
.orderDetailSec .orderlabel table td span,
.orderDetailSec .orderlabel table td a {
  font-weight: 600;
  line-height: 20px;
  color: #231535;
}
.orderDetailSec .orderlabel table td ul li button {
  color: #5B005D;
  font-size: 0.75rem;
  font-weight: bold;
  padding-right: 10px;
  background: transparent;
  border: none;
  text-transform: uppercase;
}
.orderDetailSec .orderlabel table td ul li:last-child button {
  padding: 0;
}
.orderDetailSec .orderlabel table td:nth-child(4) span {
  display: flex;
  position: relative;
}
.orderDetailSec .orderlabel table td:nth-child(4) span .tooltipWapper {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 30px;
}
.orderDetailSec .orderlabel table td:nth-child(4) span .tooltipInfo {
  top: 125%;
  left: 51%;
  transform: translate(-52%);
  z-index: 9999;
}
.orderDetailSec .orderlabel table td:nth-child(4) span.tooltip-arrowInfo {
  position: absolute;
}
.orderDetailSec .addresSec {
  display: flex;
  justify-content: space-between;
  padding: 25px 16px;
}
.orderDetailSec .addresSec .shippingAdd,
.orderDetailSec .addresSec .orderSummary {
  width: 50%;
}
.orderDetailSec .addresSec .shippingAdd h6 {
  font-size: 1rem;
  color: #231535;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 0.5rem;
}
.orderDetailSec .addresSec .shippingAdd caption {
  width: 288px;
  height: auto;
  padding-top: 0;
}
.orderDetailSec .addresSec .orderSummary {
  display: flex;
  justify-content: flex-end;
}
.orderDetailSec .addresSec .orderSummary .subSection {
  width: 288px;
}
.orderDetailSec .addresSec .orderSummary .subSection h6 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.orderDetailSec .addresSec .orderSummary .subSection > h6 {
  margin-bottom: 0.5rem;
}
.orderDetailSec .addresSec .orderSummary .subSection ul {
  flex-direction: column;
  margin-bottom: 0;
}
.orderDetailSec .addresSec .orderSummary .subSection ul li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.orderDetailSec .addresSec .orderSummary .subSection ul li span {
  font-size: 0.875rem;
  color: #231535;
  font-weight: 500;
}
.orderDetailSec .addresSec .orderSummary .subSection ul li span b {
  font-weight: 600;
}
.orderDetailSec .addresSec .orderSummary .subSection ul li:last-child span b {
  font-size: 1rem;
  font-weight: bold;
}
.orderDetailSec .tableContent {
  border: 1px solid #DCDDDD;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 20px;
  background: #FEFFF9;
}
.orderDetailSec .tableContent table,
.orderDetailSec .tableContent thead,
.orderDetailSec .tableContent th,
.orderDetailSec .tableContent tbody,
.orderDetailSec .tableContent tr,
.orderDetailSec .tableContent td {
  background: #FEFFF9;
  border: none;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 500;
  vertical-align: text-bottom;
}
.orderDetailSec .tableContent table thead {
  border-bottom: 1px solid #DBDBDB;
}
.orderDetailSec .tableContent table thead th {
  font-weight: 600;
  font-size: 0.875rem;
  color: #7F7F7C;
}
.orderDetailSec .tableContent table thead th:nth-child(2) {
  text-align: center;
}
.orderDetailSec .tableContent table tbody tr {
  border-bottom: 1px solid #DBDBDB;
}
.orderDetailSec .tableContent table tbody tr:last-child {
  border-bottom: none;
}
.orderDetailSec .tableContent table tbody tr td {
  padding: 20px 10px;
}
.orderDetailSec .tableContent table tbody tr td:nth-child(2) {
  text-align: center;
}
.orderDetailSec .tableContent table tbody tr td ul li img {
  width: 60px;
  height: 60px;
}
.orderDetailSec .tableContent table tbody tr td ul li h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0.25rem;
}
.orderDetailSec .tableContent table tbody tr td ul li p {
  font-size: 14px;
  color: #5D5D5D;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.orderDetailSec .tableContent table tbody tr td ul li:last-child {
  margin-left: 20px;
  height: 97px;
  position: relative;
}
.orderDetailSec .tableContent table tbody tr td ul li:last-child button {
  background-color: #fff;
}
.orderDetailSec .tableContent table tbody tr td ul li:last-child button:hover {
  background-color: #5B005D;
}
.orderDetailSec .tableContent table tbody tr td ul li span {
  color: #fff;
}
.orderDetailSec .tableContent table tbody tr td span {
  font-size: 12px;
  font-weight: 600;
}
.orderDetailSec .tableContent table tbody tr td .orderStatus {
  font-size: 0.875rem;
  font-weight: 600;
  color: #D69601;
  position: relative;
  padding-left: 13px;
}
.orderDetailSec .tableContent table tbody tr td .orderStatus::before {
  content: "";
  background: #D69601;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0px;
  top: 6px;
  border-radius: 50px;
}
.orderDetailSec .tableContent table tbody tr td .modeDelivered,
.orderDetailSec .tableContent table tbody tr td .modeReplacementOrder,
.orderDetailSec .tableContent table tbody tr td .modeReturnOrder,
.orderDetailSec .tableContent table tbody tr td .modeCancelorder {
  font-size: 0.875rem;
  font-weight: 600;
  color: #00662F;
  position: relative;
  padding-left: 10px;
}
.orderDetailSec .tableContent table tbody tr td .modeDelivered span,
.orderDetailSec .tableContent table tbody tr td .modeReplacementOrder span,
.orderDetailSec .tableContent table tbody tr td .modeReturnOrder span,
.orderDetailSec .tableContent table tbody tr td .modeCancelorder span {
  font-size: 0.813rem;
  font-weight: 500;
  display: block;
}
.orderDetailSec .tableContent table tbody tr td .modeDelivered::before,
.orderDetailSec .tableContent table tbody tr td .modeReplacementOrder::before,
.orderDetailSec .tableContent table tbody tr td .modeReturnOrder::before,
.orderDetailSec .tableContent table tbody tr td .modeCancelorder::before {
  content: "";
  background: #00662F;
  width: 8px;
  height: 8px;
  position: absolute;
  left: -1px;
  top: 6px;
  border-radius: 50px;
}
.orderDetailSec .tableContent table tbody tr td .modeReplacementOrder {
  color: #D69601;
}
.orderDetailSec .tableContent table tbody tr td .modeReplacementOrder span {
  color: #D69601;
}
.orderDetailSec .tableContent table tbody tr td .modeReplacementOrder::before {
  content: "";
  background: #D69601;
}
.orderDetailSec .tableContent table tbody tr td .modeReturnOrder {
  color: #DA0202;
}
.orderDetailSec .tableContent table tbody tr td .modeReturnOrder span {
  color: #DA0202;
}
.orderDetailSec .tableContent table tbody tr td .modeReturnOrder::before {
  content: "";
  background: #DA0202;
}
.orderDetailSec .tableContent table tbody tr td .modeCancelorder {
  color: #DA0202;
}
.orderDetailSec .tableContent table tbody tr td .modeCancelorder span {
  color: #DA0202;
}
.orderDetailSec .tableContent table tbody tr td .modeCancelorder span:last-child {
  color: #231535;
}
.orderDetailSec .tableContent table tbody tr td .modeCancelorder b {
  font-size: 0.875rem;
  font-weight: 600;
  color: #231535;
}
.orderDetailSec .tableContent table tbody tr td .modeCancelorder::before {
  display: none;
}
.orderDetailSec .tableContent table tbody tr td .modeInProgress {
  font-size: 0.875rem;
  font-weight: 600;
  color: #D69601;
  position: relative;
  padding-left: 10px;
}
.orderDetailSec .tableContent table tbody tr td .modeInProgress::before {
  content: "";
  background: #D69601;
  width: 8px;
  height: 8px;
  position: absolute;
  left: -1px;
  top: 6px;
  border-radius: 50px;
}
.orderDetailSec .tableContent table tbody tr td:last-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.orderDetailSec .tableContent table tbody tr td:last-child button {
  padding-bottom: 9px;
  background: transparent;
  border: none;
  text-transform: uppercase;
}
.orderDetailSec .tableContent table tbody tr td:last-child button span {
  position: relative;
  color: #5B005D;
}
.orderDetailSec .tableContent table tbody tr td:last-child button span::before {
  content: "";
  background: url("../../../assets/images/myaccount/caretDownSolid.svg") center center no-repeat;
  background-size: 100%;
  position: absolute;
  left: -10px;
  top: 3px;
  width: 6px;
  height: 10px;
  transform: rotate3d(0, 0, 10, 270deg);
}

.filterMonth .formMargin {
  display: flex;
  align-items: center;
}
.filterMonth .formMargin .input-lable {
  font-size: 0.875rem !important;
  text-transform: math-auto;
  width: auto;
  display: block;
  color: #231535 !important;
}
.filterMonth .formMargin .input-lable span {
  font-weight: 600;
}
.filterMonth .formMargin .formRe {
  width: 226px;
}
.filterMonth .formMargin .formRe select {
  border: 1px solid #D8D8D8;
  border-radius: 5px;
  width: 226px;
  height: 40px;
  margin-left: 10px;
}

.myacc_accordion .accordion {
  margin-bottom: 21px;
  --bs-accordion-border-color: none;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button {
  padding: 0;
  border: 1px solid rgba(159, 159, 159, 0.431372549);
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  background: #fefcff;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button::after {
  content: "";
  background: url("../../../assets/images/myaccount/purple-caret-down-solid.svg") center center no-repeat;
  background-size: 75%;
  width: 20px;
  height: 20px;
  margin-left: 0;
  margin-top: 0.5rem;
  margin-bottom: auto;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "";
  background: url("../../../assets/images/myaccount/purple-caret-down-solid.svg") center center no-repeat;
  background-size: 75%;
  width: 20px;
  height: 20px;
  margin-left: 0;
  margin-top: 0.5rem;
  margin-bottom: auto;
  transform-origin: center;
  transform: rotateX(180deg);
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button.collapsed {
  border-radius: 5px;
  border: 1px solid rgba(159, 159, 159, 0.431372549);
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading {
  width: 97%;
  padding: 9px 15px;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table {
  padding: 10px;
  border: none;
  background: none;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  text-align: left;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th {
  width: 120px;
  font-weight: 500;
  border: none;
  background: none;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  text-align: left;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th:nth-child(4) {
  width: 150px;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th:last-child {
  text-align: right;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th b {
  font-weight: 600;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td {
  padding-top: 5px;
  border: none;
  background: none;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  text-align: left;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:last-child ul {
  justify-content: end;
  margin-bottom: 0;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td span,
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td a {
  font-weight: 600;
  line-height: 20px;
  color: #231535;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td ul li button {
  color: #5B005D;
  font-size: 0.75rem;
  font-weight: bold;
  padding-right: 10px;
  background: transparent;
  border: none;
  text-transform: uppercase;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td ul li:last-child button {
  padding: 0;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:nth-child(4) span {
  display: flex;
  position: relative;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:nth-child(4) span .tooltipWapper {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 30px;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:nth-child(4) span .tooltipInfo {
  top: 125%;
  left: 51%;
  transform: translate(-52%);
  z-index: 9999;
}
.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:nth-child(4) span.tooltip-arrowInfo {
  position: absolute;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent {
  border: 1px solid #DCDDDD;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 20px 20px 0;
  background: #FEFFF9;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent thead,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent th,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent tbody,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent td,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent tr {
  background-color: transparent;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table {
  border: none;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table thead tr {
  border: none;
  border-bottom: 1px solid #DBDBDB;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table tbody tr {
  border: none;
  border-bottom: 1px solid #DBDBDB;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table tbody tr:last-child {
  border-bottom: none;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table th,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td {
  padding: 0.625em;
  text-align: center;
  border: none;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table th {
  font-size: 0.875rem;
  color: #7F7F7C;
  font-weight: 600;
  text-align: left;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table th:first-child {
  text-align: left;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table th:nth-child(2) {
  text-align: center;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td {
  text-align: left;
  padding: 0.9rem 0.625em;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td:first-child {
  width: 400px;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td:nth-child(2) {
  text-align: center;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td span {
  font-size: 0.875rem;
  font-weight: 600;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td span.name {
  font-size: 0.933rem;
  font-weight: 600;
  color: #231535;
  line-height: 24px;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td span.detail {
  font-size: 0.933rem;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 26px;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeDelivered,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReplacementOrder,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReturnOrder,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeCancelorder {
  font-size: 0.875rem;
  font-weight: 600;
  color: #00662F;
  position: relative;
  padding-left: 10px;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeDelivered span,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReplacementOrder span,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReturnOrder span,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeCancelorder span {
  font-size: 0.813rem;
  font-weight: 500;
  display: block;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeDelivered::before,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReplacementOrder::before,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReturnOrder::before,
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeCancelorder::before {
  content: "";
  background: #00662F;
  width: 8px;
  height: 8px;
  position: absolute;
  left: -1px;
  top: 6px;
  border-radius: 50px;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReplacementOrder {
  color: #D69601;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReplacementOrder span {
  color: #D69601;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReplacementOrder::before {
  content: "";
  background: #D69601;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReturnOrder {
  color: #DA0202;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReturnOrder span {
  color: #DA0202;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeReturnOrder::before {
  content: "";
  background: #DA0202;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeCancelorder {
  color: #DA0202;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeCancelorder span {
  color: #DA0202;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeCancelorder span:last-child {
  color: #231535;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeCancelorder b {
  font-size: 0.875rem;
  font-weight: 600;
  color: #231535;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeCancelorder::before {
  display: none;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeInProgress {
  font-size: 0.875rem;
  font-weight: 600;
  color: #D69601;
  position: relative;
  padding-left: 10px;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td .modeInProgress::before {
  content: "";
  background: #D69601;
  width: 8px;
  height: 8px;
  position: absolute;
  left: -1px;
  top: 6px;
  border-radius: 50px;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td ul li img {
  width: 60px;
  height: 60px;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td ul li:last-child {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  position: relative;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td ul li:last-child p {
  font-size: 14px;
  color: #5D5D5D;
  font-weight: 400;
  margin-bottom: 0;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td ul li:last-child h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0.25rem;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td:last-child button {
  color: #5B005D;
  background-color: transparent;
  border: none;
  text-align: left;
  display: block;
  margin: auto;
  position: relative;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td:last-child button span {
  position: relative;
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td:last-child button span::before {
  content: "";
  background: url("../../../assets/images/myaccount/caretDownSolid.svg") center center no-repeat;
  background-size: 100%;
  position: absolute;
  left: -10px;
  top: 4px;
  width: 6px;
  height: 10px;
  transform: rotate3d(0, 0, 10, 270deg);
}
.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td p {
  line-height: normal;
}

.popup-content {
  margin: auto;
  width: 276px !important;
  padding: 5px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.popup-content .pop-body svg {
  color: #231535;
  font-size: 25px;
}
.popup-content .popup-arrow {
  color: #0B2138 !important;
}
.popup-content .tootltipSec .head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 1rem;
}
.popup-content .tootltipSec .head h6 {
  font-size: 0.875rem;
  margin-bottom: 4px;
}
.popup-content .tootltipSec textarea {
  width: 100%;
  height: 100px;
  background: #fff;
  border-radius: 5px;
}
.popup-content .tootltipSec button {
  background: #5B005D;
  color: #fff;
  margin: 10px 0px 15px 0;
  font-size: 1rem;
  padding: 5px 10px;
}

.shippingDts {
  display: flex;
  justify-content: space-evenly;
  margin: 45px 0;
}
.shippingDts .secLeft,
.shippingDts .secRight {
  width: 30%;
}
.shippingDts .secLeft table,
.shippingDts .secLeft tbody,
.shippingDts .secLeft tr,
.shippingDts .secLeft td,
.shippingDts .secRight table,
.shippingDts .secRight tbody,
.shippingDts .secRight tr,
.shippingDts .secRight td {
  border: none;
}
.shippingDts .secLeft table td,
.shippingDts .secLeft tbody td,
.shippingDts .secLeft tr td,
.shippingDts .secLeft td td,
.shippingDts .secRight table td,
.shippingDts .secRight tbody td,
.shippingDts .secRight tr td,
.shippingDts .secRight td td {
  text-align: left;
}
.shippingDts .secLeft table td:first-child,
.shippingDts .secLeft tbody td:first-child,
.shippingDts .secLeft tr td:first-child,
.shippingDts .secLeft td td:first-child,
.shippingDts .secRight table td:first-child,
.shippingDts .secRight tbody td:first-child,
.shippingDts .secRight tr td:first-child,
.shippingDts .secRight td td:first-child {
  font-weight: 600;
}
.shippingDts .secLeft {
  position: relative;
  left: -90px;
}
.shippingDts .secRight {
  width: 40%;
}
.shippingDts .secRight table tbody tr td {
  width: 500px;
  line-height: 20px;
}

.backBtn {
  position: absolute;
  top: 0;
  right: 0;
}
.backBtn button {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0em 1em;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0px;
  background: #0B2138;
  border: none;
  line-height: 42px;
  color: #FFF;
  z-index: 1;
  width: 132px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1.25rem;
}

.cancelOrder .cancelForm {
  width: 560px;
  margin: auto;
  position: relative;
}
.cancelOrder .cancelForm h3 {
  font-size: 1.21rem;
  color: #0B2138;
}
.cancelOrder .cancelForm h3 small {
  position: absolute;
  right: 8px;
  font-size: 0.75rem;
  color: #847F7F;
}
.cancelOrder .cancelForm form ul {
  flex-direction: column;
}
.cancelOrder .cancelForm form ul li {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.cancelOrder .cancelForm form ul li label {
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #0B2138;
  font-weight: 500;
}
.cancelOrder .cancelForm form ul li select {
  width: 100%;
  border: 1px solid silver;
  padding: 15px 15px;
  margin: 5px 0;
  background: url("../../../assets/images/myaccount/icons/sort-down-solid.svg") no-repeat;
  background-size: 15px;
  background-position: top 6px right 16px;
}
.cancelOrder .cancelForm form ul li textarea {
  border: 1px solid silver;
  padding: 10px;
  margin: 5px 0;
}
.cancelOrder .cancelForm form ul li textarea::before {
  content: "";
}
.cancelOrder .cancelForm form ul li:last-child {
  flex-direction: initial;
  align-items: center;
}
.cancelOrder .cancelForm form ul li:last-child .checkBox {
  margin-right: 7px;
  width: 20px;
  height: 20px;
  border: 1px solid #0B2138;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.cancelOrder .cancelForm form ul li:last-child .checkBox .checkedBox svg {
  color: #fff !important;
}
.cancelOrder .cancelForm form button {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0em 1em;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0px;
  background: #0B2138;
  border: none;
  line-height: 42px;
  color: #FFF;
  z-index: 1;
  margin: 15px 0;
  width: 110px;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: none;
}
.cancelOrder .cancelForm form .disableBtn {
  opacity: 0.8;
  visibility: unset;
  cursor: not-allowed;
}

.modal ::-webkit-scrollbar {
  width: 4px;
}
.modal ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  height: 5px;
  width: 3px;
}
.modal ::-webkit-scrollbar-thumb {
  background: #0B2138;
  border-radius: 10px;
  height: 10px;
  width: 5px;
}
.modal ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #232a35;
  border-radius: 10px;
}
.modal .modal-dialog-centered {
  max-width: 1128px !important;
}
.modal .modal-dialog-centered .modal-content {
  height: 585px;
  background: #FCFCFC;
  border-radius: 0;
  padding-right: 20px;
  padding-bottom: 25px;
}
.modal .modal-dialog-centered .modal-content .modal-body {
  padding: 0 67px 40px 53px;
  overflow: auto;
}
.modal .modal-dialog-centered .modal-content .modal-body p {
  font-size: 1rem;
  color: #231535;
  font-weight: 100;
  line-height: 30px;
  margin-bottom: 30px;
}
.modal .modal-dialog-centered .modal-header {
  border: none;
  padding-top: 40px;
  font-size: 1.875rem;
  color: #0B2138;
  padding-left: 53px;
}
.modal .modal-dialog-centered .modal-header .h4 {
  font-size: 30px;
  color: #5B005D;
  font-weight: bolder;
}
.modal .modal-dialog-centered .modal-header .btn-close {
  width: 23px;
  height: 25px;
  background: url("../../../assets/images/myaccount/icons/modalClose.svg") no-repeat;
  background-size: 20px;
  display: block;
  filter: none;
  position: relative;
}

.modal.show .modal-dialog {
  max-width: 1128px !important;
}

.backGroundNon {
  background: #0B2138 !important;
}

.trackOrder .track-result {
  position: relative;
}
.trackOrder .track-result .top-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.trackOrder .track-result .top-info h2 {
  font-size: 1.875rem;
  font-weight: bold;
  color: #3B003A;
}
.trackOrder .track-result .orderDelivered {
  padding: 9px 15px;
  border: 1px solid rgba(159, 159, 159, 0.431372549);
  border-radius: 5px;
  background-color: #fefcff;
  margin-top: 0.5rem;
}
.trackOrder .track-result .orderDelivered ul {
  display: flex;
  margin-bottom: 0;
}
.trackOrder .track-result .orderDelivered ul li h4 {
  line-height: 21px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
}
.trackOrder .track-result .orderDelivered ul li span {
  font-weight: 600;
  font-size: 0.875rem;
}
.trackOrder .track-result .orderDelivered ul li.OrderPlaced {
  width: 95px;
  margin-right: 40px;
}
.trackOrder .track-result .orderDelivered ul li.OrderNo {
  width: 118px;
  margin-right: 40px;
}
.trackOrder .track-result .orderDelivered ul li.TrackingId {
  width: 110px;
  margin-right: 40px;
}
.trackOrder .track-result .orderDelivered ul li.DeliverTo {
  width: 125px;
  margin-right: 40px;
}
.trackOrder .track-result .orderDelivered ul li.DeliveryAdd {
  width: 370px;
  margin-right: 0;
}
.trackOrder .track-info {
  margin-bottom: 80px;
  width: 100%;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}
.trackOrder .track-info .track-details h4 {
  text-align: center;
  font-weight: 400;
  color: #231535;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 1.25rem;
  margin-bottom: 0;
}
.trackOrder .track-info .track-details .orderInfo {
  background-color: #FEFFF9;
  margin-bottom: 30px;
}
.trackOrder .track-info .track-details .orderInfo .ordertable {
  border: 1px solid #DCDDDD;
  padding: 15px 20px 0;
  border-radius: 5px;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table {
  border: none;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table thead tr {
  border: none;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table thead tr th {
  border: none;
  font-weight: 600;
  padding: 8px 20px 15px 0;
  border-bottom: 1px solid #DCDDDD;
  background-color: #FEFFF9;
  font-size: 0.875rem;
  color: #7F7F7C;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table tbody {
  background-color: #FEFFF9;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table tbody tr {
  border: none;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table tbody tr td {
  font-size: 0.875rem;
  padding: 17px 0;
  border: none;
  font-weight: 600;
  vertical-align: top;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table tbody tr td span {
  font-weight: 600;
  display: block;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table tbody tr td ul li {
  margin-right: 14px;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table tbody tr td ul li img {
  width: 60px;
  height: 60px;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table tbody tr td ul li p {
  font-weight: 600;
  font-size: 0.813rem;
  margin-top: 35px;
}
.trackOrder .track-info .track-details .orderInfo .ordertable table th,
.trackOrder .track-info .track-details .orderInfo .ordertable table td {
  text-align: left;
}
.trackOrder .track-info .track-details .progressbar-info {
  margin-bottom: 10px;
}
.trackOrder .track-info .track-details .progressbar-info .progress {
  height: 30px;
  border-radius: 15px;
  position: relative;
  background-color: #E9E9E9;
}
.trackOrder .track-info .track-details .progressbar-info .progress .progress-bar {
  background-color: #3B003A;
  border-radius: 15px;
}
.trackOrder .track-info .track-details .progressbar-info .progress .progress-bar::after {
  content: url("../../../assets/images/icons/done.svg");
  position: absolute;
  top: 5px;
  left: 13%;
  width: 20px;
  height: 20px;
}
.trackOrder .track-info .track-details .progressbar-info .info {
  margin-top: 20px;
  position: relative;
}
.trackOrder .track-info .track-details .progressbar-info .info ul {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
}
.trackOrder .track-info .track-details .progressbar-info .info ul li ul {
  display: inline-block;
  text-align: center;
}
.trackOrder .track-info .track-details .progressbar-info .info ul li ul li img {
  margin-bottom: 10px;
}
.trackOrder .track-info .track-details .progressbar-info .info ul li ul li h4 {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0;
  line-height: 24px;
}
.trackOrder .track-info .track-details .progressbar-info .info ul li ul li h4.incomplete {
  color: #B6B5B5;
}
.trackOrder .track-info .track-details .progressbar-info .info ul li ul li p {
  font-size: 14px;
  line-height: 24px;
  color: #231535;
}
.trackOrder .track-info .track-details .progressbar-info .info ul li:nth-child(2)::after {
  content: "";
  position: absolute;
  top: -45px;
  left: 42%;
  border-radius: 100%;
  background-color: #fff;
  width: 20px;
  height: 20px;
}
.trackOrder .track-info .track-details .progressbar-info .info ul li:nth-child(3)::after {
  content: "";
  position: absolute;
  top: -45px;
  left: 67%;
  border-radius: 100%;
  background-color: #fff;
  width: 20px;
  height: 20px;
}
.trackOrder .track-info .track-details .progressbar-info .info ul li:nth-child(4)::after {
  content: "";
  position: absolute;
  top: -45px;
  left: 89%;
  border-radius: 100%;
  background-color: #fff;
  width: 20px;
  height: 20px;
}
.trackOrder .track-info .track-details .updates p {
  font-size: 1.25rem;
  font-weight: 600;
}
.trackOrder .track-info .track-details .updates .table {
  border: 1px solid #DCDDDD;
  padding: 0 17px;
}
.trackOrder .track-info .track-details .updates .table table {
  border: none;
}
.trackOrder .track-info .track-details .updates .table table thead tr {
  border: none;
}
.trackOrder .track-info .track-details .updates .table table thead tr th {
  border: none;
  font-weight: 600;
  padding: 8px 10px;
  border-bottom: 1px solid #DCDDDD;
  background-color: #fff;
  font-size: 15px;
  line-height: 25px;
}
.trackOrder .track-info .track-details .updates .table table tbody tr {
  border: none;
}
.trackOrder .track-info .track-details .updates .table table tbody tr td {
  font-size: 15px;
  padding: 10px;
  border: none;
}
.trackOrder .track-info .track-details .updates .table table tbody tr td p {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.trackOrder .track-info .track-details .updates .table table tbody tr td small {
  font-size: 15px;
}
.trackOrder .track-info .track-details .updates .table table th,
.trackOrder .track-info .track-details .updates .table table td {
  text-align: left;
}

.trackorder .track-order table tbody tr td .addressInfo {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.tooltipInfo {
  position: absolute;
  top: 77%;
  left: 50.8%;
  transform: translate(-50%, -50%);
  background: #fefcff;
  color: #231535;
  display: none;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  width: 270px;
  font-size: 0.875rem;
  height: auto;
  border: 1px solid #5B005D;
}
.tooltipInfo h4 {
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  line-height: 22px;
  margin-bottom: 0px;
}
.tooltipInfo p {
  font-size: 0.875rem;
  line-height: 22px;
  text-align: left;
  margin-bottom: 0;
}

.tooltip-arrowInfo {
  position: absolute;
  top: -6px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #5B005D;
}

.invoiceOrder .top-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.invoiceOrder .top-info h2 {
  font-size: 1.875rem;
  font-weight: bold;
  color: #3B003A;
}
.invoiceOrder .top-info .btn-back {
  width: 102px;
  height: 40px;
  border: 1px solid #5B005D;
  color: #5B005D;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 20px;
  outline: none;
  line-height: 27px;
}
.invoiceOrder .topOrderInfo {
  padding: 9px 15px;
  border: 1px solid rgba(159, 159, 159, 0.431372549);
  border-radius: 5px;
  background-color: #fdfdfd;
  margin-top: 0.5rem;
}
.invoiceOrder .topOrderInfo ul {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.invoiceOrder .topOrderInfo ul li ul li {
  margin-right: 40px;
}
.invoiceOrder .topOrderInfo ul li ul li h4 {
  font-size: 0.875rem;
  font-weight: 500;
}
.invoiceOrder .topOrderInfo ul li ul li span {
  font-size: 0.875rem;
  font-weight: 600;
}
.invoiceOrder .topOrderInfo ul li.pdf span {
  font-size: 13px;
  color: #5B005D;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.invoiceOrder .topOrderInfo ul li.pdf span img {
  margin-right: 6px;
}
.invoiceOrder .orderDetails {
  padding: 30px 92px 0;
}
.invoiceOrder .orderDetails .addressInfo {
  margin-bottom: 40px;
}
.invoiceOrder .orderDetails .addressInfo ul {
  display: flex;
  justify-content: space-between;
}
.invoiceOrder .orderDetails .addressInfo ul li h4 {
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
}
.invoiceOrder .orderDetails .addressInfo ul li h6 {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
}
.invoiceOrder .orderDetails .addressInfo ul li p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: 388px;
}
.invoiceOrder .orderDetails .scocialInfo {
  margin-bottom: 30px;
}
.invoiceOrder .orderDetails .scocialInfo ul li {
  display: flex;
}
.invoiceOrder .orderDetails .scocialInfo ul li p {
  font-size: 15px;
  font-weight: 600;
  margin-right: 5px;
  margin-bottom: 0;
  line-height: 25px;
}
.invoiceOrder .orderDetails .scocialInfo ul li span {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}
.invoiceOrder .orderDetails .scocialInfo ul li:first-child {
  margin-right: 3rem;
}
.invoiceOrder .orderDetails .orderData {
  padding: 9px 15px;
  border: 1px solid rgba(159, 159, 159, 0.431372549);
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-bottom: 30px;
}
.invoiceOrder .orderDetails .orderData ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.invoiceOrder .orderDetails .orderData ul li h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}
.invoiceOrder .orderDetails .orderData ul li span {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.invoiceOrder .orderDetails .details {
  padding: 9px 15px;
  border: 1px solid rgba(159, 159, 159, 0.431372549);
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-bottom: 30px;
}
.invoiceOrder .orderDetails .details ul {
  margin-bottom: 0;
}
.invoiceOrder .orderDetails .details ul li h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}
.invoiceOrder .orderDetails .details ul li p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}
.invoiceOrder .orderDetails .details ul li p.address {
  line-height: 28px;
}
.invoiceOrder .orderDetails .invoiceDetails {
  margin-top: 30px;
}
.invoiceOrder .orderDetails .invoiceDetails table {
  border-radius: 5px;
}
.invoiceOrder .orderDetails .invoiceDetails table thead tr th {
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  background-color: #5B005D;
  text-align: center;
  line-height: 15px;
  vertical-align: top;
}
.invoiceOrder .orderDetails .invoiceDetails table thead tr th.sr {
  width: 25px;
  text-align: left;
}
.invoiceOrder .orderDetails .invoiceDetails table thead tr th.itemInfo {
  width: 228px;
  vertical-align: top;
  text-align: left;
}
.invoiceOrder .orderDetails .invoiceDetails table thead tr th:nth-child(5) {
  width: 60px;
}
.invoiceOrder .orderDetails .invoiceDetails table thead tr th:nth-child(7) {
  width: 60px;
  text-align: left;
}
.invoiceOrder .orderDetails .invoiceDetails table thead tr th:nth-child(11) {
  width: 92px;
}
.invoiceOrder .orderDetails .invoiceDetails table tbody tr td {
  background-color: #FEFFF9;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 7px;
  vertical-align: top;
  line-height: 24px;
}
.invoiceOrder .orderDetails .invoiceDetails table tbody tr td .productInfo {
  text-align: left;
  padding: 1px 6px;
}
.invoiceOrder .orderDetails .invoiceDetails table tbody tr td .productInfo .tags {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.invoiceOrder .orderDetails .invoiceDetails table tbody tr td .productInfo p {
  font-weight: 400;
  color: #5D5D5D;
  line-height: 24px;
  text-align: left;
  font-size: 14px;
}
.invoiceOrder .orderDetails .invoiceDetails table tfoot tr td {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 0;
  border: none;
  background-color: #FEFFF9;
}
.invoiceOrder .orderDetails .invoiceDetails table tfoot tr td span {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.invoiceOrder .orderDetails .totalInfo {
  text-align: right;
  padding: 15px 28px;
  background-color: #FEFFF9;
  border: 1px solid #DBDBDB;
  border-top: none;
}
.invoiceOrder .orderDetails .totalInfo ul {
  display: inline-block;
  margin-bottom: 0;
}
.invoiceOrder .orderDetails .totalInfo ul li {
  display: flex;
  justify-content: space-between;
  line-height: 32px;
}
.invoiceOrder .orderDetails .totalInfo ul li p {
  font-size: 0.875rem;
  font-weight: 600;
  margin-left: 81px;
  margin-bottom: 0;
}
.invoiceOrder .orderDetails .totalInfo ul li p span {
  font-weight: 600;
}
.invoiceOrder .orderDetails .wordAmt {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 25px;
  margin-top: 1rem;
}
.invoiceOrder .orderDetails .signInfo {
  font-size: 15px;
  font-weight: 400;
  text-align: right;
  padding-right: 55px;
  line-height: 25px;
}
.invoiceOrder .orderDetails .declaration {
  margin-top: 60px;
}
.invoiceOrder .orderDetails .declaration ul {
  display: flex;
  justify-content: space-between;
}
.invoiceOrder .orderDetails .declaration ul li h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}
.invoiceOrder .orderDetails .declaration ul li p {
  font-size: 14px;
  font-weight: 400;
  width: 486px;
  line-height: 24px;
}
.invoiceOrder .orderDetails .declaration ul li p.authSign {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: right;
  margin-top: 50px;
  padding-right: 65px;
}
.invoiceOrder .orderDetails .footerAddress {
  text-align: center;
  border-bottom: 1px solid #DBDBDB;
  padding: 25px 0;
}
.invoiceOrder .orderDetails .footerAddress p {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #5D5D5D;
}
.invoiceOrder .orderDetails .footerAddress p.address {
  margin-bottom: 3px;
  font-weight: 400;
  color: #5D5D5D;
}
.invoiceOrder .orderDetails .footerAddress ul {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.invoiceOrder .orderDetails .footerAddress ul li {
  margin-right: 40px;
}
.invoiceOrder .orderDetails .footerAddress ul li:last-child {
  margin-right: 0;
}
.invoiceOrder .orderDetails .footerAddress ul li p {
  margin-bottom: 1.5rem;
  font-weight: 400;
}
.invoiceOrder .orderDetails .footerAddress .info p {
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.invoiceOrder .orderDetails .footerAddress .info p strong {
  font-weight: 600;
}
.invoiceOrder .orderDetails .smallInfo {
  padding-top: 15px;
  text-align: center;
}
.invoiceOrder .orderDetails .smallInfo small {
  font-size: 0.75rem;
  color: #676666;
  font-family: "Montserrat", sans-serif;
}

.Order .top-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.Order .top-info h2 {
  font-size: 1.875rem;
  font-weight: bold;
  color: #3B003A;
}
.Order .top-info .btn-back {
  width: 102px;
  height: 40px;
  border: 1px solid #5B005D;
  color: #5B005D;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 20px;
  outline: none;
  line-height: 27px;
}
.Order .topOrderInfo {
  padding: 9px 15px;
  border: 1px solid rgba(159, 159, 159, 0.431372549);
  border-radius: 5px;
  background-color: #fefcff;
  margin-top: 0.5rem;
}
.Order .topOrderInfo ul {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.Order .topOrderInfo ul li ul li {
  margin-right: 40px;
}
.Order .topOrderInfo ul li ul li h4 {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
}
.Order .topOrderInfo ul li ul li span {
  font-size: 0.875rem;
  font-weight: 600;
}
.Order .topOrderInfo ul li h4.pdf {
  font-size: 0.75rem;
  color: #3B003A;
  font-weight: bold;
}
.Order .topOrderInfo ul li h4.pdf img {
  margin-right: 6px;
}
.Order .order-info {
  margin-bottom: 80px;
  width: 100%;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}
.Order .order-info .order-details h4 {
  text-align: center;
  font-weight: 400;
  color: #231535;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 1.25rem;
}
.Order .order-info .order-details .orderInfo {
  background-color: #FEFFF9;
  margin-bottom: 10px;
}
.Order .order-info .order-details .orderInfo .ordertable {
  border: 1px solid #DCDDDD;
  padding: 15px 20px 0;
  border-radius: 5px;
}
.Order .order-info .order-details .orderInfo .ordertable table {
  border: none;
}
.Order .order-info .order-details .orderInfo .ordertable table thead tr {
  border: none;
}
.Order .order-info .order-details .orderInfo .ordertable table thead tr th {
  border: none;
  font-weight: 600;
  padding: 8px 20px 15px 0;
  border-bottom: 1px solid #DCDDDD;
  background-color: #FEFFF9;
  font-size: 0.875rem;
  color: #5D5D5D;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody {
  background-color: #FEFFF9;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr {
  border: none;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td {
  font-size: 0.875rem;
  padding: 15px 0;
  border: none;
  font-weight: 600;
  line-height: 32px;
  vertical-align: top;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td span {
  font-weight: 600;
  line-height: normal;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td span.name, .Order .order-info .order-details .orderInfo .ordertable table tbody tr td span.details {
  display: block;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td span.details {
  font-weight: 400;
  color: #5D5D5D;
  margin-top: 5px;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td .form-select {
  width: 60px;
  height: 32px;
  border-radius: 5px;
  border-color: #B6B5B5;
  color: #231535;
  background-image: url("../../../assets/images/caret-down-solid.svg");
  background-size: 12px;
  background-position: top 6px right 6px;
  padding: 4px;
  padding-left: 9px;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td ul {
  margin-bottom: 0;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td ul li {
  margin-right: 14px;
  position: relative;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td ul li img {
  width: 60px;
  height: 60px;
}
.Order .order-info .order-details .orderInfo .ordertable table tbody tr td ul li p {
  font-weight: 600;
  font-size: 0.813rem;
  margin-bottom: 0;
}
.Order .order-info .order-details .orderInfo .ordertable table th,
.Order .order-info .order-details .orderInfo .ordertable table td {
  text-align: left;
}
.Order .order-info .FormDetails p {
  color: #5D5D5D;
  font-size: 0.875rem;
  text-align: right;
}
.Order .order-info .FormDetails form {
  margin-top: 20px;
}
.Order .order-info .FormDetails form .form-row .form-group {
  margin-right: 20px;
}
.Order .order-info .FormDetails form .form-row .form-group:last-child {
  margin-right: 0;
}
.Order .order-info .FormDetails form .form-row .form-group.return {
  width: 100%;
}
.Order .order-info .FormDetails form .form-row .form-group.return select {
  width: 100%;
}
.Order .order-info .FormDetails form .form-row .form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
  color: #231535;
  text-transform: math-auto;
  font-family: "Source Sans Pro", sans-serif;
}
.Order .order-info .FormDetails form .form-row .form-group label small {
  font-size: 12px;
  font-weight: 400;
}
.Order .order-info .FormDetails form .form-row .form-group .form-select {
  width: 317px;
  border-color: #B6B5B5;
  border-radius: 5px;
  background-image: url("../../../assets/images/caret-down-solid.svg");
  background-size: 12px;
  background-position: top 12px right 16px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #676666;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 13px 20px;
  height: auto;
}
.Order .order-info .FormDetails form .form-row .form-group .form-select.return {
  width: 100%;
}
.Order .order-info .FormDetails form .form-row .form-group.comments {
  margin-top: 20px;
}
.Order .order-info .FormDetails form .form-row .form-group.comments textarea {
  resize: none;
  width: 100%;
  height: 177px;
  border-radius: 5px;
  border: 1px solid #B6B5B5;
  padding: 10px;
  outline: none;
}
.Order .order-info .FormDetails .submitData {
  margin-top: 10px;
  text-align: center;
}
.Order .order-info .FormDetails .submitData .checkbox {
  justify-content: center;
  margin-bottom: 20px;
}
.Order .order-info .FormDetails .submitData .checkbox .styled-checkbox {
  position: absolute;
  opacity: 0;
}
.Order .order-info .FormDetails .submitData .checkbox .styled-checkbox + label {
  position: relative;
  cursor: pointer;
  color: #5D5D5D;
  font-size: 0.875rem !important;
  font-weight: 400;
}
.Order .order-info .FormDetails .submitData .checkbox .styled-checkbox + label p {
  position: absolute;
  top: 0;
  left: 29px;
}
.Order .order-info .FormDetails .submitData .checkbox .styled-checkbox + label span {
  font-size: 0.875rem;
  text-decoration: underline;
}
.Order .order-info .FormDetails .submitData .checkbox .styled-checkbox + label a {
  color: #231535;
  text-decoration: underline;
}
.Order .order-info .FormDetails .submitData .checkbox .styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid #B6B5B5;
}
.Order .order-info .FormDetails .submitData .checkbox .styled-checkbox:checked + label:before {
  background: #5B005D;
}
.Order .order-info .FormDetails .submitData .checkbox .styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #FFFFFF;
  width: 3px;
  height: 3px;
  box-shadow: 2px 0 0 #FFFFFF, 4px 0 0 #FFFFFF, 4px -2px 0 #FFFFFF, 4px -4px 0 #FFFFFF, 4px -6px 0 #FFFFFF, 4px -8px 0 #FFFFFF;
  transform: rotate(45deg);
}

.reqMesg .top-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.reqMesg .top-info h2 {
  font-size: 1.875rem;
  font-weight: bold;
  color: #3B003A;
}
.reqMesg .mesgInfo {
  padding: 0 304px;
  margin-top: 22px;
}
.reqMesg .mesgInfo h4 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 24px;
}
.reqMesg .mesgInfo p {
  font-size: 1rem;
  line-height: 32px;
  margin-top: 22px;
}
.reqMesg .mesgInfo h6 {
  font-size: 1.25rem;
  color: #3B003A;
  line-height: 24px;
  margin-top: 13px;
}
.reqMesg .mesgInfo .contactInfo {
  margin-top: 23px;
  margin-bottom: 20px;
}
.reqMesg .mesgInfo .contactInfo ul {
  display: flex;
  justify-content: space-between;
}
.reqMesg .mesgInfo .contactInfo ul li p {
  font-size: 1.125rem;
  color: #231535;
  font-weight: 600;
  line-height: 32px;
  margin-top: 0;
}
.reqMesg .mesgInfo .contactInfo ul li span {
  font-weight: 500;
  line-height: 32px;
}
.reqMesg .mesgInfo .conShopping {
  text-align: center;
}
.reqMesg .mesgInfo .conShopping .btn-conShopping {
  background-color: #5B005D;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 50px;
  color: #FFFFFF;
  text-align: center;
  padding: 11px 22px;
}

.invoicePopup #popup-root .popup-content {
  width: 440px !important;
  height: 316px !important;
  top: 40% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.invoicePopup #popup-root .popup-content .pop-body h2 {
  font-weight: bold;
}
.invoicePopup #popup-root .popup-content .pop-body p {
  font-size: 1rem;
  color: #231535;
  font-weight: 500;
  line-height: 30px;
  padding: 10px 30px;
}

.btn.btn-primary {
  border-radius: 5px;
  text-transform: uppercase;
  width: auto;
  border: 1px solid #5B005D;
}
.btn.btn-primary:hover {
  background-color: #fff;
  border: 1px solid #5B005D;
  color: #5B005D;
}

.btn.btn-secondary {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.btn.btn-secondary:before, .btn.btn-secondary:after {
  top: 0;
  width: 0;
  height: 100%;
  mix-blend-mode: overlay;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
}
.btn.btn-secondary:before {
  left: 0;
  width: 0;
  background: #5B005D;
  color: #fff;
}
.btn.btn-secondary:after {
  left: 100%;
  width: 0;
  background: #5B005D;
  color: #fff;
}
.btn.btn-secondary:hover {
  background-color: #5B005D;
  border: 1px solid #5B005D;
  color: #fff !important;
}
.btn.btn-secondary:hover:before {
  width: 100%;
}
.btn.btn-secondary:hover:after {
  width: 100%;
  left: 0;
}

.tootltipSec {
  position: absolute;
  width: 375px;
  background-color: #F6F2F7;
  z-index: 99;
  padding: 25px;
  right: -42px;
  top: 30px;
  border: 1px solid #5B005D;
  border-radius: 5px;
}
.tootltipSec::before {
  content: " ";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #5B005D;
  width: 0;
  height: 0;
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tootltipSec .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}
.tootltipSec .head img {
  cursor: pointer;
}
.tootltipSec .submit-review {
  display: flex;
  margin-bottom: 1rem;
}
.tootltipSec .submit-review h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #231535;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  margin-left: 0.5rem;
}
.tootltipSec textarea {
  width: 100%;
  height: 122px;
  background: #fff;
  resize: none;
  border: none;
  margin-bottom: 20px;
}
.tootltipSec textarea:focus-visible {
  outline: none;
}
.tootltipSec .btn-submit {
  background-color: #3B003A !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  text-align: center !important;
  border: 1px solid #3B003A !important;
  font-size: 13px;
  margin-left: 0 !important;
}
.tootltipSec .btn-submit:hover {
  background-color: #fff !important;
  color: #3B003A !important;
  border: 1px solid #3B003A !important;
}

.invoice-pdf {
  margin: 10px;
}
.invoice-pdf .invoiceHeader {
  background: #F9F9F9;
  border: 1px solid #DEDEDE;
  text-align: center;
  border-radius: 5px;
  padding: 15px;
  margin: 20px 0;
  position: relative;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invoice-pdf .invoiceHeader img {
  position: absolute;
  left: 10px;
}
.invoice-pdf .invoiceHeader h2 {
  font-size: 35px;
  font-weight: 700;
  color: #5B005D;
  line-height: 43px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.invoice-pdf .orderDetails {
  padding: 30px 0;
}
.invoice-pdf ul li {
  list-style-type: none;
}

@media screen and (max-width: 991px) {
  .mainWapper .RRT__container .RRT__tabs {
    position: relative;
  }
  .mainWapper .RRT__container .RRT__tabs .RRT__tab {
    width: auto;
  }
  .mainWapper .RRT__container .RRT__panel {
    margin-left: 0;
    position: relative;
    top: 0;
    width: 100%;
    padding: 0 0 30px;
  }
  .mainWapper .RRT__container .RRT__tabs .RRT__tab {
    border-right: 0;
  }
  .mainWapper .RRT__container .RRT__tabs {
    height: auto;
    border-right: none;
    width: 100%;
  }
  .mainWapper .RRT__container .RRT__tabs:hover {
    width: 100%;
    transition: none;
  }
  .filterMonth .formMargin {
    margin: 20px 0px 20px;
  }
  table td:last-child {
    border-bottom: 0;
  }
  .mainWapper .RRT__container .RRT__tabs .RRT__tab div span {
    position: relative;
    left: 0;
    opacity: 1;
    padding-left: 10px;
  }
  .mainWapper .RRT__container .RRT__tabs .RRT__tab div {
    border-radius: 5px;
  }
  .mainWapper .RRT__container .RRT__tabs .RRT__tab {
    padding-right: 0;
  }
  .giftcard .row .card tbody td.card-number, .giftcard .row .card tbody td.card-name {
    width: 160px;
  }
  .giftcard .row .card tbody td.full-name {
    width: 130px;
  }
  .giftcard .row .card tbody td.total-amount, .giftcard .row .card tbody td.card-validity {
    width: 100px;
  }
  .giftcard .row .card tbody td.balance-amount {
    width: 130px;
  }
  .giftcard .row .card tbody td.status {
    width: 75px;
  }
  .giftcard .row .card tbody td {
    padding: 0 10px;
  }
  .myaccount .popup-overlay .popup-content {
    max-width: 90% !important;
  }
  .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td {
    padding: 0;
  }
  .my_profile .my-setting .btn.btn-primary {
    margin-top: 1rem;
  }
  .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button::after, .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    background-size: 50%;
    width: 30px;
    height: 30px;
  }
  .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td ul li button, .myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td, .Data .Table table thead tr th, .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th, .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:nth-child(4) span, .myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table th, .Data .Table table tbody tr td, .invoiceOrder .orderDetails .invoiceDetails table tbody tr td .productInfo p, .invoiceOrder .orderDetails .invoiceDetails table thead tr th, .orderDetailSec .tableContent table tbody tr td:last-child button span, .orderDetailSec .tableContent table tbody tr td ul li h6, .orderDetailSec .tableContent table tbody tr td, .orderDetailSec .orderlabel table th {
    white-space: nowrap;
  }
  .invoiceOrder .orderDetails .addressInfo ul li p {
    width: auto;
  }
  .invoiceOrder .orderDetails {
    padding: 30px 16px 0;
  }
  .invoiceOrder .orderDetails .addressInfo ul li {
    width: 40%;
  }
  .invoiceOrder .orderDetails .wordAmt {
    font-size: 1rem;
  }
  .Data .Table table tbody tr td .remark {
    width: 300px;
    white-space: break-spaces;
  }
  .tryathome_acc .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th, .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
@media screen and (max-width: 450px) {
  .mainWapper .userdetails, .myaccount .popup-overlay .popup-content {
    padding-bottom: 0;
  }
  .mainWapper .RRT__container .RRT__panel h5 {
    font-size: 1.5rem;
    line-height: normal;
  }
  .mainWapper .RRT__container .RRT__panel h5, .address-form .office-address form .form-row, .address-form .office-address form, .invoiceOrder .topOrderInfo ul li ul li h4, .invoiceOrder .orderDetails .scocialInfo, .invoiceOrder .orderDetails .addressInfo, .myOrderTab .content-container .active-content .filterMonth {
    margin-bottom: 0;
  }
  .orderDetailSec .content-container .active-content .filterMonth {
    flex-wrap: wrap;
  }
  .filterMonth .formMargin {
    margin: 20px 0;
  }
  .filterMonth .formMargin .formRe select {
    margin-left: 0;
  }
  .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading, .address-form .office-address form .form-row .form-group, .giftcard #myTab .nav-item, .giftcard #myTab .nav-item .nav-link, .giftcard #myTab .nav-item .nav-link, .invoiceOrder .orderDetails .addressInfo ul li, .topOrderInfo ul li.invoiceOrder .orderDetails .totalInfo ul, .giftcard #myTab .nav-item .nav-link, .giftcard #myTab .nav-item .nav-link {
    width: 100%;
  }
  .mainWapper .RRT__container .RRT__panel {
    padding-left: 0;
    padding-right: 0;
  }
  .mainWapper .RRT__container .RRT__tabs .RRT__tab {
    padding-right: 0;
  }
  .address-form .office-address form .save-address {
    width: 100px;
    padding: 0;
  }
  .mainWapper .RRT__container .RRT__panel .btn-cancel {
    width: 100px;
    padding: 0;
  }
  .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th {
    white-space: nowrap;
    padding-right: 10px;
  }
  .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th:last-child {
    padding-right: 0;
  }
  .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:nth-child(4) span, .invoiceOrder .orderDetails .orderData ul li h4, .invoiceOrder .orderDetails .orderData ul li span {
    white-space: nowrap;
  }
  .my_profile .my-setting .form-row, .invoiceOrder .orderDetails .addressInfo ul, .invoiceOrder .topOrderInfo ul, .invoiceOrder .orderDetails .scocialInfo ul, .invoiceOrder .orderDetails .footerAddress ul {
    flex-wrap: wrap;
  }
  .my_profile .my-setting .form-row li:first-child, .my_profile .my-setting .form-row li:nth-child(2) {
    margin-right: 0;
  }
  .my_wishlist.myaccWislist .card {
    height: auto;
    width: 100%;
  }
  .card .card-header {
    height: auto;
  }
  .my_wishlist.myaccWislist .card .card-body {
    padding: 10px;
    height: auto;
  }
  .myaccount .popup-overlay .popup-content .terms-popup .pop-title h2 {
    font-size: 24px !important;
  }
  .myaccount .popup-overlay .popup-content .terms-popup .pop-title .close {
    font-size: 1rem;
  }
  .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.img {
    margin-right: 1.5rem;
  }
  .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.card-number {
    width: 160px;
  }
  .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.card-name, .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.full-name {
    width: 130px;
  }
  .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.total-amount {
    width: 100px;
  }
  .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.balance-amount {
    width: 130px;
  }
  .myaccount .popup-overlay .popup-content .terms-popup .pop-body {
    padding: 1rem !important;
  }
  .topOrderInfo ul li.pdf {
    margin-top: 1rem;
  }
  .invoiceOrder .topOrderInfo ul li ul li, .invoiceOrder .orderDetails .footerAddress ul li {
    margin-right: 0;
  }
  .invoiceOrder .orderDetails .scocialInfo ul li:first-child {
    margin-right: 0;
  }
  .invoiceOrder .orderDetails .orderData {
    padding: 0 5px;
  }
  .invoiceOrder .orderDetails .orderData ul {
    overflow: auto;
  }
  .invoiceOrder .orderDetails .orderData ul li {
    padding: 5px;
  }
  .invoiceOrder .orderDetails .invoiceDetails table tfoot tr td {
    border-top: 1px solid #DBDBDB;
  }
  .invoiceOrder .orderDetails .totalInfo {
    padding: 15px;
  }
  .invoiceOrder .orderDetails .totalInfo ul li p {
    margin-left: 0;
  }
  .invoiceOrder .orderDetails .signInfo {
    padding-right: 0;
  }
  .invoiceOrder .orderDetails .footerAddress ul, .filterMonth .formMargin, .myOrderTab .content-container .active-content .filterMonth, .videocall .Data .filterMonth {
    justify-content: space-between;
  }
  .invoiceOrder .orderDetails .footerAddress ul li {
    width: 45%;
    text-align: left;
  }
  .filterMonth .clearfix::after {
    display: none;
  }
  .my-setting .unsubscribe-mail h4 {
    margin-bottom: 1rem;
  }
  .filterMonth .formMargin .formRe, .videocall .Data .filterMonth select {
    width: 175px;
  }
  .mainWapper .userdetails .userName {
    font-size: 1.5rem;
  }
  .myacc_accordion:last-child .accordion {
    margin-bottom: 0;
  }
  .giftcard #myTab .nav-item {
    width: 33.33%;
  }
  .giftcard #myTab .nav-item .nav-link {
    padding: 8px 10px;
  }
  .giftcard .row {
    margin-bottom: 0;
  }
  .giftcard .row .card:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 390px) {
  .myaccount .popup-overlay .popup-content .terms-popup .pop-title h2 {
    font-size: 20px !important;
  }
  .giftcard #myTab .nav-item .nav-link {
    padding: 8px 3px;
  }
}
.Data .Table {
  margin-top: 1.5rem;
}/*# sourceMappingURL=myAccount.css.map */