@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';

.login-page {
    background-color: $filtertext;

    .wrapper {
        margin-top: 10rem;
    }
}

.login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 14rem;
}

.login-wrapper {
    width: 505px;
    // height: 453px;
    background-color: $whitecolor;
    padding: 50px 60px 30px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.16);
    h5 {
        font-size: 15px;
        font-weight: 400;
    }

    h6 {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        position: relative;

        &:before {
            background-color: #B7B7B7;
            height: 1px;
            width: 150px;
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
        }

        &:after {
            background-color: #B7B7B7;
            height: 1px;
            width: 150px;
            content: '';
            position: absolute;
            right: 0;
            top: 6px;
        }
    }

    p.text {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0.5rem;
        text-align: center;

        &.account {
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            color: $maincolor;

            a {
                color: inherit;
            }
        }
    }

    .login-list {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            list-style-type: none;
            margin: 0 0.5rem;
        }
    }

    label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .form-control {
        border: 1px solid $bordercolor;
        border-radius: 5px;

        &:focus {
            box-shadow: none;
        }
    }

    .btn-primary {
        width: 100%;
        margin: 1.5rem 0 1rem;

        span{font-size: 13px;}
    }

    p {
        // margin-top: 10px;
        font-size: 14px;
        color: $textcolor;
        font-weight: 600;
        a {
            color: $maincolor;
            text-decoration: underline;
        }
    }

    a.forgot_pass {
        text-align: right;
        display: block;
        font-size: 12px;
        color: inherit;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
    }

    .otpverifyPage {
        form {
            .inputFields {
                border: none;
                margin: 0px;
                margin-bottom: 6px;
                padding:0;
                input {
                    width: 64px;
                    border-radius: inherit;
                    text-align: center;
                    height:40px;
                    border-top: 1px solid $bordercolor;
                    border-right: 1px solid $bordercolor;
                    border-bottom: 1px solid $bordercolor;
                    border-left: none;
                    border-radius: 0;
                    &:first-child {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-left: 1px solid $bordercolor;
                    }

                    &:last-child {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                    &:focus-visible{
                        outline: none;
                    }
                }
            }

            p {
                margin-top: 10px;
                font-size: 14px;
                color: $textcolor;
                font-weight: 600;
                a {
                    color: $maincolor;
                    text-decoration: underline;
                }
            }
        }
    }
}

.form-group.password {
    margin-top: 1rem;

    .field-icon {
        float: right;
        right: 10px;
        margin-top: -25px;
        position: relative;
        z-index: 2;
        color: #9B9B9B;
    }

}

