@import '../../../assets/Scss/variable';

.booking-information {

    .details{
        padding-top: 40px;
        .contact {
            display: flex;
            flex-wrap: wrap;
            .left {
                width: 50%;
                .booking-details {border: 1px solid $cardcolor;padding: 2rem 1rem 1rem;border-radius: 5px;
                .change{list-style-type: none;display: flex;padding-left: 0;
                    li{
                        padding-right: 1rem;display: flex;align-items: center;
                        p{font-size: 13px;margin-bottom: 0;}
                        .back-button{color: $linkcolor;border: 1px solid $linkcolor;background-color: #fff;font-size: 13px;padding: 5px 10px;height: auto;line-height: normal;
                            &:hover{background-color: $linkcolor;color: #fff;border: 1px solid $linkcolor;}
                        }
                    }
                }
                }

                .mandatory{text-align: right;color: #5D5D5D;font-size: 14px;line-height: 17px;font-weight: 600;margin-bottom: 0;}

                .form-group{margin-bottom: 1rem;list-style: none;

                    label{color: #3F3F3F;font-size: 13px;line-height: 23px;margin-bottom: 5px;}
                    .form-control{
                        font-size: 13px;height: 40px;border: 1px solid #ced4da;
                        &::placeholder{color: #C8C7C7;font-size: 13px;}
                        &:focus{border: 1px solid #ced4da;}
                        &:disabled{background-color: #F5F1F1;color: #5D5D5D;}
                    }
                }
            
                .select-group{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                        
                    .form-group {
                        width: 48%;
                        .form-select{border: 1px solid #B6B5B5;background-color: #F5F1F1;}
            
                        &.mobile {
                            .cust-select {
                                display: flex;

                                .formMargin {width: 100%;}
            
                                .form-select {
                                    border-radius: 5px;
                                    border: 1px solid #B6B5B5;
                                    height: 40px;
                                    font-size: 13px;
                                    color: #7F7F7F;
                                    width: 80px;
                                    background: url(../../../assets/images/icons/sort-down-solid.svg) 55px 9px no-repeat;
                                    margin-right: 6px;
                                    background-size: 8px;
                                    // background-color: #F5F1F1;

                                    &:disabled{background-color: #F5F1F1;color: #5D5D5D;}
                                }
            
                                .form-control {
                                    // background-color: #F5F1F1;
            
                                    &::placeholder {
                                        font-size: 0.875rem;
                                        color: #7F7F7F;
                                    }
                                }
                            }
                        }
                        // &:nth-child(6),&:nth-child(7),&:nth-child(8),&:nth-child(9){
                        //     .form-control{background-color: #F5F1F1;}
                        // }
                    }
                    h6{color: #3F3F3F;font-size: 13px;line-height: 23px;margin-bottom: 5px;width: 100%;}
                }
                
                .customise {
                    text-align: center;
                    .btn-primary{width: auto;margin: 1.5rem 0 1rem;padding: 0 3rem;
                    span{font-size: 13px;}
                    }
                }
            
            }
            .right{
                width: 50%;
                
                .faq-accordion {
                    padding: 0rem 1rem 1rem 3rem;

                    h5{font-size: 20px;color: $linkcolor;font-weight: 600;}

                    .accordion-item{
                        &:first-of-type{border-top: 0;}
                        .accordion-button{font-size: 15px;font-weight: 600;}
                         .accordion-body{font-size: 14px;    padding-left: 15px;}
                    }
                }
            }
        }
    }

    

    

}