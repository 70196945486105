.schedule-appointment .details {
  padding-top: 40px;
}
.schedule-appointment .details .contact {
  display: flex;
  flex-wrap: wrap;
}
.schedule-appointment .details .contact .left {
  width: 50%;
}
.schedule-appointment .details .contact .left .select-details {
  border: 1px solid #E6E6E6;
  padding: 1rem 1rem;
  border-radius: 5px;
}
.schedule-appointment .details .contact .left .select-details ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.schedule-appointment .details .contact .left .select-details ul li {
  margin-bottom: 1rem;
}
.schedule-appointment .details .contact .left .select-details ul li:last-child {
  margin-bottom: 0;
}
.schedule-appointment .details .contact .left .select-details ul li h5 {
  font-size: 15px;
  margin-bottom: 0;
}
.schedule-appointment .details .contact .left .select-details ul li h5.selected {
  margin-bottom: 1rem;
}
.schedule-appointment .details .contact .left .select-details ul li .dates {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.schedule-appointment .details .contact .left .select-details ul li .dates li {
  padding-right: 1rem;
}
.schedule-appointment .details .contact .left .select-details ul li .dates li .btn.btn-primary {
  color: #5B005D;
  border: 1px solid #5B005D;
  background-color: #fff;
  padding: 0 5px;
  font-size: 13px;
  width: 100px;
}
.schedule-appointment .details .contact .left .select-details ul li .dates li .btn.btn-primary.active, .schedule-appointment .details .contact .left .select-details ul li .dates li .btn.btn-primary:hover {
  background-color: #5B005D;
  color: #fff;
  border: 1px solid #5B005D;
}
.schedule-appointment .details .contact .left .select-details ul li .selected-designs {
  display: flex;
}
.schedule-appointment .details .contact .left .select-details ul li .selected-designs li {
  position: relative;
  width: 100px;
  margin-right: 2.5rem;
  margin-bottom: 0;
}
.schedule-appointment .details .contact .left .select-details ul li .selected-designs li .close {
  position: absolute;
  font-size: 14px;
  width: 16px;
  height: 16px;
  top: -6px;
  right: -7px;
  border-radius: 50%;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
}
.schedule-appointment .details .contact .left .select-details ul li .selected-designs li .close .closebtn {
  color: #5B005D;
  margin-top: -9px;
}
.schedule-appointment .details .contact .left .select-details ul li .selected-designs li .img-div {
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  margin-bottom: 0.5rem;
}
.schedule-appointment .details .contact .left .select-details ul li .selected-designs li .img-div img {
  width: 75px;
}
.schedule-appointment .details .contact .left .select-details ul li .selected-designs li h6 {
  font-size: 12px;
  margin-bottom: 0;
}
.schedule-appointment .details .contact .left .customise {
  text-align: center;
}
.schedule-appointment .details .contact .left .customise .btn-primary {
  width: auto;
  margin-top: 1.5rem;
  padding: 0 2rem;
}
.schedule-appointment .details .contact .left .customise .btn-primary span {
  font-size: 13px;
}
.schedule-appointment .details .contact .right {
  width: 50%;
}
.schedule-appointment .details .contact .right .faq-accordion {
  padding: 0rem 1rem 1rem 3rem;
}
.schedule-appointment .details .contact .right .faq-accordion h5 {
  font-size: 20px;
  color: #5B005D;
  font-weight: 600;
}
.schedule-appointment .details .contact .right .faq-accordion .accordion-item:first-of-type {
  border-top: 0;
}
.schedule-appointment .details .contact .right .faq-accordion .accordion-item .accordion-button {
  font-size: 15px;
  font-weight: 600;
}
.schedule-appointment .details .contact .right .faq-accordion .accordion-item .accordion-body {
  font-size: 14px;
  padding-left: 15px;
}/*# sourceMappingURL=index.css.map */