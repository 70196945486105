@import '../../../assets/Scss/mixing';

@include order('../../../assets/images/myaccount/select-caret-down-solid.svg');

.videocall {
    .Data {
        .filterMonth {margin-bottom: 1.5rem;display: flex;align-items: center;
            label{@include font(14px,400,$textcolor,24px);
                span{font-weight: 700;}
            }
            select{margin-left: 5px;@include font(14px,400,$textcolor,24px);
                height: 40px;
                border: 1px solid #D8D8D8;
                border-radius: 5px;
                background-image: url(../../../assets/images/caret-sharp-purple.svg);
                background-size: 12px;
                background-position: top 12px right 10px;
                background-repeat: no-repeat;
                appearance: none;
                width: 225px;
                padding: 5px 7px 7px 5px;
                outline: none;
            }
        }
        .Table {
            table {
                tbody {
                    tr {
                        td {
                            span{display: block;text-align: left;
                                &.name{margin-bottom: 0;font-weight: 600;color: $textcolor;}
                                &.mobile{margin-bottom: 0;font-weight: 400;color: $textcolor;}
                            }
                        }
                    }
                }
            }
        }
    }
}