@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';


// .search-option {
    // width: 500px;
//     a.fa.fa-times-circle {
//         display: none;
//     }
// }
// .search-input-select {
//     color: $textcolor;width: 400px;height: 32px;

//     .btn {
//         border-color: $bordercolor;
//         background-color: $iconcolor;
//         border-radius: 0;
//         color: $textcolor;
//         flex: inherit;font-size: 0.875rem;
//         width: 90px;
//         text-align: left;
//         padding-right: 2rem;

//         &:focus {
//             box-shadow: none;
//         }
//     }

//     input {
//         &::placeholder {
//             color: $textcolor;font-size: 14px;font-weight: 400;
//         }

//         &:focus {
//             box-shadow: none;
//             border-color: $bordercolor;
//         }
//         &.form-control{
//             margin-left: 0;
//             height: 32px;
//             border:1px solid #B6B5B5;
//         }
        
//     }   

//     .search-icon-input {
//         right: 0;
//         position: absolute;
//         z-index: 2;
//         display: block;
//         width: 2.375rem;
//         height: 32px;
//         line-height: 32px;
//         text-align: center;
//         pointer-events: none;
//         color: #FFF;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//     }
// }

.search {
    width: 400px;
    color: $textcolor;
    height: 32px;
    position: relative;
  
    input {
        margin-left: 0;
        height: 32px;
        // border:1px solid #B6B5B5;
        font-size: 14px !important;
        font-weight: 400;
        &::placeholder {
            color: $maincolor !important;font-size: 14px;font-weight: 400;
        }

        &:focus {
            box-shadow: none;
            border-color: $bordercolor;
        }
    }
  
    .search-icon {
      display: none;
    }
  
    // .form-control {
    //   font-size: 14px;
  
    //   &::placeholder,
    //   &::-webkit-input-placeholder {
    //     color: $maincolor;
    //     font-size: 14px;
    //   }
    // }

    .wrapper{
        margin-top: 0;
        border-radius: 5px !important;
        padding-right: 2.5rem;
        margin-left: 0;
        height: 32px;
        border: 1px solid #B6B5B5;
        z-index: 9999 !important;

        &>div{min-height: auto;}
    }
  
    .sc-gueYoa {
        z-index: 99999;
        position: relative;
        background: #fff;
    }

    .flpAas > div.line{
        border-top-color: rgb(232,234,237);
        border-top-style: solid;
        border-top-width: 1px;
        margin-bottom: 0px;
        margin-left: 14px;
        margin-right: 20px;
        margin-top: 0px;
        padding-bottom: 4px;
    }
    .flpAas > ul {
        list-style-type: none;
        margin: 0;
        padding: 0px 0 16px 0;
    }
    .flpAas > ul > li {
        display: flex;
        align-items: center;
        padding: 4px 0 4px 0;
    }
    .flpAas .ellipsis {
        text-align: left;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .gXQXJO,
    .dlcLYL{min-height: 30px;}
    .cpmfQo,
    .fZTllq{height: 32px;}
    .jZvqdQ{margin: 0;}

    .btn {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99999;
        border-color: $linkcolor;
        width: 40px;
        height: 32px;
        background-color: $linkcolor;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 15px;
        cursor: pointer;

        svg{fill: #fff;display: flex;}

        &:focus {
            box-shadow: none;
        }
    }
  }