.review-order {
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 2rem;
  border-top: 1px solid #c8c7c7;
}
.review-order > li {
  list-style-type: none;
}
.review-order > li:first-child {
  width: 72.7%;
  margin-right: 1.5rem;
}
.review-order > li:nth-child(2) {
  width: 25%;
}
.review-order .wishlist-link a svg {
  color: #3B003A;
  cursor: pointer;
  font-size: 14px;
}
.review-order .summary-cancel {
  display: none;
  text-align: center;
}
.review-order .product-price-counter .mapped {
  display: flex;
  width: 77%;
}
.review-order .product-price-counter .mapped > li {
  list-style-type: none;
}
.review-order .product-price-counter .mapped > li figure {
  height: 92px;
  width: 80px;
}
.review-order .product-price-counter .mapped > li figure img {
  width: 100%;
  height: 100%;
}
.review-order .product-price-counter .mapped > li span.product {
  display: block;
}
.review-order .product-price-counter .mapped > li span.product-desc {
  display: block;
}
.review-order .product-price-counter .mapped > li .product-calculation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.review-order .product-price-counter .mapped > li:nth-child(2) {
  width: 89%;
  padding-left: 1.5rem;
}
.review-order .product-price-counter .mapped > li:nth-child(2) p {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
}
.review-order .product-price-counter .mapped > li:nth-child(2) p.size {
  color: #5D5D5D;
  font-size: 14px;
  font-weight: 400;
}
.review-order .product-price-counter .mapped > li .wishlist {
  padding: 0;
  color: #5B005D;
  padding-left: 3.5rem;
  margin-top: 3.6rem;
}
.review-order .product-price-counter .mapped > li .wishlist li {
  line-height: 1.5rem;
  font-size: 13px;
  letter-spacing: 1px;
  cursor: pointer;
}
.review-order .product-price-counter .mapped > li .wishlist li a {
  color: #5B005D;
}
.review-order .product-price-counter .mapped > li .wishlist li i.fa {
  padding-right: 4px;
}
.review-order .product-price-counter .mapped > li .prize-details {
  font-size: 15px;
  font-weight: 600;
  margin-left: 0;
}
.review-order .product-price-counter .mapped > li .prize-details span.multiply-icon {
  font-size: 0.75rem;
}
.review-order .product-price-counter .mapped > li .prize-details span {
  display: inline-block;
}
.review-order .product-price-counter .mapped > li .prize-details small {
  font-size: 0.875rem;
  margin: 0 1rem;
  font-weight: 400;
  display: inline-block;
  text-decoration: line-through;
}
.review-order .product-price-counter .mapped > li .prize-details s {
  text-decoration: line-through;
}
.review-order .product-price-counter .mapped > li .last-prize {
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
}
.review-order .product-price-counter .mapped > li .last-prize i.fa.fa-trash-o {
  padding-left: 0.937rem;
}
.review-order .product-price-counter .mapped > li .last-prize .deleteamt {
  color: #5B005D;
  margin-left: 6px;
}
.review-order .product-price-counter > li:nth-child(2) {
  width: auto !important;
}

.try-at-home {
  margin-bottom: 7rem;
}
.try-at-home p.count {
  border-top: 1px solid #c8c7c7;
  padding-top: 1rem;
  font-size: 15px;
}
.try-at-home p.count span {
  font-weight: 600;
}
.try-at-home .try-at-home-cart {
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
}
.try-at-home .try-at-home-cart > li {
  list-style-type: none;
}
.try-at-home .try-at-home-cart > li:first-child {
  width: 72.7%;
  margin-right: 1.5rem;
}
.try-at-home .try-at-home-cart > li:nth-child(2) {
  width: 25%;
}
.try-at-home .try-at-home-cart .right .order-summary .summary-data .btn.btn-primary {
  padding: 0 20px;
}
.try-at-home .try-at-home-cart .right .text-center a .continue-link {
  font-size: 14px;
}
.try-at-home .try-at-home-cart .right .text-center .min {
  font-size: 13px;
  color: #3F3F3F;
  line-height: 23px;
}
.try-at-home .home-works h2 {
  margin-bottom: 0.5rem;
}
.try-at-home .home-works .sub-title {
  color: #5D5D5D;
  font-size: 20px;
}
.try-at-home .home-works .process {
  display: flex;
  justify-content: space-between;
  padding: 4rem 0;
}
.try-at-home .home-works .process .card {
  height: 300px;
  min-height: 300px;
  width: 23%;
  border: none;
}
.try-at-home .home-works .process .card:first-child::before, .try-at-home .home-works .process .card:nth-child(3)::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 55px;
  top: -55px;
  left: 9rem;
  background: url(../../../assets/images/up_arrow.png) top no-repeat;
}
.try-at-home .home-works .process .card:nth-child(2)::after {
  content: "";
  position: absolute;
  width: 115%;
  height: 55px;
  bottom: -55px;
  left: 9rem;
  background: url(../../../assets/images/down_arrow.png) bottom no-repeat;
}
.try-at-home .home-works .process .card .card-body {
  padding: 2rem;
  text-align: center;
  background-color: #f9f9fb;
}
.try-at-home .home-works .process .card .card-body img {
  margin-bottom: 1rem;
}
.try-at-home .home-works .process .card .card-body h5 {
  color: #5B005D;
  font-size: 20px;
  line-height: 28px;
}
.try-at-home .home-works .process .card .card-body h5 span {
  display: block;
}
.try-at-home .home-works .process .card .card-body p {
  font-size: 13px;
  color: #3F3F3F;
  line-height: 23px;
}
.try-at-home .home-works .process .card .card-body p span {
  display: block;
}

a.continue-link {
  font-weight: 600;
  color: #5B005D;
  text-transform: uppercase;
}

.order-summary {
  background-color: #F6F6F6;
  padding: 1rem 1rem 1.5rem;
  margin-bottom: 1.5rem;
}
.order-summary h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #5B005D;
}
.order-summary ul {
  padding: 0;
  margin: 0;
}
.order-summary ul li {
  list-style-type: none;
  line-height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
.order-summary ul li.product-code {
  flex-wrap: wrap;
  line-height: 1.5rem;
  padding: 0.5rem 0;
}
.order-summary ul li.product-code .code {
  font-size: 12px;
  font-weight: 400;
  color: #3B003A;
  margin-bottom: 0;
}
.order-summary ul li.product-code .code span {
  font-weight: 600;
}
.order-summary ul li h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.order-summary ul li span.amount {
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 600;
}
.order-summary ul li span.amount strong {
  font-weight: 700;
}
.order-summary ul li:last-child {
  margin-bottom: 10px;
}
.order-summary ul .btn-primary {
  margin-bottom: 15px;
}
.order-summary span.close {
  cursor: pointer;
  position: absolute;
  top: 1.6rem;
  right: 0;
  padding: 12px 16px;
  transform: translate(0%, -50%);
  font-size: 30px;
  display: none;
}

.review_cart ul.review-list {
  border-radius: 0;
  height: 55px;
  width: 580px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 580px;
  margin: 0 auto;
}
.review_cart ul.review-list li {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-bottom: 0;
  height: 55px;
  padding: 6px 12px;
  font-size: 20px;
  font-weight: 400;
  color: #5B005D;
  text-align: center;
  width: 290px;
  border-bottom: none;
  cursor: pointer;
}
.review_cart ul.review-list li.custom-active {
  background: #3B003A;
  border-color: #3B003A;
  color: #fff;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}/*# sourceMappingURL=index.css.map */