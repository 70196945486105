@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';

.menu{display: flex;align-items: center;padding-left: 0;border-bottom: 1px solid #EFEAEA;
    > li{list-style-type: none;width: 100%;
            .navbar {
                .container {
                    padding-left: 0;

                    .navbar-nav{
                        .brand-logo{width: 100px;cursor: pointer;}
                        // li.nav-item:nth-child(2){border-left: 1px solid #EFEAEA;}
                        .nav-item {
                            .nav-link{
                                &.active {
                                    color:$hovercolor;
                                    &::after {
                                        content: '';
                                        // border-bottom: 5px solid #FF0000;
                                        transition: .5s all ease-in-out;
                                        position: absolute;
                                        left: 50%;
                                        width:100%;
                                        bottom:-10px;
                                        transform: translateX(-50%);
                                        animation: linkHover .35s ease-in-out forwards;
                                    }
                                }
                            }
                            .navbar-light .navbar-nav .show>.nav-link {@include font(0.933rem, 600);}
                            @keyframes linkHover {
                                from {
                                    width: 0%;
                                    height:4px;
                                    background: $hovercolor;
                                    position: absolute;
                                    bottom:-5px;
                            
                                }
                                to {
                                    width: 100%;
                                    height:4px;
                                    background: $hovercolor;
                                    position: absolute;
                                    bottom:-5px;
                                }
                            }
                        }
                    }
                     
                }
            }
            &:last-child{display: none;}
    }
 }
 ul.navbar-nav > li:hover > .sub-menu-block{width: auto;}
 .menu ul.navbar-nav>li>.sub-menu-block {margin-top: 21px;}
 .menu .navbar-expand-lg .navbar-nav .nav-link{padding: 14px 0;}
 .menu .navbar-light .navbar-nav .nav-link.active,.menu  .navbar-light .navbar-nav .show > .nav-link{font-size: 0.933rem;}
 .menu .navbar {height: 50px;}
 .menu .navbar-expand-lg .navbar-collapse{justify-content: flex-start;}
 .menu ul.navbar-nav > li > .sub-menu-block li a {padding-left: 0;}