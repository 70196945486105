.customise-request .bannerSec {
  position: relative;
  height: 565px;
}
.customise-request .bannerSec figure {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
}
.customise-request .bannerSec figure img {
  width: 100%;
  height: 100%;
}
.customise-request .bannerSec figure figcaption {
  width: 45%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.customise-request .bannerSec figure figcaption h1 {
  color: #fff;
  line-height: 50px;
  font-family: "Bahnschrift";
}
.customise-request .bannerSec figure figcaption h1 span {
  display: block;
}
.customise-request .row.gap0 {
  margin-bottom: 5rem;
}
.customise-request .row.gap0 .col-md-5 {
  padding-right: 0;
}
.customise-request .row.gap0 .col-md-5 h2 {
  margin-top: 1.5rem;
}
.customise-request .row.gap0 .col-md-5 .orangeBox {
  background: #FFF7EB;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 40px 0 100px 80px;
}
.customise-request .row.gap0 .col-md-5 .orangeBox ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.customise-request .row.gap0 .col-md-5 .orangeBox ul li {
  padding-left: 80px;
  position: relative;
  margin-bottom: 3rem;
  color: #5D5D5D;
}
.customise-request .row.gap0 .col-md-5 .orangeBox ul li .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #fff;
}
.customise-request .row.gap0 .col-md-5 .orangeBox ul li .icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.customise-request .row.gap0 .col-md-5 .orangeBox ul li h5 {
  font-size: 20px;
  margin: 0;
  line-height: normal;
}
.customise-request .row.gap0 .col-md-5 .bgImg {
  margin-top: -125px;
  text-align: center;
}
.customise-request .row.gap0 .col-md-7 {
  padding-left: 0;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded {
  border-radius: 10px;
  padding: 30px 30px 40px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .topheading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .topheading h5 {
  font-size: 20px;
  font-weight: bold;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 0;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .topheading span {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded h6 {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
  margin-bottom: 0;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded ul {
  display: flex;
  list-style: none;
  padding-left: 0;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded ul li {
  margin-right: 1rem;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded ul li input {
  margin-right: 10px;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded ul li label {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group {
  width: 48%;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.country-code {
  width: 12%;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.country-code .lbl {
  white-space: nowrap;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.tel {
  width: 35%;
  display: flex;
  align-items: flex-end;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.tel .formMargin {
  width: 95%;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.req {
  width: 100%;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.req .requirements {
  display: block;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.req textarea {
  padding: 5px 7px 7px 5px;
  width: 100%;
  resize: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid #b5b5b5;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group {
  margin-bottom: 1rem;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group label {
  margin-bottom: 0.25rem;
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group .mb-3 {
  margin-bottom: 0 !important;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group .mb-3 input {
  height: 40px;
  padding: 5px 7px 7px 5px;
  font-size: 14px;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group .mb-3 input:focus {
  border: 1px solid #ced4da;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group .mb-3 .error-message {
  position: relative;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group .formMargin .lbl {
  display: inline-block;
  margin-bottom: 0.25rem;
  text-transform: capitalize;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px !important;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group .formMargin .formRe .txtbox {
  border-radius: 5px;
  font-size: 13px;
  font-family: "Source Sans Pro", sans-serif;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group .formMargin .formRe .txtbox:disabled {
  background-color: #f8f8f8;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .form-group .formMargin .error-message {
  position: relative;
  line-height: normal;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .make-info {
  padding-top: 1rem;
  text-align: center;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary {
  background-color: #5B005D;
  width: 250px;
  height: 40px;
  color: #FFFFFF;
  line-height: 40px;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary.btnmakepayment {
  background-color: #0B2138;
  color: #FFFFFF;
}
.customise-request .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary:disabled {
  opacity: 40%;
}

.customise-thank-you .login-box {
  margin-top: 3rem;
}
.customise-thank-you .login-box .login-wrapper {
  width: 900px;
  text-align: center;
  border-radius: 5px;
}
.customise-thank-you .login-box .login-wrapper h5 {
  font-size: 20px;
  font-weight: bold;
  color: #5D5D5D;
  line-height: 30px;
}
.customise-thank-you .login-box .login-wrapper h5.grant {
  font-weight: 600;
}
.customise-thank-you .login-box .login-wrapper h5.grant span {
  display: block;
}
.customise-thank-you .login-box .login-wrapper .contact-list {
  justify-content: center;
  margin-bottom: 2rem;
}
.customise-thank-you .login-box .login-wrapper .contact-list li {
  width: 100%;
  display: flex;
  background: linear-gradient(90deg, rgb(66, 224, 209) 35%, rgb(99, 1, 93) 100%);
}
.customise-thank-you .login-box .login-wrapper .contact-list li img {
  width: 50px;
}
.customise-thank-you .login-box .login-wrapper .contact-list li h2 {
  color: #fff;
  margin-bottom: 0;
  margin-left: 1rem;
}
.customise-thank-you .login-box .login-wrapper h6 {
  font-size: 20px;
  font-weight: 600;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 2rem;
}
.customise-thank-you .login-box .login-wrapper h6 span {
  display: block;
}
.customise-thank-you .login-box .login-wrapper h6::before, .customise-thank-you .login-box .login-wrapper h6::after {
  display: none;
}
.customise-thank-you .login-box .login-wrapper p {
  font-size: 20px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 3rem;
}
.customise-thank-you .login-box .login-wrapper p span {
  display: block;
}
.customise-thank-you .login-box .login-wrapper p span a {
  color: #5B005D;
  text-decoration: none;
}
.customise-thank-you .login-box .login-wrapper p.choice {
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 0rem;
}
.customise-thank-you .login-box .login-wrapper p.team {
  line-height: 45px;
  margin-bottom: 0rem;
}/*# sourceMappingURL=index.css.map */