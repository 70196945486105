@import '../../../assets/Scss/_variable.scss';
@import '../../../assets/Scss/_mixing.scss';

.toplist{
    ul{
        display: flex;
        li{ // a{color:$whitecolor}
            a {color:$whitecolor;font-size: $font12;line-height: 25px;padding-right:1rem;
                &:hover {color:$texthover}
            }
            &:last-child{
                padding-right: 0;
           }
           & +li {
             a {border-left: 0.5px solid #CFCFCF;padding:0 1rem;}
           }
        }
    }
}