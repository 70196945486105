/* On Click Cart section open */
.cart-list {
  position: fixed;
  height: 100vh;
  width: 0vw;
  max-width: 560px;
  background-color: #FFF;
  z-index: 1000;
  right: 0;
  top: 0;
  transition: width 1s;
}
.cart-list.show {
  width: 50vw;
  transition: width 1s;
  z-index: 9999;
}
.cart-list.show .btn-close {
  display: block !important;
}
.cart-list .topheader {
  background-color: #3B003A;
  height: 100px;
  color: #fff;
  padding: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart-list .topheader h2 {
  color: #fff;
  margin-bottom: 0;
}
.cart-list .topheader h2 span {
  font-weight: 300;
}
.cart-list .topheader .btn-close {
  display: none;
  background-image: none;
  opacity: 1;
  color: #FFF;
  font-size: 35px;
  top: 10px;
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
}
.cart-list .topheader .btn-close:focus {
  box-shadow: none;
}
.cart-list .topheader .btn-close i.fa {
  color: #FFF;
  font-size: 1.875rem;
}
.cart-list .cart-body {
  height: 100%;
}
.cart-list .cart-body .product-price {
  padding: 1.375rem 1.875rem 0rem;
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 0rem;
}
.cart-list .cart-body .product-price li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-bottom: 1.25rem;
}
.cart-list .cart-body .product-price li h5 {
  font-size: 1.125rem;
  font-weight: bold;
}
.cart-list .cart-body .product-details {
  padding: 0.8rem 0rem 1.375rem;
  margin: 0;
  height: 765px;
  overflow-y: auto;
}
.cart-list .cart-body .product-details > li {
  list-style-type: none;
  padding: 0 1.5rem 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #E6E6E6;
}
.cart-list .cart-body .product-details::-webkit-scrollbar {
  width: 2px;
}
.cart-list .cart-body .product-details::-webkit-scrollbar-track {
  width: 5px;
  background-color: #F5F5F5;
  border-radius: 10px;
}
.cart-list .cart-body .product-details::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: #5B005D;
  height: 62px;
  border-radius: 10px;
}
.cart-list .cart-body .cart-footer {
  height: 100px;
  padding: 1.375rem 1.875rem 1.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -3px 6px -5px #000;
  background-color: #fff;
}
.cart-list .cart-body .cart-footer .cart-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cart-list .cart-body .cart-footer .total-prize {
  font-size: 1.5rem;
  font-weight: bold;
}
.cart-list .cart-body .cart-footer .btn-secondary {
  width: 130px;
  margin-right: 1rem;
  font-size: 13px;
  height: 40px;
  font-weight: 600;
}
.cart-list .cart-body .cart-footer .btn-primary {
  font-size: 13px;
  font-weight: 600;
}
.cart-list .cart-body .product-label-offer h5.offer {
  padding-left: 0;
  font-weight: 400;
  font-size: 13px;
}
.cart-list .cart-body .product-price-counter > li:nth-child(2) {
  width: 100%;
  padding-left: 1rem;
}
.cart-list .cart-body .product-price-counter {
  align-items: flex-start;
}/*# sourceMappingURL=index.css.map */