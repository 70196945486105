.testimonial {
  margin: 0rem 0 5rem;
}
.testimonial .item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonial .testi-icon svg {
  color: #3B003A;
  font-size: 26px;
  margin: 1.5rem 0;
}
.testimonial p {
  font-size: 20px;
  font-weight: 600;
  color: "";
  line-height: "";
  margin-bottom: 0;
  align-items: center;
}
.testimonial p.content {
  font-size: 20px;
  font-weight: 300;
  color: "";
  line-height: "";
  width: 668px;
}
.testimonial p.title {
  font-size: 16px;
  font-weight: 600;
  color: "";
  line-height: "";
}
.testimonial p.client-location {
  font-size: 14px;
  font-weight: 400;
  color: "";
  line-height: "";
}
.testimonial .testimonial-carousel {
  padding-bottom: 2.5rem;
}
.testimonial .img-wrapper {
  position: relative;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.testimonial .img-wrapper figure {
  height: 90px;
  width: 90px;
}
.testimonial .img-wrapper figure img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}
.testimonial .img-wrapper figcaption {
  text-align: left;
  padding-left: 10px;
}
.testimonial .owl-carousel button.owl-dot {
  background: #3B003A;
  border: 2px solid #3B003A;
  width: 16px;
  height: 16px;
  margin: 0px 2px;
  border-radius: 100%;
  display: inline;
}
.testimonial .owl-carousel button.owl-dot.active, .testimonial .owl-carousel button.owl-dot:hover {
  background: transparent;
}
.testimonial .owl-dots {
  bottom: -16px;
}
.testimonial .inner-wrapper {
  padding: 0 5rem;
}
.testimonial .slick-dots li button:before {
  content: none;
}
.testimonial .slick-dots li button {
  content: none;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  color: #5B005D;
  opacity: 1;
  border: 2px solid #5B005D;
  background: #5B005D;
}
.testimonial .slick-dots li.slick-active button {
  background: transparent;
}
.testimonial .slick-dots {
  bottom: -40px;
}
.testimonial .slick-dots li {
  margin: 0 2px;
}/*# sourceMappingURL=index.css.map */