@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';

.sell-on-summera{
    .bannerSec {position: relative;height: 557px;
        figure {width: 100%;height: 100%;margin: 0;position: absolute;
            img {width: 100%;height: 100%;object-fit: cover;}
            figcaption {width: 45%;position: absolute;top:50%;transform: translateY(-50%);
                h1 {color: $whitecolor;line-height: 50px;font-family: 'Bahnschrift';margin-bottom: 0;
                span{display: block;}
                }
            }
        }
    }

    .row.gap0{margin-bottom: 5rem;
        .col-md-5{padding-right: 0;
            .orangeBox {background: #FFF7EB;border-top-left-radius: 10px;border-bottom-left-radius: 10px;padding: 1rem;margin-top: 1rem;
                h2{margin: 0;font-size: 1.9rem;}
                ul {padding: 40px 20px 100px 60px;margin:0;list-style-type: none;
                    li {padding-left: 80px;position: relative;margin-bottom:3rem;color: $grayColor;
                        .icon{position: absolute;top:0;left:0;width: 60px;height: 60px;border-radius: 100%;background: $whitecolor;
                            img{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);}
                        }
                        h5{font-size: 20px;margin:0;line-height: normal;}
                    }
                    .error-message {color: #FF0D0D;font-size: 12px;}
                }
            }
            .bgImg {margin-top: -100px;}
        }

        .col-md-7{padding-left: 0;
            .shadow-box-rounded {border-radius: 10px;padding:30px 30px 40px;box-shadow: 0 0 30px rgba(0,0,0,0.10);
                .topheading{display: flex;justify-content: space-between;margin-bottom:1.5rem;
                    h5{@include font(20px,bold,$grayColor,30px);margin-bottom: 0;}
                    span{@include font(15px,400,$grayColor,21px);}
                }
                h6{@include font(15px,400,$grayColor,21px);margin-bottom:0;}
                ul{display: flex;list-style: none;padding-left: 0;flex-wrap:wrap;margin-bottom: 0;
                    li{margin-right: 1rem;cursor: pointer;width: 22%;
                        input{margin-right: 10px;}
                        label{@include font(15px,400,$grayColor,21px);cursor: pointer;}
                    }
                }
                .form-row{list-style: none;display: flex;justify-content: space-between;margin-bottom: 1rem;
                    .form-group{width: 48%;position: relative;
                        &.salutation{width: 13%;}
                        &.fname{width: 31%;}
                        &.resize{display: flex;align-items: center;position: relative;
                            .pan-withbtn{width: 72%;}
                            .btn-verify{width: 100px;text-align: center;position: absolute;right: 0;bottom: 0;
                                button{display: block;width: 100%;padding: 7px 0px;outline: 0;border: 1px solid #b5b5b5;border-radius: 0 5px 5px 0;color: $linkcolor;background-color: $whitecolor;font-weight: 600;
                                    &.green {background-color: green;color:$whitecolor;border: 1px solid green;}
                                    &.red {background-color: red;color:$whitecolor;border: 1px solid red;}
                                }
                            }
                        }
                        &.mobile {width:48%;
                            .cust-select {display: flex;
                                .form-select{width: 84px;height: 40px;
                                    &:disabled{background-color: #f8f8f8;border-top-right-radius: 0;border-bottom-right-radius: 0;border-right: 0;}
                                }
                                .formMargin{width: 100%;
                                    .lbl{display: none;}
                                    .error-message{left: -83px;}
                                    &:first-child {width: 110px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;}
                                    input.txtbox.form-control{border-top-left-radius: 0;border-bottom-left-radius: 0;}
                                }
                            }
                        }
                        .btn.btn-primary{position: absolute;right: 0;top: 27px;width: 130px;background-color: $whitecolor;color: $linkcolor;padding: 0;border: 0;border-left: 1px solid #ced4da;border-radius: 0;}
                    }
                    &.company {
                        .form-group {width: 100%;}
                    }
                }

                .form-group{margin-bottom: 1rem;
                    label{margin-bottom: 0.25rem;
                        @include font(15px !important,'',$grayColor !important,21px);
                    }
                    .mb-3{margin-bottom: 0 !important;
                    input{height: 40px;padding: 8px 135px 8px 7px;font-size: 14px;z-index: 2;position: relative;background-color: transparent;
                    &:focus{border: 1px solid #ced4da;}
                    }
                    .error-message{position: relative;}
                    }
                    .formMargin {
                        .lbl{display: inline-block;text-transform: capitalize;font-family: $basefont;width: auto;margin-right: 5px;}
                        .formRe {
                            .txtbox{border-radius: 5px;font-size: 13px;padding: 5px 10px;
                                &:disabled{background-color: #f8f8f8;}
                            }
                        }
                        .lbl-wrap {display: flex;justify-content: flex-start;}
                    }
                    &.are-you {position: relative;margin-bottom: 2rem;
                        .error-message {bottom:-10px;}
                    }
                }
                  
                  input[type=file]::file-selector-button {display: none;background-color: $whitecolor;font-family: 'Nunito Sans', sans-serif;color: $linkcolor;font-weight: 700;border: 0px;
                    text-transform: uppercase;letter-spacing: .1em;border-right: 1px solid #e5e5e5;margin-right: 20px;transition: .5s;height: 40px;}
                .file-info{color: $grayColor;font-size: 13px;}  
                .make-info {padding-top: 2.2rem;text-align: center;
                    .btn-primary {background-color: $linkcolor;padding: 0em 4em;height: 40px;color: $whitecolor;line-height: 40px;
                        &.btnmakepayment {background-color: #0B2138;color: $whitecolor;}
                        &:disabled{opacity: 40%;}
                    }
                }
            }
        }
    }
    .select-city {position: relative;
        // .error-message {bottom:-14px;}
        &.mobile {
            .error-message {bottom:-17px;}
        }
        // &.salutation   {
        //     .error-message {bottom:-17px;line-height: 9px;}
        // }
    }
    .why-register-on-summera{margin-bottom: 4rem;
        h2{text-align: center;}
        ul{list-style: none;display: flex;padding-left: 0;
            li{text-align: center;position: relative;width: 325px;
                h6{@include font(20px,600,$grayColor,30px);}
                p{@include font(15px,400,$grayColor,21px);width: 75%;margin: auto;}
                &:first-child::after{content: "";background-image: url(../../../assets/images/curve1.png);    background-size: 100%;
                background-repeat: no-repeat;
                width: 274px;
                height: 60px;
                position: absolute;
                bottom: -5px;
                right: -131px;
                top: 0;}
                &:nth-child(2){margin-top: 2rem;
                    &::after{content: "";background-image: url(../../../assets/images/curve2.png);    background-size: 100%;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        width: 270px;
                        height: 70px;
                        position: absolute;
                        bottom: -5px;
                        right: -123px;
                        top: 8px;}
                }
                &:nth-child(3){
                    &::after{content: "";background-image: url(../../../assets/images/curve1.png);  background-size: 98%;
                        background-repeat: no-repeat;
                        width: 300px;
                        height: 82px;
                        position: absolute;
                        bottom: 20px;
                        right: -166px;
                        top: 8px;}
                }
                &:last-child{margin-top: 2rem;}
            }
        }
    }

    .seller-says{text-align: center;
        .inner-wrapper{
            h2{margin-bottom: 0;}
            .testi-icon{svg{color: $linkcolor;}}
            p.content{color: $grayColor;}
            .img-wrapper{
                figure{height: auto;width: auto;
                    img{width: auto;max-width: 200px;height: auto;border-radius: 0;}
                }
            }
        }
    }
    .sell-faq-accordion{margin-bottom: 5rem;
        h2{text-align: center;}
        .accordion{
            .accordion-item{margin-bottom: 2rem;border: 1px solid #5d5d5d52;padding: 0 .5rem;
                .accordion-header{
                    button{@include font(15px,600,$grayColor,normal);}
                }
            }
        }
    }
}

.sell-thank-you {
    .login-box{margin-top: 3rem;
        .login-wrapper{width: 900px;text-align:center;border-radius: 5px;
            h5{@include font(20px,bold,$grayColor,30px);
                span{display: block;font-weight: 400;}
                &.grant{font-weight: 600;
                    span{font-weight: 600;}
                }
            }
            .contact-list{justify-content: center;margin-bottom:2rem;
                li{width: 100%;display: flex;
                    img{width: 50px;}
                    h2{color: $whitecolor;margin-bottom: 0;margin-left: 1rem;}
                }
            }
            h6{@include font(20px,600,$grayColor,30px);margin-bottom:2rem;
                span{display: block;}
                &::before,&::after{display: none;}
            }
            p{@include font(20px,400,$grayColor,30px);margin-bottom:3rem;
                span{display: block;
                    a{color: $linkcolor;text-decoration: none;}
                }
                &.choice{font-weight: 600;line-height: 45px;margin-bottom: 0rem;}
                &.team{line-height: 45px;margin-bottom: 0rem;}
            }
        }
    }
}

.membership-thankyou {
    .inner { margin-bottom: 6rem;max-width: 100%;
        .order-info {
            .track-order {
                .order-details {
                    ul {
                        li {font-size: 15px;}
                    }
                }
            }
        }
        .address-details {
            .billing-shipping {
                ul {
                    li {
                        address {width: 400px;}
                    }
                }
            }
        }
        
        .thank-order-summary {
            h4 {font-weight: 600;}
            .your-order-summary {
                .left { height: 165px;
                    .left-wrapper { padding: 12px;height: 100%;
                        ul {
                            li {
                                .order-product {
                                    .order_product_details {
                                        ul {align-items: flex-end;flex-direction: column;
                                            li { line-height: 25px;margin-bottom: 0.5rem;
                                                h4 {line-height: 25px;margin-bottom: 0.5rem;padding-bottom: 0;
                                                    &:last-child {margin-bottom: 0;padding-bottom: 0;}
                                                }
                                                &:last-child {margin-bottom: 0;}
                                            }
                                            &.second-list { width: 200px;font-size: 15px;
                                                li {text-align: right;width: 100%;
                                                &:last-child {display: flex;justify-content: space-between;}
                                                span {font-weight: 400;}
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .right { height: 165px;
                    .order-summary { height: 100%;
                        .summary-data { height: 88%;
                            ul { display: flex;flex-direction: column;justify-content: space-between;height: 100%;
                                li { line-height: normal;
                                    h5 {line-height: normal;}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.reviews { padding-top: 3rem;
    h2 {padding-bottom: 0;}
    .contact {
        .left {
            .contact-details {
                .review-add-details {
                    ul { margin-bottom: 1.5rem;
                        h6 {font-size: 13px;font-weight: 600;margin-bottom: .25rem;}
                        p {margin-bottom: 0;}
                        &:last-child {margin-bottom: 0;
                            h6{font-weight: 700;}
                        }
                    }
                    h4{margin-bottom: .75rem;}
                    address {
                        span {margin-bottom: .5rem;}
                    }
                }
            }
            .make-info {
                .checkbox {
                    .styled-checkbox {
                        + label {font-size: 14px;
                            .terms {color: $linkcolor;font-size: 14px;}
                        }
                    }
                }
            }
        }

        .right {
            .summary-data {
                ._border_bottom {
                    .custom_flex {
                        .price {font-size: 14px;}
                        .cust-total {font-size: 15px;}
                        .view_all { border-bottom: 0;
                            span {border-bottom: 1px solid $linkcolor;}
                        }
                    }
                    &:first-child {
                        .custom_flex {
                            .cust-total {color: $blackcolor;}
                        }
                    }
                    &:last-child {
                        .custom_flex {
                            .cust-total {font-weight: 700;}
                        }
                    }
                }
            }
        }
    }
}

.membership-review,.subscription-task {
    .popup-overlay {
        .popup-content{padding: 0 0 2rem;border: 0;max-width: 825px;
            .terms-popup {
                .pop-title{padding: 1rem 1.5rem;border-top-right-radius: 5px;border-top-left-radius: 5px;background-color: $maincolor;display: flex;justify-content: space-between;align-items: center;
                    h2{color: $whitecolor;font-size: 30px;line-height: 38px;font-weight: 400;text-align: left;margin-bottom: 0;}
                    .close{position: relative;top: 0;margin: 0;background: transparent;border: none;color: $whitecolor;font-size: 20px;}
                }
                .pop-body{text-align: left;padding: 1rem 1.5rem;
                    .inner-div{overflow-y: auto;height: 450px;
                        .para {font-size: 14px;color: $textcolor;margin-bottom: 1rem;
                            .title {font-size: 15px;margin-bottom: .25rem;font-weight: 600;line-height: 25px;}
                            p {margin-bottom: .25rem;line-height: 28px;font-size: 20px;}
                            ul {padding-left: 1rem;margin-bottom: .5rem;
                              li {list-style-type: inherit;line-height: 24px;
                                &::marker {color: #676666;}
                              }
                            }
                        }
                        &::-webkit-scrollbar {width: 2px;}
                        &::-webkit-scrollbar-track {width: 5px;background-color: #F5F5F5;border-radius: 10px;}
                        &::-webkit-scrollbar-thumb {width: 2px;background-color: $linkcolor;height: 62px;border-radius: 10px;}
                    }
                }
            }
        }
    }
}

.subscription-task { background: #FFF7EB;
    .wrapper.sell-on-summera { margin-top: 10.7rem;
        .row {
            &.gap0 {
                .col-md-5 {
                    .bgImg {margin-top: -35px;
                        img {position: absolute;left: 0;}
                    }
                }
                .col-md-7 {
                    .shadow-box-rounded {
                        background-color: $whitecolor;z-index: 9;position: relative;padding: 30px 80px;
                        .topheading { display: block;
                            h5 {font-weight: 600;line-height: normal;color: $linkcolor;margin-bottom: .5rem;}
                            p {font-size: 15px;line-height: normal;color: #1F1F1F;}
                        }
                        h2 {text-align: center;}
                        .fees { border-radius: 100px;background: #F0EEFF;box-shadow: 0px 10px 10px 0px #F0EEFF;padding: 1rem;margin-bottom: 2.5rem;position: relative;
                            ul { padding-left: 0;flex-wrap: nowrap;margin-bottom: 0;
                                li { color: $linkcolor;text-align: center;font-size: 36px;font-weight: 700;line-height: normal;width: 50%;
                                    p { margin-bottom: 0;margin-top: .5rem;color: $grayColor;font-size: 15px;font-weight: 600;line-height: normal;
                                        span {color: $linkcolor;text-align: center;font-size: 20px;font-weight: 600;line-height: normal;}
                                    }
                                    &:last-child {color: $hovercolor;
                                        span {color: $hovercolor;}
                                    }
                                }
                            }
                            img {position: absolute;left: 50%;transform: translate(-50%, -50%);top: 50%;
                                &.plus {z-index: 9;}
                                &.line {margin-top: 5px;}
                            }
                        }
                        .plan-details { width: 500px;margin: auto;
                            .card {width: 100%;margin: 0 auto 1rem;min-height: 170px;
                                .card-body { padding: 1rem 3rem;background: rgba(255, 247, 235, 0.50);
                                    h4 {color: #181059;font-size: 15px;font-weight: 700;line-height: normal;}
                                    ul {
                                        li {width: 100%;color: #231535;font-size: 15px;line-height: normal;margin-bottom: .5rem;cursor: default;
                                            img {margin-right: .25rem;margin-top: -0.25rem;}
                                            span {font-weight: 700;}
                                        }
                                    }
                                }
                                &:hover {box-shadow: none;}
                            }

                            .extra-service { margin: 0 3.25rem 1.5rem;
                                .want {color: #5B015D;font-size: 15px;font-weight: 400;line-height: normal;
                                    span {font-weight: 700;}
                                }
                                > ul { justify-content: space-between;display: flex;width: 320px;
                                    li { color: #231535;font-size: 15px;font-weight: 400;line-height: normal;margin: 0;width: auto;
                                        span {display: block;margin-top: .5rem;font-weight: 700;
                                        .form-select {box-shadow: none;border: 1px solid #ced4da;width: 85px !important;height: 35px;}
                                        }
                                        &.total {visibility: hidden;}
                                    }
                                }
                            }

                            .total-amount {text-align: center;
                                p { color: $grayColor;text-align: center;font-size: 15px;font-weight: 700;line-height: normal;margin: 1.5rem 0 1rem;
                                    span {display: block;color: #3B003A;text-align: center;font-size: 36px;font-weight: 700;line-height: normal;
                                        sup {font-size: .5em;font-weight: 400;}
                                    }
                                }
                                .btn.btn-primary {height: 50px;}
                                .terms { color: $blackcolor;font-size: 15px;font-weight: 400;line-height: normal;display: flex;justify-content: center;margin-top: 2.5rem;
                                    span{ font-size: inherit;
                                        a {color: $linkcolor;font-size: 15px;font-weight: 400;margin-right: .25rem;cursor: pointer;}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.membership-review {background-color: $whitecolor;}
}