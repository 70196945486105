.promise-card-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1201px;
  margin-top: 1.2rem;
}
.promise-card-wrapper .promise-card {
  height: 95px;
  width: 120px;
}
.promise-card-wrapper .promise-card figure {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.promise-card-wrapper .promise-card figure img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.promise-card-wrapper .promise-card figcaption {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 0.5rem;
}
.promise-card-wrapper .promise-card:nth-child(3), .promise-card-wrapper .promise-card:nth-child(6) {
  width: 80px;
}/*# sourceMappingURL=index.css.map */