.my_wishlist.myaccWislist {
  margin-top: 1.5rem;
}
.my_wishlist.myaccWislist .card {
  height: 400px;
}
.my_wishlist.myaccWislist .card .card-body {
  height: 115px;
}
.my_wishlist.myaccWislist .card .card-body p {
  margin-bottom: 0;
}
.my_wishlist.myaccWislist .card .card-body h5.offer {
  font-size: 13px;
  color: #C5017C;
}
.my_wishlist.myaccWislist .card:hover {
  height: 400px;
}/*# sourceMappingURL=mywishlist.css.map */