@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

// Thank You Page
.thankyou {
    margin-top: 10.2rem;
    .thankyou-wrapper{
        .inner{
            width: 994px;
        }
        .d-flex {justify-content: center;}
    }
    .heading {
        h2 {
            color: $linkcolor;font-size: 32px;font-weight: 600;
        }

        h4 {
            font-size: 15px;font-weight: 400;
            margin-top: 9px;
        }
    }

    .track-order {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1.938rem;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
        }

        .order-details {
            ul {
                display: block;
                li {
                    padding-right: 2.2rem;font-weight: 400;
                    span{
                        font-weight: 600;
                    }
                }
            }
        }

        .track-order-details {
            ul {
                li {
                    padding-right: 2.75rem;

                    &:first-child {
                        svg{transform: rotate3d(0, 25, 2, 180deg);}
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    a {
                        &.btn-primary-outline {
                            border: 1px solid #0B2138;
                            border-radius: 0;
                            width: 250px;
                            color: #0B2138;
                        }
                    }
                }
            }
        }
        .btn-secondary{
            font-size: 13px;
            display: flex;justify-content:center;align-items:center;color: $linkcolor;
            svg{
                font-size: 16px;margin-right: 4px;
            }
            &:hover{background-color: $linkcolor;color: #fff;}
        }
    }

    .billing-shipping {
        padding: 10px;
        border: 1px solid $cardcolor;
        margin-bottom: 1.8rem;
        border-radius: 5px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;

            li {
                padding-right: 4.5rem;

                h4{font-size: 15px;font-weight: 600;}

                h6{font-size: 14px;}

                address{margin-bottom: 0;font-size: 14px;}

                .contact-info {
                    h4 {
                        font-weight: 400;
                        position: relative;
                        strong{width: 108px;display: inline-block;}
                    }
                }
            }
        }
    }

    .thank-order-summary {
        h4 {font-size: 20px;color: #231535;font-weight: 400;padding-bottom: 10px;}

        .your-order-summary {
            display: flex;

            .left {
                .left-wrapper{
                    width: 100%;
                    width: 661px;
                    background-color: #FFF;
                    border: 1px solid #F5F5F5;
                    padding:10px 10px 0px 10px;
                    border-radius: 10px;

                    .delivery-info{
                        padding:0 0 1rem 0;display: flex;
                        li{padding-right: 12px;}
                    }

                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            .order-product {
                                .order_product_details {
                                    display: flex;
                                    justify-content: space-between;

                                    ul {
                                        display: flex;
                                        align-items: flex-start;
                                        font-weight: 600;
                                        li {
                                            padding: 0 1rem 0 0;font-size:15px;
                                        figure{
                                            width: 80px;height:93px;
                                            img{width: 100%;height: 100%;object-fit: cover;
                                            }
                                        }
                                            ul {
                                                padding: 0;

                                                li {
                                                    padding-left: 0;

                                                    h5{margin-bottom: 0;}
                                                }
                                            }

                                            h4{text-transform: capitalize;font-size: 15px;font-weight: 600;margin-bottom: 0;padding-bottom: 4px;}
                                            h6{font-size: 13px;margin-bottom: 0;}
                        

                                        }
                                    
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right {
                width: 100%;
                max-width: 310px;
                background-color: #F6F6F6;
                padding: 15px 15px 10px 15px;
                margin-left: 1.625rem;
                height: 100%;

                &.open {
                    height: 100vh;
                    transition: $DefaultTransition;
                    margin-left: 0;

                    .summary-data {
                        display: inline-block;
                    }

                    .summary-cancle {
                        text-align: center;
                    }
                }

                h4 {
                    font-size: 1.125rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
                .order-summary{
                    padding: 0;margin-bottom: 0;
                    ul
                    {
                        li:last-child{
                            margin-bottom: 4px;
                        }
                    }
                }
                .summary-data {
                    font-size: 14px;
                    color:#231535;
                    .close{cursor: pointer;position: absolute;top: 1.6rem;right: 0;padding: 12px 16px;transform: translate(0%, -50%);font-size: 30px;display: none;}
                }

                .summary-cancle{display: none;}
            }
        }
    }
}

// Thank You Page
.thankyou {
    .track-order {

        .track-order-details {
            ul {
                li {
                   
                    a {
                        &.btn-primary-outline {
                            border: 1px solid #0B2138;
                            border-radius: 0;
                            width: 250px;
                            color: #0B2138;
                            &:focus{
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }

        .custompayment {
            @include flexitem;
        }
    }
}