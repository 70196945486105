.search {
  width: 400px;
  color: #231535;
  height: 32px;
  position: relative;
}
.search input {
  margin-left: 0;
  height: 32px;
  font-size: 14px !important;
  font-weight: 400;
}
.search input::-moz-placeholder {
  color: #3B003A !important;
  font-size: 14px;
  font-weight: 400;
}
.search input::placeholder {
  color: #3B003A !important;
  font-size: 14px;
  font-weight: 400;
}
.search input:focus {
  box-shadow: none;
  border-color: #B6B5B5;
}
.search .search-icon {
  display: none;
}
.search .wrapper {
  margin-top: 0;
  border-radius: 5px !important;
  padding-right: 2.5rem;
  margin-left: 0;
  height: 32px;
  border: 1px solid #B6B5B5;
  z-index: 9999 !important;
}
.search .wrapper > div {
  min-height: auto;
}
.search .sc-gueYoa {
  z-index: 99999;
  position: relative;
  background: #fff;
}
.search .flpAas > div.line {
  border-top-color: rgb(232, 234, 237);
  border-top-style: solid;
  border-top-width: 1px;
  margin-bottom: 0px;
  margin-left: 14px;
  margin-right: 20px;
  margin-top: 0px;
  padding-bottom: 4px;
}
.search .flpAas > ul {
  list-style-type: none;
  margin: 0;
  padding: 0px 0 16px 0;
}
.search .flpAas > ul > li {
  display: flex;
  align-items: center;
  padding: 4px 0 4px 0;
}
.search .flpAas .ellipsis {
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search .gXQXJO,
.search .dlcLYL {
  min-height: 30px;
}
.search .cpmfQo,
.search .fZTllq {
  height: 32px;
}
.search .jZvqdQ {
  margin: 0;
}
.search .btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  border-color: #5B005D;
  width: 40px;
  height: 32px;
  background-color: #5B005D;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 15px;
  cursor: pointer;
}
.search .btn svg {
  fill: #fff;
  display: flex;
}
.search .btn:focus {
  box-shadow: none;
}/*# sourceMappingURL=SearchField.css.map */