.bg-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 0vw;
  height: 100vh;
  background-color: #231535;
  content: "";
  z-index: 999;
  opacity: 0;
  transition: opacity 0.5s, width 0.5s;
}
.bg-overlay.show {
  transition: opacity 0.5s, width 0.5s;
  width: 100vw;
  opacity: 0.5;
}

.product-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wishlist-link svg {
  font-size: 22px;
  color: #231535;
}

.shareicon {
  font-size: 22px;
  margin-left: 5px;
  color: #231535;
}

.product-detail {
  color: #5D5D5D;
  font-weight: 400;
}
.product-detail .desc {
  padding-bottom: 45px;
  display: flex;
}
.product-detail .desc .maain {
  display: flex;
  width: 50%;
}
.product-detail .desc .maain .flex {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 8px;
  width: auto;
  height: -moz-fit-content;
  height: fit-content;
}
.product-detail .desc .maain .flex .img-div {
  border: 1px solid #E6E6E6;
  padding: 0px;
  border-radius: 0px;
  margin: 0 auto 10px;
  width: 60px;
  height: 60px;
}
.product-detail .desc .maain .flex .img-div .mini-img {
  max-width: 100%;
  width: 60px;
  height: 58px;
  cursor: pointer;
}
.product-detail .desc .maain .view-img {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 30px;
  border: 1px solid rgba(182, 181, 181, 0.7607843137);
  border-radius: 5px;
  width: 100%;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 550px;
}
.product-detail .desc .maain .view-img .img {
  max-width: 100%;
  height: auto;
}
.product-detail .desc .details {
  width: 50%;
}
.product-detail .desc .details h3.product-title {
  font-size: 14px;
  color: #676666;
  margin-bottom: 4px;
}
.product-detail .desc .details .share {
  display: flex;
}
.product-detail .desc .details .share .add-wishlist {
  color: #00A0E3;
  font-size: 14px;
  font-weight: 600;
  margin: 0 5px;
}
.product-detail .desc .details .share .add-wishlist a {
  display: flex;
  align-items: center;
  color: #00A0E3;
}
.product-detail .desc .details .share .add-wishlist a .FaRegHeart {
  margin-right: 5px;
  font-size: 20px;
}
.product-detail .desc .details .share .share-text {
  color: #00A0E3;
  font-size: 14px;
  font-weight: 600;
  margin: 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.product-detail .desc .details .share .share-text .FaShareAlt {
  margin-right: 5px;
}
.product-detail .desc .details .cat {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.product-detail .desc .details .price .value {
  font-size: 32px;
  color: #231535;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}
.product-detail .desc .details .price .value svg {
  font-weight: 400;
}
.product-detail .desc .details .price .value .fa-rupee-sign {
  margin-right: 0.5rem;
}
.product-detail .desc .details .price .value span.can-price {
  font-size: 16px;
  color: #5D5D5D;
  font-weight: 700;
  text-decoration: line-through;
  margin-left: 10px;
}
.product-detail .desc .details .price .value span.offer {
  color: #C5017C;
  font-size: 13px;
  font-weight: 400;
  margin-left: 1rem;
}
.product-detail .desc .details .size {
  font-weight: 400;
  margin-bottom: 1.25rem;
}
.product-detail .desc .details .size span {
  font-weight: 500;
}
.product-detail .desc .details .product-size {
  margin-bottom: 2rem;
}
.product-detail .desc .details .product-size .title {
  font-size: 12px;
  padding-right: 6px;
}
.product-detail .desc .details .product-size .title span {
  color: #5B005D;
  margin-left: 0px;
}
.product-detail .desc .details .product-size .title span a {
  color: #5B005D;
  padding-right: 6px;
}
.product-detail .desc .details .product-size .add {
  display: flex;
}
.product-detail .desc .details .product-size .add.btngroup {
  justify-content: flex-start;
  margin-top: 1rem;
}
.product-detail .desc .details .product-size .add.btngroup .btn {
  margin-right: 10px;
}
.product-detail .desc .details .product-size .add.btngroup .btn span svg {
  margin-right: 4px;
  vertical-align: inherit;
}
.product-detail .desc .details .product-size .add.btngroup .btn-primary.disabled {
  background-color: #5B005D;
  opacity: 0.6;
}
.product-detail .desc .details .product-size .add .form-select {
  padding: 0px 30px 1px 10px;
  outline: none;
  border: 1px solid #B6B5B5;
  border-radius: 5px;
  margin-right: 10px;
  width: 140px;
  height: 40px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.product-detail .desc .details .product-size .add .form-select::-ms-expand {
  display: none;
}
.product-detail .desc .details .product-size .add .form-select:focus {
  box-shadow: none;
}
.product-detail .desc .details .product-size .add .add_cart {
  background-color: #0F4A8A;
  color: #fff;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}
.product-detail .desc .details .product-size .add .add_cart .fa-shopping-cart {
  margin-right: 0.5rem;
}
.product-detail .desc .details .product-size .add .site-nav {
  top: 0;
  right: 0;
  width: 0vh;
  max-width: 750px;
  z-index: 1000;
  height: 100vh;
  margin-right: auto;
  position: fixed;
  transition: all 0.3s ease;
  background-color: #fff;
  border-left: 0;
}
.product-detail .desc .details .product-size .add .site-nav .btn-close {
  display: block;
  position: inherit;
}
.product-detail .desc .details .product-size .add .site-nav.site-nav--open {
  width: 50vw;
  transition: width 1s;
  z-index: 9999;
}
.product-detail .desc .details .product-size .add .site-nav .topheader {
  background-color: #0F4A8A;
  height: 100px;
  color: #FFF;
  text-transform: uppercase;
  padding: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-detail .desc .details .product-size .add .site-nav .topheader h2 {
  color: #FFF;
  margin-bottom: 0;
  font-size: 1.875rem;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: normal;
  text-transform: uppercase;
}
.product-detail .desc .details .product-size .add .site-nav .topheader h2 span {
  font-weight: 300;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body {
  height: 100%;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .product-price {
  padding: 1.375rem 1.875rem 0rem;
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 0rem;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .product-price li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-bottom: 1.25rem;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .product-price li h5 {
  font-size: 1.125rem;
  font-weight: bold;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .product-details {
  padding: 0.8rem 1.875rem 1.375rem;
  margin: 0;
  height: 645px;
  overflow-y: scroll;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer {
  height: 100px;
  padding: 1.375rem 1.875rem 1.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -3px 6px -5px #231535;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer a .btn-secondary {
  width: 150px;
  margin-right: 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 0px;
  background-color: transparent;
  color: #0F4A8A;
  overflow: hidden;
  position: relative;
  border: 1px solid #0F4A8A;
  height: 40px;
  display: inline-block;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer a .btn-primary {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0em 1em;
  text-transform: uppercase;
  border-radius: 0px;
  background: #0F4A8A;
  border: none;
  line-height: 42px;
  color: #FFF;
  z-index: 1;
  height: 40px;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  position: relative;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: auto;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer a .btn-primary::before, .product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer a .btn-primary::after {
  top: 0;
  width: 0;
  height: 100%;
  mix-blend-mode: overlay;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer a .btn-primary::before {
  left: 0;
  width: 0;
  background: #00A0E3;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer a .btn-primary::after {
  left: 100%;
  width: 0;
  background: #00A0E3;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer a .btn-primary span {
  position: relative;
  z-index: 9;
}
.product-detail .desc .details .product-size .add .site-nav .cart-body .cart-footer .total-prize {
  font-size: 1.5rem;
  font-weight: bold;
}
.product-detail .desc .details .product-size .add .quote,
.product-detail .desc .details .product-size .add .customise {
  color: #00A0E3;
  border-radius: 25px;
  padding: 10px 20px;
  border: 1px solid #00A0E3;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.product-detail .desc .details .product-size .add .quote .fa-cog,
.product-detail .desc .details .product-size .add .customise .fa-cog {
  margin-right: 0.5rem;
}
.product-detail .desc .details .product-size .add .select-wrapper:last-child select.form-select {
  margin-right: 0;
}
.product-detail .desc .details .select_delivery {
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.product-detail .desc .details .select_delivery strong {
  font-size: 1rem;
  font-weight: 600;
  display: block;
  margin-bottom: 0.5rem;
}
.product-detail .desc .details .select_delivery .enter {
  display: block;
}
.product-detail .desc .details .product-fetures {
  padding: 0 0 1rem 0;
  margin: 0 0 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #c8c7c7;
}
.product-detail .desc .details .product-fetures li {
  list-style-type: none;
  width: 33%;
  margin-bottom: 13px;
}
.product-detail .desc .details .product-fetures li img {
  margin-right: 4px;
}
.product-detail .desc .details .pin-check {
  margin-bottom: 1rem;
}
.product-detail .desc .details .pin-check .input-group {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  width: 210px;
}
.product-detail .desc .details .pin-check .input-group input {
  border: none;
  height: 40px;
}
.product-detail .desc .details .pin-check .input-group .btn-outline-secondary {
  background-color: #fff;
  color: #5B005D;
  border: none;
  border-left: 0;
  padding: 8px 10px;
  text-transform: uppercase;
  box-shadow: none;
}
.product-detail .desc .details .pin-check .form-error {
  color: red;
  font-size: 0.8rem;
  font-weight: 700;
  padding-top: 0.6rem;
}
.product-detail .desc .details .accordion .accordion-item:first-child .accordion-body {
  padding-left: 5px;
}
.product-detail .desc .details .accordion .accordion-item:first-child .accordion-body h6 {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 0;
}
.product-detail .desc .details .accordion .accordion-item:first-child .accordion-body table {
  border: 0;
  width: auto;
  margin-bottom: 0.75rem;
}
.product-detail .desc .details .accordion .accordion-item:first-child .accordion-body table td {
  border: 0;
  text-align: left;
  padding: 5px 0 0;
}
.product-detail .desc .details .accordion .accordion-item:nth-child(2) .accordion-body {
  padding-left: 5px;
}
.product-detail .desc .details .accordion .accordion-item:nth-child(2) .accordion-body table {
  border: 0;
  margin-bottom: 0.75rem;
}
.product-detail .desc .details .accordion .accordion-item:nth-child(2) .accordion-body table tr th {
  padding: 0;
  border: 0;
  background-color: #fbfbfb;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}
.product-detail .desc .details .accordion .accordion-item:nth-child(2) .accordion-body table td {
  border: 0;
  text-align: left;
}
.product-detail .desc .details .accordion .accordion-item:nth-child(3) .accordion-body, .product-detail .desc .details .accordion .accordion-item:last-child .accordion-body {
  padding-left: 5px;
}
.product-detail .desc .details .product_information {
  margin-bottom: 1rem;
  font-size: 500;
  font-weight: 600;
}
.product-detail .sliderWapper {
  margin-bottom: 4rem;
}
.product-detail .sliderWapper .title {
  color: #5B005D;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.product-detail .sliderWapper .slick-arrow.slick-prev {
  left: -20px;
}
.product-detail .sliderWapper .slick-arrow.slick-next {
  right: -20px;
}
.product-detail .sliderWapper .slick-list {
  padding-top: 2rem;
}
.product-detail .sliderWapper .slick-list {
  margin: 0 -8px;
}
.product-detail .sliderWapper .slick-list .slick-slide {
  margin: 0 8px;
}
.product-detail .sliderWapper .slick-list .slick-slide .card {
  width: 246px !important;
}
.product-detail .sliderWapper .slick-list .slick-slide .card:hover .btn-similar {
  display: none;
}

.largeimage {
  z-index: 9;
}

.popup-content a {
  color: inherit;
  margin-right: 6px;
}
.popup-content a > svg {
  border-radius: 5px;
}
.popup-content a:focus-visible {
  outline: none;
}
.popup-content a.facebook {
  color: #4267b2;
}
.popup-content a.insta {
  color: #df427b;
}
.popup-content a.twitter {
  color: #1da1f2;
}
.popup-content a.linkedin {
  color: #0a66c2;
}
.popup-content a.whatsapp {
  color: #31d131;
}
.popup-content a.mail {
  color: #b40909;
}
.popup-content .link {
  color: #fff;
  cursor: pointer;
}
.popup-content .link svg {
  background: #091f34;
  padding: 3px;
  border-radius: 5px;
}

.product-share .wishlist-link .tooltip-label:after {
  bottom: 11px;
}
.product-share .share-icon {
  cursor: pointer;
}

.popup-content {
  width: 170px !important;
}
.popup-content .popup-arrow {
  display: none;
}
.popup-content .close {
  color: #5B005D;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -8px;
  font-size: 13px;
  font-weight: 700;
  background: #fff;
  border-radius: 50px;
  display: flex;
  width: 18px;
  align-items: center;
  justify-content: center;
  height: 18px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #D8D8D8;
}

.react-responsive-modal-modal {
  width: 1246px;
  max-width: 1246px;
  padding: 0;
  overflow-y: unset;
  height: 800px;
}
.react-responsive-modal-modal .maindiv {
  height: 100%;
  overflow-y: auto;
}
.react-responsive-modal-modal .maindiv figure img {
  width: 100%;
}
.react-responsive-modal-modal .maindiv::-webkit-scrollbar {
  width: 10px;
}
.react-responsive-modal-modal .maindiv::-webkit-scrollbar-track {
  width: 5px;
  background-color: #F5F5F5;
  border-radius: 10px;
}
.react-responsive-modal-modal .maindiv::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: #5B005D;
  height: 62px;
  border-radius: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.react-responsive-modal-closeButton {
  top: -10px;
  right: -10px;
  width: 26px;
  height: 26px;
  border: 1px solid #d8d8d8;
  background-color: #FFF;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-responsive-modal-closeButton svg {
  font-size: 15px;
  fill: #5B005D;
  width: 18px;
  height: 18px;
}

.react-tabs__tab-list {
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 55px;
  width: 580px;
  overflow: hidden;
  max-width: 580px;
  margin: 0 auto;
  border: 1px solid #5B005D;
}

.react-tabs__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 55px;
  padding: 6px 12px;
  border-radius: 0;
  color: #5B005D;
  text-align: center;
  width: 290px;
  border-bottom: none;
}

.react-tabs__tab--selected {
  background: #5B005D;
  border-color: #5B005D;
  color: #FFF;
  font-size: 20px;
  border-radius: 0;
  font-weight: 400;
}

.react-tabs__tab-panel {
  padding-top: 2rem;
}

.Ring-tabs {
  padding: 0 40px 2rem;
}
.Ring-tabs h2 {
  font-size: 20px;
  background-color: #C5017C;
  color: #FFF;
  width: 124px;
  font-weight: 600;
  text-transform: uppercase;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Ring-tabs p {
  font-size: 20px;
  font-weight: 400;
  color: #231535;
}
.Ring-tabs figure {
  width: 360px;
  margin-bottom: 2.5rem;
}
.Ring-tabs figure img {
  width: 100%;
  height: 100%;
}
.Ring-tabs .inner-wrapper {
  padding: 0 100px;
}
.Ring-tabs .graybg {
  background-color: #F2F2F2;
  border: 1px solid #D5D4D4;
  text-align: center;
  padding: 2rem;
}
.Ring-tabs h3 {
  font-size: 32px;
  font-weight: 600;
  color: #5B005D;
}
.Ring-tabs table {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}
.Ring-tabs table th {
  background-color: #C5017C;
  color: #FFF;
  line-height: normal;
  padding: 1.2rem 0;
  vertical-align: top;
}
.Ring-tabs .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg:#FFF;
  color: #212529 --bs-table-striped-color;
}
.Ring-tabs .table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-accent-bg:#F9F9FA;
  color: #212529 --bs-table-striped-color;
}

.btm-content {
  text-align: center;
}
.btm-content p {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  color: #231535;
}

.have-ring {
  margin-top: 1.5rem;
}
.have-ring figure {
  width: 490px;
}
.have-ring .d-flex {
  justify-content: flex-start;
  align-items: center;
  color: #231535;
  font-size: 20px;
  font-weight: 400;
  padding-right: 4rem;
}
.have-ring table thead tr th span {
  display: block;
}

.btn-wishlist {
  background-color: #53C9C5;
  color: #FFF;
  width: 220px;
  height: 60px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.btn-wishlist svg {
  font-size: 32px;
  margin-right: 10px;
}

.wishlisttooltip {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.wishlisttooltip .btn.btn-wishlist {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 9;
}

.popup-overlay .popup-content {
  width: auto !important;
  padding: 2rem !important;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
}
.popup-overlay .popup-content .pop-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.popup-overlay .popup-content .pop-body .close {
  right: -20px;
  font-size: 30px;
  width: 40px;
  height: 40px;
  top: -20px;
}
.popup-overlay .popup-content .pop-body .close .closebtn {
  color: #5B005D;
  margin-top: -6px;
  margin-right: -1.5px;
}
.popup-overlay .popup-content .pop-body h2 {
  font-size: 30px;
  margin-bottom: 2rem;
  letter-spacing: normal;
  font-weight: 400;
}
.popup-overlay .popup-content .pop-body p {
  font-size: 14px;
  color: #5D5D5D;
}
.popup-overlay .popup-content .pop-body img {
  margin-bottom: 2rem;
  display: flex;
}
.popup-overlay .popup-content .pop-body img.iconLocation {
  width: 58px;
  margin: 1rem auto;
}
.popup-overlay .popup-content .pop-body .btn-primary.btn-lg {
  background-color: #5B005D;
  width: 286px;
  height: 40px;
  color: #FFFFFF;
  line-height: 40px;
  margin-right: 0;
}

.home .popup-overlay .popup-content .pop-body h2 {
  font-size: 32px;
  font-weight: 600;
}

.formMargin .formRe {
  line-height: 40px;
}
.formMargin .formRe .txtbox {
  height: 40px;
  border: 1px solid #b5b5b5;
}/*# sourceMappingURL=index.css.map */