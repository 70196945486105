.gifts p {
  font-size: 15px;
  font-weight: "";
  color: #231535;
  line-height: 25px;
}
.gifts h2 {
  text-align: center;
}
.gifts .banner-div {
  background: url(../../assets/images/gifts/gifts-banner.png) no-repeat center;
  background-size: cover;
}
.gifts .banner-div {
  height: 500px;
}
.gifts .banner-div .container {
  justify-content: center;
}
.gifts .banner-div .container h1 {
  margin-top: auto;
  margin-bottom: 2.5rem;
}
.gifts .banner-div .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gifts .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  padding-left: 0;
}
.gifts .steps li {
  list-style: none;
  text-align: center;
  position: relative;
  width: 215px;
}
.gifts .steps li h5 {
  font-size: 20px;
  font-weight: 600;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 0;
}
.gifts .steps li:first-child:after, .gifts .steps li:nth-child(2):after {
  position: absolute;
  content: "";
  width: 142px;
  height: 1px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(../../assets/images/gifts/icons/hr.svg);
  top: 30%;
  right: 0;
  transform: translateX(50%);
}
.gifts #myTab {
  justify-content: center;
  border-color: #B6B5B5;
}
.gifts #myTab .nav-item .nav-link {
  font-size: 14px;
  font-weight: 600;
  color: #5D5D5D;
  line-height: 17px;
  text-transform: uppercase;
  padding: 0.5rem 2rem;
}
.gifts #myTab .nav-item .nav-link.active, .gifts #myTab .nav-item .nav-link:hover {
  background-color: #C5017C;
  color: #F9F9FA;
  border: 1px solid #C5017C;
}
.gifts #myTabContent #giftcard .row {
  margin-top: 2rem;
}
.gifts #myTabContent #giftcard .row .gift-card {
  width: 33%;
  margin-bottom: 2rem;
}
.gifts #myTabContent #giftcard .row .gift-card .card {
  width: 100%;
  border: 0;
  box-shadow: none;
  position: relative;
}
.gifts #myTabContent #giftcard .row .gift-card .card .pric {
  font-size: 30px;
  font-weight: 700;
  color: #F9F9FA;
  line-height: 38px;
  position: absolute;
  right: 1.5rem;
  top: 1.25rem;
}
.gifts #myTabContent #giftcard .row .gift-card .card .card-body {
  text-align: center;
  height: auto;
  padding: 25px 10px;
}
.gifts #myTabContent #giftcard .row .gift-card .card .card-body .btn {
  margin: 0 10px;
  text-transform: uppercase;
}
.gifts #myTabContent #termsconditions .card {
  width: 1080px;
  max-width: 100%;
  margin: 2rem auto;
  padding: 1.5rem 2rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
}
.gifts #myTabContent #termsconditions .card h6 {
  font-size: 15px;
  font-weight: 600;
  color: #231535;
  line-height: 25px;
}
.gifts #myTabContent #termsconditions .card p {
  font-size: 15px;
  font-weight: "";
  color: #5D5D5D;
  line-height: 25px;
}
.gifts #myTabContent #termsconditions .card ol {
  padding-left: 15px;
}
.gifts #myTabContent #termsconditions .card ol li {
  font-size: 15px;
  font-weight: "";
  color: #5D5D5D;
  line-height: 25px;
}
.gifts #myTabContent #faqs .card {
  width: 1080px;
  max-width: 100%;
  margin: 2rem auto;
  padding: 1rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
}
.gifts #myTabContent #faqs .card .accordion .accordion-item:first-of-type {
  border-top: 0;
}
.gifts #myTabContent #faqs .card .accordion .accordion-item .accordion-button {
  font-size: 15px;
  font-weight: 600;
  color: #231535;
  line-height: 25px;
  padding-left: 15px;
}
.gifts #myTabContent #faqs .card .accordion .accordion-item .accordion-body {
  padding-left: 15px;
}
.gifts #myTabContent #faqs .card .accordion .accordion-item .accordion-body p {
  font-size: 15px;
  font-weight: "";
  color: #231535;
  line-height: 25px;
}
.gifts #myTabContent #faqs .card .accordion:last-child .accordion-item {
  border-bottom: 0;
}
.gifts #myTabContent #corporateenquiry > .card {
  width: 1174px;
  max-width: 100%;
  margin: 2rem auto 4rem;
  padding: 1.5rem 2rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
}
.gifts #myTabContent #corporateenquiry > .card .gap0 {
  display: flex;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 {
  padding-right: 0;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 h2 {
  text-align: left;
  margin-top: -40px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 0 100px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li {
  padding-left: 80px;
  position: relative;
  margin-bottom: 3rem;
  color: #5D5D5D;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #fff;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li .icon img {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li h5 {
  font-size: 20px;
  margin: 0;
  line-height: normal;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 {
  padding-left: 0;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded {
  border-radius: 10px;
  padding: 0px 30px 40px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .topheading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .topheading h5 {
  font-size: 20px;
  font-weight: bold;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 0;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .topheading span {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group {
  margin-bottom: 1rem;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group label {
  margin-bottom: 0.25rem;
  font-size: 15px !important;
  font-weight: "";
  color: #5D5D5D !important;
  line-height: 21px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .mb-3 {
  margin-bottom: 0 !important;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .mb-3 input {
  height: 40px;
  padding: 8px 7px;
  font-size: 14px;
  z-index: 2;
  position: relative;
  background-color: transparent;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .mb-3 input:focus {
  border: 1px solid #ced4da;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .mb-3 .error-message {
  position: relative;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .formMargin .lbl {
  display: inline-block;
  text-transform: capitalize;
  font-family: "Source Sans Pro", sans-serif;
  width: auto;
  margin-right: 5px;
  white-space: nowrap;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .formMargin .formRe .txtbox {
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 10px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .formMargin .formRe .txtbox:disabled {
  background-color: #f8f8f8;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .formMargin .lbl-wrap {
  display: flex;
  justify-content: flex-start;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select {
  display: flex;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .form-select {
  width: 84px !important;
  height: 40px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .form-select:disabled {
  background-color: #f8f8f8;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .formMargin {
  width: 100%;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .formMargin .error-message {
  line-height: normal;
  white-space: nowrap;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .formMargin:first-child {
  width: 110px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .formMargin:last-child .formRe {
  margin-top: 3px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.quantity .formRe {
  width: 68px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.quantity .error-message {
  white-space: nowrap;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group textarea {
  width: 100%;
  outline: none;
  resize: none;
  font-size: 14px;
  font-weight: "";
  color: #5D5D5D;
  line-height: 1.5;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  padding: 5px 10px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .txtbox {
  width: 102px !important;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li {
  padding-right: 0.5rem;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li .btn.btn-primary {
  color: #231535;
  border: 1px solid #C8C7C7;
  background-color: #fff;
  padding: 0 5px;
  font-size: 13px;
  width: 74px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.14902);
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li .btn.btn-primary.active, .gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li .btn.btn-primary:hover {
  color: #5B005D;
  border: 1px solid #5B005D;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card {
  width: 100%;
  min-height: auto;
  cursor: pointer;
  border: 1px solid #C8C7C7;
  border-radius: 5px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card img {
  width: 100%;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card .card-body {
  height: auto;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card .card-body h6 {
  font-size: 13px;
  font-weight: "";
  color: #000;
  line-height: 19px;
  margin-bottom: 0;
  text-align: center;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style.active .card {
  border: 2px solid #5B005D;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .slick-arrow.slick-prev {
  left: -25px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .slick-arrow.slick-next {
  right: -25px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .slick-slide {
  padding: 0 3px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info {
  padding-top: 1rem;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info .btn-primary {
  background-color: #5B005D;
  width: 164px;
  height: 40px;
  color: #FFFFFF;
  line-height: 40px;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info .btn-primary.btnmakepayment {
  background-color: #0B2138;
  color: #FFFFFF;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info .btn-primary:disabled {
  opacity: 40%;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .lbl {
  display: none;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .formRe {
  margin-top: 0.5rem;
}
.gifts #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .error-message {
  position: relative;
  width: 100%;
}

.buy-a-card .banner-div {
  background: url(../../assets/images/gifts/gifts-banner.png) no-repeat center;
  background-size: cover;
}
.buy-a-card .banner-div {
  height: 500px;
}
.buy-a-card .banner-div .container {
  justify-content: center;
}
.buy-a-card .banner-div .container h1 {
  margin-top: auto;
  margin-bottom: 2.5rem;
}
.buy-a-card .banner-div .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buy-a-card .gap0 {
  margin-bottom: 5rem;
}
.buy-a-card .gap0 .card-benefits {
  width: 40%;
  padding-right: 0;
  margin-top: 40px;
}
.buy-a-card .gap0 .card-benefits h2 {
  margin-top: 2rem;
}
.buy-a-card .gap0 .card-benefits .orangeBox {
  background: #FFF7EB;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 50px 50px 100px;
}
.buy-a-card .gap0 .card-benefits .orangeBox .bgImg {
  position: relative;
}
.buy-a-card .gap0 .card-benefits .orangeBox .bgImg img {
  width: 100%;
}
.buy-a-card .gap0 .card-benefits .orangeBox .bgImg .pric {
  font-size: 30px;
  font-weight: 700;
  color: #F9F9FA;
  line-height: 38px;
  position: absolute;
  right: 2rem;
  top: 1.25rem;
}
.buy-a-card .gap0 .card-benefits .orangeBox ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.buy-a-card .gap0 .card-benefits .orangeBox ul li {
  padding-left: 80px;
  position: relative;
  margin-bottom: 3rem;
  color: #5D5D5D;
}
.buy-a-card .gap0 .card-benefits .orangeBox ul li .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #fff;
}
.buy-a-card .gap0 .card-benefits .orangeBox ul li .icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.buy-a-card .gap0 .card-benefits .orangeBox ul li h5 {
  font-size: 20px;
  margin: 0;
  line-height: normal;
}
.buy-a-card .gap0 .form {
  width: 60%;
  padding-left: 0;
}
.buy-a-card .gap0 .form .shadow-box-rounded {
  border-radius: 10px;
  padding: 30px 30px 40px;
  height: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.buy-a-card .gap0 .form .shadow-box-rounded .topheading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.buy-a-card .gap0 .form .shadow-box-rounded .topheading h5 {
  font-size: 20px;
  font-weight: 600;
  color: #231535;
  line-height: 30px;
  margin-bottom: 0;
}
.buy-a-card .gap0 .form .shadow-box-rounded .topheading span {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group {
  margin-bottom: 1rem;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group label {
  margin-bottom: 0.25rem;
  font-size: 15px !important;
  font-weight: "";
  color: #5D5D5D !important;
  line-height: 21px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .mb-3 {
  margin-bottom: 0 !important;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .mb-3 input {
  height: 40px;
  padding: 8px 7px;
  font-size: 14px;
  z-index: 2;
  position: relative;
  background-color: transparent;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .mb-3 input:focus {
  border: 1px solid #ced4da;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .mb-3 .error-message {
  position: relative;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .lbl {
  display: inline-block;
  text-transform: capitalize;
  font-family: "Source Sans Pro", sans-serif;
  width: auto;
  margin-right: 5px;
  white-space: nowrap;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .formRe .txtbox {
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 10px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .formRe .txtbox:disabled {
  background-color: #f8f8f8;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .lbl-wrap {
  display: flex;
  justify-content: flex-start;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select {
  display: flex;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .form-select {
  width: 84px !important;
  height: 40px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .form-select:disabled {
  background-color: #f8f8f8;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin {
  width: 100%;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin .error-message {
  line-height: normal;
  white-space: nowrap;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:first-child {
  width: 110px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:last-child .formRe {
  margin-top: 3px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.quantity .formRe {
  width: 68px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.quantity .error-message {
  white-space: nowrap;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group textarea {
  width: 100%;
  outline: none;
  resize: none;
  font-size: 14px;
  font-weight: "";
  color: #5D5D5D;
  line-height: 1.5;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  padding: 5px 10px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount .txtbox {
  width: 102px !important;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount li {
  padding-right: 0.5rem;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount li .btn.btn-primary {
  color: #231535;
  border: 1px solid #C8C7C7;
  background-color: #fff;
  padding: 0 5px;
  font-size: 13px;
  width: 74px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.14902);
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount li .btn.btn-primary.active, .buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount li .btn.btn-primary:hover {
  color: #5B005D;
  border: 1px solid #5B005D;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .card-style .card {
  width: 100%;
  min-height: auto;
  cursor: pointer;
  border: 1px solid #C8C7C7;
  border-radius: 5px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .card-style .card img {
  width: 100%;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .card-style .card .card-body {
  height: auto;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .card-style .card .card-body h6 {
  font-size: 13px;
  font-weight: "";
  color: #000;
  line-height: 19px;
  margin-bottom: 0;
  text-align: center;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .card-style.active .card {
  border: 2px solid #5B005D;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .slick-arrow.slick-prev {
  left: -25px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .slick-arrow.slick-next {
  right: -25px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .slick-slide {
  padding: 0 3px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .make-info {
  padding-top: 1rem;
}
.buy-a-card .gap0 .form .shadow-box-rounded .make-info .btn-primary {
  background-color: #5B005D;
  width: 164px;
  height: 40px;
  color: #FFFFFF;
  line-height: 40px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .make-info .btn-primary.btnmakepayment {
  background-color: #0B2138;
  color: #FFFFFF;
}
.buy-a-card .gap0 .form .shadow-box-rounded .make-info .btn-primary:disabled {
  opacity: 40%;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .txtbox::-moz-placeholder {
  color: #C8C7C7;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .txtbox::placeholder {
  color: #C8C7C7;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .formRe {
  line-height: normal;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.radio {
  display: flex;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.radio input[type=radio] {
  accent-color: #5B005D;
  margin-right: 0.5rem;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .ammount {
  font-size: 15px;
  font-weight: 700 !important;
  color: #231535 !important;
  line-height: 21px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount .formMargin .lbl {
  display: none;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount .formMargin .btn.btn-primary {
  width: 74px !important;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group .amount .error-message {
  position: relative;
  width: 100%;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.card-name {
  width: 40%;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.card-name .message {
  font-size: 13px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 23px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.card-name .error-message {
  bottom: -35px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.name {
  display: flex;
  justify-content: space-between;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin {
  width: 43%;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin:first-child {
  width: 68px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin .formRe .txtbox {
  background-position: right 0.25rem center;
  padding: 5px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin .formRe .error-message {
  line-height: normal;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.quantity .formMargin .input-lable {
  font-size: 15px;
  font-weight: 700 !important;
  color: #231535 !important;
  line-height: 21px;
  margin-bottom: 0.5rem;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select {
  justify-content: space-between;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .form-select {
  background-position: right 0.25rem center;
  width: 68px !important;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin {
  width: 43%;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:first-child {
  width: 70px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:nth-child(2) .formRe {
  margin-top: 3px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:last-child .formRe {
  margin-top: 0;
}
.buy-a-card .gap0 .form .shadow-box-rounded h4 {
  font-size: 15px;
  font-weight: 700;
  color: #231535;
  line-height: 21px;
}
.buy-a-card .gap0 .form .shadow-box-rounded .make-info {
  text-align: center;
}
.buy-a-card .gap0 .form .shadow-box-rounded .make-info .checkbox .styled-checkbox + label {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
  letter-spacing: 0.02em;
}
.buy-a-card .gap0 .form .shadow-box-rounded .make-info .checkbox .styled-checkbox + label a {
  text-decoration: underline;
}

.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div {
  margin-bottom: 3rem;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .mb-3 input {
  height: 262px;
  width: 422px;
  border: 0;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .mb-3 input:focus {
  border: 0;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .mb-3 input[type=file] {
  padding: 0;
  background: url("../../assets/images/gifts/customised-upload.png") center center no-repeat;
  color: rgba(0, 0, 0, 0);
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .mb-3 input[type=file]::file-selector-button {
  display: none;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .mb-3 input[type=file].edit {
  background: url("../../assets/images/gifts/icons/edit.svg") center center no-repeat;
  height: 20px;
  width: 20px;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details {
  position: relative;
  width: 630px;
  max-width: 100%;
  margin: 0;
  width: 420px;
  height: 260px;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .card-img {
  width: auto;
  max-width: 100%;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .card-type {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 25px;
  position: absolute;
  left: 1.5rem;
  top: 1.75rem;
  text-shadow: 4.49286px 5.99048px 5.99048px rgba(0, 0, 0, 0.25);
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .pric {
  font-size: 30px;
  font-weight: 700;
  color: #F9F9FA;
  line-height: 38px;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .card-number {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  line-height: 32px;
  font-family: "Credit Card";
  position: absolute;
  right: 0;
  top: 7rem;
  left: 0;
  letter-spacing: 1.95643px;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  left: 10%;
  transform: translateX(0%);
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .valid-from {
  font-size: 8px;
  font-weight: 600;
  color: #fff;
  line-height: 10px;
  position: absolute;
  left: 2.5rem;
  bottom: 3rem;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .valid-from span {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  line-height: 16px;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .valid-thru {
  font-size: 8px;
  font-weight: 600;
  color: #fff;
  line-height: 10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3rem;
  text-align: center;
  text-shadow: 1.8268px 2.43573px 2.43573px rgba(0, 0, 0, 0.25);
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .valid-thru span {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  line-height: 16px;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .card-details .name {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  line-height: 19px;
  position: absolute;
  left: 2.5rem;
  bottom: 0.75rem;
  text-shadow: 1.8268px 2.43573px 2.43573px rgba(0, 0, 0, 0.25);
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .update {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 422px;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .update .edit {
  margin: 10px;
  cursor: pointer;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .update .edit[type=file] {
  padding: 0;
  color: rgba(0, 0, 0, 0);
  background: url("../../assets/images/gifts/icons/edit.svg") center center no-repeat;
  height: 20px;
  width: 20px;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .update .edit[type=file]::file-selector-button {
  display: none;
}
.buy-a-card-customised .gap0 .form .shadow-box-rounded .card-div .update .delete {
  margin: 10px;
  cursor: pointer;
}

.gift-a-card .banner-div {
  background: url(../../assets/images/gifts/gifts-banner.png) no-repeat center;
  background-size: cover;
}
.gift-a-card .banner-div {
  height: 500px;
}
.gift-a-card .banner-div .container {
  justify-content: center;
}
.gift-a-card .banner-div .container h1 {
  margin-top: auto;
  margin-bottom: 2.5rem;
}
.gift-a-card .banner-div .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gift-a-card .gap0 {
  margin-bottom: 5rem;
}
.gift-a-card .gap0 .card-benefits {
  width: 40%;
  padding-right: 0;
  margin-top: 40px;
}
.gift-a-card .gap0 .card-benefits h2 {
  margin-top: 2rem;
}
.gift-a-card .gap0 .card-benefits .orangeBox {
  background: #FFF7EB;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 50px 50px 100px;
  position: relative;
}
.gift-a-card .gap0 .card-benefits .orangeBox .pric {
  font-size: 30px;
  font-weight: 700;
  color: #F9F9FA;
  line-height: 38px;
  position: absolute;
  right: 2rem;
  top: 1.25rem;
}
.gift-a-card .gap0 .card-benefits .orangeBox ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.gift-a-card .gap0 .card-benefits .orangeBox ul li {
  padding-left: 80px;
  position: relative;
  margin-bottom: 3rem;
  color: #5D5D5D;
}
.gift-a-card .gap0 .card-benefits .orangeBox ul li .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #fff;
}
.gift-a-card .gap0 .card-benefits .orangeBox ul li .icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gift-a-card .gap0 .card-benefits .orangeBox ul li h5 {
  font-size: 20px;
  margin: 0;
  line-height: normal;
}
.gift-a-card .gap0 .form {
  width: 60%;
  padding-left: 0;
}
.gift-a-card .gap0 .form .shadow-box-rounded {
  border-radius: 10px;
  padding: 30px 30px 40px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.gift-a-card .gap0 .form .shadow-box-rounded .topheading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded .topheading h5 {
  font-size: 20px;
  font-weight: 600;
  color: #231535;
  line-height: 30px;
  margin-bottom: 0;
}
.gift-a-card .gap0 .form .shadow-box-rounded .topheading span {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group {
  margin-bottom: 1rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group label {
  margin-bottom: 0.25rem;
  font-size: 15px !important;
  font-weight: "";
  color: #5D5D5D !important;
  line-height: 21px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .mb-3 {
  margin-bottom: 0 !important;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .mb-3 input {
  height: 40px;
  padding: 8px 7px;
  font-size: 14px;
  z-index: 2;
  position: relative;
  background-color: transparent;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .mb-3 input:focus {
  border: 1px solid #ced4da;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .mb-3 .error-message {
  position: relative;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .lbl {
  display: inline-block;
  text-transform: capitalize;
  font-family: "Source Sans Pro", sans-serif;
  width: auto;
  margin-right: 5px;
  white-space: nowrap;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .formRe .txtbox {
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 10px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .formRe .txtbox:disabled {
  background-color: #f8f8f8;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .formMargin .lbl-wrap {
  display: flex;
  justify-content: flex-start;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select {
  display: flex;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .form-select {
  width: 84px !important;
  height: 40px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .form-select:disabled {
  background-color: #f8f8f8;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin {
  width: 100%;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin .error-message {
  line-height: normal;
  white-space: nowrap;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:first-child {
  width: 110px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:last-child .formRe {
  margin-top: 3px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.quantity .formRe {
  width: 68px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.quantity .error-message {
  white-space: nowrap;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group textarea {
  width: 100%;
  outline: none;
  resize: none;
  font-size: 14px;
  font-weight: "";
  color: #5D5D5D;
  line-height: 1.5;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  padding: 5px 10px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount .txtbox {
  width: 102px !important;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount li {
  padding-right: 0.5rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount li .btn.btn-primary {
  color: #231535;
  border: 1px solid #C8C7C7;
  background-color: #fff;
  padding: 0 5px;
  font-size: 13px;
  width: 74px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.14902);
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount li .btn.btn-primary.active, .gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount li .btn.btn-primary:hover {
  color: #5B005D;
  border: 1px solid #5B005D;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .card-style .card {
  width: 100%;
  min-height: auto;
  cursor: pointer;
  border: 1px solid #C8C7C7;
  border-radius: 5px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .card-style .card img {
  width: 100%;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .card-style .card .card-body {
  height: auto;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .card-style .card .card-body h6 {
  font-size: 13px;
  font-weight: "";
  color: #000;
  line-height: 19px;
  margin-bottom: 0;
  text-align: center;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .card-style.active .card {
  border: 2px solid #5B005D;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .slick-arrow.slick-prev {
  left: -25px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .slick-arrow.slick-next {
  right: -25px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .slick-slide {
  padding: 0 3px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .make-info {
  padding-top: 1rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded .make-info .btn-primary {
  background-color: #5B005D;
  width: 164px;
  height: 40px;
  color: #FFFFFF;
  line-height: 40px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .make-info .btn-primary.btnmakepayment {
  background-color: #0B2138;
  color: #FFFFFF;
}
.gift-a-card .gap0 .form .shadow-box-rounded .make-info .btn-primary:disabled {
  opacity: 40%;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .txtbox::-moz-placeholder {
  color: #C8C7C7;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .txtbox::placeholder {
  color: #C8C7C7;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.radio {
  display: flex;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.radio input[type=radio] {
  accent-color: #5B005D;
  margin-right: 0.5rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .ammount {
  font-size: 15px;
  font-weight: 700 !important;
  color: #231535 !important;
  line-height: 21px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount .formMargin .lbl {
  display: none;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group .amount .error-message {
  position: relative;
  width: 100%;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.name {
  display: flex;
  justify-content: space-between;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin {
  width: 43%;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin:first-child {
  width: 68px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin .formRe .txtbox {
  background-position: right 0.25rem center;
  padding: 5px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.name .formMargin .formRe .error-message {
  line-height: normal;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.quantity {
  margin-bottom: 2rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.quantity .formMargin .input-lable {
  font-size: 15px;
  font-weight: 700 !important;
  color: #231535 !important;
  line-height: 21px;
  margin-bottom: 0.5rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile {
  margin-bottom: 2rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select {
  justify-content: space-between;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .form-select {
  background-position: right 0.25rem center;
  width: 68px !important;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin {
  width: 43%;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:first-child {
  width: 70px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:nth-child(2) .formRe {
  margin-top: 3px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .form-group.mobile .cust-select .formMargin:last-child .formRe {
  margin-top: 0;
}
.gift-a-card .gap0 .form .shadow-box-rounded h3 {
  font-size: 20px;
  font-weight: 700;
  color: #3B003A;
  line-height: 25px;
  margin-top: 1.5rem;
}
.gift-a-card .gap0 .form .shadow-box-rounded h4 {
  font-size: 15px;
  font-weight: 700;
  color: #231535;
  line-height: 21px;
}
.gift-a-card .gap0 .form .shadow-box-rounded .make-info {
  text-align: center;
}
.gift-a-card .gap0 .form .shadow-box-rounded .make-info .checkbox .styled-checkbox + label {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
  letter-spacing: 0.02em;
}
.gift-a-card .gap0 .form .shadow-box-rounded .make-info .checkbox .styled-checkbox + label a {
  text-decoration: underline;
}

.giftsreview .gifts-review, .giftsreview .gift-review, .giftsreview .customise-gifts-review, .giftreview .gifts-review, .giftreview .gift-review, .giftreview .customise-gifts-review, .customisegiftsreview .gifts-review, .customisegiftsreview .gift-review, .customisegiftsreview .customise-gifts-review {
  margin-top: 11rem;
}
.giftsreview .gifts-review .checkout-details .contact .left h4, .giftsreview .gift-review .checkout-details .contact .left h4, .giftsreview .customise-gifts-review .checkout-details .contact .left h4, .giftreview .gifts-review .checkout-details .contact .left h4, .giftreview .gift-review .checkout-details .contact .left h4, .giftreview .customise-gifts-review .checkout-details .contact .left h4, .customisegiftsreview .gifts-review .checkout-details .contact .left h4, .customisegiftsreview .gift-review .checkout-details .contact .left h4, .customisegiftsreview .customise-gifts-review .checkout-details .contact .left h4 {
  font-size: 20px;
  font-weight: 600;
  color: #231535;
  line-height: 30px;
}
.giftsreview .gifts-review .checkout-details .contact .left h5, .giftsreview .gift-review .checkout-details .contact .left h5, .giftsreview .customise-gifts-review .checkout-details .contact .left h5, .giftreview .gifts-review .checkout-details .contact .left h5, .giftreview .gift-review .checkout-details .contact .left h5, .giftreview .customise-gifts-review .checkout-details .contact .left h5, .customisegiftsreview .gifts-review .checkout-details .contact .left h5, .customisegiftsreview .gift-review .checkout-details .contact .left h5, .customisegiftsreview .customise-gifts-review .checkout-details .contact .left h5 {
  font-size: 15px;
  font-weight: 600;
  color: #231535;
  line-height: 25px;
  margin-bottom: 0.5rem;
}
.giftsreview .gifts-review .checkout-details .contact .left .review-add-details h6, .giftsreview .gift-review .checkout-details .contact .left .review-add-details h6, .giftsreview .customise-gifts-review .checkout-details .contact .left .review-add-details h6, .giftreview .gifts-review .checkout-details .contact .left .review-add-details h6, .giftreview .gift-review .checkout-details .contact .left .review-add-details h6, .giftreview .customise-gifts-review .checkout-details .contact .left .review-add-details h6, .customisegiftsreview .gifts-review .checkout-details .contact .left .review-add-details h6, .customisegiftsreview .gift-review .checkout-details .contact .left .review-add-details h6, .customisegiftsreview .customise-gifts-review .checkout-details .contact .left .review-add-details h6 {
  font-size: 13px;
  font-weight: 600;
  color: #3F3F3F;
  line-height: 23px;
  margin-bottom: 0;
}
.giftsreview .gifts-review .checkout-details .contact .left .review-add-details ul li, .giftsreview .gift-review .checkout-details .contact .left .review-add-details ul li, .giftsreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul li, .giftreview .gifts-review .checkout-details .contact .left .review-add-details ul li, .giftreview .gift-review .checkout-details .contact .left .review-add-details ul li, .giftreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul li, .customisegiftsreview .gifts-review .checkout-details .contact .left .review-add-details ul li, .customisegiftsreview .gift-review .checkout-details .contact .left .review-add-details ul li, .customisegiftsreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul li {
  padding-right: 3rem;
}
.giftsreview .gifts-review .checkout-details .contact .left .review-add-details ul li:last-child, .giftsreview .gift-review .checkout-details .contact .left .review-add-details ul li:last-child, .giftsreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul li:last-child, .giftreview .gifts-review .checkout-details .contact .left .review-add-details ul li:last-child, .giftreview .gift-review .checkout-details .contact .left .review-add-details ul li:last-child, .giftreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul li:last-child, .customisegiftsreview .gifts-review .checkout-details .contact .left .review-add-details ul li:last-child, .customisegiftsreview .gift-review .checkout-details .contact .left .review-add-details ul li:last-child, .customisegiftsreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul li:last-child {
  padding-right: 0;
}
.giftsreview .gifts-review .checkout-details .contact .left .review-add-details ul:last-child, .giftsreview .gift-review .checkout-details .contact .left .review-add-details ul:last-child, .giftsreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul:last-child, .giftreview .gifts-review .checkout-details .contact .left .review-add-details ul:last-child, .giftreview .gift-review .checkout-details .contact .left .review-add-details ul:last-child, .giftreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul:last-child, .customisegiftsreview .gifts-review .checkout-details .contact .left .review-add-details ul:last-child, .customisegiftsreview .gift-review .checkout-details .contact .left .review-add-details ul:last-child, .customisegiftsreview .customise-gifts-review .checkout-details .contact .left .review-add-details ul:last-child {
  margin-bottom: 0;
}
.giftsreview .gifts-review .checkout-details .contact .left .contact-details, .giftsreview .gift-review .checkout-details .contact .left .contact-details, .giftsreview .customise-gifts-review .checkout-details .contact .left .contact-details, .giftreview .gifts-review .checkout-details .contact .left .contact-details, .giftreview .gift-review .checkout-details .contact .left .contact-details, .giftreview .customise-gifts-review .checkout-details .contact .left .contact-details, .customisegiftsreview .gifts-review .checkout-details .contact .left .contact-details, .customisegiftsreview .gift-review .checkout-details .contact .left .contact-details, .customisegiftsreview .customise-gifts-review .checkout-details .contact .left .contact-details {
  padding: 0.5rem 1rem 0rem 1rem;
}

.giftreview .gift-review h3 {
  font-size: 20px;
  font-weight: 700;
  color: #5B005D;
  line-height: 25px;
}
.giftreview .gift-review .checkout-details .contact .left .contact-details .review-add-details ul {
  margin-bottom: 0;
}

.thanks .heading h4 {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 25px;
}
.thanks .heading h5 {
  font-size: 15px;
  font-weight: 700;
  color: #5D5D5D;
  line-height: 25px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.thanks .order-info .track-order .order-details ul {
  display: flex;
}
.thanks .order-info .track-order .track-order-details .btn-secondary svg {
  font-size: 1.25rem;
}
.thanks .thank-order-summary {
  text-align: center;
  margin-bottom: 4rem;
}
.thanks .thank-order-summary h4 {
  font-size: 20px;
  font-weight: 400;
  color: #231535;
  line-height: 25px;
}
.thanks .thank-order-summary .card-details {
  position: relative;
  width: 630px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 2rem;
}
.thanks .thank-order-summary .card-details .card-img {
  width: auto;
  max-width: 100%;
}
.thanks .thank-order-summary .card-details .card-type {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  line-height: 38px;
  position: absolute;
  left: 2.5rem;
  top: 2.75rem;
  text-shadow: 4.49286px 5.99048px 5.99048px rgba(0, 0, 0, 0.25);
}
.thanks .thank-order-summary .card-details .pric {
  font-size: 45px;
  font-weight: 700;
  color: #F9F9FA;
  line-height: 38px;
  position: absolute;
  right: 2rem;
  top: 2.75rem;
}
.thanks .thank-order-summary .card-details .card-number {
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  line-height: 32px;
  font-family: "Credit Card";
  position: absolute;
  right: 0;
  top: 9rem;
  left: 0;
  letter-spacing: 2px;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
}
.thanks .thank-order-summary .card-details .valid-from {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  position: absolute;
  left: 2.5rem;
  bottom: 4rem;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
}
.thanks .thank-order-summary .card-details .valid-from span {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 25px;
}
.thanks .thank-order-summary .card-details .valid-thru {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  line-height: 16px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 4rem;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
}
.thanks .thank-order-summary .card-details .valid-thru span {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 25px;
}
.thanks .thank-order-summary .card-details .name {
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  line-height: 31px;
  position: absolute;
  left: 2.5rem;
  bottom: 0.75rem;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
}
.thanks .thank-order-summary .get-card ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  padding-left: 0;
}
.thanks .thank-order-summary .get-card ul li {
  list-style-type: none;
  margin: 0px 15px;
}
.thanks .thank-order-summary .get-card ul li span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.thanks .thank-order-summary .get-card ul li span svg {
  font-size: 1.25rem;
  margin-right: 5px;
}
.thanks .thank-order-summary .get-card .btn.btn-primary {
  width: 223px;
}/*# sourceMappingURL=index.css.map */