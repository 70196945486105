.BudgetList {
  display: flex;
  justify-content: space-between;
}

.card.BudgetCard {
  min-height: 340px;
}
.card.BudgetCard p {
  font-size: 15px;
  margin-bottom: 0px;
}
.card.BudgetCard .price {
  bottom: 0px;
  font-weight: 600;
  line-height: normal;
}
.card.BudgetCard .price span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.card.BudgetCard .card-body {
  padding: 10px 10px 15px;
  height: 70px;
}
.card.BudgetCard:hover {
  height: auto;
}/*# sourceMappingURL=index.css.map */