.tryathome_acc{
    .accordion {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    .accorHeading {
                        table {
                            th {
                                padding: 0 0 8px !important;
                                &:first-child{width: 150px;}
                                &:nth-child(2){width: 200px;}
                                &:nth-child(3){width: 100px;}
                            }
                        }
                    }
                }
            }
            .accordion-body{
                .tableContent{
                    table{
                        tbody{
                            tr{
                                td{
                                    &:last-child{
                                        display: table-cell !important;
                                    }

                                    ul{margin-bottom: 0;}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}