@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

.hide-price-offer{
    .card{
        h5.offer{
            display: none;
        }
        small{
            display: none;
        }
    }
}

.card {
    border: 1px solid $cardcolor;
    border-radius: 10px;
    min-height: 320px;
    width: 250px;

    .card-header{
        height: 270px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 0;
        transition: .5s all ease-in-out;
        background: #fff;
        padding-top: 50px;
    }

    img.card-img-top {
        width: auto;
        max-width: 100%;
        transition: all .5s ease;
        height: 100%;

        &:hover{
            transition: all 0.5s ease;
            transform: scale(1.1);
        }
    }

    .product-label {
        ul {
            @include copyright(0, flex);
            align-items: flex-start;
            margin: 0;
        }

        position: absolute;
        top: 0;
        padding:10px;
        width: 100%;

        .wishlist-link {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    a {
        color: inherit;display: inline-block;border-top-left-radius:10px;border-top-right-radius:10px;    width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;overflow: hidden;
    }

    p {
        margin-bottom: 10px;line-height: normal;
    }

    .price {
        position: relative;
        bottom: 0;
        @include font(1.25rem, bold, $maincolor);
        width: 100%;
        left: 0;
        line-height: 2.5rem;
        i.fa {
            padding-right: 4px;
        }

        s {
            font-size: 0.875rem;
            padding-left: 4px;
        }

        span.cart {
            float: right;display: none;
        }

        .tooltip-label:after {
            bottom: 24px;
        }
        small{
            font-size: 0.875rem;
        }
    }

    .card-body {
        padding: 10px 10px;
        position: relative;
        height:100px;
        p{
            font-size: 13px;
        }
    }
    .jewels-name {
        font-size: 14px;font-weight: 400;margin-bottom: 4px;
    }
    h5.offer {position: relative;bottom:0px;margin-bottom: 0;line-height: normal;}
    .btn-similar {display: none;}
    &:hover {
        box-shadow: 1px 1px 5px #ddd;
        padding-bottom: 0;
        // height: 420px;
        .btn-similar {
            border:1px solid #5B005D;
            border-radius: 5px;
            position: absolute;
            bottom:10px;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            display: flex;
            justify-content: center;
            
            .btn {
                text-transform: uppercase;
                width: 100%;
                color:#5B005D;
                font-weight: 600;
                border-radius: 5px;
                &::focus {
                    outline: 0;
                }
            }
        }

        > img{
            transform: scale(1.1);
            transition: all 0.5s ease;
        }
    }
}