.offer-msg {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding-right: 300px;
}
.offer-msg h5 {
  font-size: 0.9rem;
  margin-bottom: 0;
  line-height: 25px;
}
.offer-msg h5 a {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.marquee-container .overlay {
  display: none;
}/*# sourceMappingURL=Offermsg.css.map */