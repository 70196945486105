@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';

.customise-request{
    .bannerSec {
        position: relative;
        // height:445px;
        height: 565px;
        figure {
            width: 100%;
            height: 100%;
            margin: 0;
            position: absolute;
            img {width: 100%; height: 100%;}
            figcaption {
                width: 45%;
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                h1 {color:#fff;line-height: 50px;font-family: 'Bahnschrift';
                    span{display: block;}
                }
            }
        }
    }

    .row.gap0{
        margin-bottom: 5rem;
        .col-md-5{
            padding-right: 0;

            h2{margin-top: 1.5rem;}

            .orangeBox {
                background: #FFF7EB;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                padding:40px 0 100px 80px;
                ul {
                    margin:0;padding:0;list-style-type: none;
                    li {
                        padding-left: 80px;position: relative;margin-bottom:3rem;
                        color:#5D5D5D;
                        .icon{
                            position: absolute;
                            top:0;
                            left:0;
                            width: 60px;
                            height: 60px;
                            border-radius: 100%;
                            background: #fff;
                            img{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);}
                        }
                        h5{font-size: 20px;margin:0;line-height: normal;}
                    }
                }
            }
            .bgImg {margin-top: -125px;text-align: center;}
        }

        .col-md-7{
            padding-left: 0;
            .shadow-box-rounded {
                border-radius: 10px;
                padding:30px 30px 40px;
                box-shadow: 0 0 30px rgba(0,0,0,0.10);

                .topheading{display: flex;justify-content: space-between;margin-bottom:1.5rem;
                    h5{@include font(20px,bold,$grayColor,30px);margin-bottom: 0;}
                    span{@include font(15px,400,$grayColor,21px);}
                }

                h6{@include font(15px,400,$grayColor,21px);margin-bottom:0;}
                ul{display: flex;list-style: none;padding-left: 0;
                    li{margin-right: 1rem;
                        input{margin-right: 10px;}
                        label{@include font(15px,400,$grayColor,21px);}
                    }
                }

                .form-row{list-style: none;display: flex;justify-content: space-between;
                    .form-group{width: 48%;
                    &.country-code{width: 12%;
                    .lbl{white-space: nowrap;}
                    }
                    &.tel{width: 35%;display: flex;align-items: flex-end;
                        .formMargin{width: 95%;}
                    }
                    &.req{
                        width: 100%;
                        .requirements{display: block;}
                            textarea{padding: 5px 7px 7px 5px;width: 100%;resize: none;outline: none;border-radius: 5px;border: 1px solid #b5b5b5;}
                    }
                    
                    }
                }

                .form-group{
                    margin-bottom: 1rem;
                    label{margin-bottom: 0.25rem;
                        @include font(15px,400,$grayColor,21px);
                    }
                    .mb-3{margin-bottom: 0 !important;
                    input{height: 40px;padding: 5px 7px 7px 5px;font-size: 14px;
                    &:focus{border: 1px solid #ced4da;}
                    }
                    .error-message{position: relative;}
                    }
                    .formMargin {
                        .lbl{display: inline-block;margin-bottom: 0.25rem;text-transform: capitalize;font-family: $basefont;
                            @include font(15px !important,400,$grayColor,21px);
                        }
                        .formRe {
                            .txtbox{border-radius: 5px;font-size: 13px;font-family:$basefont;
                                &:disabled{background-color: #f8f8f8;}
                            }
                        }
                        .error-message{position: relative;line-height: normal;}
                    }
                }
                  
                .make-info {
                    padding-top: 1rem;
                    text-align: center;
    
                    .btn-primary {
                        background-color: $linkcolor;
                        width: 250px;
                        height: 40px;
                        color: #FFFFFF;
                        line-height: 40px;
    
                        &.btnmakepayment {
                            background-color: #0B2138;
                            color: #FFFFFF;
                        }
                        &:disabled{
                            opacity: 40%;
                        }
                    }
    
                }

            }
        }
    }
}

.customise-thank-you {
    .login-box{
        margin-top: 3rem;
        .login-wrapper{
            width: 900px;text-align:center;border-radius: 5px;

            h5{@include font(20px,bold,$grayColor,30px);
                &.grant{font-weight: 600;
                    span{display: block;}
                }
            }

            .contact-list{justify-content: center;margin-bottom:2rem;
            li{width: 100%;display: flex;
                background: linear-gradient(90deg, rgba(66,224,209,1) 35%, rgba(99,1,93,1) 100%);
                img{width: 50px;}
                h2{color: $whitecolor;margin-bottom: 0;margin-left: 1rem;}
            }
            }

            h6{@include font(20px,600,$grayColor,30px);margin-bottom:2rem;
                span{display: block;}
                &::before,&::after{display: none;}
            }

            p{@include font(20px,400,$grayColor,30px);margin-bottom:3rem;
                span{display: block;
                    a{color: $linkcolor;text-decoration: none;}
                }
                &.choice{font-weight: 600;line-height: 45px;margin-bottom: 0rem;}
                &.team{line-height: 45px;margin-bottom: 0rem;}
            }

        }
        
    }
}