.giftcard #myTab {
  justify-content: center;
  border-color: #B6B5B5;
}
.giftcard #myTab .nav-item .nav-link {
  font-size: 14px;
  font-weight: 600;
  color: #5D5D5D;
  line-height: 17px;
  text-transform: uppercase;
  padding: 0.5rem 2rem;
}
.giftcard #myTab .nav-item .nav-link.active, .giftcard #myTab .nav-item .nav-link:hover {
  background-color: #C5017C;
  color: #F9F9FA;
  border: 1px solid #C5017C;
}
.giftcard #myTabContent #giftcard .row {
  margin-top: 2rem;
}
.giftcard #myTabContent #giftcard .row .gift-card {
  width: 33%;
  margin-bottom: 2rem;
}
.giftcard #myTabContent #giftcard .row .gift-card .card {
  width: 100%;
  border: 0;
  box-shadow: none;
  position: relative;
}
.giftcard #myTabContent #giftcard .row .gift-card .card .pric {
  font-size: 30px;
  font-weight: 700;
  color: #F9F9FA;
  line-height: 38px;
  position: absolute;
  right: 1.5rem;
  top: 1.25rem;
}
.giftcard #myTabContent #giftcard .row .gift-card .card .card-body {
  text-align: center;
  height: auto;
  padding: 25px 10px;
}
.giftcard #myTabContent #giftcard .row .gift-card .card .card-body .btn {
  margin: 0 10px;
  text-transform: uppercase;
}
.giftcard #myTabContent #termsconditions .card {
  width: 1080px;
  max-width: 100%;
  margin: 2rem auto;
  padding: 1.5rem 2rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
}
.giftcard #myTabContent #termsconditions .card h6 {
  font-size: 15px;
  font-weight: 600;
  color: #231535;
  line-height: 25px;
}
.giftcard #myTabContent #termsconditions .card p {
  font-size: 15px;
  font-weight: "";
  color: #5D5D5D;
  line-height: 25px;
}
.giftcard #myTabContent #termsconditions .card ol {
  padding-left: 15px;
}
.giftcard #myTabContent #termsconditions .card ol li {
  font-size: 15px;
  font-weight: "";
  color: #5D5D5D;
  line-height: 25px;
}
.giftcard #myTabContent #faqs .card {
  width: 1080px;
  max-width: 100%;
  margin: 2rem auto;
  padding: 1rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
}
.giftcard #myTabContent #faqs .card .accordion .accordion-item:first-of-type {
  border-top: 0;
}
.giftcard #myTabContent #faqs .card .accordion .accordion-item .accordion-button {
  font-size: 15px;
  font-weight: 600;
  color: #231535;
  line-height: 25px;
  padding-left: 15px;
}
.giftcard #myTabContent #faqs .card .accordion .accordion-item .accordion-body {
  padding-left: 15px;
}
.giftcard #myTabContent #faqs .card .accordion .accordion-item .accordion-body p {
  font-size: 15px;
  font-weight: "";
  color: #231535;
  line-height: 25px;
}
.giftcard #myTabContent #faqs .card .accordion:last-child .accordion-item {
  border-bottom: 0;
}
.giftcard #myTabContent #corporateenquiry > .card {
  width: 1174px;
  max-width: 100%;
  margin: 2rem auto 4rem;
  padding: 1.5rem 2rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.160784);
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 {
  display: flex;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 {
  padding-right: 0;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 h2 {
  text-align: left;
  margin-top: -40px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 0 100px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li {
  padding-left: 80px;
  position: relative;
  margin-bottom: 3rem;
  color: #5D5D5D;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #fff;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li .icon img {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .orangeBox ul li h5 {
  font-size: 20px;
  margin: 0;
  line-height: normal;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 {
  padding-left: 0;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded {
  border-radius: 10px;
  padding: 0px 30px 40px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .topheading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .topheading h5 {
  font-size: 20px;
  font-weight: bold;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 0;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .topheading span {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group {
  margin-bottom: 1rem;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group label {
  margin-bottom: 0.25rem;
  font-size: 15px !important;
  font-weight: "";
  color: #5D5D5D !important;
  line-height: 21px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .mb-3 {
  margin-bottom: 0 !important;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .mb-3 input {
  height: 40px;
  padding: 8px 7px;
  font-size: 14px;
  z-index: 2;
  position: relative;
  background-color: transparent;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .mb-3 input:focus {
  border: 1px solid #ced4da;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .mb-3 .error-message {
  position: relative;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .formMargin .lbl {
  display: inline-block;
  text-transform: capitalize;
  font-family: "Source Sans Pro", sans-serif;
  width: auto;
  margin-right: 5px;
  white-space: nowrap;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .formMargin .formRe .txtbox {
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 10px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .formMargin .formRe .txtbox:disabled {
  background-color: #f8f8f8;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .formMargin .lbl-wrap {
  display: flex;
  justify-content: flex-start;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select {
  display: flex;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .form-select {
  width: 84px !important;
  height: 40px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .form-select:disabled {
  background-color: #f8f8f8;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .formMargin {
  width: 100%;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .formMargin .error-message {
  line-height: normal;
  white-space: nowrap;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .formMargin:first-child {
  width: 110px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.mobile .cust-select .formMargin:last-child .formRe {
  margin-top: 3px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.quantity .formRe {
  width: 68px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group.quantity .error-message {
  white-space: nowrap;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group textarea {
  width: 100%;
  outline: none;
  resize: none;
  font-size: 14px;
  font-weight: "";
  color: #5D5D5D;
  line-height: 1.5;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  padding: 5px 10px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .txtbox {
  width: 102px !important;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li {
  padding-right: 0.5rem;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li .btn.btn-primary {
  color: #231535;
  border: 1px solid #C8C7C7;
  background-color: #fff;
  padding: 0 5px;
  font-size: 13px;
  width: 74px;
  box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.14902);
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li .btn.btn-primary.active, .giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount li .btn.btn-primary:hover {
  color: #5B005D;
  border: 1px solid #5B005D;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card {
  width: 100%;
  min-height: auto;
  cursor: pointer;
  border: 1px solid #C8C7C7;
  border-radius: 5px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card img {
  width: 100%;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card .card-body {
  height: auto;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style .card .card-body h6 {
  font-size: 13px;
  font-weight: "";
  color: #000;
  line-height: 19px;
  margin-bottom: 0;
  text-align: center;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .card-style.active .card {
  border: 2px solid #5B005D;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .slick-arrow.slick-prev {
  left: -25px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .slick-arrow.slick-next {
  right: -25px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .slick-slide {
  padding: 0 3px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info {
  padding-top: 1rem;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info .btn-primary {
  background-color: #5B005D;
  width: 164px;
  height: 40px;
  color: #FFFFFF;
  line-height: 40px;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info .btn-primary.btnmakepayment {
  background-color: #0B2138;
  color: #FFFFFF;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .make-info .btn-primary:disabled {
  opacity: 40%;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .lbl {
  display: none;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .formRe {
  margin-top: 0.5rem;
}
.giftcard #myTabContent #corporateenquiry > .card .gap0 .col-md-6 .shadow-box-rounded .form-group .amount .error-message {
  position: relative;
  width: 100%;
}
.giftcard h5 {
  margin-bottom: 1rem !important;
}
.giftcard #myTab {
  justify-content: flex-start;
}
.giftcard .row {
  margin: 1.5rem 0;
}
.giftcard .row .card {
  width: 100%;
  min-height: auto;
  padding: 16px 5px;
  border: 1px solid #E7E7E7;
  background-color: #FEFCFE;
  border-radius: 4px;
  margin-bottom: 30px;
}
.giftcard .row .card .all-card {
  border: 0;
}
.giftcard .row .card tbody td {
  list-style-type: none;
}
.giftcard .row .card tbody td:first-child {
  padding-top: 0;
}
.giftcard .row .card tbody td:last-child {
  margin-right: 0;
}
.giftcard .row .card tbody td img {
  width: 110px;
}
.giftcard .row .card tbody td h4 {
  font-size: 14px;
  font-weight: 400;
  color: #231535;
  line-height: 24px;
  margin-bottom: 0;
}
.giftcard .row .card tbody td span {
  font-size: 14px;
  font-weight: 600;
  color: #231535;
  line-height: 24px;
}
.giftcard .row .card tbody td span.redeem {
  font-size: 12px;
  font-weight: 600;
  color: #5B005D;
  line-height: 24px;
  text-decoration: underline;
  display: block;
  cursor: pointer;
}
.giftcard .row .card tbody td span.Active {
  color: #00662F;
}
.giftcard .row .card tbody td span.Expired {
  color: #DA0202;
}
.giftcard .row .card:hover {
  box-shadow: none;
}
.giftcard .row .card tbody {
  display: flex;
  justify-content: space-around;
}
.giftcard .row .card tbody td {
  border: 0;
  text-align: left;
}
.giftcard .row .card tbody td.img {
  width: 110px;
}
.giftcard .row .card tbody td.img img {
  width: 100%;
}
.giftcard .row .card tbody td.card-name {
  width: 120px;
}
.giftcard .row .card tbody td.card-number {
  width: 140px;
}
.giftcard .row .card tbody td.full-name {
  width: 110px;
}
.giftcard .row .card tbody td.total-amount {
  width: 90px;
}
.giftcard .row .card tbody td.balance-amount {
  width: 100px;
}
.giftcard .row .card tbody td.card-validity {
  width: 80px;
}
.giftcard .row .card tbody td.status {
  width: 50px;
}

.myaccount .popup-overlay .popup-content tbody td {
  list-style-type: none;
}
.myaccount .popup-overlay .popup-content tbody td:first-child {
  padding-top: 0;
}
.myaccount .popup-overlay .popup-content tbody td:last-child {
  margin-right: 0;
}
.myaccount .popup-overlay .popup-content tbody td img {
  width: 110px;
}
.myaccount .popup-overlay .popup-content tbody td h4 {
  font-size: 14px;
  font-weight: 400;
  color: #231535;
  line-height: 24px;
  margin-bottom: 0;
}
.myaccount .popup-overlay .popup-content tbody td span {
  font-size: 14px;
  font-weight: 600;
  color: #231535;
  line-height: 24px;
}
.myaccount .popup-overlay .popup-content tbody td span.redeem {
  font-size: 12px;
  font-weight: 600;
  color: #5B005D;
  line-height: 24px;
  text-decoration: underline;
  display: block;
  cursor: pointer;
}
.myaccount .popup-overlay .popup-content tbody td span.Active {
  color: #00662F;
}
.myaccount .popup-overlay .popup-content tbody td span.Expired {
  color: #DA0202;
}
.myaccount .popup-overlay .popup-content .inner-div {
  height: auto !important;
  width: 100%;
  max-width: 800px;
}
.myaccount .popup-overlay .popup-content .inner-div .all-card {
  border: 0;
}
.myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail {
  display: flex;
  justify-content: space-around;
}
.myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td {
  padding: 0 10px;
  text-align: left;
  border: 0;
}
.myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td img {
  margin-bottom: 0;
}
.myaccount .popup-overlay .popup-content .inner-div ul {
  padding-left: 0;
  margin-bottom: 0;
}
.myaccount .popup-overlay .popup-content .inner-div ul li {
  margin-bottom: 0;
}
.myaccount .popup-overlay .popup-content .inner-div ul li img {
  margin-bottom: 0;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails {
  margin-top: 30px;
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 30px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table {
  border-radius: 4px;
  border: 1px solid #DBDBDB;
  border-radius: 5px 5px 0px 0px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table thead tr th {
  font-size: 0.8rem;
  font-weight: 600;
  color: #F9F9FA;
  line-height: 15px;
  background-color: #5B005D;
  text-align: center;
  vertical-align: top;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table thead tr th.itemInfo {
  width: 228px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table thead tr th:nth-child(5) {
  width: 60px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table thead tr th:nth-child(7) {
  width: 60px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table thead tr th:nth-child(11) {
  width: 92px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tbody tr td {
  font-size: 0.933rem;
  font-weight: 600;
  color: #231535;
  line-height: 24px;
  padding: 10px 7px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tbody tr td .productInfo {
  text-align: left;
  padding: 1px 6px 20px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tbody tr td .productInfo .tags {
  font-size: 0.933rem;
  font-weight: 700;
  color: #231535;
  line-height: 24px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tbody tr td .productInfo p {
  font-size: 0.933rem;
  font-weight: 600;
  color: #231535;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tbody tr td .productInfo p.prodName {
  font-size: 0.933rem;
  font-weight: 600;
  color: #231535;
  line-height: 24px;
  margin-top: 0;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tfoot tr td {
  font-size: 0.933rem;
  font-weight: 600;
  color: #231535;
  line-height: 24px;
  padding: 10px 0;
  border: none;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tfoot tr td span {
  font-weight: 700;
  white-space: nowrap;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails {
  padding-bottom: 0;
  border-bottom: 0;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails .tags {
  font-size: 0.933rem;
  font-weight: 700;
  color: #231535;
  line-height: 24px;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table thead tr th {
  text-align: left;
  white-space: nowrap;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tbody tr td {
  text-align: left;
  padding: 7px;
  background-color: #f9f9f9;
}
.myaccount .popup-overlay .popup-content .inner-div .invoiceDetails table tfoot tr td {
  text-align: left;
  padding-left: 7px;
  border-top: 1px solid #DBDBDB;
}/*# sourceMappingURL=giftCard.css.map */