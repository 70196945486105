@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

/* On Click Cart section open */
.similar-product {
    position: fixed;
    height: 100vh;
    width: 0vw;
    max-width: 620px;
    background-color: #FFF;
    z-index: 1000;
    right: 0;
    top: 0;
    transition: width 1s;


    &.similar-show {
        width: 50vw;
        transition: width 1s;
        z-index: 9999;
        .btn-close {
            display: block !important;
        }
    }

    .card{
        margin: auto;
        margin-bottom: 1em;
        min-height: 390px;
        .btn-similar{
            display: none;
        }
        &:hover{
           height: auto;
        }
        .card-body{
            height: auto;
        }
    }

    .topheader {
        height: 100px;
        @include copyright(1.875rem 40px, flex, space-between);
        align-items: center;

        h2 {
            color: $linkcolor;
            margin-bottom: 0;

            span {
                font-weight: 300;
            }
        }

        .btn-close {
            display: none;
            background-image: none;
            opacity: 1;
            color:$linkcolor;
            top:10px;position: absolute;right:1.5rem;
            width: 26px;
            height: 26px;
            border-radius: 50px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            cursor: pointer;
            &:focus {
                box-shadow: none;
            }

            svg {
                font-size: 26px;
            }
        }

    }

    .cart-body {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        padding:0 40px;
        overflow-y: scroll;
        padding-bottom: 7rem;
        .product-price {
            padding: 1.375rem 1.875rem 0rem;
            border-bottom: 1px solid $cardcolor;
            margin-bottom: 0rem;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style-type: none;
                margin-bottom: 1.25rem;

                h5 {
                    font-size: 1.125rem;
                    font-weight: bold;
                }
            }

        }

        .product-details {
            padding: 0.8rem 1.5rem 1.375rem;
            margin: 0;
            height: 645px;
            overflow-y: scroll;

            >li {
                list-style-type: none;
                padding-top: 0;
            }
        }

        .cart-footer {
            height: 100px;
            @include copyright(1.375rem 1.875rem 1.375rem, flex, space-between);
            align-items: center;
            position: absolute;
            bottom: 0;
            width: 100%;
            box-shadow: 0 -3px 6px -5px #000;

            .total-prize {
                font-size: 1.5rem;
                font-weight: bold;
            }

            .btn-secondary {
                width: 130px;
                margin-right: 1rem;
                font-size: 13px;
                height: 40px;line-height: normal;padding:0em 1.2em;
                font-weight: 600;
            }
            .btn-primary{
                font-size: 13px;
                font-weight: 600;
                padding:0em 1.2em;line-height: normal;
            }
        }
        .product-label-offer h5.offer {
            padding-left: 0;font-weight: 400;font-size: 13px;
        }
        .product-price-counter > li:nth-child(2) {
            width: 100%;padding-left: 1rem;
        }
        .product-price-counter {
            align-items: flex-start;
        }
    }
}

