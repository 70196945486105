.my_profile h5 {
  font-size: 30px;
  font-weight: bolder;
  color: #5B005D;
}
.my_profile .my-setting.edit-details {
  position: relative;
  display: block;
}
.my_profile .my-setting h4 {
  font-weight: 700;
  margin-bottom: 1rem;
}
.my_profile .my-setting .form-row {
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}
.my_profile .my-setting .form-row .form-group label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.my_profile .my-setting .form-row .form-group .txtbox {
  font-size: 0.875rem;
  color: #676666;
  border: 1px solid #ced4da;
}
.my_profile .my-setting .form-row .form-group .txtbox.form-control {
  height: 40px;
  width: 95%;
}
.my_profile .my-setting .form-row .form-group .txtbox.form-control::-moz-placeholder {
  font-size: 0.875rem;
  color: #676666;
}
.my_profile .my-setting .form-row .form-group .txtbox.form-control::placeholder {
  font-size: 0.875rem;
  color: #676666;
}
.my_profile .my-setting .form-row .form-group .txtbox.form-control:focus {
  box-shadow: none;
}
.my_profile .my-setting .form-row .form-group .txtbox.form-control:disabled, .my_profile .my-setting .form-row .form-group .txtbox.form-control[readonly] {
  background-color: #f9f9f9;
}
.my_profile .my-setting .form-row .form-group .form-select {
  height: 40px;
  font-size: 0.875rem;
  color: #7F7F7F;
  width: 300px;
}
.my_profile .my-setting .form-row .form-group .form-select:disabled, .my_profile .my-setting .form-row .form-group .form-select[readonly] {
  background-color: #f9f9f9;
}
.my_profile .my-setting .form-row .form-group.mobile .cust-select {
  display: flex;
}
.my_profile .my-setting .form-row .form-group.mobile .cust-select .lbl {
  display: none;
}
.my_profile .my-setting .form-row .form-group.mobile .cust-select .form-select {
  width: 70px;
  background-position: right 0.25rem center;
  box-shadow: none;
  margin-right: 0.5rem;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
}
.my_profile .my-setting .form-row .form-group.mobile .cust-select .form-control {
  width: 210px;
}
.my_profile .my-setting .form-row li {
  font-size: 1rem;
  font-weight: 700;
  color: #2E2E2E;
  width: 100%;
}
.my_profile .my-setting .form-row li:first-child, .my_profile .my-setting .form-row li:nth-child(2) {
  margin-right: 0.8rem;
}
.my_profile .my-setting .form-row li .formMargin {
  width: 100%;
}
.my_profile .my-setting .form-row li .form-group.other-field .form-control {
  width: 377px;
}
.my_profile .my-setting .form-row li h4 {
  margin-right: 1.425rem;
}
.my_profile .my-setting .btn {
  width: 92px;
  padding: 0;
}
.my_profile .my-setting .btn.btn-primary {
  margin-right: 10px;
  margin-top: 1rem;
}
.my_profile .my-setting .btn.btn-secondary {
  margin-top: 1rem;
}/*# sourceMappingURL=myprofile.css.map */