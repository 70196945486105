h5 {
  font-size: 2rem;
  line-height: 46px;
  margin-bottom: 2rem;
}

.filterMonth .formMargin select {
  margin-left: 9px;
  font-size: 0.875rem;
  color: #030303;
  font-weight: 500;
  font-family: "Montserrat";
}
.filterMonth .formMargin select.txtbox {
  background-image: url(../../../assets/images/myaccount/select-caret-down-solid.svg);
  background-size: 12px;
  background-position: top 12px right 8px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}

.Data .Table {
  border: 1px solid #DBDBDB;
  border-radius: 5px;
}
.Data .Table table {
  border: none;
}
.Data .Table table thead tr {
  border: none;
  border-bottom: 1px solid #EFEAEA;
}
.Data .Table table thead tr th {
  border: none;
  background-color: #fefcff;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  padding: 16px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: 24px;
}
.Data .Table table tbody tr {
  border: none;
  border-bottom: 1px solid #DBDBDB;
}
.Data .Table table tbody tr:last-child {
  border-bottom: none;
}
.Data .Table table tbody tr td {
  border: none;
  background-color: #FEFFF9;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;
  padding: 15px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.Data .Table table tbody tr td ul {
  margin-bottom: 0;
}
.Data .Table table tbody tr td ul li img {
  margin-right: 14px;
  width: 60px;
  height: 60px;
}
.Data .Table table tbody tr td ul li p {
  margin-bottom: 0.25rem;
}
.Data .Table table tbody tr td ul li p.name {
  font-weight: bold;
  width: 200px;
}
.Data .Table table tbody tr td ul li p.detail {
  color: #5D5D5D;
}
.Data .Table table tbody tr td p {
  font-size: 0.875rem;
  line-height: 24px;
}
.Data .Table table tbody tr td p a {
  text-decoration: none;
  color: #5B005D;
}
.Data .Table table tbody tr td .remark {
  width: 200px;
}
.Data .Table table tbody tr td .open {
  color: #D69601;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
}

.videocall .Data .filterMonth {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
.videocall .Data .filterMonth label {
  font-size: 14px;
  font-weight: 400;
  color: #231535;
  line-height: 24px;
}
.videocall .Data .filterMonth label span {
  font-weight: 700;
}
.videocall .Data .filterMonth select {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #231535;
  line-height: 24px;
  height: 40px;
  border: 1px solid #D8D8D8;
  border-radius: 5px;
  background-image: url(../../../assets/images/caret-sharp-purple.svg);
  background-size: 12px;
  background-position: top 12px right 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 225px;
  padding: 5px 7px 7px 5px;
  outline: none;
}
.videocall .Data .Table table tbody tr td span {
  display: block;
  text-align: left;
}
.videocall .Data .Table table tbody tr td span.name {
  margin-bottom: 0;
  font-weight: 600;
  color: #231535;
}
.videocall .Data .Table table tbody tr td span.mobile {
  margin-bottom: 0;
  font-weight: 400;
  color: #231535;
}/*# sourceMappingURL=videoCall.css.map */