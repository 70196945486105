@import '../../../assets/Scss/variable';

.schedule-appointment {

    .details{
        padding-top: 40px;
        .contact {
            display: flex;
            flex-wrap: wrap;
            .left {
                width: 50%;
                .select-details {border: 1px solid $cardcolor;padding: 1rem 1rem;border-radius: 5px;
                    ul{
                        list-style-type: none;padding-left:0;margin-bottom:0;
                        li{
                            margin-bottom: 1rem;
                            &:last-child{margin-bottom: 0;}

                            h5{font-size: 15px;margin-bottom: 0;
                            &.selected{margin-bottom: 1rem;}
                            }
                            .dates{list-style-type: none;padding-left:0;display: flex;flex-wrap: wrap;
                                li{
                                    padding-right: 1rem;
                                    .btn.btn-primary{
                                        color: $linkcolor;border: 1px solid $linkcolor;background-color: #fff;padding: 0 5px;font-size: 13px;width: 100px;
                                        &.active,&:hover{background-color: $linkcolor;color: #fff;border: 1px solid $linkcolor;}
                                    }
                                }
                            }
                            .selected-designs{
                                display: flex;
                                li{
                                    position: relative;
                                    width: 100px;
                                    margin-right: 2.5rem;
                                    margin-bottom: 0;
                                    .close{
                                        position: absolute;
                                        font-size: 14px;
                                        width: 16px;
                                        height: 16px;
                                        top: -6px;
                                        right: -7px;
                                        border-radius: 50%;
                                        border: 1px solid #ddd;
                                        background-color: #fff;
                                        cursor: pointer;
                        
                                        .closebtn{color: $linkcolor;margin-top: -9px;}
                                    }
                                    .img-div{width: 100px;text-align: center;display: flex;align-items: center;justify-content: center;background: #f3f3f3;margin-bottom: .5rem;
                                        img{width: 75px;}
                                    }
                                    h6{font-size: 12px;margin-bottom: 0;}
                                }
                            }
                        }
                    }
                }
                
                .customise {
                    text-align: center;
                    .btn-primary{width: auto;margin-top: 1.5rem;padding: 0 2rem;
                    span{font-size: 13px;}
                    }
                }
            
            }
            .right{
                width: 50%;
                
                .faq-accordion {
                    padding: 0rem 1rem 1rem 3rem;

                    h5{font-size: 20px;color: $linkcolor;font-weight: 600;}

                    .accordion-item{
                        &:first-of-type{border-top: 0;}
                        .accordion-button{font-size: 15px;font-weight: 600;}
                         .accordion-body{font-size: 14px;padding-left: 15px;}
                    }
                }
            }
        }
    }

    

    

}