.my_wishlist {
    &.myaccWislist{
        margin-top: 1.5rem;
        .card{
            height: 400px;
            // .whiteList{
            //     position: absolute;
            //     right: 8px;
            //     top: 0;
            //     background-color: unset !important;
            //     padding: 0 !important;
            //     .nextui-c-ftJsHY{
            //         img{   
            //             top: 4px;
            //             right: -10px;
            //             position: absolute;
            //             padding: 0;
            //         }
            //     }
            // }
            .card-body{
                height: 115px;
                p{margin-bottom: 0;}
                h5.offer{font-size: 13px;color: #C5017C;}
            }
            &:hover{height: 400px;}
        }
    }
}
