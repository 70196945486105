.thankyou {
  margin-top: 10.2rem;
}
.thankyou .thankyou-wrapper .inner {
  width: 994px;
}
.thankyou .thankyou-wrapper .d-flex {
  justify-content: center;
}
.thankyou .heading h2 {
  color: #5B005D;
  font-size: 32px;
  font-weight: 600;
}
.thankyou .heading h4 {
  font-size: 15px;
  font-weight: 400;
  margin-top: 9px;
}
.thankyou .track-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1.938rem;
}
.thankyou .track-order ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.thankyou .track-order .order-details ul {
  display: block;
}
.thankyou .track-order .order-details ul li {
  padding-right: 2.2rem;
  font-weight: 400;
}
.thankyou .track-order .order-details ul li span {
  font-weight: 600;
}
.thankyou .track-order .track-order-details ul li {
  padding-right: 2.75rem;
}
.thankyou .track-order .track-order-details ul li:first-child svg {
  transform: rotate3d(0, 25, 2, 180deg);
}
.thankyou .track-order .track-order-details ul li:last-child {
  padding-right: 0;
}
.thankyou .track-order .track-order-details ul li a.btn-primary-outline {
  border: 1px solid #0B2138;
  border-radius: 0;
  width: 250px;
  color: #0B2138;
}
.thankyou .track-order .btn-secondary {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5B005D;
}
.thankyou .track-order .btn-secondary svg {
  font-size: 16px;
  margin-right: 4px;
}
.thankyou .track-order .btn-secondary:hover {
  background-color: #5B005D;
  color: #fff;
}
.thankyou .billing-shipping {
  padding: 10px;
  border: 1px solid #E6E6E6;
  margin-bottom: 1.8rem;
  border-radius: 5px;
}
.thankyou .billing-shipping ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.thankyou .billing-shipping ul li {
  padding-right: 4.5rem;
}
.thankyou .billing-shipping ul li h4 {
  font-size: 15px;
  font-weight: 600;
}
.thankyou .billing-shipping ul li h6 {
  font-size: 14px;
}
.thankyou .billing-shipping ul li address {
  margin-bottom: 0;
  font-size: 14px;
}
.thankyou .billing-shipping ul li .contact-info h4 {
  font-weight: 400;
  position: relative;
}
.thankyou .billing-shipping ul li .contact-info h4 strong {
  width: 108px;
  display: inline-block;
}
.thankyou .thank-order-summary h4 {
  font-size: 20px;
  color: #231535;
  font-weight: 400;
  padding-bottom: 10px;
}
.thankyou .thank-order-summary .your-order-summary {
  display: flex;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper {
  width: 100%;
  width: 661px;
  background-color: #FFF;
  border: 1px solid #F5F5F5;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper .delivery-info {
  padding: 0 0 1rem 0;
  display: flex;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper .delivery-info li {
  padding-right: 12px;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details {
  display: flex;
  justify-content: space-between;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul {
  display: flex;
  align-items: flex-start;
  font-weight: 600;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li {
  padding: 0 1rem 0 0;
  font-size: 15px;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li figure {
  width: 80px;
  height: 93px;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li ul {
  padding: 0;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li ul li {
  padding-left: 0;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li ul li h5 {
  margin-bottom: 0;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li h4 {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 4px;
}
.thankyou .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li h6 {
  font-size: 13px;
  margin-bottom: 0;
}
.thankyou .thank-order-summary .your-order-summary .right {
  width: 100%;
  max-width: 310px;
  background-color: #F6F6F6;
  padding: 15px 15px 10px 15px;
  margin-left: 1.625rem;
  height: 100%;
}
.thankyou .thank-order-summary .your-order-summary .right.open {
  height: 100vh;
  transition: all 0.5s ease-in-out;
  margin-left: 0;
}
.thankyou .thank-order-summary .your-order-summary .right.open .summary-data {
  display: inline-block;
}
.thankyou .thank-order-summary .your-order-summary .right.open .summary-cancle {
  text-align: center;
}
.thankyou .thank-order-summary .your-order-summary .right h4 {
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}
.thankyou .thank-order-summary .your-order-summary .right .order-summary {
  padding: 0;
  margin-bottom: 0;
}
.thankyou .thank-order-summary .your-order-summary .right .order-summary ul li:last-child {
  margin-bottom: 4px;
}
.thankyou .thank-order-summary .your-order-summary .right .summary-data {
  font-size: 14px;
  color: #231535;
}
.thankyou .thank-order-summary .your-order-summary .right .summary-data .close {
  cursor: pointer;
  position: absolute;
  top: 1.6rem;
  right: 0;
  padding: 12px 16px;
  transform: translate(0%, -50%);
  font-size: 30px;
  display: none;
}
.thankyou .thank-order-summary .your-order-summary .right .summary-cancle {
  display: none;
}

.thankyou .track-order .track-order-details ul li a.btn-primary-outline {
  border: 1px solid #0B2138;
  border-radius: 0;
  width: 250px;
  color: #0B2138;
}
.thankyou .track-order .track-order-details ul li a.btn-primary-outline:focus {
  box-shadow: none;
}
.thankyou .track-order .custompayment {
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=index.css.map */