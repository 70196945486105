.menu {
  display: flex;
  align-items: center;
  padding-left: 0;
  border-bottom: 1px solid #EFEAEA;
}
.menu > li {
  list-style-type: none;
  width: 100%;
}
.menu > li .navbar .container {
  padding-left: 0;
}
.menu > li .navbar .container .navbar-nav .brand-logo {
  width: 100px;
  cursor: pointer;
}
.menu > li .navbar .container .navbar-nav .nav-item .nav-link.active {
  color: #C5017C;
}
.menu > li .navbar .container .navbar-nav .nav-item .nav-link.active::after {
  content: "";
  transition: 0.5s all ease-in-out;
  position: absolute;
  left: 50%;
  width: 100%;
  bottom: -10px;
  transform: translateX(-50%);
  animation: linkHover 0.35s ease-in-out forwards;
}
.menu > li .navbar .container .navbar-nav .nav-item .navbar-light .navbar-nav .show > .nav-link {
  font-size: 0.933rem;
  font-weight: 600;
  color: "";
  line-height: "";
}
@keyframes linkHover {
  from {
    width: 0%;
    height: 4px;
    background: #C5017C;
    position: absolute;
    bottom: -5px;
  }
  to {
    width: 100%;
    height: 4px;
    background: #C5017C;
    position: absolute;
    bottom: -5px;
  }
}
.menu > li:last-child {
  display: none;
}

ul.navbar-nav > li:hover > .sub-menu-block {
  width: auto;
}

.menu ul.navbar-nav > li > .sub-menu-block {
  margin-top: 21px;
}

.menu .navbar-expand-lg .navbar-nav .nav-link {
  padding: 14px 0;
}

.menu .navbar-light .navbar-nav .nav-link.active, .menu .navbar-light .navbar-nav .show > .nav-link {
  font-size: 0.933rem;
}

.menu .navbar {
  height: 50px;
}

.menu .navbar-expand-lg .navbar-collapse {
  justify-content: flex-start;
}

.menu ul.navbar-nav > li > .sub-menu-block li a {
  padding-left: 0;
}/*# sourceMappingURL=brand.css.map */