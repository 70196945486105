.slick-arrow {
  position: absolute;
  z-index: 8;
  width: 45px;
  height: 45px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.15);
}
.slick-arrow::before {
  content: "";
  background: url("/src/assets/images/icons/prevArrow.png");
  width: 20px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slick-arrow.slick-prev {
  left: 0;
}
.slick-arrow.slick-prev::before {
  content: "";
  background: url("/src/assets/images/icons/prevArrow.png");
}
.slick-arrow.slick-next {
  right: 0;
}
.slick-arrow.slick-next::before {
  content: "";
  background: url("/src/assets/images/icons/nextArrow.png");
}
.slick-arrow:hover {
  background: #3B003A;
}
.slick-arrow:hover:before {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(142deg) brightness(110%) contrast(101%);
}

.slick-slide {
  height: auto;
}
.slick-slide figure {
  margin: 0;
}
.slick-slide figure img {
  margin: auto;
  width: 100%;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
}

.bannerTxt {
  position: absolute;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
}
.bannerTxt h3 {
  font-family: "Bahnschrift";
  color: #FFC46E;
  text-transform: uppercase;
  font-size: 1.333rem;
  font-weight: 200;
  margin-bottom: 1rem;
}
.bannerTxt h2 {
  font-family: "Bahnschrift";
  color: #fff;
  font-size: 40px;
  font-weight: 200;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.bannerTxt .btn-primary span {
  font-weight: 800;
}

.typ2 .slick-slider {
  margin-left: 0px;
}
.typ2 .slick-slider .slick-slide {
  margin-left: 0px;
}
.typ2 .slick-slider .slick-arrow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.typ2 .slick-slider .slick-arrow.slick-prev {
  left: -22px;
  top: 45%;
}
.typ2 .slick-slider .slick-arrow.slick-next {
  right: -10px;
  top: 45%;
}
.typ2 .slick-slider .slick-slide {
  height: auto;
}
.typ2 .slick-slider .slick-slide figure {
  margin: 0;
  height: 242px;
  width: 242px !important;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}
.typ2 .slick-slider .slick-slide figure img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: all 0.5s ease;
}
.typ2 .slick-slider .slick-slide figure img:hover {
  transform: scale(1.1);
}
.typ2 .slick-slider .slick-slide figure h3 {
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 10px;
}

.home .bannerTxt h2 {
  margin-bottom: 1rem;
}
.home .imgslider .slick-slider .slick-dots {
  bottom: 35px;
}
.home .imgslider .slick-slider .slick-dots li button::before {
  font-size: 12px;
  border: 1px solid #5B005D;
  border-radius: 10px;
  color: rgba(91, 0, 93, 0.3607843137);
  opacity: 1;
  background: #fff;
}
.home .imgslider .slick-slider .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #5B005D;
}

.singleimgslider figure {
  position: relative;
}
.singleimgslider figure h3 {
  position: absolute;
  bottom: 50px;
  font-size: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: #FFF;
  font-family: "Bahnschrift";
}/*# sourceMappingURL=Slider.css.map */