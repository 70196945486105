.shop-list {
  padding: 0;
  margin: 0;
  display: flex;
}
.shop-list li {
  width: auto;
  padding: 0 8px 15px;
  list-style-type: none;
}
.shop-list li .card {
  width: 180px;
  min-height: 125px;
  padding: 0;
  overflow: inherit;
}
.shop-list li .card img.card-img-top {
  height: 125px;
  min-height: 125px;
  width: 180px;
  -o-object-fit: cover;
     object-fit: cover;
}
.shop-list li .card .card-body {
  text-transform: uppercase;
  background-color: #3B003A;
  width: 180px;
  height: 50px;
  padding: 0.8rem 1rem;
  position: absolute;
  bottom: -50px;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.shop-list li .card .card-body h3 {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  line-height: "";
  text-align: center;
  margin: 0;
}
.shop-list li .card .card-body p {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  line-height: normal;
  text-align: center;
  margin-bottom: 2px;
}
.shop-list li .card:hover {
  height: auto;
}
.shop-list li .card:hover img {
  transition: 0.5s all ease-in-out;
}
.shop-list.btm-border {
  padding-bottom: 6.5rem;
}
.shop-list a:hover .card-body {
  height: 70px;
}/*# sourceMappingURL=index.css.map */