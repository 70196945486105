.e-cart-list {
  padding: 0;
  float: right;
  margin-top: 1rem;
  margin-bottom: 0;
}
.e-cart-list .home-video {
  position: relative;
  padding-right: 0;
  margin-right: 20px;
}
.e-cart-list .home-video .tryathome {
  line-height: normal;
  padding-right: 20px;
}
.e-cart-list .home-video .tryathome svg {
  height: 22px;
}
.e-cart-list .home-video .tryathome .icon-text {
  line-height: 26px;
}
.e-cart-list .home-video .video-call svg {
  height: 22px;
}
.e-cart-list .home-video .video-call svg.videoIcn {
  font-size: 22px;
}
.e-cart-list .cart-wishlist {
  padding-right: 0;
}
.e-cart-list .cart-wishlist li:last-child {
  padding-right: 0;
  padding-left: 10px;
}
.e-cart-list li {
  position: relative;
  list-style-type: none;
  padding-right: 20px;
  display: inline-block;
  text-align: center;
}
.e-cart-list li .cart-product a {
  font-size: 28px;
}
.e-cart-list li .cart-product a span.cart-counter {
  left: 18px;
}
.e-cart-list li a {
  color: inherit;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 50px;
  line-height: 35px;
}
.e-cart-list li a svg {
  height: 22px;
  color: #3B003A;
}
.e-cart-list li a svg.homeIcn {
  font-size: 18px;
}
.e-cart-list li a svg.wishlistIcn {
  font-size: 25px;
}
.e-cart-list li a svg.cartIcn {
  font-size: 25px;
}
.e-cart-list li a svg.userIcn {
  font-size: 40px;
}
.e-cart-list li .after-login .userIcn {
  margin-bottom: 5px;
}
.e-cart-list li button.video-call, .e-cart-list li button.tryathome {
  border: 0;
  background-color: #fff;
  padding-right: 20px;
}
.e-cart-list li span.icon-text {
  font-size: 0.8rem;
  font-weight: 400;
  display: block;
  color: #231535;
  line-height: 30px;
}
.e-cart-list li span.cart-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #53C9C5;
  border-radius: 50px;
  position: absolute;
  left: 27px;
  top: -7px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: "";
}
.e-cart-list li.after-login {
  display: none;
  border: 1px solid transparent;
}
.e-cart-list li.after-login a {
  padding-right: 9px;
}
.e-cart-list li.currency_drop {
  position: relative;
  flex-direction: column;
}
.e-cart-list li.currency_drop li:before {
  content: "";
  border-left: 0.5px solid #b4b4b4;
  position: absolute;
  left: -10px;
  height: 35px;
  top: 50%;
  transform: translateY(-50%);
}
.e-cart-list li.currency_drop li ul {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.e-cart-list li.currency_drop li ul li {
  padding: 0 0 0 10px;
  width: 100%;
}
.e-cart-list li.currency_drop li ul li::before {
  display: none;
}
.e-cart-list li.currency_drop li ul li > span {
  width: 100%;
}
.e-cart-list li.currency_drop li ul li > span .ReactFlagsSelect-module_selectFlag__2q5gC {
  padding-right: 10px;
  width: 30px;
  background: none;
}/*# sourceMappingURL=CartList.css.map */