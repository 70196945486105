/* On Click Cart section open */
.similar-product {
  position: fixed;
  height: 100vh;
  width: 0vw;
  max-width: 620px;
  background-color: #FFF;
  z-index: 1000;
  right: 0;
  top: 0;
  transition: width 1s;
}
.similar-product.similar-show {
  width: 50vw;
  transition: width 1s;
  z-index: 9999;
}
.similar-product.similar-show .btn-close {
  display: block !important;
}
.similar-product .card {
  margin: auto;
  margin-bottom: 1em;
  min-height: 390px;
}
.similar-product .card .btn-similar {
  display: none;
}
.similar-product .card:hover {
  height: auto;
}
.similar-product .card .card-body {
  height: auto;
}
.similar-product .topheader {
  height: 100px;
  padding: 1.875rem 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.similar-product .topheader h2 {
  color: #5B005D;
  margin-bottom: 0;
}
.similar-product .topheader h2 span {
  font-weight: 300;
}
.similar-product .topheader .btn-close {
  display: none;
  background-image: none;
  opacity: 1;
  color: #5B005D;
  top: 10px;
  position: absolute;
  right: 1.5rem;
  width: 26px;
  height: 26px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.similar-product .topheader .btn-close:focus {
  box-shadow: none;
}
.similar-product .topheader .btn-close svg {
  font-size: 26px;
}
.similar-product .cart-body {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  overflow-y: scroll;
  padding-bottom: 7rem;
}
.similar-product .cart-body .product-price {
  padding: 1.375rem 1.875rem 0rem;
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 0rem;
}
.similar-product .cart-body .product-price li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-bottom: 1.25rem;
}
.similar-product .cart-body .product-price li h5 {
  font-size: 1.125rem;
  font-weight: bold;
}
.similar-product .cart-body .product-details {
  padding: 0.8rem 1.5rem 1.375rem;
  margin: 0;
  height: 645px;
  overflow-y: scroll;
}
.similar-product .cart-body .product-details > li {
  list-style-type: none;
  padding-top: 0;
}
.similar-product .cart-body .cart-footer {
  height: 100px;
  padding: 1.375rem 1.875rem 1.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -3px 6px -5px #000;
}
.similar-product .cart-body .cart-footer .total-prize {
  font-size: 1.5rem;
  font-weight: bold;
}
.similar-product .cart-body .cart-footer .btn-secondary {
  width: 130px;
  margin-right: 1rem;
  font-size: 13px;
  height: 40px;
  line-height: normal;
  padding: 0em 1.2em;
  font-weight: 600;
}
.similar-product .cart-body .cart-footer .btn-primary {
  font-size: 13px;
  font-weight: 600;
  padding: 0em 1.2em;
  line-height: normal;
}
.similar-product .cart-body .product-label-offer h5.offer {
  padding-left: 0;
  font-weight: 400;
  font-size: 13px;
}
.similar-product .cart-body .product-price-counter > li:nth-child(2) {
  width: 100%;
  padding-left: 1rem;
}
.similar-product .cart-body .product-price-counter {
  align-items: flex-start;
}/*# sourceMappingURL=index.css.map */