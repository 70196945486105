.collection-list figure {
  height: 420px;
}
.collection-list figure img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: 10px;
}
.collection-list figure:hover img {
  filter: grayscale(1);
  transition: 0.5s all ease-in-out;
}/*# sourceMappingURL=index.css.map */