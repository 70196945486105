@import '../../assets/Scss/variable';

.accordion-item{
    border-left: none;
    border-right: none;
    background-color: #FBFBFB;
    &:first-of-type{
        border-top-left-radius: 0;
        border-top-right-radius:0;
    }
    &:last-of-type {
        border-bottom-right-radius:0;
        border-bottom-left-radius:0;
    }
    .accordion-body {
        padding: 0;font-size: 14px;font-weight: 400;color:#231535;
        table{
            td{
                padding-right: 6px;
            }
        }
    }
}
.accordion-button:not(.collapsed) {
    background-color: transparent;
    color:#0B2138;
    box-shadow:none;
}
.accordion-button {
    font-size: 20px;
    color:#0B2138;
    padding-left: 6px;
    line-height: 1rem;
    &:focus{
        box-shadow: none;
        border-color: transparent;
    }
    &:after{
        background-image: none;
    }
}
button.accordion-button.collapsed {
    span.minusicon{
        display: none;
    }
    span.plusicon{
        display: block;
    } 
}
button.accordion-button {
    span.plusicon{
        display: none;color: $linkcolor;
    }   
}
span.plusicon,span.minusicon{
    position: absolute;right:0;color: $linkcolor;
}
.accordion-button:not(.collapsed)::after {
    background-image: none;
}
table.price-breakup{
    width: 100%;
    th,td {line-height: 1.5rem;}
    tr.paddbtm {
        td{
            padding-bottom: 10px;
        }
    }
}
.imp-heading {font-weight: 600;}
p.imp-heading {font-weight: 600;margin-bottom: 5px;font-size: 15px;}
th {
    font-weight: 600;
    span {font-weight: 400;font-size: 12px;}
}
// tr.border-top {

// }
.sub-total{
        padding-top: 10px;
}
.jewelry-list{
    padding:0;margin:0;
    li{
        list-style-type: none;position: relative;padding-left:2rem;margin-bottom:10px;
        span.icon{
            position: absolute;left: 0;
            img{
                width: 100%;height: 100%;object-fit: cover;
            }
        }
        span.title{
            font-weight: 600;display: block;margin-bottom: 4px;
        }
    }
}
.reviewStats {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0px;
    padding-bottom: 25px;
    border-bottom: solid 1px #E8E3E3;
    margin-bottom: 25px;
    h2{
        margin:0;text-align: left;
    }
    .overall {
        .stars {
            font-size:18px;color: $linkcolor;
            svg {margin-right: 4px;}
        }
        p {
            font-size: 12px;
            font-weight: 400;
            margin-top: 6px;
            margin-bottom: 0;
        }
    }
    .detailReviews {
        margin-left: 60px;
        padding-left: 40px;
        position: relative;
        &:before{
            content:'';
            position: absolute;
            width: 1px;
            background-color: #E8E3E3;
            height: 91px;
            left: 0;top:50%;transform: translateY(-50%);
        }
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: center;
                font-size: 15px;color: $linkcolor;
                font-weight: 600;
                span+span{
                    margin-left: 5px;font-size: 12px;
                }
            }
        }
        .progress{
            height: 2px;
            border-radius: 3px;
            background: #F1F1F1;
            width: 80px;
        }
        .progress-bar {
            background-color: $linkcolor;
            border-radius: 10px;
        }
    }
}

.review-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li{
        font-size: 18px;
        display: block;
        margin-bottom: 1rem;padding-bottom: 1.3rem;
        border-bottom: 1px solid #e8e3e3;
        p{
            margin-bottom: 0;font-size: 14px;font-weight: 400;color: #5D5D5D;
        }
        strong{
            margin-bottom: 4px;font-size: 14px;font-weight: 600;
        }
        &:last-child {border-bottom: none;padding-bottom: 0;margin-bottom: 0;}
    }
    .stars {display: block;margin-bottom: 4px;color: $linkcolor;margin-right: 4px;}
    .reviewerDls {
        span{
            font-weight: 600;font-size: 14px;
        }
        .date{
            color: #5D5D5D;
            font-size: 14px;
            position: relative;
            padding-left: 12px;margin-left:6px;
            &:before{
                content: '';
                width: 4px;
                height: 4px;
                position: absolute;
                background: #5D5D5D;
                border-radius: 100%;
                left: 4px;
                top: 8px;
            }
        }
    }
}
.review-wrapper {
    overflow-y: scroll;height: 420px;
    &::-webkit-scrollbar {
        width: 4px;
    }
     
    &::-webkit-scrollbar-track {
        width: 5px;background-color: #F5F5F5;border-radius: 10px;
    }
     
    &::-webkit-scrollbar-thumb {
      width: 2px;background-color: $linkcolor;height: 62px;border-radius: 10px;
    }
}