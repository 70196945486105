.occasion-list {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  flex-wrap: wrap;
}
.occasion-list figure {
  height: 150px;
  position: relative;
  margin-bottom: 1.75rem;
}
.occasion-list figure img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: 10px;
}
.occasion-list figure figcaption {
  transition: 0.5s all ease-in-out;
  position: absolute;
  font-size: 35px;
  font-weight: 200;
  color: "";
  line-height: "";
  line-height: normal;
  left: 20px;
  bottom: 10px;
  transform: translateY(0%);
  width: 30%;
}
.occasion-list figure figcaption.color-blue h3 {
  color: #031355;
  font-size: 35px;
  font-weight: 200;
}
.occasion-list figure figcaption h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.occasion-list figure:hover figcaption {
  transform: translateY(-50%);
}
.occasion-list figure:hover img {
  filter: grayscale(1);
  transition: 0.5s all ease-in-out;
}
.occasion-list li {
  width: 31.8%;
  list-style-type: none;
}
.occasion-list li:last-child {
  margin-right: 0;
}
.occasion-list li:last-child figure figcaption h3 {
  color: #000;
}
.occasion-list li:nth-child(3) {
  margin-right: 0;
}/*# sourceMappingURL=shopoccassion.css.map */