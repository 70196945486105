.my-setting {
    .topheading {display: flex;justify-content: space-between;
        h6{font-size: 12px;color: #5D5D5D;margin-top: auto;}
    }
    .unsubscribe-mail{
        h4{font-size: 1.25rem;font-weight: 600;margin-bottom: 2rem;}
        .form-group {margin-bottom: 1.5rem;
            label{display: block;text-transform: capitalize;}
        }
    }
}