@import '../../../assets/Scss/variable';

// Empty
.empty{
    text-align: center;
    margin-top: 2rem;
    img{
        margin-bottom: 40px;
    }
    p{
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 20px;
    }

    .btn.btn-primary {
        border-radius: 5px;
        text-transform: uppercase;
        // height: auto;
        width: auto;
        border: 1px solid $linkcolor;
    
        &:hover{background-color: #fff;border: 1px solid $linkcolor;color: $linkcolor;}
    }
    // .btn-shopNow{
    //     width: 165px;
    //     height: 40px;
    //     background-color: $linkcolor;
    //     border-radius: 20px;
    //     color: #FFFFFF;
    //     font-size: 1rem;
    //     font-weight: 600;
    //     font-family: 'Poppins', sans-serif;
    //     line-height: 28px;
    //     padding: 0.375rem 0.75rem;
    //     display: inline-block;
    // }


}

