@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';

// Global Back Button css of tabs 
.backBtnToTab {
    // border: 1px solid $linkcolor;
    // background: transparent;
    // color: $linkcolor;
    // padding: 11px 33px;
    // border-radius: 50px;
    // font-size: 0.875rem;
    // font-weight: 600;
    position: absolute !important;
    top: 0;
    right: 0;
}

// Responsive table gobal in this file only
table {
    border: 1px solid #ccc;
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
        // border: 1px solid #EFEAEA;
        padding: 5px;
    }

    th,
    td {
        padding: 10px;
        text-align: center;
    }

    th {
        border: 1px solid #DBDBDB;
        background-color: #EFEFEF;
        color: #0B2138;
        padding: 5px 10px;
        font-size: 1rem;
        font-weight: 500;
    }

    td {
        border: 1px solid #DBDBDB;
        // vertical-align: top;
        padding: 10px 0 0;
    }
}

.mainWapper {
    .breadcrumbs {
        margin-top: 20px;

        .breadcrumb {
            font-size: 0.875rem;
            font-weight: 500;
            padding: 0;
        }
    }

    ul {
        display: flex;
        list-style: none;
        padding-left: 0;
    }

    .userdetails {
        display: flex;
        align-items: center;
        padding-bottom: 1.5rem;
        margin: 1.5rem 0rem;

        .uploadImg {
            position: relative;
            border-radius: 50%;
            // width: 90px;
            // height: 90px;

            img {
                border-radius: 50px;
            }

            .editImg {
                width: 28px;
                height: 28px;
                border-radius: 50px;
                position: absolute;
                bottom: -4px;
                left: 23px;
                /* opacity: 0; */
                z-index: 2;
                cursor: pointer;

                &::after {
                    content: "";
                    background: url('../../../assets/images/myaccount/user-edit.svg') no-repeat;
                    background-size: 100%;
                    width: 28px;
                    height: 28px;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                }
            }

            .dltImg {
                content: "";
                background: url('../../../assets/images/myaccount/userdelete.svg') no-repeat;
                background-size: 100%;
                width: 28px;
                height: 28px;
                position: absolute;
                bottom: -4px;
                right: 15px;
                cursor: pointer;
            }
        }

        .userName {
            padding-left: 25px;
            font-weight: 600;
            color: $linkcolor;
            font-size: 1.875rem;

            span {
                display: block;
                font-size: 1rem;
                margin-bottom: 1rem;
                font-weight: 600;
                color: #5d5d5d;
            }
        }
    }

    .verticalTab {
        position: relative;

        .tabs {
            position: absolute;
            left: 0;
            background: white;
        }

        .contentWapper {
            margin-left: 60px;
            width: 100%;
            position: relative;

            .activeContent {
                display: block;
                width: 90%;
            }
        }
    }

    .RRT__container {
        margin: 15px 0 40px;
        box-shadow: 1px 1px 20px -16px $textcolor;
        background: #FFFFFF;
        // height: 820px;
        height: 100%;
        padding: 20px;
        // overflow: hidden;
        min-height: 600px;

        .RRT__tabs {
            flex-direction: column;
            height: 97%;
            border-right: 2px solid $linkcolor;
            width: 57px;
            background: #fff;
            z-index: 9;
            position: absolute;
            transition: all 0.3s ease-in;
            overflow: hidden;

            .RRT__tab--first{
                div{
                    img{filter: none !important;}
                }
            }

            .RRT__tab {
                width: 100%;
                padding: 0;
                border: none;
                padding-right: 10px;
                background: none;
                cursor: pointer;

                div {
                    padding: 0.7em 0.78em;
                    // margin: 5px 0;
                    border: 1px solid #DCDCDC;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;

                    span {
                        font-weight: 600;
                        text-transform: capitalize;
                        padding-left: 40px;
                        // padding-top: 5px;
                        position: absolute;
                        width: 160px;
                        display: block;
                        transition: all 0.5s ease;
                        left: -100px;
                        font-size: 15px;
                        opacity: 0;
                        color: $linkcolor;

                    }
                }
            }

            .RRT__tab--selected {
                padding: 0;
                // background: $linkcolor;
                color: #fff;

                div {
                    color: #fff;
                    border-right: none;
                    background-color: $maincolor;
                    border: 1px solid $maincolor;

                    span {
                        color: #fff;
                        font-weight: 700;
                    }

                    img {
                        filter: invert(89%) sepia(89%) saturate(1%) hue-rotate(188deg) brightness(4) contrast(97%);
                    }
                }
            }

            &:hover {
                width: 218px;
                transition: all 0.5s ease-in;

                div {
                    // overflow: hidden;

                    span {
                        opacity: 1;
                        left: 0px;
                    }
                }
            }
        }

        .RRT__panel {
            margin-left: 60px;
            position: relative;
            top: 0;
            width: 95%;
            border: none;
            padding-top: 0.5rem;

            h5 {
                font-size: 2rem;
                font-weight: 600;
                color: $linkcolor;
                margin-bottom: 1rem;
            }

            .btn-cancel {
                width: 92px ;
                padding: 0;
            }
        }
    }
}

// My Order tab 
.myOrderTab,
.orderDetailSec {
    .tab-list {
        height: 60px;
        display: flex;
        justify-content: flex-start;
        list-style: none;
        font-size: 18px;
        margin: 0;
        border-bottom: 1px solid #DBDBDB;
        padding-left: 0;

        .tabs {
            width: 175px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 500;
            color: $textcolor;

            .tabs:not(:last-child) {
                border-right: 1px solid rgba(0, 0, 0, 0.3);
            }
        }

        .active-tabs {
            width: 175px;
            justify-content: center;
            display: flex;
            font-size: 1rem;
            height: 100%;
            align-items: center;
            color: $linkcolor;
            position: relative;
            font-weight: 600;

            &::before {
                content: '';
                border-bottom: 3px solid;
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
    }

    .content-container {
        .content {
            background-color: #eeeded;
            display: none;
            padding: 10px;
        }

        .active-content {
            .filterMonth {
                margin: 1.5rem 0;
                display: flex;
                align-items: center;

                label {
                    font-size: 14px;
                    font-weight: 400;
                    color: #231535;
                    line-height: 24px;

                    span {
                        font-weight: 700;
                    }
                }
            }
        }

        .filterSection {
            width: 100%;
            padding: 10px 0;
            margin-bottom: 10px;

            ul {
                li {
                    width: 226px;
                    background: #FFFFFF;
                    list-style: none;
                    margin: 0rem 1rem;
                    border: 1px solid #D8D8D8;
                    position: relative;
                    display: flex;
                    align-items: center;

                    input {
                        width: 100%;
                        height: 100%;
                        border: none;
                        outline: none;
                        font-size: 14px;
                        color: #232A35;
                        padding: 10px;
                        border-color: #D8D8D8;

                        ::placeholder {
                            font-size: 14px;
                        }
                    }

                    &:first-child {
                        width: auto;
                        border: none;
                    }

                    &:last-child {
                        width: 287px;
                    }

                    svg {
                        width: 30px;
                    }
                }
            }
        }

    }

    //table css
    .tableSec {
        table {
            thead {
                tr {
                    th {
                        &:first-child {
                            text-align: left;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        svg {
                            color: $linkcolor;
                        }

                        p {
                            font-size: 1rem;
                            color: $textcolor;
                            font-weight: 400;
                            text-align: left;
                            padding-left: 10px;
                            padding-top: 15px;

                            span {
                                font-size: 0.7rem;
                                color: #847F7F;
                                padding-top: 5px;
                                font-weight: 500;
                            }
                        }

                        ul {
                            flex-direction: column;
                            padding-left: 10px;

                            li {
                                display: flex;
                                align-items: center;

                                span {
                                    color: #FF0000;
                                    font-size: 0.75rem;
                                    font-weight: 600;
                                    // text-transform: uppercase;
                                    position: relative;
                                    top: 6px;
                                    // margin-bottom: 15px;
                                }

                                img {
                                    width: 60px;
                                    height: 60px;
                                }

                                p {
                                    span {
                                        font-size: 0.7rem;
                                        color: #847F7F;
                                        text-transform: none;
                                        font-weight: 500;
                                        padding-top: 0;
                                    }
                                }

                                button {
                                    border: 1px solid $linkcolor;
                                    padding: 7px 5px;
                                    width: 115px;
                                    background: transparent;
                                    color: $linkcolor;
                                    font-size: 0.75rem;
                                    margin: 5px 0;
                                    font-weight: 500;
                                    outline: none;
                                }
                            }
                        }

                        &:first-child {
                            ul {
                                li {
                                    &:first-child {
                                        p {
                                            display: flex;
                                            flex-direction: column;
                                            padding-top: 0;
                                        }
                                    }
                                }
                            }
                        }

                        &:nth-child(2),
                        &:last-child {
                            p {
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        &:first-child {
                            width: 55%;
                        }
                    }

                    &:last-child {
                        td {
                            &:last-child {
                                ul {
                                    li {
                                        span {
                                            padding-bottom: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Order details compounent css
.orderDetailSec {
    position: relative;

    .orderlabel {
        padding: 9px 15px;
        border: 1px solid #9f9f9f6e;
        border-radius: 5px;
        background: #fefcff;
        margin-top: 1.5rem;

        @include my-table;
    }

    .addresSec {
        display: flex;
        justify-content: space-between;
        padding: 25px 16px;

        .shippingAdd,
        .orderSummary {
            width: 50%;
        }

        .shippingAdd {
            h6 {
                font-size: 1rem;
                color: $textcolor;
                font-weight: 600;
                padding-bottom: 0;
                margin-bottom: 0.5rem;
            }

            caption {
                width: 288px;
                height: auto;
                padding-top: 0;

                // p {
                //     color: $textcolor;
                //     font-size: 1rem;
                //     font-weight: 400;
                //     line-height: 1.5;

                //     b {
                //         font-weight: 500;
                //     }

                //     &:last-child {
                //         padding-top: 5px;
                //     }
                // }
            }
        }

        .orderSummary {
            display: flex;
            justify-content: flex-end;

            .subSection {
                width: 288px;

                h6 {
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 0;
                    // padding-bottom: 10px;
                }

                &>h6{margin-bottom: .5rem;}

                ul {
                    flex-direction: column;
                    margin-bottom: 0;

                    li {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 10px;

                        span {
                            font-size: 0.875rem;
                            color: $textcolor;
                            font-weight: 500;

                            b {
                                font-weight: 600;
                            }
                        }

                        &:last-child {
                            span {
                                b {
                                    font-size: 1rem;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .tableContent {
        border: 1px solid #DCDDDD;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
        padding: 20px;
        background: #FEFFF9;

        table,
        thead,
        th,
        tbody,
        tr,
        td {
            background: #FEFFF9;
            border: none;
            text-align: left;
            font-size: 0.875rem;
            font-weight: 500;
            vertical-align: text-bottom;
        }

        table {
            thead {
                border-bottom: 1px solid #DBDBDB;

                th {
                    font-weight: 600;
                    font-size: 0.875rem;
                    color: #7F7F7C;

                    &:nth-child(2) {
                        text-align: center;
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #DBDBDB;

                    &:last-child {
                        border-bottom: none;
                    }

                    td {
                        padding: 20px 10px;

                        &:nth-child(2) {
                            text-align: center;
                        }

                        ul {
                            li {

                                img{width: 60px;height: 60px;}

                                h6{
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    margin-bottom: 0.25rem;
                                }

                                p{font-size: 14px;color: #5D5D5D;font-weight: 400;margin-bottom: .5rem;}

                                &:last-child{margin-left: 20px;height: 97px;position: relative;

                                    button {
                                        // border: 1px solid $linkcolor;
                                        background-color: $whitecolor;
                                        // color: $linkcolor;
                                        // padding: 11px 13px;
                                        // border-radius: 50px;
                                        // font-size: 0.875rem;
                                        // font-weight: 600;
                                        &:hover{background-color: $linkcolor}
                                    }
                                }

                                span {
                                    color: #fff;
                                }
                            }
                        }

                        span {
                            font-size: 12px;
                            // color: $textcolor;
                            font-weight: 600;
                        }

                        .orderStatus {
                            font-size: 0.875rem;
                            font-weight: 600;
                            color: #D69601;
                            position: relative;
                            padding-left: 13px;

                            &::before {
                                content: "";
                                background: #D69601;
                                width: 8px;
                                height: 8px;
                                position: absolute;
                                left: 0px;
                                top: 6px;
                                border-radius: 50px;
                            }
                        }

                        @include order-status;

                        &:last-child {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            button {
                                padding-bottom: 9px;
                                background: transparent;
                                border: none;
                                text-transform: uppercase;

                                span {
                                    position: relative;
                                    color: $linkcolor;

                                    &::before {
                                        content: "";
                                        background: url('../../../assets/images/myaccount/caretDownSolid.svg') center center no-repeat;
                                        background-size: 100%;
                                        position: absolute;
                                        left: -10px;
                                        top: 3px;
                                        width: 6px;
                                        height: 10px;
                                        transform: rotate3d(0, 0, 10, 270deg);
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

// Fliter Month Css 
.filterMonth {
    .formMargin {
        display: flex;
        align-items: center;
        // margin: 20px 0px;

        .input-lable {
            font-size: 0.875rem !important;
            text-transform: math-auto;
            width: auto;
            display: block;
            color: #231535 !important;

            span {
                font-weight: 600;
            }
        }

        .formRe{
            width: 226px;
            select {
                border: 1px solid #D8D8D8;
                border-radius: 5px;
                width: 226px;
                height: 40px;
                margin-left: 10px;
            }
        }
        
    }

}

.myacc_accordion {
    .accordion {
        margin-bottom: 21px;
        --bs-accordion-border-color: none;

        .accordion-item {
            .accordion-header {
                .accordion-button {
                    padding: 0;
                    // border: 1px solid #9F9F9F;
                    border: 1px solid #9f9f9f6e;
                    border-bottom: 0;
                    border-radius: 5px 5px 0 0;
                    background: #fefcff;

                    &::after {
                        content: "";
                        background: url('../../../assets/images/myaccount/purple-caret-down-solid.svg') center center no-repeat;
                        background-size: 75%;
                        width: 20px;
                        height: 20px;
                        margin-left: 0;
                        margin-top: .5rem;
                        margin-bottom: auto;
                    }

                    &:not(.collapsed)::after {
                        content: "";
                        background: url('../../../assets/images/myaccount/purple-caret-down-solid.svg') center center no-repeat;
                        background-size: 75%;
                        width: 20px;
                        height: 20px;
                        margin-left: 0;
                        margin-top: .5rem;
                        margin-bottom: auto;
                        transform-origin: center;
                        transform: rotateX(180deg);
                    }

                    &.collapsed{
                        border-radius: 5px; 
                        border: 1px solid #9f9f9f6e;
                        // border-bottom: 0;
                    }

                    .accorHeading {
                        width: 97%;
                        padding: 9px 15px;

                        @include my-table;
                    }
                }
            }

            .accordion-collapse {
                .accordion-body {
                    .tableContent {
                        border: 1px solid #DCDDDD;
                        border-bottom-right-radius: 7px;
                        border-bottom-left-radius: 7px;
                        padding: 20px 20px 0;
                        background: #FEFFF9;

                        table,
                        thead,
                        th,
                        tbody,
                        td,
                        tr {
                            background-color: transparent;
                        }

                        table {
                            border: none;

                            thead {
                                tr {
                                    border: none;
                                    border-bottom: 1px solid #DBDBDB;
                                }
                            }

                            tbody {
                                tr {
                                    border: none;
                                    border-bottom: 1px solid #DBDBDB;


                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }

                            th,
                            td {
                                padding: .625em;
                                text-align: center;
                                border: none;
                            }

                            th {
                                font-size: 0.875rem;
                                color: #7F7F7C;
                                font-weight: 600;
                                text-align: left;

                                &:first-child {
                                    text-align: left;
                                }

                                &:nth-child(2) {
                                    text-align: center;
                                }
                            }

                            td {
                                text-align: left;
                                padding: 0.9rem 0.625em;

                                &:first-child {
                                    width: 400px;
                                }

                                &:nth-child(2) {
                                    text-align: center;
                                }

                                span {
                                    font-size: 0.875rem;
                                    font-weight: 600;

                                    &.name{@include font($font14,600,$textcolor,24px);}
                                    &.detail{@include font($font14,400,$grayColor,26px);}
                                }

                                @include order-status;

                                ul {
                                    li {
                                        img {
                                            width: 60px;
                                            height: 60px;
                                        }

                                        &:last-child {
                                            display: flex;
                                            // align-items: baseline;
                                            // justify-content: end;
                                            flex-direction: column;
                                            margin-left: 15px;
                                            position: relative;

                                            // span.tag-wrap {
                                            //     top: 0;

                                            //     .tags {
                                            //         height: 20px;
                                            //         width: 63px;
                                            //         font-size: 0.75rem !important;
                                            //         padding: 2px 4px;
                                            //     }
                                            // }

                                            p {
                                                font-size: 14px;
                                                color: #5D5D5D;
                                                font-weight: 400;
                                                margin-bottom: 0;
                                            }
                                            
                                            h6{font-size: 14px;font-weight: 600;line-height: 24px;margin-bottom: 0.25rem;}
                                        }
                                    }
                                }

                                &:last-child {
                                    // display: flex;
                                    // flex-direction: column;
                                    // justify-content: center;

                                    button {
                                        color: $linkcolor;
                                        background-color: transparent;
                                        border: none;
                                        text-align: left;
                                        display: block;
                                        margin: auto;
                                        position: relative;

                                        span {
                                            position: relative;

                                            &::before {
                                                content: "";
                                                background: url('../../../assets/images/myaccount/caretDownSolid.svg')center center no-repeat;
                                                background-size: 100%;
                                                position: absolute;
                                                left: -10px;
                                                top: 4px;
                                                width: 6px;
                                                height: 10px;
                                                transform: rotate3d(0, 0, 10, 270deg);
                                            }
                                        }
                                    }
                                }

                                p {
                                    line-height: normal;
                                }
                            }

                        }

                        // @media (max-width: 600px) {
                        //     table {
                        //         border: 0;

                        //         caption {
                        //             font-size: 1.3em;
                        //         }

                        //         thead {
                        //             border: none;
                        //             clip: rect(0 0 0 0);
                        //             height: 1px;
                        //             margin: -1px;
                        //             overflow: hidden;
                        //             padding: 0;
                        //             position: absolute;
                        //             width: 1px;
                        //         }

                        //         tr {
                        //             border-bottom: 3px solid #ddd;
                        //             display: block;
                        //             margin-bottom: .625em;
                        //         }

                        //         td {
                        //             border-bottom: 1px solid #ddd;
                        //             display: block;
                        //             font-size: .8em;
                        //             text-align: right;

                        //             &::before {
                        //                 /*
                        //                 * aria-label has no advantage, it won't be read inside a table
                        //                 content: attr(aria-label);
                        //                 */
                        //                 content: attr(data-label);
                        //                 float: left;
                        //                 font-weight: bold;
                        //                 text-transform: uppercase;
                        //             }

                        //             &:last-child {
                        //                 border-bottom: 0;
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }
            }
        }
    }
}

// .replacementWrapper {
//     .tableContent {
//         table {
//             tbody {
//                 tr {
                    
//                 }
//             }
//         }
//     }
// }

.popup-content {
    .pop-body {
        svg {color:$textcolor;font-size: 25px;}
    }
    .popup-arrow {
        color: #0B2138 !important;
    }

    margin: auto;
    width: 276px !important;
    padding: 5px;
    // background: #0B2138;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;

    .tootltipSec {
        .head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            margin-top: 1rem;

            h6 {
                font-size: 0.875rem;
                // font-weight: bold;
                margin-bottom: 4px;
            }
        }

        textarea {
            width: 100%;
            height: 100px;
            background: #fff;
            border-radius: 5px;
        }

        button {
            background: $linkcolor;
            color: #fff;
            margin: 10px 0px 15px 0;
            font-size: 1rem;
            // font-weight: 700;
            padding: 5px 10px;
        }
    }


}

.shippingDts {
    display: flex;
    justify-content: space-evenly;
    margin: 45px 0;

    .secLeft,
    .secRight {
        width: 30%;

        table,
        tbody,
        tr,
        td {
            border: none;

            td {
                text-align: left;

                &:first-child {
                    font-weight: 600;
                }
            }
        }
    }

    .secLeft {
        position: relative;
        left: -90px;
    }

    .secRight {
        width: 40%;

        table {
            tbody {
                tr {
                    td {
                        width: 500px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

.backBtn {
    position: absolute;
    top: 0;
    right: 0;

    button {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0em 1em;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 0px;
        background: #0B2138;
        border: none;
        line-height: 42px;
        color: #FFF;
        z-index: 1;
        width: 132px;
        font-weight: 600;
        text-transform: capitalize;
        font-size: 1.25rem;
    }
}

.cancelOrder {
    .cancelForm {
        width: 560px;
        margin: auto;
        position: relative;

        h3 {
            font-size: 1.21rem;
            color: #0B2138;

            small {
                position: absolute;
                right: 8px;
                font-size: 0.75rem;
                color: #847F7F;
            }
        }

        form {
            ul {
                flex-direction: column;

                li {
                    display: flex;
                    flex-direction: column;
                    margin-top: 40px;

                    label {
                        font-size: 0.875rem;
                        text-transform: uppercase;
                        color: #0B2138;
                        font-weight: 500;
                    }

                    select {
                        width: 100%;
                        border: 1px solid silver;
                        padding: 15px 15px;
                        margin: 5px 0;
                        background: url('../../../assets/images/myaccount/icons/sort-down-solid.svg') no-repeat;
                        background-size: 15px;
                        background-position: top 6px right 16px;

                    }

                    textarea {
                        border: 1px solid silver;
                        padding: 10px;
                        margin: 5px 0;

                        &::before {
                            content: "";

                        }
                    }

                    &:last-child {
                        flex-direction: initial;
                        align-items: center;

                        .checkBox {
                            margin-right: 7px;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #0B2138;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: transparent;

                            .checkedBox {
                                svg {
                                    color: #fff !important;
                                }
                            }
                        }
                    }
                }
            }

            button {
                font-size: 0.875rem;
                font-weight: 400;
                padding: 0em 1em;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                cursor: pointer;
                border-radius: 0px;
                background: #0B2138;
                border: none;
                line-height: 42px;
                color: #FFF;
                z-index: 1;
                margin: 15px 0;
                width: 110px;
                font-weight: 500;
                letter-spacing: 0.1rem;
                text-transform: none;
            }

            .disableBtn {
                opacity: 0.8;
                visibility: unset;
                cursor: not-allowed;
            }
        }
    }
}

// boostrap modal css 
.modal {
    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        height: 5px;
        width: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background: #0B2138;
        border-radius: 10px;
        height: 10px;
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #232a35;
        border-radius: 10px;
    }

    .modal-dialog-centered {
        max-width: 1128px !important;

        .modal-content {
            height: 585px;
            background: $filtertext;
            border-radius: 0;
            padding-right: 20px;
            padding-bottom: 25px;

            .modal-body {
                padding: 0 67px 40px 53px;
                overflow: auto;

                p {
                    font-size: 1rem;
                    color: $textcolor;
                    font-weight: 100;
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }
        }

        .modal-header {
            border: none;
            padding-top: 40px;
            font-size: 1.875rem;
            color: #0B2138;
            padding-left: 53px;

            .h4 {
                font-size: 30px;
                color: $linkcolor;
                font-weight: bolder;
            }

            .btn-close {
                width: 23px;
                height: 25px;
                background: url('../../../assets/images/myaccount/icons/modalClose.svg') no-repeat;
                background-size: 20px;
                display: block;
                filter: none;
                position: relative;
            }
        }
    }
}

.modal.show .modal-dialog {
    max-width: 1128px !important;
}

.backGroundNon {
    background: #0B2138 !important;
}

// #panel-3 {
//     .save-address {
//         width: auto;
//         padding: 0em 2rem;
//         color: #FFF;
//     }

//     .btn {
//         width: 112px;
//         margin-right: 1rem;
//         text-transform: uppercase;
//         box-shadow: none;
//     }

//     .save-address {
//         font-size: 0.875rem;
//         font-weight: 400;
//         letter-spacing: 0.5px;
//         border-radius: 0px;
//         background: #0B2138;
//         border: none;
//         line-height: 42px;
//         color: #FFF !important;
//         height: 40px;
//         display: inline-block;
//         text-align: center;
//         position: relative;
//         transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

//         span {
//             position: relative;
            // z-index: 9;
//         }
//     }

//     .btn-cancel {
//         border: 1px solid #0B2138;
//         color: #0B2138;
//         border-radius: 0;
//     }
// }




// .displayNone{
//     display: none;
// }

// Track Order 
.trackOrder {
    .track-result {
        position: relative;

        .top-info {
            display: flex;
            justify-content: space-between;
            position: relative;

            h2 {
                font-size: 1.875rem;
                font-weight: bold;
                color: $maincolor;
            }
        }

        .orderDelivered {
            padding: 9px 15px;
            border: 1px solid #9f9f9f6e;
            border-radius: 5px;
            background-color: #fefcff;
            margin-top: .5rem;

            ul {
                display: flex;margin-bottom: 0;

                li {
                    h4 {
                        line-height: 21px;
                        font-size: 0.875rem;
                        font-weight: 500;
                        margin-bottom: 0;
                    }

                    span {
                        font-weight: 600;
                        font-size: 0.875rem;
                    }

                    &.OrderPlaced {
                        width: 95px;
                        margin-right: 40px;
                    }

                    &.OrderNo {
                        width: 118px;
                        margin-right: 40px;
                    }

                    &.TrackingId {
                        width: 110px;
                        margin-right: 40px;
                    }

                    &.DeliverTo {
                        width: 125px;
                        margin-right: 40px;
                    }

                    &.DeliveryAdd {
                        width: 370px;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .track-info {
        // padding: 0 255px;
        margin-bottom: 80px;
        width: 100%;
        max-width: 740px;
        margin-left: auto;
        margin-right: auto;

        .track-details {
            h4 {
                text-align: center;
                font-weight: 400;
                color: $textcolor;
                padding-top: 30px;
                padding-bottom: 30px;
                font-size: 1.25rem;
                margin-bottom: 0;
            }

            .orderInfo {
                background-color: #FEFFF9;
                margin-bottom: 30px;

                .ordertable {
                    border: 1px solid #DCDDDD;
                    padding: 15px 20px 0;
                    border-radius: 5px;

                    table {
                        border: none;

                        thead {
                            tr {
                                border: none;

                                th {
                                    border: none;
                                    font-weight: 600;
                                    padding: 8px 20px 15px 0;
                                    border-bottom: 1px solid #DCDDDD;
                                    background-color: #FEFFF9;
                                    font-size: 0.875rem;
                                    color: #7F7F7C;
                                }
                            }
                        }

                        tbody {
                            background-color: #FEFFF9;

                            tr {
                                border: none;

                                td {
                                    font-size: 0.875rem;
                                    padding: 17px 0;
                                    border: none;
                                    font-weight: 600;
                                    vertical-align: top;

                                    span {
                                        font-weight: 600;display: block;
                                    }

                                    ul {
                                        li {
                                            margin-right: 14px;

                                            img {
                                                width: 60px;
                                                height: 60px;
                                            }

                                            p {
                                                font-weight: 600;
                                                font-size: 0.813rem;
                                                margin-top: 35px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    table th,
                    table td {
                        text-align: left;
                    }
                }
            }

            .progressbar-info {
                margin-bottom: 10px;

                .progress {
                    height: 30px;
                    border-radius: 15px;
                    position: relative;
                    background-color: #E9E9E9;

                    .progress-bar {
                        background-color: $maincolor;
                        border-radius: 15px;

                        &::after {
                            content: url('../../../assets/images/icons/done.svg');
                            position: absolute;
                            top: 5px;
                            left: 13%;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                .info {
                    margin-top: 20px;
                    position: relative;

                    ul {
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 0;

                        li {
                            ul {
                                display: inline-block;
                                text-align: center;

                                li {
                                    img {
                                        margin-bottom: 10px;
                                    }

                                    h4 {
                                        font-size: 0.875rem;
                                        font-weight: 400;
                                        padding: 0;
                                        line-height: 24px;

                                        &.incomplete {
                                            color: #B6B5B5;
                                        }
                                    }

                                    p {
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: $textcolor;
                                    }
                                }
                            }

                            &:nth-child(2)::after {
                                content: '';
                                position: absolute;
                                top: -45px;
                                left: 42%;
                                border-radius: 100%;
                                background-color: #fff;
                                width: 20px;
                                height: 20px;
                            }

                            &:nth-child(3)::after {
                                content: '';
                                position: absolute;
                                top: -45px;
                                left: 67%;
                                border-radius: 100%;
                                background-color: #fff;
                                width: 20px;
                                height: 20px;
                            }

                            &:nth-child(4)::after {
                                content: '';
                                position: absolute;
                                top: -45px;
                                left: 89%;
                                border-radius: 100%;
                                background-color: #fff;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

            .updates {
                p {
                    font-size: 1.25rem  ;
                    font-weight: 600;
                }

                .table {
                    border: 1px solid #DCDDDD;
                    padding: 0 17px;

                    table {
                        border: none;

                        thead {
                            tr {
                                border: none;

                                th {
                                    border: none;
                                    font-weight: 600;
                                    padding: 8px 10px;
                                    border-bottom: 1px solid #DCDDDD;
                                    background-color: #fff;
                                    font-size: 15px;
                                    line-height: 25px;
                                }
                            }
                        }

                        tbody {
                            tr {
                                border: none;

                                td {
                                    font-size: 15px;
                                    padding: 10px;
                                    border: none;

                                    p {
                                        font-size: 15px;
                                        font-weight: 600;
                                        margin-bottom: 0;
                                    }

                                    small {
                                        font-size: 15px;
                                        // line-height: 22px;
                                    }

                                }
                            }
                        }
                    }

                    table th,
                    table td {
                        text-align: left;
                    }
                }
            }
        }
    }

}

.trackorder .track-order table tbody tr td .addressInfo {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.tooltipInfo {
    position: absolute;
    top: 77%;
    left: 50.8%;
    transform: translate(-50%, -50%);
    background: #fefcff;
    color: $textcolor;
    display: none;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    width: 270px;
    font-size: 0.875rem;
    height: auto;
    border: 1px solid $linkcolor;

    h4 {
        font-size: 0.875rem;
        font-weight: 600;
        text-align: left;
        line-height: 22px;
        margin-bottom: 0px;
    }

    p {
        font-size: 0.875rem;
        line-height: 22px;
        text-align: left;
        margin-bottom: 0;
    }
}

.tooltip-arrowInfo {
    position: absolute;
    top: -6px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $linkcolor;
}




// #panel-5 .save-address {
//     background: $linkcolor;
//     border: 1px solid $linkcolor;
//     color: #FFF;

//     &:hover {
//         border: 1px solid $linkcolor;
//         color: $linkcolor;
//         background-color: #FFF;
//     }
// }

// #panel-5 .btn-cancel {
//     background-color: #fff;
//     border: 1px solid $linkcolor;
//     color: $linkcolor;

//     &:hover {
//         border: 1px solid $linkcolor;
//         color: #fff;
//         background-color: $linkcolor;
//     }
// }

// #panel-5 .btn {
//     padding: .5rem 2rem;
//     font-size: 0.875rem;
//     font-weight: 400;
//     box-shadow: none;
//     height: auto;
//     font-size: 14px;
//     font-weight: 600;
//     border-radius: 25px;
//     display: inline-block;
//     text-align: center;
//     cursor: pointer;
//     transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
//     margin-right: 1rem;
// }

// Invoice Order
.invoiceOrder {
    .top-info {
        display: flex;
        justify-content: space-between;
        position: relative;

        h2 {
            font-size: 1.875rem;
            font-weight: bold;
            color: $maincolor;
        }

        .btn-back {
            width: 102px;
            height: 40px;
            border: 1px solid $linkcolor;
            color: $linkcolor;
            font-size: 0.875rem;
            font-weight: 600;
            border-radius: 20px;
            outline: none;
            line-height: 27px;
        }
    }

    .topOrderInfo {
        padding: 9px 15px;
        border: 1px solid #9f9f9f6e;
        border-radius: 5px;
        background-color: #fdfdfd;
        margin-top: .5rem;

        ul {
            justify-content: space-between;
            align-items: center;margin-bottom: 0;

            li {
                ul {
                    li {
                        margin-right: 40px;

                        h4 {
                            font-size: 0.875rem;
                            font-weight: 500;
                        }

                        span {
                            font-size: 0.875rem;
                            font-weight: 600;
                        }
                    }
                }

                &.pdf {
                    span{
                        font-size: 13px;
                        color: $linkcolor;
                        font-weight: 600;
                        line-height: 24px;
                        text-transform: uppercase;
                        font-family: "Montserrat", sans-serif;
                        margin-bottom: 0;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 6px;
                        }
                    }
                    
                }
            }
        }
    }

    .orderDetails {

        padding: 30px 92px 0;

        .addressInfo {
            margin-bottom: 40px;

            ul {display: flex;
                justify-content: space-between;

                li {
                    h4 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 25px;
                    }

                    h6 {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 25px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        width: 388px;
                    }
                }
            }
        }

        .scocialInfo {
            margin-bottom: 30px;

            ul {
                li {display: flex;
                    p {
                        font-size: 15px;
                        font-weight: 600;
                        margin-right: 5px;
                        margin-bottom: 0;
                        line-height: 25px;
                    }
                    span{font-size: 15px;font-weight: 600;line-height: 25px;}
                    &:first-child{margin-right: 3rem;}
                }
            }
        }

        .orderData {
            padding: 9px 15px;
            border: 1px solid rgba(159, 159, 159, 0.431372549);
            border-radius: 5px;
            margin-top: 0.5rem;
            margin-bottom: 30px;

            ul {
                display: flex;justify-content: space-between;margin-bottom: 0;

                li {
                    h4 {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 0;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }
        }

        .details {
            padding: 9px 15px;
            border: 1px solid rgba(159, 159, 159, 0.431372549);
            border-radius: 5px;
            margin-top: 0.5rem;
            margin-bottom: 30px;

            ul{
                margin-bottom: 0;
                li{
                    h4 {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 0;
                    }
        
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        margin-bottom: 0;
        
                        &.address {
                            line-height: 28px;
                        }
                    }
                }
            }
        }

        .invoiceDetails {
            margin-top: 30px;
            // border-bottom: 1px solid #DBDBDB;
            // padding-bottom: 30px;

            table {
                border-radius: 5px;

                thead {
                    tr {
                        th {
                            font-size: 0.75rem;
                            font-weight: bold;
                            color: $whitecolor;
                            background-color: $linkcolor;
                            text-align: center;
                            line-height: 15px;
                            vertical-align: top;

                            &.sr{width: 25px;text-align: left;}

                            &.itemInfo {
                                width: 228px;vertical-align: top;text-align: left;
                            }

                            &:nth-child(5) {
                                width: 60px;
                            }

                            &:nth-child(7) {
                                width: 60px;text-align: left;
                            }

                            &:nth-child(11) {
                                width: 92px;
                            }

                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            background-color: #FEFFF9;
                            font-size: 14px;
                            font-weight: 600;
                            padding: 10px 7px;
                            vertical-align: top;
                            line-height: 24px;

                            .productInfo {
                                text-align: left;
                                padding: 1px 6px;

                                .tags{font-size: 14px;font-weight: 600;line-height: 24px;}

                                p {
                                    font-weight: 400;
                                    color: $grayColor;
                                    line-height: 24px;
                                    text-align: left;
                                    font-size: 14px;

                                    // &.prodName {
                                    //     margin-top: 5px;
                                    // }
                                }

                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 24px;
                            padding: 10px 0;
                            border: none;
                            background-color: #FEFFF9;

                            span {
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }

        .totalInfo {
            text-align: right;
            padding: 15px 28px;
            background-color: #FEFFF9;
            border: 1px solid #DBDBDB;
            border-top: none;

            ul {
                display: inline-block;margin-bottom: 0;

                li {
                    display: flex;
                    justify-content: space-between;
                    line-height: 32px;

                    p {
                        font-size: 0.875rem;
                        font-weight: 600;
                        margin-left: 81px;
                        margin-bottom: 0;

                        & span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .wordAmt {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 25px;
            margin-top: 1rem;
        }

        .signInfo {
            font-size: 15px;
            font-weight: 400;
            text-align: right;
            padding-right: 55px;
            line-height: 25px;
        }

        .declaration {
            margin-top: 60px;

            ul {
                display: flex;
                justify-content: space-between;

                li {
                    h4 {
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 25px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        width: 486px;
                        line-height: 24px;

                        &.authSign {
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 25px;
                            text-align: right;
                            margin-top: 50px;
                            padding-right: 65px;
                        }
                    }
                }
            }
        }

        .footerAddress {
            text-align: center;
            border-bottom: 1px solid #DBDBDB;
            padding: 25px 0;

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                color: $grayColor;

                // &:first-child {margin-bottom: 1rem;}

                &.address {
                    margin-bottom: 3px;font-weight: 400;color: $grayColor;
                }
            }

            ul {
                display: flex;
                justify-content: center;margin-bottom: 0;

                li {
                    margin-right: 40px;

                    &:last-child {
                        margin-right: 0;
                    }

                    p{margin-bottom: 1.5rem;font-weight: 400;}
                }
            }

            .info {

                p {
                    font-weight: 400;margin-bottom: 0.25rem;

                    strong {
                        font-weight: 600;
                    }
                }
            }
        }

        .smallInfo {
            padding-top: 15px;
            text-align: center;

            small {
                font-size: 0.75rem;
                color: #676666;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}

//Exchange and Return Order 
.Order {
    .top-info {
        display: flex;
        justify-content: space-between;
        position: relative;

        h2 {
            font-size: 1.875rem;
            font-weight: bold;
            color: $maincolor;
        }

        .btn-back {
            width: 102px;
            height: 40px;
            border: 1px solid $linkcolor;
            color: $linkcolor;
            font-size: 0.875rem;
            font-weight: 600;
            border-radius: 20px;
            outline: none;
            line-height: 27px;
        }
    }

    .topOrderInfo {
        padding: 9px 15px;
        border: 1px solid #9f9f9f6e;
        border-radius: 5px;
        background-color: #fefcff;
        margin-top: .5rem;

        ul {
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;

            li {
                ul {
                    li {
                        margin-right: 40px;

                        h4 {
                            font-size: 0.875rem;
                            font-weight: 500;
                            margin-bottom: 0;
                        }

                        span {
                            font-size: 0.875rem;
                            font-weight: 600;
                        }
                    }
                }

                h4 {
                    &.pdf {
                        font-size: 0.75rem;
                        color: $maincolor;
                        font-weight: bold;

                        img {
                            margin-right: 6px;
                        }
                    }
                }
            }
        }
    }

    .order-info {
        // padding: 0 255px;
        margin-bottom: 80px;
        width: 100%;
        max-width: 740px;
        margin-left: auto;
        margin-right: auto;

        .order-details {
            h4 {
                text-align: center;
                font-weight: 400;
                color: $textcolor;
                padding-top: 30px;
                padding-bottom: 30px;
                font-size: 1.25rem;
            }

            .orderInfo {
                background-color: #FEFFF9;
                margin-bottom: 10px;

                .ordertable {
                    border: 1px solid #DCDDDD;
                    padding: 15px 20px 0;
                    border-radius: 5px;

                    table {
                        border: none;

                        thead {
                            tr {
                                border: none;

                                th {
                                    border: none;
                                    font-weight: 600;
                                    padding: 8px 20px 15px 0;
                                    border-bottom: 1px solid #DCDDDD;
                                    background-color: #FEFFF9;
                                    font-size: 0.875rem;
                                    color: $grayColor;
                                }
                            }
                        }

                        tbody {
                            background-color: #FEFFF9;

                            tr {
                                border: none;

                                td {
                                    font-size: 0.875rem;
                                    padding: 15px 0;
                                    border: none;
                                    font-weight: 600;
                                    line-height: 32px;                           
                                    vertical-align: top;

                                    span {
                                        font-weight: 600;
                                        line-height: normal;

                                        &.name,&.details{display: block;}
                                        &.details{font-weight: 400;color: $grayColor;margin-top: 5px;}
                                    }

                                    .form-select {
                                        width: 60px;
                                        height: 32px;
                                        border-radius: 5px;
                                        border-color: #B6B5B5;
                                        color: $textcolor;
                                        background-image: url('../../../assets/images/caret-down-solid.svg');
                                        background-size: 12px;
                                        background-position: top 6px right 6px;
                                        padding: 4px;
                                        padding-left: 9px;
                                        // margin: 0 -12px;
                                    }

                                    ul {margin-bottom: 0;
                                        li {
                                            margin-right: 14px;
                                            position: relative;

                                            img {
                                                width: 60px;
                                                height: 60px;
                                            }

                                            p {
                                                font-weight: 600;
                                                font-size: 0.813rem;
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    table th,
                    table td {
                        text-align: left;
                    }
                }
            }
        }

        .FormDetails {
            p {
                color: $grayColor;
                font-size: 0.875rem;
                text-align: right;
            }

            form {
                margin-top: 20px;

                .form-row {
                    .form-group {
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.return {
                            width: 100%;

                            select {
                                width: 100%;
                            }
                        }

                        label {
                            font-size: 0.875rem;
                            font-weight: 600;
                            margin-bottom: 5px;
                            display: block;
                            color: $textcolor;
                            text-transform: math-auto;
                            font-family: $basefont;

                            small {
                                font-size: 12px;
                                font-weight: 400;
                            }
                        }

                        .form-select {
                            width: 317px;
                            border-color: #B6B5B5;
                            border-radius: 5px;
                            background-image: url('../../../assets/images/caret-down-solid.svg');
                            background-size: 12px;
                            background-position: top 12px right 16px;
                            font-size: 0.875rem;
                            font-weight: 500;
                            color: #676666;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            padding: 13px 20px;
                            height: auto;

                            &.return {
                                width: 100%;
                            }
                        }

                        &.comments {
                            margin-top: 20px;

                            textarea {
                                resize: none;
                                width: 100%;
                                height: 177px;
                                border-radius: 5px;
                                border: 1px solid #B6B5B5;
                                padding: 10px;
                                outline: none;
                            }
                        }

                    }
                }
            }

            .submitData {
                margin-top: 10px;
                text-align: center;

                .checkbox {
                    justify-content: center;
                    margin-bottom: 20px;

                    .styled-checkbox {
                        position: absolute;
                        opacity: 0;

                        &+label {
                            position: relative;
                            cursor: pointer;
                            color: $grayColor;
                            font-size: 0.875rem !important;
                            font-weight: 400;

                            p {
                                position: absolute;
                                top: 0;
                                left: 29px;
                            }

                            span {
                                font-size: 0.875rem;
                                text-decoration: underline;
                            }

                            a {
                                color: $textcolor;
                                text-decoration: underline;
                            }
                        }

                        // Box.
                        &+label:before {
                            content: '';
                            margin-right: 10px;
                            display: inline-block;
                            vertical-align: text-top;
                            width: 20px;
                            height: 20px;
                            background: transparent;
                            border: 1px solid #B6B5B5;
                        }

                        // Box checked
                        &:checked+label:before {
                            background: $linkcolor;
                        }

                        // Checkmark. Could be replaced with an image
                        &:checked+label:after {
                            content: '';
                            position: absolute;
                            left: 5px;
                            top: 9px;
                            background: #FFFFFF;
                            width: 3px;
                            height: 3px;
                            box-shadow: 2px 0 0 #FFFFFF, 4px 0 0 #FFFFFF, 4px -2px 0 #FFFFFF, 4px -4px 0 #FFFFFF, 4px -6px 0 #FFFFFF, 4px -8px 0 #FFFFFF;
                            transform: rotate(45deg);
                        }

                    }
                }

                // .btn-submit {
                //     width: 132px;
                //     height: 40px;
                //     border-radius: 20px;
                //     background-color: $maincolor;
                //     color: #FFFFFF;
                //     font-size: 0.875rem;
                //     font-weight: 600;
                // }
            }
        }
    }
}

//Exchange and Return Request Message
.reqMesg {
    .top-info {
        display: flex;
        justify-content: space-between;
        position: relative;

        h2 {
            font-size: 1.875rem;
            font-weight: bold;
            color: $maincolor;
        }
    }

    .mesgInfo {
        padding: 0 304px;
        margin-top: 22px;

        h4 {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 24px;
        }

        p {
            font-size: 1rem;
            line-height: 32px;
            margin-top: 22px;
        }

        h6 {
            font-size: 1.25rem;
            color: $maincolor;
            line-height: 24px;
            margin-top: 13px;
        }

        .contactInfo {
            margin-top: 23px;
            margin-bottom: 20px;

            ul {
                display: flex;
                justify-content: space-between;

                li {
                    p {
                        font-size: 1.125rem;
                        color: $textcolor;
                        font-weight: 600;
                        line-height: 32px;
                        margin-top: 0;
                    }

                    span {
                        font-weight: 500;
                        line-height: 32px;
                    }
                }
            }
        }

        .conShopping {
            text-align: center;

            .btn-conShopping {
                background-color: $linkcolor;
                font-size: 0.875rem;
                font-weight: 600;
                border-radius: 50px;
                color: #FFFFFF;
                text-align: center;
                padding: 11px 22px;
            }
        }
    }
}

// invoice popup 
.invoicePopup {
    #popup-root {
        .popup-content {
            width: 440px !important;
            height: 316px !important;
            top: 40% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .pop-body {
                h2 {
                    font-weight: bold;
                }

                p {
                    font-size: 1rem;
                    color: $textcolor;
                    font-weight: 500;
                    line-height: 30px;
                    padding: 10px 30px;
                }
            }
        }
    }
}


.btn.btn-primary {
    border-radius: 5px;
    text-transform: uppercase;
    // height: auto;
    width: auto;
    border: 1px solid $linkcolor;

    &:hover{background-color: #fff;border: 1px solid $linkcolor;color: $linkcolor;}
}

.btn.btn-secondary{
    // margin-left: 2rem;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:before,
    &:after {
        top: 0;
        width: 0;
        height: 100%;
        mix-blend-mode: overlay;
        transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
        content: "";
        position: absolute;
        display: block;
        box-sizing: border-box;
    }

    &:before {
        left: 0;
        width: 0;
        background: $linkcolor;
        color: #fff;
    }

    &:after {
        left: 100%;
        width: 0;
        background: $linkcolor;
        color: #fff;
    }

    &:hover {
        background-color: $linkcolor;
        border: 1px solid $linkcolor;
        color: #fff !important;

        &:before {
            width: 100%;
        }

        &:after {
            width: 100%;
            left: 0;
        }
    }
}

// Tooltip Content
.tootltipSec {
    position: absolute;
    width: 375px;
    background-color: #F6F2F7;
    z-index: 99;
    padding: 25px;
    // bottom: -21.5rem;
    right: -42px;
    top: 30px;
    border: 1px solid $linkcolor;
    border-radius: 5px;
    &::before{
        content: " ";
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $linkcolor;
        width: 0;
        height: 0;
        position: absolute;
        top: -3px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;
        img{
            cursor: pointer;
        }
    }

    .submit-review{display: flex;margin-bottom: 1rem;
        h6 {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: $textcolor;
            margin-bottom: 0;
            font-family: "Montserrat", sans-serif;
            margin-left: .5rem;
        }
    }

    textarea {
        width: 100%;
        height: 122px;
        background: $whitecolor;
        resize: none;
        border: none;
        margin-bottom: 20px;
        &:focus-visible{
            outline: none;
        }
    }

    .btn-submit {
        background-color: $maincolor !important;
        font-weight: 600 !important;
        line-height: 16px !important;
        text-transform: uppercase !important;
        color: $whitecolor !important;
        text-align: center !important;
        border: 1px solid $maincolor !important;
        font-size: 13px;
        margin-left: 0 !important;
        &:hover{
            background-color: $whitecolor !important;
            color: $maincolor !important;
            border: 1px solid $maincolor !important;
        }
    }
}

.invoice-pdf {margin: 10px;
    .invoiceHeader {
        background: #F9F9F9;
        border: 1px solid #DEDEDE;
        text-align: center;
        border-radius: 5px;
        padding: 15px;
        margin: 20px 0;
        position: relative;
        height: 140px;
        @include flexitem;
        img{position: absolute;left: 10px;}
        h2{@include font(35px,700,$linkcolor,43px);margin-bottom: 0;text-transform: capitalize;}
    }

    .orderDetails {
        padding: 30px 0;
    }

    ul{
        li{list-style-type: none;}
    }
}



@media screen and (max-width: 991px) {
    .mainWapper .RRT__container .RRT__tabs{position: relative;}

    .mainWapper .RRT__container .RRT__tabs .RRT__tab {
        width: auto;
    }

    .mainWapper .RRT__container .RRT__panel {
        margin-left: 0;
        position: relative;
        top: 0;
        width: 100%;
        padding: 0 0 30px;
    }

    .mainWapper .RRT__container .RRT__tabs .RRT__tab {
        border-right: 0;
    }

    .mainWapper .RRT__container .RRT__tabs {
        height: auto;
        border-right: none;
        width: 100%;
    }

    .mainWapper .RRT__container .RRT__tabs:hover {
        width: 100%;
        transition: none
    }

    // table {
    //     border: 0;
    // }

    .filterMonth .formMargin{margin: 20px 0px 20px;}

    // table thead {
    //     display: none;
    // }

    // table tr {
    //     margin-bottom: 10px;
    //     display: block;
    //     border-bottom: 2px solid #ddd;
    // }

    // table td {
    //     display: block;
    //     text-align: right;
    //     font-size: 13px;
    //     border-bottom: 1px dotted #ccc;
    // }

    table td:last-child {
        border-bottom: 0;
    }

    // table td:before {
    //     content: attr(data-label);
    //     float: left;
    //     text-transform: uppercase;
    //     font-weight: bold;
    // }

    .mainWapper .RRT__container .RRT__tabs .RRT__tab div span{position: relative;left: 0;opacity: 1;padding-left: 10px;}

    .mainWapper .RRT__container .RRT__tabs .RRT__tab div{border-radius: 5px;}
    .mainWapper .RRT__container .RRT__tabs .RRT__tab{padding-right: 0;}
    // .mainWapper .RRT__container .RRT__tabs:hover div{overflow: auto;}
    .giftcard .row .card tbody td.card-number,.giftcard .row .card tbody td.card-name{width: 160px;}
    .giftcard .row .card tbody td.full-name {width: 130px;}
    .giftcard .row .card tbody td.total-amount,.giftcard .row .card tbody td.card-validity {width: 100px;}
    .giftcard .row .card tbody td.balance-amount {width: 130px;}
    .giftcard .row .card tbody td.status {width: 75px;}
    .giftcard .row .card tbody td {padding: 0 10px;}
    .myaccount .popup-overlay .popup-content{max-width: 90% !important;}
    .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td {padding: 0;}
    .my_profile .my-setting .btn.btn-primary {margin-top: 1rem;}
    .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button::after,.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after{background-size: 50%;width: 30px;height: 30px;}
    .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td ul li button,.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table td,.Data .Table table thead tr th,.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th,.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:nth-child(4) span,.myacc_accordion .accordion .accordion-item .accordion-collapse .accordion-body .tableContent table th,.Data .Table table tbody tr td,.invoiceOrder .orderDetails .invoiceDetails table tbody tr td .productInfo p,.invoiceOrder .orderDetails .invoiceDetails table thead tr th,.orderDetailSec .tableContent table tbody tr td:last-child button span,.orderDetailSec .tableContent table tbody tr td ul li h6,.orderDetailSec .tableContent table tbody tr td,.orderDetailSec .orderlabel table th{white-space: nowrap;}
    .invoiceOrder .orderDetails .addressInfo ul li p{width: auto;}
    .invoiceOrder .orderDetails {padding: 30px 16px 0;}
    .invoiceOrder .orderDetails .addressInfo ul li {width: 40%;}
    .invoiceOrder .orderDetails .wordAmt{font-size: 1rem;}
    .Data .Table table tbody tr td .remark{width: 300px;white-space: break-spaces;}
    .tryathome_acc .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th,.myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td{padding-left: 5px !important;padding-right: 5px !important;}
}

@media screen and (max-width: 450px) {
    .mainWapper .userdetails,.myaccount .popup-overlay .popup-content{padding-bottom: 0;}
    .mainWapper .RRT__container .RRT__panel h5{font-size: 1.5rem;line-height: normal;}
    .mainWapper .RRT__container .RRT__panel h5,.address-form .office-address form .form-row,.address-form .office-address form,.invoiceOrder .topOrderInfo ul li ul li h4,.invoiceOrder .orderDetails .scocialInfo,.invoiceOrder .orderDetails .addressInfo,.myOrderTab .content-container .active-content .filterMonth{margin-bottom: 0;}
    .orderDetailSec .content-container .active-content .filterMonth{flex-wrap: wrap;}
    .filterMonth .formMargin{margin: 20px 0;}
    .filterMonth .formMargin .formRe select{margin-left: 0;}
    .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading,.address-form .office-address form .form-row .form-group,.giftcard #myTab .nav-item,.giftcard #myTab .nav-item .nav-link, .giftcard #myTab .nav-item .nav-link,.invoiceOrder .orderDetails .addressInfo ul li,.topOrderInfo ul li.invoiceOrder .orderDetails .totalInfo ul,.giftcard #myTab .nav-item .nav-link, .giftcard #myTab .nav-item .nav-link{width: 100%;}
    .mainWapper .RRT__container .RRT__panel{padding-left: 0;padding-right: 0;}
    .mainWapper .RRT__container .RRT__tabs .RRT__tab{padding-right: 0;}
    .address-form .office-address form .save-address{width: 100px;padding: 0;}
    .mainWapper .RRT__container .RRT__panel .btn-cancel{width: 100px;padding: 0;}
    .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th{white-space:nowrap;padding-right: 10px;}
    .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th:last-child{padding-right: 0;}
    .myacc_accordion .accordion .accordion-item .accordion-header .accordion-button .accorHeading table td:nth-child(4) span,.invoiceOrder .orderDetails .orderData ul li h4,.invoiceOrder .orderDetails .orderData ul li span{white-space:nowrap;}
    .my_profile .my-setting .form-row,.invoiceOrder .orderDetails .addressInfo ul,.invoiceOrder .topOrderInfo ul,.invoiceOrder .orderDetails .scocialInfo ul,.invoiceOrder .orderDetails .footerAddress ul{flex-wrap: wrap;}
    .my_profile .my-setting .form-row li:first-child, .my_profile .my-setting .form-row li:nth-child(2){margin-right: 0;}
    .my_wishlist.myaccWislist .card{height: auto;width: 100%;}
    .card .card-header{height: auto;}
    .my_wishlist.myaccWislist .card .card-body{padding: 10px;height: auto;}
    .myaccount .popup-overlay .popup-content .terms-popup .pop-title h2{font-size: 24px !important;}
    .myaccount .popup-overlay .popup-content .terms-popup .pop-title .close{font-size: 1rem;}
    .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.img{margin-right: 1.5rem;}
    .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.card-number{width: 160px;}
    .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.card-name,.myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.full-name {width: 130px;}
    .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.total-amount {width: 100px;}
    .myaccount .popup-overlay .popup-content .inner-div .all-card .redeem-detail td.balance-amount {width: 130px;}
    .myaccount .popup-overlay .popup-content .terms-popup .pop-body{padding: 1rem !important;}
    .topOrderInfo ul li.pdf{margin-top: 1rem;}
    .invoiceOrder .topOrderInfo ul li ul li,.invoiceOrder .orderDetails .footerAddress ul li{margin-right: 0;}
    .invoiceOrder .orderDetails .scocialInfo ul li:first-child{margin-right: 0;}
    .invoiceOrder .orderDetails .orderData{padding: 0 5px;}
    .invoiceOrder .orderDetails .orderData ul{overflow: auto;}
    .invoiceOrder .orderDetails .orderData ul li{padding: 5px;}
    .invoiceOrder .orderDetails .invoiceDetails table tfoot tr td{border-top: 1px solid #DBDBDB;}
    .invoiceOrder .orderDetails .totalInfo{padding: 15px;}
    .invoiceOrder .orderDetails .totalInfo ul li p{margin-left: 0;}
    .invoiceOrder .orderDetails .signInfo{padding-right: 0;}
    .invoiceOrder .orderDetails .footerAddress ul,.filterMonth .formMargin,.myOrderTab .content-container .active-content .filterMonth,.videocall .Data .filterMonth{justify-content: space-between;}
    .invoiceOrder .orderDetails .footerAddress ul li{width: 45%;text-align: left;}
    .filterMonth .clearfix::after{display: none;}
    .my-setting .unsubscribe-mail h4{margin-bottom: 1rem;}
    .filterMonth .formMargin .formRe,.videocall .Data .filterMonth select {width: 175px;}
    .mainWapper .userdetails .userName{font-size: 1.5rem;}
    .myacc_accordion:last-child .accordion{margin-bottom: 0;}
    .giftcard #myTab .nav-item{width: 33.33%;}
    .giftcard #myTab .nav-item .nav-link{padding: 8px 10px;}
    .giftcard .row{margin-bottom: 0;}
    .giftcard .row .card:last-child{margin-bottom: 0;}
}

@media screen and (max-width: 390px) {
    .myaccount .popup-overlay .popup-content .terms-popup .pop-title h2{font-size: 20px !important;}
    .giftcard #myTab .nav-item .nav-link{padding: 8px 3px;}
}

.Data .Table{margin-top: 1.5rem;}