.booking-information .details {
  padding-top: 40px;
}
.booking-information .details .contact {
  display: flex;
  flex-wrap: wrap;
}
.booking-information .details .contact .left {
  width: 50%;
}
.booking-information .details .contact .left .booking-details {
  border: 1px solid #E6E6E6;
  padding: 2rem 1rem 1rem;
  border-radius: 5px;
}
.booking-information .details .contact .left .booking-details .change {
  list-style-type: none;
  display: flex;
  padding-left: 0;
}
.booking-information .details .contact .left .booking-details .change li {
  padding-right: 1rem;
  display: flex;
  align-items: center;
}
.booking-information .details .contact .left .booking-details .change li p {
  font-size: 13px;
  margin-bottom: 0;
}
.booking-information .details .contact .left .booking-details .change li .back-button {
  color: #5B005D;
  border: 1px solid #5B005D;
  background-color: #fff;
  font-size: 13px;
  padding: 5px 10px;
  height: auto;
  line-height: normal;
}
.booking-information .details .contact .left .booking-details .change li .back-button:hover {
  background-color: #5B005D;
  color: #fff;
  border: 1px solid #5B005D;
}
.booking-information .details .contact .left .mandatory {
  text-align: right;
  color: #5D5D5D;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin-bottom: 0;
}
.booking-information .details .contact .left .form-group {
  margin-bottom: 1rem;
  list-style: none;
}
.booking-information .details .contact .left .form-group label {
  color: #3F3F3F;
  font-size: 13px;
  line-height: 23px;
  margin-bottom: 5px;
}
.booking-information .details .contact .left .form-group .form-control {
  font-size: 13px;
  height: 40px;
  border: 1px solid #ced4da;
}
.booking-information .details .contact .left .form-group .form-control::-moz-placeholder {
  color: #C8C7C7;
  font-size: 13px;
}
.booking-information .details .contact .left .form-group .form-control::placeholder {
  color: #C8C7C7;
  font-size: 13px;
}
.booking-information .details .contact .left .form-group .form-control:focus {
  border: 1px solid #ced4da;
}
.booking-information .details .contact .left .form-group .form-control:disabled {
  background-color: #F5F1F1;
  color: #5D5D5D;
}
.booking-information .details .contact .left .select-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.booking-information .details .contact .left .select-group .form-group {
  width: 48%;
}
.booking-information .details .contact .left .select-group .form-group .form-select {
  border: 1px solid #B6B5B5;
  background-color: #F5F1F1;
}
.booking-information .details .contact .left .select-group .form-group.mobile .cust-select {
  display: flex;
}
.booking-information .details .contact .left .select-group .form-group.mobile .cust-select .formMargin {
  width: 100%;
}
.booking-information .details .contact .left .select-group .form-group.mobile .cust-select .form-select {
  border-radius: 5px;
  border: 1px solid #B6B5B5;
  height: 40px;
  font-size: 13px;
  color: #7F7F7F;
  width: 80px;
  background: url(../../../assets/images/icons/sort-down-solid.svg) 55px 9px no-repeat;
  margin-right: 6px;
  background-size: 8px;
}
.booking-information .details .contact .left .select-group .form-group.mobile .cust-select .form-select:disabled {
  background-color: #F5F1F1;
  color: #5D5D5D;
}
.booking-information .details .contact .left .select-group .form-group.mobile .cust-select .form-control::-moz-placeholder {
  font-size: 0.875rem;
  color: #7F7F7F;
}
.booking-information .details .contact .left .select-group .form-group.mobile .cust-select .form-control::placeholder {
  font-size: 0.875rem;
  color: #7F7F7F;
}
.booking-information .details .contact .left .select-group h6 {
  color: #3F3F3F;
  font-size: 13px;
  line-height: 23px;
  margin-bottom: 5px;
  width: 100%;
}
.booking-information .details .contact .left .customise {
  text-align: center;
}
.booking-information .details .contact .left .customise .btn-primary {
  width: auto;
  margin: 1.5rem 0 1rem;
  padding: 0 3rem;
}
.booking-information .details .contact .left .customise .btn-primary span {
  font-size: 13px;
}
.booking-information .details .contact .right {
  width: 50%;
}
.booking-information .details .contact .right .faq-accordion {
  padding: 0rem 1rem 1rem 3rem;
}
.booking-information .details .contact .right .faq-accordion h5 {
  font-size: 20px;
  color: #5B005D;
  font-weight: 600;
}
.booking-information .details .contact .right .faq-accordion .accordion-item:first-of-type {
  border-top: 0;
}
.booking-information .details .contact .right .faq-accordion .accordion-item .accordion-button {
  font-size: 15px;
  font-weight: 600;
}
.booking-information .details .contact .right .faq-accordion .accordion-item .accordion-body {
  font-size: 14px;
  padding-left: 15px;
}/*# sourceMappingURL=index.css.map */