@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';
.review-order {
    display: flex;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    width: 100%;
    // margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #c8c7c7;

    >li {
        list-style-type: none;

        &:first-child {
            width: 72.7%;
            margin-right: 1.5rem;
        }

        &:nth-child(2) {
            width: 25%;
        }
    }

    .wishlist-link {
        a {
            svg {
                color: $maincolor;
                cursor: pointer;
                font-size: 14px;
            }
        }
    }

    .summary-cancel {
        display: none;
        text-align: center;
    }

    .product-price-counter {
        @include mapped-product-price-counter;
         > li:nth-child(2) {
            width: auto !important;
        }
    }
}

.try-at-home {
    margin-bottom: 7rem;
    p.count{border-top: 1px solid #c8c7c7;padding-top: 1rem;font-size: 15px;
    span{font-weight: 600;}
    }
    .try-at-home-cart {
        display: flex;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;
        
        >li {
            list-style-type: none;
    
            &:first-child {
                width: 72.7%;
                margin-right: 1.5rem;
            }
    
            &:nth-child(2) {
                width: 25%;
            }
        }
    
        .right {
            .order-summary {
                .summary-data {
                    .btn.btn-primary{padding: 0 20px;}
                }
            }
            .text-center {
                a {
                    .continue-link{font-size: 14px;}
                }
                .min{font-size: 13px;color: #3F3F3F;line-height: 23px;}
            }
        }
    }

    .home-works{
        h2{margin-bottom: .5rem;}
        .sub-title{color: #5D5D5D;font-size: 20px;}

        .process {
            display: flex;
            justify-content: space-between;
            padding: 4rem 0;

            .card {
                height: 300px;
                min-height: 300px;
                width: 23%;
                border: none;

                &:first-child::before,&:nth-child(3)::before{
                    content: "";
                    position: absolute;
                    width: 115%;
                    height: 55px;
                    top: -55px;
                    left: 9rem;
                    background: url(../../../assets/images/up_arrow.png) top no-repeat;
                }

                &:nth-child(2)::after{
                    content: "";
                    position: absolute;
                    width: 115%;
                    height: 55px;
                    bottom: -55px;
                    left: 9rem;
                    background: url(../../../assets/images/down_arrow.png) bottom no-repeat;
                }
                
                .card-body {
                    padding: 2rem;
                    text-align: center;
                    background-color: #f9f9fb;

                    // &::before{
                        
                    // }

                    img{margin-bottom: 1rem;}
                    h5{color: $linkcolor;font-size: 20px;line-height: 28px;
                        span{display: block;}
                    }
                    p{font-size: 13px;color: #3F3F3F;line-height: 23px;
                        span{display: block;}
                    }
                }
            }
        }
    }
}

a.continue-link{
    // font-size: 14px;
    font-weight: 600;color: $linkcolor;text-transform: uppercase;
}

.order-summary {
    background-color: $iconcolor;
    padding: 1rem 1rem 1.5rem;
    margin-bottom: 1.5rem;

    h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 1rem;
        color:$linkcolor;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            list-style-type: none;
            line-height: 2.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 400;

            &.product-code {
                flex-wrap: wrap;
                line-height: 1.5rem;
                padding: 0.5rem 0;

                .code {
                    font-size: 12px;
                    font-weight: 400;
                    color: $maincolor;
                    margin-bottom: 0;

                    span {
                        font-weight: 600;
                    }
                }
            }

            h5 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
            }
            span.amount{
                font-family: "Nunito Sans";font-size: 14px;font-weight: 600;
                strong{font-weight: 700;}
            }
            &:last-child{
                margin-bottom: 10px;
            }
        }

        .btn-primary {
            margin-bottom: 15px;
        }
    }

    span.close {
        cursor: pointer;
        position: absolute;
        top: 1.6rem;
        right: 0;
        padding: 12px 16px;
        transform: translate(0%, -50%);
        font-size: 30px;
        display: none;
    }
    
}

.review_cart {
    ul.review-list{
        border-radius: 0;
        height: 55px;
        width: 580px;
        overflow: hidden;
        display: flex;justify-content: center;align-items: center;
        max-width: 580px;
        // border: 1px solid $linkcolor;
        margin: 0 auto;
        li{
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;margin-bottom: 0;
            height: 55px;
            padding: 6px 12px;
            font-size: 20px;
            font-weight: 400;
            // border-radius: 0;
            color: $linkcolor;
            text-align: center;
            width: 290px;
            border-bottom: none;
            cursor: pointer;
            &.custom-active{
                background: $maincolor;
                border-color: $maincolor;
                color: $whitecolor;
                
                // border-radius: 0;
                border-radius: 5px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}