@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';

// .registration-page {
//     background-color: #FCFCFC;

//     .wrapper {
//         margin-top: 10rem;
//     }
// }

.registration-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 14rem;
}

.registration-wrapper {
    width: 505px;
    background-color: $whitecolor;
    padding: 50px 60px 5px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.16);

    h2 {
        margin-bottom: 15px;
    }

    h5 {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 1.25rem;
    }

    .form-group {
        text-align: left;
        margin-bottom: 0.75rem;
    }

    p.text {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0.5rem;
        text-align: center;

        &.account {
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            color: $maincolor;

            a {
                color: inherit;
            }
        }
    }


    label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .form-control {
        border: 1px solid $bordercolor;
        border-radius: 5px;

        &:focus {
            box-shadow: none;
        }
    }

    .btn-primary {
        width: 100%;
        margin: 1rem 0;

        span{font-size: 13px;}
    }

    a.forgot_pass {
        text-align: right;
        display: block;
        font-size: 12px;
        color: inherit;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
    }

    .otpverifyPage {

        h2{margin-bottom: 25px;}

        form {
            .inputFields {
                border: none;
                margin: 0px;
                margin-bottom: 6px;
                padding:0;
                input {
                    width: 64px;
                    border-radius: inherit;
                    text-align: center;
                    height:40px;
                    border-top: 1px solid $bordercolor;
                    border-right: 1px solid $bordercolor;
                    border-bottom: 1px solid $bordercolor;
                    border-left: none;
                    border-radius: 0;
                    &:first-child {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-left: 1px solid $bordercolor;
                    }

                    &:last-child {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                    &:focus-visible{
                        outline: none;
                    }
                }
            }

            p {
                margin-top: 10px;
                font-size: 14px;
                color: $textcolor;
                font-weight: 600;
                a {
                    color: $maincolor;
                    text-decoration: underline;
                }
            }
        }
    }
}