@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

.collection-list {
        figure {
            height: 420px;

            img {
                width: 100%;
                @include cover;
                border-radius: 10px;
            }

            &:hover {
                img {
                    filter: grayscale(1);
                    transition: .5s all ease-in-out;
                }
            }
        }
}
