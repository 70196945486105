@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';

.offer-msg{width: 100%;text-align: center;text-transform: uppercase;
    padding-right: 300px;
    h5{
        font-size: 0.9rem;margin-bottom:0;line-height: 25px;
        a{
            color:$whitecolor;font-weight: bold;text-decoration: underline;
        }   
    }
}

.marquee-container {
    .overlay{
        display: none;
    }
}