.address-form .topheading {
  display: flex;
  justify-content: space-between;
}
.address-form .topheading h5 {
  margin-bottom: 0;
}
.address-form .topheading h6 {
  font-size: 12px;
  color: #5D5D5D;
  margin-top: auto;
  margin-bottom: 0;
}
.address-form .office-address .new-address {
  display: flex;
  justify-content: space-between;
}
.address-form .office-address .new-address h5 {
  font-size: 20px !important;
  font-weight: 600;
  line-height: 28px;
}
.address-form .office-address .new-address h6 {
  font-size: 14px;
  color: #676666;
  margin-top: auto;
  margin-bottom: 0;
}
.address-form .office-address form {
  margin-bottom: 2rem;
}
.address-form .office-address form .form-row {
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}
.address-form .office-address form .form-row .form-group {
  margin-bottom: 1rem;
  width: 32%;
}
.address-form .office-address form .form-row .form-group label {
  text-transform: capitalize;
  font-size: 13px !important;
  font-weight: 600;
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  color: #3F3F3F !important;
  line-height: 23px;
}
.address-form .office-address form .form-row .form-group .form-control {
  border-radius: 5px;
  border: 1px solid #B6B5B5;
  height: 40px;
  margin-right: 5px;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
}
.address-form .office-address form .form-row .form-group .form-control::-moz-placeholder {
  font-size: 0.75rem;
  color: #676666;
}
.address-form .office-address form .form-row .form-group .form-control::placeholder {
  font-size: 0.75rem;
  color: #676666;
}
.address-form .office-address form .form-row .form-group .form-control:disabled {
  background-color: #f9f9f9;
}
.address-form .office-address form .form-row .form-group .form-control:focus {
  box-shadow: none;
}
.address-form .office-address form .form-row .form-group p.form-error {
  color: red;
  font-size: 0.8rem;
  font-weight: 700;
  padding-top: 0.6rem;
}
.address-form .office-address form .form-row .form-group .form-select {
  border-radius: 5px;
  border: 1px solid #B6B5B5;
  height: 40px;
  font-size: 0.75rem;
  color: #676666;
  background-image: url("../../../assets/images/caret-down-solid.svg");
  background-size: 8px;
  background-position: top 12px right 15px;
  font-family: "Montserrat";
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.address-form .office-address form .form-row .form-group .form-select::-moz-placeholder {
  font-size: 0.75rem;
  color: #7F7F7F;
}
.address-form .office-address form .form-row .form-group .form-select::placeholder {
  font-size: 0.75rem;
  color: #7F7F7F;
}
.address-form .office-address form .form-row .form-group.state .error-message, .address-form .office-address form .form-row .form-group.city .error-message {
  line-height: 1.5;
}
.address-form .office-address form .form-row:last-child {
  margin-bottom: 1.5rem;
}
.address-form .office-address form .save-address {
  margin-right: 10px;
}
.address-form .office-address .address-review {
  margin-bottom: 2rem;
}
.address-form .office-address .address-review .addresses {
  display: flex;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}
.address-form .office-address .address-review .addresses .address-box {
  border-radius: 5px;
  border: 1px solid #E6E6E6;
  padding: 1rem;
  width: 31%;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.address-form .office-address .address-review .addresses .address-box h4 {
  font-size: 15px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 0.25rem;
}
.address-form .office-address .address-review .addresses .address-box h6 {
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
}
.address-form .office-address .address-review .addresses .address-box p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 1rem;
  word-wrap: break-word;
  word-break: break-word;
}
.address-form .office-address .address-review .addresses .address-box .address-span {
  line-height: 25px;
}
.address-form .office-address .address-review .addresses .address-box .address-span span {
  color: #5B005D;
  font-weight: 600;
  cursor: pointer;
}
.address-form .office-address .address-review .addresses .address-box .address-span span:first-child {
  margin-right: 0.5rem;
}
.address-form .office-address .address-review .addresses .address-box .address-span span.or {
  color: #E8E5E5;
}
.address-form .office-address .address-review .addresses .address-box .address-span span:last-child {
  margin-left: 0.5rem;
}/*# sourceMappingURL=addressform.css.map */