//Summera Promise

.promise-card-wrapper{
    display: flex;justify-content:space-between;margin:0 auto;max-width:1201px;margin-top:1.2rem;
    .promise-card{
        height: 95px;width: 120px;
        
        figure{
            width: 40px;height: 40px;margin:0 auto;
            img{
                object-fit: cover;width: 100%;
            }
        }
        figcaption{
            font-size: 16px;font-weight: 400;line-height: normal;text-align: center;margin-top: 0.5rem;
        }
        &:nth-child(3),&:nth-child(6){
            width: 80px;
        }
    }
}
