.registration-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  margin-top: 14rem;
}

.registration-wrapper {
  width: 505px;
  background-color: #fff;
  padding: 50px 60px 5px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.16);
}
.registration-wrapper h2 {
  margin-bottom: 15px;
}
.registration-wrapper h5 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 1.25rem;
}
.registration-wrapper .form-group {
  text-align: left;
  margin-bottom: 0.75rem;
}
.registration-wrapper p.text {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0.5rem;
  text-align: center;
}
.registration-wrapper p.text.account {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #3B003A;
}
.registration-wrapper p.text.account a {
  color: inherit;
}
.registration-wrapper label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.registration-wrapper .form-control {
  border: 1px solid #B6B5B5;
  border-radius: 5px;
}
.registration-wrapper .form-control:focus {
  box-shadow: none;
}
.registration-wrapper .btn-primary {
  width: 100%;
  margin: 1rem 0;
}
.registration-wrapper .btn-primary span {
  font-size: 13px;
}
.registration-wrapper a.forgot_pass {
  text-align: right;
  display: block;
  font-size: 12px;
  color: inherit;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.registration-wrapper .otpverifyPage h2 {
  margin-bottom: 25px;
}
.registration-wrapper .otpverifyPage form .inputFields {
  border: none;
  margin: 0px;
  margin-bottom: 6px;
  padding: 0;
}
.registration-wrapper .otpverifyPage form .inputFields input {
  width: 64px;
  border-radius: inherit;
  text-align: center;
  height: 40px;
  border-top: 1px solid #B6B5B5;
  border-right: 1px solid #B6B5B5;
  border-bottom: 1px solid #B6B5B5;
  border-left: none;
  border-radius: 0;
}
.registration-wrapper .otpverifyPage form .inputFields input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #B6B5B5;
}
.registration-wrapper .otpverifyPage form .inputFields input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.registration-wrapper .otpverifyPage form .inputFields input:focus-visible {
  outline: none;
}
.registration-wrapper .otpverifyPage form p {
  margin-top: 10px;
  font-size: 14px;
  color: #231535;
  font-weight: 600;
}
.registration-wrapper .otpverifyPage form p a {
  color: #3B003A;
  text-decoration: underline;
}/*# sourceMappingURL=index.css.map */