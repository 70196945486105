@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

.occasion-list {
    @include copyright(0, flex, space-between);

    margin-bottom: 0;
    flex-wrap: wrap;
    figure {
        height: 150px;
        position: relative;
        margin-bottom: 1.75rem;

        img {
            width: 100%;
            @include cover;border-radius: 10px;
        }

        figcaption {
            transition: 0.5s all ease-in-out;
            position: absolute;
            @include font(35px, 200);
            line-height: normal;
            left: 20px;
            bottom:10px;
            transform: translateY(0%);
            width: 30%;

            &.color-blue {
                h3 {
                    color: #031355;font-size: 35px;font-weight: 200;
                }
            }

            h3 {
                color: $whitecolor;font-size: 20px;font-weight: 400;
            }
        }

        &:hover {
            figcaption {
                transform: translateY(-50%);
            }
        }

        &:hover {
            img {
                filter: grayscale(1);
                transition: .5s all ease-in-out;
            }
        }
    }

    li {
        width: 31.8%;
        // margin-right: 1.75rem;
        list-style-type: none;
        &:last-child {
            margin-right: 0;
            figure{
                figcaption{
                    h3{
                        color: #000;
                    }
                }
            }
        }

        &:nth-child(3) {
            margin-right: 0;
        }
    }
}

