.BudgetList{
    display: flex;justify-content: space-between;
}
.card.BudgetCard{
    min-height: 340px;
    p {
        font-size: 15px;margin-bottom: 0px;
    }
    .price{
        bottom: 0px;font-weight: 600;line-height:normal;
        span{
            display: flex;justify-content: flex-start;align-items: center;
        }
    }
    .card-body {
        padding:10px 10px 15px;height: 70px;
    }
    &:hover {
        height: auto;
    }
}