.my-setting .topheading {
  display: flex;
  justify-content: space-between;
}
.my-setting .topheading h6 {
  font-size: 12px;
  color: #5D5D5D;
  margin-top: auto;
}
.my-setting .unsubscribe-mail h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.my-setting .unsubscribe-mail .form-group {
  margin-bottom: 1.5rem;
}
.my-setting .unsubscribe-mail .form-group label {
  display: block;
  text-transform: capitalize;
}/*# sourceMappingURL=mysettings.css.map */