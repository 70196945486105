.hide-price-offer .card h5.offer {
  display: none;
}
.hide-price-offer .card small {
  display: none;
}

.card {
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  min-height: 320px;
  width: 250px;
}
.card .card-header {
  height: 270px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0;
  transition: 0.5s all ease-in-out;
  background: #fff;
  padding-top: 50px;
}
.card img.card-img-top {
  width: auto;
  max-width: 100%;
  transition: all 0.5s ease;
  height: 100%;
}
.card img.card-img-top:hover {
  transition: all 0.5s ease;
  transform: scale(1.1);
}
.card .product-label {
  position: absolute;
  top: 0;
  padding: 10px;
  width: 100%;
}
.card .product-label ul {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
}
.card .product-label .wishlist-link {
  position: absolute;
  right: 10px;
  top: 10px;
}
.card a {
  color: inherit;
  display: inline-block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.card p {
  margin-bottom: 10px;
  line-height: normal;
}
.card .price {
  position: relative;
  bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: #3B003A;
  line-height: "";
  width: 100%;
  left: 0;
  line-height: 2.5rem;
}
.card .price i.fa {
  padding-right: 4px;
}
.card .price s {
  font-size: 0.875rem;
  padding-left: 4px;
}
.card .price span.cart {
  float: right;
  display: none;
}
.card .price .tooltip-label:after {
  bottom: 24px;
}
.card .price small {
  font-size: 0.875rem;
}
.card .card-body {
  padding: 10px 10px;
  position: relative;
  height: 100px;
}
.card .card-body p {
  font-size: 13px;
}
.card .jewels-name {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
}
.card h5.offer {
  position: relative;
  bottom: 0px;
  margin-bottom: 0;
  line-height: normal;
}
.card .btn-similar {
  display: none;
}
.card:hover {
  box-shadow: 1px 1px 5px #ddd;
  padding-bottom: 0;
}
.card:hover .btn-similar {
  border: 1px solid #5B005D;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  display: flex;
  justify-content: center;
}
.card:hover .btn-similar .btn {
  text-transform: uppercase;
  width: 100%;
  color: #5B005D;
  font-weight: 600;
  border-radius: 5px;
}
.card:hover .btn-similar .btn::focus {
  outline: 0;
}
.card:hover > img {
  transform: scale(1.1);
  transition: all 0.5s ease;
}/*# sourceMappingURL=card.css.map */