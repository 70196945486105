@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';

.shop-list {
    padding:0;margin:0;display: flex;
    li {
        width: auto;padding:0 8px 15px;list-style-type: none;
        .card {
            width: 180px;min-height:125px;padding:0;overflow: inherit;
            img {
                &.card-img-top {
                    height: 125px;min-height: 125px;width: 180px;object-fit: cover;
                }
            }

            .card-body {
                text-transform: uppercase;
                background-color: $maincolor;
                width: 180px;
                height: 50px;
                padding: 0.8rem 1rem;
                position: absolute;
                bottom: -50px;
                transition: $DefaultTransition;
                @include flexitem;
                border-bottom-right-radius:10px;
                border-bottom-left-radius:10px;
                h3 {
                    @include font(20px, 400, $whitecolor);
                    text-align: center;
                    margin: 0;
                }

                p {
                    @include font(13px, 400, $whitecolor, normal);
                    text-align: center;
                    margin-bottom: 2px;
                }
            }

            &:hover {height:auto;
                img {
                    // filter: grayscale(1);
                    transition: .5s all ease-in-out;
                }
            }
        }
    }

    &.btm-border {
        padding-bottom: 6.5rem;
    }

    a {
        &:hover {
            .card-body {
                height: 70px;
            }
        }
    }
}