.tryathome_acc .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th {
  padding: 0 0 8px !important;
}
.tryathome_acc .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th:first-child {
  width: 150px;
}
.tryathome_acc .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th:nth-child(2) {
  width: 200px;
}
.tryathome_acc .accordion .accordion-item .accordion-header .accordion-button .accorHeading table th:nth-child(3) {
  width: 100px;
}
.tryathome_acc .accordion .accordion-item .accordion-body .tableContent table tbody tr td:last-child {
  display: table-cell !important;
}
.tryathome_acc .accordion .accordion-item .accordion-body .tableContent table tbody tr td ul {
  margin-bottom: 0;
}/*# sourceMappingURL=index.css.map */