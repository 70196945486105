.about {
  margin-top: 1.8rem;
  position: relative;
  height: 450px;
}
.about figure {
  height: 450px;
  background-size: cover;
  position: absolute;
  background-position: center;
  width: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(../../assets/images/banner-about.jpg);
}
.about section {
  left: 50%;
  width: 50%;
  height: 450px;
  position: relative;
  text-align: center;
  flex-direction: column;
  padding: 0 4rem;
  display: flex;
  justify-content: center;
}
.about section p {
  color: #fff;
  margin-bottom: 2rem;
  text-align: justify;
}
.about section h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  line-height: "";
  margin-bottom: 1rem;
}/*# sourceMappingURL=index.css.map */