.login-page {
  background-color: #FCFCFC;
}
.login-page .wrapper {
  margin-top: 10rem;
}

.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  margin-top: 14rem;
}

.login-wrapper {
  width: 505px;
  background-color: #fff;
  padding: 50px 60px 30px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.16);
}
.login-wrapper h5 {
  font-size: 15px;
  font-weight: 400;
}
.login-wrapper h6 {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  position: relative;
}
.login-wrapper h6:before {
  background-color: #B7B7B7;
  height: 1px;
  width: 150px;
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
}
.login-wrapper h6:after {
  background-color: #B7B7B7;
  height: 1px;
  width: 150px;
  content: "";
  position: absolute;
  right: 0;
  top: 6px;
}
.login-wrapper p.text {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0.5rem;
  text-align: center;
}
.login-wrapper p.text.account {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #3B003A;
}
.login-wrapper p.text.account a {
  color: inherit;
}
.login-wrapper .login-list {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper .login-list li {
  list-style-type: none;
  margin: 0 0.5rem;
}
.login-wrapper label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.login-wrapper .form-control {
  border: 1px solid #B6B5B5;
  border-radius: 5px;
}
.login-wrapper .form-control:focus {
  box-shadow: none;
}
.login-wrapper .btn-primary {
  width: 100%;
  margin: 1.5rem 0 1rem;
}
.login-wrapper .btn-primary span {
  font-size: 13px;
}
.login-wrapper p {
  font-size: 14px;
  color: #231535;
  font-weight: 600;
}
.login-wrapper p a {
  color: #3B003A;
  text-decoration: underline;
}
.login-wrapper a.forgot_pass {
  text-align: right;
  display: block;
  font-size: 12px;
  color: inherit;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.login-wrapper .otpverifyPage form .inputFields {
  border: none;
  margin: 0px;
  margin-bottom: 6px;
  padding: 0;
}
.login-wrapper .otpverifyPage form .inputFields input {
  width: 64px;
  border-radius: inherit;
  text-align: center;
  height: 40px;
  border-top: 1px solid #B6B5B5;
  border-right: 1px solid #B6B5B5;
  border-bottom: 1px solid #B6B5B5;
  border-left: none;
  border-radius: 0;
}
.login-wrapper .otpverifyPage form .inputFields input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #B6B5B5;
}
.login-wrapper .otpverifyPage form .inputFields input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.login-wrapper .otpverifyPage form .inputFields input:focus-visible {
  outline: none;
}
.login-wrapper .otpverifyPage form p {
  margin-top: 10px;
  font-size: 14px;
  color: #231535;
  font-weight: 600;
}
.login-wrapper .otpverifyPage form p a {
  color: #3B003A;
  text-decoration: underline;
}

.form-group.password {
  margin-top: 1rem;
}
.form-group.password .field-icon {
  float: right;
  right: 10px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
  color: #9B9B9B;
}/*# sourceMappingURL=index.css.map */