.accordion-item {
  border-left: none;
  border-right: none;
  background-color: #FBFBFB;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item .accordion-body {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: #231535;
}
.accordion-item .accordion-body table td {
  padding-right: 6px;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #0B2138;
  box-shadow: none;
}

.accordion-button {
  font-size: 20px;
  color: #0B2138;
  padding-left: 6px;
  line-height: 1rem;
}
.accordion-button:focus {
  box-shadow: none;
  border-color: transparent;
}
.accordion-button:after {
  background-image: none;
}

button.accordion-button.collapsed span.minusicon {
  display: none;
}
button.accordion-button.collapsed span.plusicon {
  display: block;
}

button.accordion-button span.plusicon {
  display: none;
  color: #5B005D;
}

span.plusicon, span.minusicon {
  position: absolute;
  right: 0;
  color: #5B005D;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

table.price-breakup {
  width: 100%;
}
table.price-breakup th, table.price-breakup td {
  line-height: 1.5rem;
}
table.price-breakup tr.paddbtm td {
  padding-bottom: 10px;
}

.imp-heading {
  font-weight: 600;
}

p.imp-heading {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 15px;
}

th {
  font-weight: 600;
}
th span {
  font-weight: 400;
  font-size: 12px;
}

.sub-total {
  padding-top: 10px;
}

.jewelry-list {
  padding: 0;
  margin: 0;
}
.jewelry-list li {
  list-style-type: none;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 10px;
}
.jewelry-list li span.icon {
  position: absolute;
  left: 0;
}
.jewelry-list li span.icon img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.jewelry-list li span.title {
  font-weight: 600;
  display: block;
  margin-bottom: 4px;
}

.reviewStats {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0px;
  padding-bottom: 25px;
  border-bottom: solid 1px #E8E3E3;
  margin-bottom: 25px;
}
.reviewStats h2 {
  margin: 0;
  text-align: left;
}
.reviewStats .overall .stars {
  font-size: 18px;
  color: #5B005D;
}
.reviewStats .overall .stars svg {
  margin-right: 4px;
}
.reviewStats .overall p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 0;
}
.reviewStats .detailReviews {
  margin-left: 60px;
  padding-left: 40px;
  position: relative;
}
.reviewStats .detailReviews:before {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #E8E3E3;
  height: 91px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.reviewStats .detailReviews ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.reviewStats .detailReviews ul li {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  color: #5B005D;
  font-weight: 600;
}
.reviewStats .detailReviews ul li span + span {
  margin-left: 5px;
  font-size: 12px;
}
.reviewStats .detailReviews .progress {
  height: 2px;
  border-radius: 3px;
  background: #F1F1F1;
  width: 80px;
}
.reviewStats .detailReviews .progress-bar {
  background-color: #5B005D;
  border-radius: 10px;
}

.review-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.review-list li {
  font-size: 18px;
  display: block;
  margin-bottom: 1rem;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #e8e3e3;
}
.review-list li p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #5D5D5D;
}
.review-list li strong {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
}
.review-list li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.review-list .stars {
  display: block;
  margin-bottom: 4px;
  color: #5B005D;
  margin-right: 4px;
}
.review-list .reviewerDls span {
  font-weight: 600;
  font-size: 14px;
}
.review-list .reviewerDls .date {
  color: #5D5D5D;
  font-size: 14px;
  position: relative;
  padding-left: 12px;
  margin-left: 6px;
}
.review-list .reviewerDls .date:before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  background: #5D5D5D;
  border-radius: 100%;
  left: 4px;
  top: 8px;
}

.review-wrapper {
  overflow-y: scroll;
  height: 420px;
}
.review-wrapper::-webkit-scrollbar {
  width: 4px;
}
.review-wrapper::-webkit-scrollbar-track {
  width: 5px;
  background-color: #F5F5F5;
  border-radius: 10px;
}
.review-wrapper::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: #5B005D;
  height: 62px;
  border-radius: 10px;
}/*# sourceMappingURL=index.css.map */