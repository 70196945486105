@import '../../assets/Scss/variable';
@import '../../assets/Scss/mixing';
.bg-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 0vw;
    height: 100vh;
    background-color: $textcolor;
    content: "";
    z-index: 999;
    opacity: 0;
    transition: opacity 0.5s, width 0.5s;

    &.show {
        transition: opacity 0.5s, width 0.5s;
        width: 100vw;
        opacity: 0.5;
    }
}
.product-top {
    display: flex;justify-content: space-between;align-items: center;
}
.wishlist-link {
    svg{
       font-size: 22px;color:#231535;
    }
}
.shareicon{
    font-size: 22px;margin-left: 5px;color:#231535;
}


.product-detail {
    color:#5D5D5D;font-weight:400;
    .desc {
        padding-bottom: 45px;
        display: flex;

        .maain {
            display: flex;
            width: 50%;

            .flex {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                margin-right: 8px;
                width: auto;
                // height: 400px;
                height: fit-content;

                .img-div {
                    border: 1px solid $cardcolor;
                    padding: 0px;
                    border-radius: 0px;
                    margin: 0 auto 10px;
                    width: 60px;
                    height: 60px;

                    .mini-img {
                        max-width: 100%;
                        // max-height: 100%;
                        width: 60px;
                        height: 58px;
                        cursor: pointer;
    
                        // &:first-child {
                        //     border-bottom: 8px solid rgb(179, 136, 255);
                        // }
    
                        //   &:hover {
                        //     transform: scale(1.12);
                        //     transition: all .75s ease-in-out;
                        //   }
                    }
                }
            }

            .view-img {
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin-right: 30px;
                border: 1px solid #B6B5B5C2;
                // padding: 50px;
                border-radius: 5px;
                width: 100%;
                align-items: center;
                // height: 400px;
                height: fit-content;
                min-height: 550px;
                // overflow: hidden;
                .img {
                    max-width: 100%;
                    height: auto;
                    // max-height: 100%;
                }
            }
        }

        .details {
            width: 50%;
          

                h3.product-title {
                    font-size: 14px;color: #676666;margin-bottom:4px;
                }

                .share {
                    display: flex;

                    .add-wishlist {
                        color: #00A0E3;
                        font-size: 14px;
                        font-weight: 600;
                        margin: 0 5px;

                        a {
                            display: flex;
                            align-items: center;
                            color: #00A0E3;

                            .FaRegHeart {
                                margin-right: 5px;
                                font-size: 20px;
                            }
                        }
                    }

                    .share-text {
                        color: #00A0E3;
                        font-size: 14px;
                        font-weight: 600;
                        margin: 0 5px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        .FaShareAlt {
                            margin-right: 5px;
                        }
                    }
                }

            .cat {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 1rem;
            }

            .price {
                .value {
                    font-size: 32px;
                    color: #231535;
                    font-weight: 700;
                    display: flex;justify-content: flex-start;align-items: center;
                    margin-bottom: 1rem;
                    svg{
                        font-weight: 400;
                    }

                    .fa-rupee-sign {
                        margin-right: .5rem;
                    }

                    span.can-price {
                        font-size: 16px;
                        color: #5D5D5D;
                        font-weight: 700;
                        text-decoration: line-through;
                        margin-left: 10px;
                    }
                    span.offer{
                        color:$hovercolor;font-size: 13px;font-weight: 400;margin-left: 1rem;
                    }
                }
            }

            .size {
                font-weight: 400;
                margin-bottom: 1.25rem;

                span {
                    font-weight: 500;
                }
            }

            .product-size {
                margin-bottom: 2rem;

                .title {
                    // font-size: 400;
                    font-size: 12px;
                    padding-right: 6px;
                    span{
                        color:$linkcolor;margin-left:0px;
                        a {color:$linkcolor;padding-right: 6px;}
                    }
                }

                .add {
                    display: flex;

                    &.btngroup{
                        justify-content: flex-start;margin-top:1rem;
                        .btn{
                            margin-right: 10px;
                            span{
                                svg{
                                    margin-right: 4px;vertical-align: inherit;
                                }
                            }
                        }
                        .btn-primary{
                            &.disabled{
                                background-color: $linkcolor;opacity: 0.6;
                            }
                        }
                    }

                    .form-select {
                        padding: 0px 30px 1px 10px;
                        outline: none;
                        border: 1px solid #B6B5B5;
                        border-radius: 5px;
                        margin-right: 10px;
                        width: 140px;
                        height: 40px;
                        appearance: none;

                        &::-ms-expand {
                            display: none
                        };

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .add_cart {
                        background-color: #0F4A8A;
                        color: #fff;
                        border-radius: 25px;
                        padding: 10px 20px;
                        font-size: 14px;
                        font-weight: 600;

                        .fa-shopping-cart {
                            margin-right: .5rem;
                        }
                    }

                    .site-nav {
                        top: 0;
                        right: 0;
                        width: 0vh;
                        max-width: 750px;
                        z-index: 1000;
                        height: 100vh;
                        margin-right: auto;
                        position: fixed;
                        transition: all .3s ease;
                        background-color: #fff;
                        border-left: 0;

                        .btn-close {
                            display: block;
                            position: inherit;
                        }

                        &.site-nav--open {
                            width: 50vw;
                            transition: width 1s;
                            z-index: 9999;
                        }

                        .topheader {
                            background-color: #0F4A8A;
                            height: 100px;
                            color: #FFF;
                            text-transform: uppercase;
                            padding: 1.875rem;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            h2 {
                                color: #FFF;
                                margin-bottom: 0;
                                font-size: 1.875rem;
                                font-weight: bold;
                                letter-spacing: 1px;
                                line-height: normal;
                                text-transform: uppercase;

                                span {
                                    font-weight: 300;
                                }
                            }
                        }

                        .cart-body {
                            height: 100%;

                            .product-price {
                                padding: 1.375rem 1.875rem 0rem;
                                border-bottom: 1px solid $cardcolor;
                                margin-bottom: 0rem;

                                li {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    list-style-type: none;
                                    margin-bottom: 1.25rem;

                                    h5 {
                                        font-size: 1.125rem;
                                        font-weight: bold;
                                    }
                                }
                            }

                            .product-details {
                                padding: 0.8rem 1.875rem 1.375rem;
                                margin: 0;
                                height: 645px;
                                overflow-y: scroll;

                                
                            }

                            .cart-footer {
                                height: 100px;
                                padding: 1.375rem 1.875rem 1.375rem;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                box-shadow: 0 -3px 6px -5px $textcolor;

                                a {
                                    .btn-secondary {
                                        width: 150px;
                                        margin-right: 1rem;
                                        font-size: 0.875rem;
                                        font-weight: 400;
                                        // padding: 0em 1em;
                                        // letter-spacing: 0.5px;
                                        text-transform: uppercase;
                                        border-radius: 0px;
                                        // background: #0F4A8A;
                                        // background-color: rgb(11, 33, 56);
                                        // line-height: 42px;
                                        // z-index: 1;
                                        background-color: transparent;
                                        color: #0F4A8A;
                                        overflow: hidden;
                                        position: relative;
                                        border: 1px solid #0F4A8A;
                                        height: 40px;
                                        display: inline-block;
                                    }

                                    .btn-primary {
                                        font-size: 0.875rem;
                                        font-weight: 400;
                                        padding: 0em 1em;
                                        text-transform: uppercase;
                                        border-radius: 0px;
                                        background: #0F4A8A;
                                        border: none;
                                        line-height: 42px;
                                        color: #FFF;
                                        z-index: 1;
                                        height: 40px;
                                        display: inline-block;
                                        text-align: center;
                                        overflow: hidden;
                                        position: relative;
                                        transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
                                        width: auto;

                                        &::before, &::after {
                                            top: 0;
                                            width: 0;
                                            height: 100%;
                                            mix-blend-mode: overlay;
                                            transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
                                            content: "";
                                            position: absolute;
                                            display: block;
                                            box-sizing: border-box;
                                        }

                                        &::before {
                                            left: 0;
                                            width: 0;
                                            background: #00A0E3;
                                        }

                                        &::after {
                                            left: 100%;
                                            width: 0;
                                            background: #00A0E3;
                                        }

                                        span {
                                            position: relative;
                                            z-index: 9;
                                        }
                                    }
                                }

                                .total-prize {
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                }
                            }
                        }
                    }

                    .quote,
                    .customise {
                        color: #00A0E3;
                        border-radius: 25px;
                        padding: 10px 20px;
                        border: 1px solid #00A0E3;
                        margin-left: 1rem;
                        margin-right: 1rem;
                        font-size: 14px;
                        font-weight: 600;
                        display: flex;
                        align-items: center;

                        .fa-cog {
                            margin-right: .5rem;
                        }
                    }

                    .select-wrapper{
                        &:last-child{
                            select.form-select{margin-right: 0;}
                        }
                    }

                }
            }

            .select_delivery {
                font-size: 14px;
                margin-bottom: 0.5rem;

                strong {
                    font-size: 1rem;
                    font-weight: 600;
                    display: block;
                    margin-bottom: .5rem;
                }

                .enter {
                    display: block;
                }

            }
            .product-fetures{
                padding:0 0 1rem 0;margin:0 0 1.5rem 0;display: flex;flex-wrap:wrap;border-bottom:1px solid #c8c7c7;
                li{
                    list-style-type: none;width: 33%;margin-bottom: 13px;
                    img{
                        margin-right: 4px;
                    }
                }
            }

            .pin-check {
                margin-bottom: 1rem;
                
                .input-group {
                    border: 1px solid #dee2e6;
                    border-radius: 5px;
                    width: 210px;

                    input {
                        border: none;height: 40px;
                    }

                    .btn-outline-secondary {
                        background-color: #fff;
                        color: $linkcolor;
                        border: none;
                        border-left: 0;
                        padding: 8px 10px;
                        text-transform: uppercase;
                        box-shadow: none;
                    }
                }

                .form-error {
                    color: red;
                    font-size: 0.8rem;
                    font-weight: 700;
                    padding-top: 0.6rem;
                }
            }

            .accordion {
                .accordion-item{
                    &:first-child{
                        .accordion-body{
                            padding-left: 5px;
                            h6{font-size: 15px;font-weight: 600;line-height: 25px;margin-bottom: 0;}
                            table{
                                border: 0;
                                width: auto;
                                margin-bottom: 0.75rem;
                                td{border: 0;text-align: left;padding: 5px 0 0;}
                            }
                        }
                    }
                    &:nth-child(2){
                        .accordion-body{
                            padding-left: 5px;
                            table{
                                border: 0;
                                margin-bottom: 0.75rem;
                                tr{
                                    th{padding: 0;border: 0;background-color: #fbfbfb;text-align: left;font-weight: 600;font-size: 14px;}
                                }
                                td{border: 0;text-align: left;}
                            }
                        }
                    }
                    &:nth-child(3),&:last-child{
                        .accordion-body{padding-left: 5px;}
                    }
                }
            }

            .product_information {
                margin-bottom: 1rem;
                font-size: 500;
                font-weight: 600;
            }
        }
    }

    .sliderWapper {
        margin-bottom: 4rem;
        .title {
            color: $linkcolor;
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 1rem;
        }
       
        .slick-arrow.slick-prev {left:-20px;}
        .slick-arrow.slick-next {right:-20px;}
        .slick-list {padding-top: 2rem;}
        .slick-list {
            margin: 0 -8px;
            .slick-slide {
                margin:0 8px;
                .card {width: 246px !important;
                    &:hover {
                        // height: 388px;
                        .btn-similar {display: none;}
                    }
                }
            }
        }
    }

}

.largeimage {
    z-index: 9;
}

.popup-content {
    a{
        color:inherit;margin-right: 6px;

        &>svg{border-radius:5px;}
        &:focus-visible{
            outline: none;
        }
        &.facebook{
            color: #4267b2;
        }
        &.insta{
            color: #df427b;
        }
        &.twitter{
            color:#1da1f2;
        }
        &.linkedin{
            color:#0a66c2;
        }
        &.whatsapp{
            color:#31d131;
        }
        &.mail{
            color:#b40909;
        }
    }
    .link{
        color: #fff;
        cursor: pointer;
        svg{background: #091f34;padding: 3px;border-radius: 5px;}
    }
}

.product-share{
    .wishlist-link{
        .tooltip-label:after{
            bottom: 11px;
        }
    }
    .share-icon{cursor: pointer;}
}
.popup-content {
    width: 170px !important;
    .popup-arrow {display: none;}
    .close {
        color: $linkcolor;
        cursor: pointer;
        position: absolute;
        top: -10px;
        right: -8px;
        font-size: 13px;
        font-weight: 700;
        background: #fff;
        border-radius: 50px;
        display: flex;
        width: 18px;
        align-items: center;
        justify-content: center;
        height: 18px;
        box-shadow: 0 5px 6px rgba(0, 0, 0, 0.20);
        border: 1px solid #D8D8D8;
        //display: none;
    }
}

.react-responsive-modal-modal {
    width: 1246px;max-width: 1246px;padding:0;overflow-y:unset; height: 800px;

    .maindiv{
        height: 100%;
        overflow-y: auto;

        figure{
            img {width: 100%;}
        }
    
        &::-webkit-scrollbar {
            width: 10px;
        }
         
        &::-webkit-scrollbar-track {
            width: 5px;background-color: #F5F5F5;border-radius: 10px;
        }
         
        &::-webkit-scrollbar-thumb {
          width: 2px;background-color: $linkcolor;height: 62px;border-radius: 10px;border: 4px solid rgba(0, 0, 0, 0);background-clip: padding-box;
        }
    }
}
.react-responsive-modal-closeButton {top:-10px;right:-10px;width: 26px;height: 26px;border:1px solid #d8d8d8;background-color: #FFF;border-radius: 50px;
    display: flex;justify-content: center;align-items: center;
    svg {font-size: 15px;fill:$linkcolor;width: 18px;height: 18px;}
}
.react-tabs__tab-list {
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 55px;
    width: 580px;
    overflow: hidden;
    max-width: 580px;
    margin: 0 auto;
    border: 1px solid $linkcolor;}
.react-tabs__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 55px;
    padding: 6px 12px;
    border-radius: 0;
    color: $linkcolor;
    text-align: center;
    width: 290px;
    border-bottom: none;
}
.react-tabs__tab--selected {
    background: $linkcolor;
    border-color: $linkcolor;
    color: #FFF;
    font-size: 20px;
    border-radius: 0;
    font-weight: 400;
}
.react-tabs__tab-panel {
    padding-top: 2rem;
}
.Ring-tabs {
    padding:0 40px 2rem;
    h2{
        font-size: 20px;background-color: $hovercolor;color: #FFF;width: 124px;font-weight: 600;text-transform: uppercase;height: 43px;display: flex;justify-content: center;align-items: center;
    }
    p {font-size: 20px;font-weight: 400;color: #231535;}
    figure{
        width: 360px;margin-bottom:2.5rem;
        img {width: 100%;height: 100%;}
    }
    .inner-wrapper {padding:0 100px;}
    .graybg {background-color: #F2F2F2;border:1px solid #D5D4D4;text-align: center;padding:2rem}
    h3 {font-size: 32px;font-weight: 600;color: $linkcolor;}
    table {text-align: center;font-size:30px;font-weight:400;
        th{
            background-color: $hovercolor;color: #FFF;line-height: normal;padding:1.2rem 0;vertical-align: top;
        }
    }
    .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-accent-bg:#FFF;
        color:#212529(--bs-table-striped-color);
    }
    .table-striped>tbody>tr:nth-of-type(even)>* {
        --bs-table-accent-bg:#F9F9FA;
        color:#212529(--bs-table-striped-color);
    }
}
.btm-content {
    text-align: center;
    p {font-size: 15px;font-weight: 400;margin-bottom: 0;color: #231535;}
}
.have-ring {
    margin-top: 1.5rem;
    figure {width: 490px;}
    .d-flex {
        justify-content: flex-start;align-items: center;color: #231535;font-size: 20px;font-weight: 400;padding-right: 4rem;
    }
    table{
        thead{
            tr{
                th{
                    span{display: block;}
                }
            }
        }
    }    
}
.btn-wishlist {
    background-color: #53C9C5;color:#FFF;width: 220px;height: 60px;border-radius: 10px;font-size: 20px;font-weight: 400;text-transform: uppercase;
    position: absolute;top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    svg {
        font-size: 32px;margin-right: 10px;
    }
}

.wishlisttooltip {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    .btn.btn-wishlist{
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        z-index: 9;
    }
}

.popup-overlay  {
    .popup-content {
        width: auto !important;
        padding: 2rem !important;
        text-align: center;
        border-radius: 5px;
        background-color: #fff;

        .pop-body{
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            .close{
                right: -20px;
                font-size: 30px;
                width: 40px;
                height: 40px;
                top: -20px;

                .closebtn{color: $linkcolor;margin-top: -6px;margin-right: -1.5px;}
            }

            h2{font-size: 30px;margin-bottom: 2rem;letter-spacing: normal;font-weight: 400;}

            p{font-size: 14px;color: #5D5D5D;}

            img{margin-bottom: 2rem;display: flex;
                &.iconLocation {width: 58px;margin: 1rem auto;}
            }

            .btn-primary {
                &.btn-lg {
                    background-color: $linkcolor;
                    width: 286px;
                    height: 40px;
                    color: #FFFFFF;
                    line-height: 40px;
                    margin-right: 0;
                }
            }
        }
    }
}
.home .popup-overlay .popup-content .pop-body h2 {
    font-size: 32px;font-weight: 600;
}
.formMargin {
    .formRe {line-height: 40px;
        .txtbox {height: 40px;
            // border-top-right-radius: 0;
            // border-bottom-right-radius: 0;
            border:1px solid #b5b5b5}
    }
}