@import '../../../assets/Scss/variable';

.location{
    margin:0 2rem;
    span.loc-text{font-size: 12px;font-weight: 400;font-family: 'Source Sans Pro', sans-serif;}
    .input-group{
        align-items: center;width: 110px;
        .input-group-prepend {
            display: flex;
            span:nth-child(2) {font-size: 15px;}
        }
    }
    .input-group-text{
        color: #231535;font-size: 18px;align-items: center;font-weight: 400;background-color: transparent;border:none;border-radius: 0;padding:0;
    }
    .form-control{
        border:none;padding:0 0 0 4px;font-size: 0.875rem;
        &::placeholder{
            color:#231535;font-family: 'Source Sans Pro', sans-serif;
        }
    }
}


