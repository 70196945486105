.location {
  margin: 0 2rem;
}
.location span.loc-text {
  font-size: 12px;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}
.location .input-group {
  align-items: center;
  width: 110px;
}
.location .input-group .input-group-prepend {
  display: flex;
}
.location .input-group .input-group-prepend span:nth-child(2) {
  font-size: 15px;
}
.location .input-group-text {
  color: #231535;
  font-size: 18px;
  align-items: center;
  font-weight: 400;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}
.location .form-control {
  border: none;
  padding: 0 0 0 4px;
  font-size: 0.875rem;
}
.location .form-control::-moz-placeholder {
  color: #231535;
  font-family: "Source Sans Pro", sans-serif;
}
.location .form-control::placeholder {
  color: #231535;
  font-family: "Source Sans Pro", sans-serif;
}/*# sourceMappingURL=DeliveryLocation.css.map */