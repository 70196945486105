.sell-on-summera .bannerSec {
  position: relative;
  height: 557px;
}
.sell-on-summera .bannerSec figure {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
}
.sell-on-summera .bannerSec figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.sell-on-summera .bannerSec figure figcaption {
  width: 45%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.sell-on-summera .bannerSec figure figcaption h1 {
  color: #fff;
  line-height: 50px;
  font-family: "Bahnschrift";
  margin-bottom: 0;
}
.sell-on-summera .bannerSec figure figcaption h1 span {
  display: block;
}
.sell-on-summera .row.gap0 {
  margin-bottom: 5rem;
}
.sell-on-summera .row.gap0 .col-md-5 {
  padding-right: 0;
}
.sell-on-summera .row.gap0 .col-md-5 .orangeBox {
  background: #FFF7EB;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
}
.sell-on-summera .row.gap0 .col-md-5 .orangeBox h2 {
  margin: 0;
  font-size: 1.9rem;
}
.sell-on-summera .row.gap0 .col-md-5 .orangeBox ul {
  padding: 40px 20px 100px 60px;
  margin: 0;
  list-style-type: none;
}
.sell-on-summera .row.gap0 .col-md-5 .orangeBox ul li {
  padding-left: 80px;
  position: relative;
  margin-bottom: 3rem;
  color: #5D5D5D;
}
.sell-on-summera .row.gap0 .col-md-5 .orangeBox ul li .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #fff;
}
.sell-on-summera .row.gap0 .col-md-5 .orangeBox ul li .icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sell-on-summera .row.gap0 .col-md-5 .orangeBox ul li h5 {
  font-size: 20px;
  margin: 0;
  line-height: normal;
}
.sell-on-summera .row.gap0 .col-md-5 .orangeBox ul .error-message {
  color: #FF0D0D;
  font-size: 12px;
}
.sell-on-summera .row.gap0 .col-md-5 .bgImg {
  margin-top: -100px;
}
.sell-on-summera .row.gap0 .col-md-7 {
  padding-left: 0;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded {
  border-radius: 10px;
  padding: 30px 30px 40px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .topheading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .topheading h5 {
  font-size: 20px;
  font-weight: bold;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 0;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .topheading span {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded h6 {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
  margin-bottom: 0;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded ul li {
  margin-right: 1rem;
  cursor: pointer;
  width: 22%;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded ul li input {
  margin-right: 10px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded ul li label {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
  cursor: pointer;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group {
  width: 48%;
  position: relative;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.salutation {
  width: 13%;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.fname {
  width: 31%;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.resize {
  display: flex;
  align-items: center;
  position: relative;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.resize .pan-withbtn {
  width: 72%;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.resize .btn-verify {
  width: 100px;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.resize .btn-verify button {
  display: block;
  width: 100%;
  padding: 7px 0px;
  outline: 0;
  border: 1px solid #b5b5b5;
  border-radius: 0 5px 5px 0;
  color: #5B005D;
  background-color: #fff;
  font-weight: 600;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.resize .btn-verify button.green {
  background-color: green;
  color: #fff;
  border: 1px solid green;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.resize .btn-verify button.red {
  background-color: red;
  color: #fff;
  border: 1px solid red;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile {
  width: 48%;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select {
  display: flex;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .form-select {
  width: 84px;
  height: 40px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .form-select:disabled {
  background-color: #f8f8f8;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .formMargin {
  width: 100%;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .formMargin .lbl {
  display: none;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .formMargin .error-message {
  left: -83px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .formMargin:first-child {
  width: 110px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group.mobile .cust-select .formMargin input.txtbox.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row .form-group .btn.btn-primary {
  position: absolute;
  right: 0;
  top: 27px;
  width: 130px;
  background-color: #fff;
  color: #5B005D;
  padding: 0;
  border: 0;
  border-left: 1px solid #ced4da;
  border-radius: 0;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-row.company .form-group {
  width: 100%;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group {
  margin-bottom: 1rem;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group label {
  margin-bottom: 0.25rem;
  font-size: 15px !important;
  font-weight: "";
  color: #5D5D5D !important;
  line-height: 21px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group .mb-3 {
  margin-bottom: 0 !important;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group .mb-3 input {
  height: 40px;
  padding: 8px 135px 8px 7px;
  font-size: 14px;
  z-index: 2;
  position: relative;
  background-color: transparent;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group .mb-3 input:focus {
  border: 1px solid #ced4da;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group .mb-3 .error-message {
  position: relative;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group .formMargin .lbl {
  display: inline-block;
  text-transform: capitalize;
  font-family: "Source Sans Pro", sans-serif;
  width: auto;
  margin-right: 5px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group .formMargin .formRe .txtbox {
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group .formMargin .formRe .txtbox:disabled {
  background-color: #f8f8f8;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group .formMargin .lbl-wrap {
  display: flex;
  justify-content: flex-start;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group.are-you {
  position: relative;
  margin-bottom: 2rem;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .form-group.are-you .error-message {
  bottom: -10px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded input[type=file]::file-selector-button {
  display: none;
  background-color: #fff;
  font-family: "Nunito Sans", sans-serif;
  color: #5B005D;
  font-weight: 700;
  border: 0px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-right: 1px solid #e5e5e5;
  margin-right: 20px;
  transition: 0.5s;
  height: 40px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .file-info {
  color: #5D5D5D;
  font-size: 13px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info {
  padding-top: 2.2rem;
  text-align: center;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary {
  background-color: #5B005D;
  padding: 0em 4em;
  height: 40px;
  color: #fff;
  line-height: 40px;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary.btnmakepayment {
  background-color: #0B2138;
  color: #fff;
}
.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .make-info .btn-primary:disabled {
  opacity: 40%;
}
.sell-on-summera .select-city {
  position: relative;
}
.sell-on-summera .select-city.mobile .error-message {
  bottom: -17px;
}
.sell-on-summera .why-register-on-summera {
  margin-bottom: 4rem;
}
.sell-on-summera .why-register-on-summera h2 {
  text-align: center;
}
.sell-on-summera .why-register-on-summera ul {
  list-style: none;
  display: flex;
  padding-left: 0;
}
.sell-on-summera .why-register-on-summera ul li {
  text-align: center;
  position: relative;
  width: 325px;
}
.sell-on-summera .why-register-on-summera ul li h6 {
  font-size: 20px;
  font-weight: 600;
  color: #5D5D5D;
  line-height: 30px;
}
.sell-on-summera .why-register-on-summera ul li p {
  font-size: 15px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 21px;
  width: 75%;
  margin: auto;
}
.sell-on-summera .why-register-on-summera ul li:first-child::after {
  content: "";
  background-image: url(../../../assets/images/curve1.png);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 274px;
  height: 60px;
  position: absolute;
  bottom: -5px;
  right: -131px;
  top: 0;
}
.sell-on-summera .why-register-on-summera ul li:nth-child(2) {
  margin-top: 2rem;
}
.sell-on-summera .why-register-on-summera ul li:nth-child(2)::after {
  content: "";
  background-image: url(../../../assets/images/curve2.png);
  background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 270px;
  height: 70px;
  position: absolute;
  bottom: -5px;
  right: -123px;
  top: 8px;
}
.sell-on-summera .why-register-on-summera ul li:nth-child(3)::after {
  content: "";
  background-image: url(../../../assets/images/curve1.png);
  background-size: 98%;
  background-repeat: no-repeat;
  width: 300px;
  height: 82px;
  position: absolute;
  bottom: 20px;
  right: -166px;
  top: 8px;
}
.sell-on-summera .why-register-on-summera ul li:last-child {
  margin-top: 2rem;
}
.sell-on-summera .seller-says {
  text-align: center;
}
.sell-on-summera .seller-says .inner-wrapper h2 {
  margin-bottom: 0;
}
.sell-on-summera .seller-says .inner-wrapper .testi-icon svg {
  color: #5B005D;
}
.sell-on-summera .seller-says .inner-wrapper p.content {
  color: #5D5D5D;
}
.sell-on-summera .seller-says .inner-wrapper .img-wrapper figure {
  height: auto;
  width: auto;
}
.sell-on-summera .seller-says .inner-wrapper .img-wrapper figure img {
  width: auto;
  max-width: 200px;
  height: auto;
  border-radius: 0;
}
.sell-on-summera .sell-faq-accordion {
  margin-bottom: 5rem;
}
.sell-on-summera .sell-faq-accordion h2 {
  text-align: center;
}
.sell-on-summera .sell-faq-accordion .accordion .accordion-item {
  margin-bottom: 2rem;
  border: 1px solid rgba(93, 93, 93, 0.3215686275);
  padding: 0 0.5rem;
}
.sell-on-summera .sell-faq-accordion .accordion .accordion-item .accordion-header button {
  font-size: 15px;
  font-weight: 600;
  color: #5D5D5D;
  line-height: normal;
}

.sell-thank-you .login-box {
  margin-top: 3rem;
}
.sell-thank-you .login-box .login-wrapper {
  width: 900px;
  text-align: center;
  border-radius: 5px;
}
.sell-thank-you .login-box .login-wrapper h5 {
  font-size: 20px;
  font-weight: bold;
  color: #5D5D5D;
  line-height: 30px;
}
.sell-thank-you .login-box .login-wrapper h5 span {
  display: block;
  font-weight: 400;
}
.sell-thank-you .login-box .login-wrapper h5.grant {
  font-weight: 600;
}
.sell-thank-you .login-box .login-wrapper h5.grant span {
  font-weight: 600;
}
.sell-thank-you .login-box .login-wrapper .contact-list {
  justify-content: center;
  margin-bottom: 2rem;
}
.sell-thank-you .login-box .login-wrapper .contact-list li {
  width: 100%;
  display: flex;
}
.sell-thank-you .login-box .login-wrapper .contact-list li img {
  width: 50px;
}
.sell-thank-you .login-box .login-wrapper .contact-list li h2 {
  color: #fff;
  margin-bottom: 0;
  margin-left: 1rem;
}
.sell-thank-you .login-box .login-wrapper h6 {
  font-size: 20px;
  font-weight: 600;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 2rem;
}
.sell-thank-you .login-box .login-wrapper h6 span {
  display: block;
}
.sell-thank-you .login-box .login-wrapper h6::before, .sell-thank-you .login-box .login-wrapper h6::after {
  display: none;
}
.sell-thank-you .login-box .login-wrapper p {
  font-size: 20px;
  font-weight: 400;
  color: #5D5D5D;
  line-height: 30px;
  margin-bottom: 3rem;
}
.sell-thank-you .login-box .login-wrapper p span {
  display: block;
}
.sell-thank-you .login-box .login-wrapper p span a {
  color: #5B005D;
  text-decoration: none;
}
.sell-thank-you .login-box .login-wrapper p.choice {
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 0rem;
}
.sell-thank-you .login-box .login-wrapper p.team {
  line-height: 45px;
  margin-bottom: 0rem;
}

.membership-thankyou .inner {
  margin-bottom: 6rem;
  max-width: 100%;
}
.membership-thankyou .inner .order-info .track-order .order-details ul li {
  font-size: 15px;
}
.membership-thankyou .inner .address-details .billing-shipping ul li address {
  width: 400px;
}
.membership-thankyou .inner .thank-order-summary h4 {
  font-weight: 600;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left {
  height: 165px;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper {
  padding: 12px;
  height: 100%;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul {
  align-items: flex-end;
  flex-direction: column;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li {
  line-height: 25px;
  margin-bottom: 0.5rem;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li h4 {
  line-height: 25px;
  margin-bottom: 0.5rem;
  padding-bottom: 0;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li h4:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul li:last-child {
  margin-bottom: 0;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul.second-list {
  width: 200px;
  font-size: 15px;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul.second-list li {
  text-align: right;
  width: 100%;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul.second-list li:last-child {
  display: flex;
  justify-content: space-between;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .left .left-wrapper ul li .order-product .order_product_details ul.second-list li span {
  font-weight: 400;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .right {
  height: 165px;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .right .order-summary {
  height: 100%;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .right .order-summary .summary-data {
  height: 88%;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .right .order-summary .summary-data ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .right .order-summary .summary-data ul li {
  line-height: normal;
}
.membership-thankyou .inner .thank-order-summary .your-order-summary .right .order-summary .summary-data ul li h5 {
  line-height: normal;
}

.reviews {
  padding-top: 3rem;
}
.reviews h2 {
  padding-bottom: 0;
}
.reviews .contact .left .contact-details .review-add-details ul {
  margin-bottom: 1.5rem;
}
.reviews .contact .left .contact-details .review-add-details ul h6 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.reviews .contact .left .contact-details .review-add-details ul p {
  margin-bottom: 0;
}
.reviews .contact .left .contact-details .review-add-details ul:last-child {
  margin-bottom: 0;
}
.reviews .contact .left .contact-details .review-add-details ul:last-child h6 {
  font-weight: 700;
}
.reviews .contact .left .contact-details .review-add-details h4 {
  margin-bottom: 0.75rem;
}
.reviews .contact .left .contact-details .review-add-details address span {
  margin-bottom: 0.5rem;
}
.reviews .contact .left .make-info .checkbox .styled-checkbox + label {
  font-size: 14px;
}
.reviews .contact .left .make-info .checkbox .styled-checkbox + label .terms {
  color: #5B005D;
  font-size: 14px;
}
.reviews .contact .right .summary-data ._border_bottom .custom_flex .price {
  font-size: 14px;
}
.reviews .contact .right .summary-data ._border_bottom .custom_flex .cust-total {
  font-size: 15px;
}
.reviews .contact .right .summary-data ._border_bottom .custom_flex .view_all {
  border-bottom: 0;
}
.reviews .contact .right .summary-data ._border_bottom .custom_flex .view_all span {
  border-bottom: 1px solid #5B005D;
}
.reviews .contact .right .summary-data ._border_bottom:first-child .custom_flex .cust-total {
  color: #000;
}
.reviews .contact .right .summary-data ._border_bottom:last-child .custom_flex .cust-total {
  font-weight: 700;
}

.membership-review .popup-overlay .popup-content, .subscription-task .popup-overlay .popup-content {
  padding: 0 0 2rem;
  border: 0;
  max-width: 825px;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-title, .subscription-task .popup-overlay .popup-content .terms-popup .pop-title {
  padding: 1rem 1.5rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #3B003A;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-title h2, .subscription-task .popup-overlay .popup-content .terms-popup .pop-title h2 {
  color: #fff;
  font-size: 30px;
  line-height: 38px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-title .close, .subscription-task .popup-overlay .popup-content .terms-popup .pop-title .close {
  position: relative;
  top: 0;
  margin: 0;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body {
  text-align: left;
  padding: 1rem 1.5rem;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div {
  overflow-y: auto;
  height: 450px;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para {
  font-size: 14px;
  color: #231535;
  margin-bottom: 1rem;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para .title, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para .title {
  font-size: 15px;
  margin-bottom: 0.25rem;
  font-weight: 600;
  line-height: 25px;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para p, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para p {
  margin-bottom: 0.25rem;
  line-height: 28px;
  font-size: 20px;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para ul, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para ul {
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para ul li, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para ul li {
  list-style-type: inherit;
  line-height: 24px;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para ul li::marker, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div .para ul li::marker {
  color: #676666;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div::-webkit-scrollbar, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div::-webkit-scrollbar {
  width: 2px;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div::-webkit-scrollbar-track, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div::-webkit-scrollbar-track {
  width: 5px;
  background-color: #F5F5F5;
  border-radius: 10px;
}
.membership-review .popup-overlay .popup-content .terms-popup .pop-body .inner-div::-webkit-scrollbar-thumb, .subscription-task .popup-overlay .popup-content .terms-popup .pop-body .inner-div::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: #5B005D;
  height: 62px;
  border-radius: 10px;
}

.subscription-task {
  background: #FFF7EB;
}
.subscription-task .wrapper.sell-on-summera {
  margin-top: 10.7rem;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-5 .bgImg {
  margin-top: -35px;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-5 .bgImg img {
  position: absolute;
  left: 0;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded {
  background-color: #fff;
  z-index: 9;
  position: relative;
  padding: 30px 80px;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .topheading {
  display: block;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .topheading h5 {
  font-weight: 600;
  line-height: normal;
  color: #5B005D;
  margin-bottom: 0.5rem;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .topheading p {
  font-size: 15px;
  line-height: normal;
  color: #1F1F1F;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded h2 {
  text-align: center;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees {
  border-radius: 100px;
  background: #F0EEFF;
  box-shadow: 0px 10px 10px 0px #F0EEFF;
  padding: 1rem;
  margin-bottom: 2.5rem;
  position: relative;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees ul {
  padding-left: 0;
  flex-wrap: nowrap;
  margin-bottom: 0;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees ul li {
  color: #5B005D;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
  width: 50%;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees ul li p {
  margin-bottom: 0;
  margin-top: 0.5rem;
  color: #5D5D5D;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees ul li p span {
  color: #5B005D;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees ul li:last-child {
  color: #C5017C;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees ul li:last-child span {
  color: #C5017C;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees img.plus {
  z-index: 9;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .fees img.line {
  margin-top: 5px;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details {
  width: 500px;
  margin: auto;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .card {
  width: 100%;
  margin: 0 auto 1rem;
  min-height: 170px;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .card .card-body {
  padding: 1rem 3rem;
  background: rgba(255, 247, 235, 0.5);
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .card .card-body h4 {
  color: #181059;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .card .card-body ul li {
  width: 100%;
  color: #231535;
  font-size: 15px;
  line-height: normal;
  margin-bottom: 0.5rem;
  cursor: default;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .card .card-body ul li img {
  margin-right: 0.25rem;
  margin-top: -0.25rem;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .card .card-body ul li span {
  font-weight: 700;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .card:hover {
  box-shadow: none;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service {
  margin: 0 3.25rem 1.5rem;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service .want {
  color: #5B015D;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service .want span {
  font-weight: 700;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service > ul {
  justify-content: space-between;
  display: flex;
  width: 320px;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service > ul li {
  color: #231535;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  width: auto;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service > ul li span {
  display: block;
  margin-top: 0.5rem;
  font-weight: 700;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service > ul li span .form-select {
  box-shadow: none;
  border: 1px solid #ced4da;
  width: 85px !important;
  height: 35px;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .extra-service > ul li.total {
  visibility: hidden;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .total-amount {
  text-align: center;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .total-amount p {
  color: #5D5D5D;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  margin: 1.5rem 0 1rem;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .total-amount p span {
  display: block;
  color: #3B003A;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .total-amount p span sup {
  font-size: 0.5em;
  font-weight: 400;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .total-amount .btn.btn-primary {
  height: 50px;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .total-amount .terms {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .total-amount .terms span {
  font-size: inherit;
}
.subscription-task .wrapper.sell-on-summera .row.gap0 .col-md-7 .shadow-box-rounded .plan-details .total-amount .terms span a {
  color: #5B005D;
  font-size: 15px;
  font-weight: 400;
  margin-right: 0.25rem;
  cursor: pointer;
}
.subscription-task.membership-review {
  background-color: #fff;
}/*# sourceMappingURL=index.css.map */