.toplist ul {
  display: flex;
}
.toplist ul li a {
  color: #fff;
  font-size: 0.8rem;
  line-height: 25px;
  padding-right: 1rem;
}
.toplist ul li a:hover {
  color: #53C9C5;
}
.toplist ul li:last-child {
  padding-right: 0;
}
.toplist ul li + li a {
  border-left: 0.5px solid #CFCFCF;
  padding: 0 1rem;
}/*# sourceMappingURL=TopList.css.map */