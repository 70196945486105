@import '../../../assets/Scss/mixing';

.giftcard{
    @include tabs;
    h5{margin-bottom: 1rem !important;}
    #myTab{justify-content: flex-start;}

    .row{
        margin: 1.5rem 0;
        .card{width: 100%;min-height: auto;
            padding: 16px 5px;
            border: 1px solid #E7E7E7;
            background-color: #FEFCFE;
            border-radius: 4px;
            margin-bottom: 30px;

            .all-card{border: 0;}

            @include giftcard;
            &:hover{box-shadow: none;}

            tbody {
                display: flex;justify-content: space-around;
                td{
                    border: 0;text-align: left;
                    &.img {width: 110px;
                        img{width: 100%;}
                    }
                    &.card-name {width: 120px;}
                    &.card-number{width: 140px;}
                    &.full-name{width: 110px;}
                    &.total-amount{width: 90px;}
                    &.balance-amount{width: 100px;}
                    &.card-validity{width: 80px;}
                    &.status{width: 50px;}
                }
            }
        }
    }

    // #myTabContent{
    //     #allcard{}
    //     #buyacard{}
    //     #giftacard{}
    // }
}


.myaccount .popup-overlay .popup-content{
    // padding-bottom: 1rem !important;
    @include giftcard;
    .inner-div { height: auto !important;width: 100%;max-width: 800px;
        .all-card {border: 0;
            .redeem-detail {display: flex;justify-content: space-around;
                td{padding: 0 10px;text-align: left;border: 0;
                    img{margin-bottom: 0;}
                }
            }
        }
        ul {padding-left: 0;margin-bottom: 0;
            li{margin-bottom: 0;
                img{margin-bottom: 0}
            }
        }
        @include invoiceDetails;
        .invoiceDetails{padding-bottom: 0;border-bottom: 0;
            .tags {
                font-size: 0.933rem;
                font-weight: 700;
                color: $textcolor;
                line-height: 24px;
            }
            table {
                thead {
                    tr {
                        th{text-align: left;white-space: nowrap;}
                    }
                }
                tbody {
                    tr {
                        td{text-align: left;padding: 7px;background-color: #f9f9f9;}
                    }
                }
                tfoot {
                    tr {
                        td{text-align: left;padding-left: 7px;border-top: 1px solid #DBDBDB;}
                    }
                }
            }
        }
    }
}