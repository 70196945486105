.error-message {
  color: #FF0D0D;
  font-size: 12px;
  position: absolute;
  line-height: normal;
  /* text-align: center !important;
  display: flex; */
}
/* .error-message {
  color: #FF0D0D;
  font-size: 12px;
  position: absolute;
  top: 24px;
  background: #fff;
  padding: 0 10px;
  right: 5px;
} */
.err_block {
  display: block;
  text-align: center !important;
  left: 0;
  right: 0;
  margin: 0px auto;
}
.err_hide {
  display: none;
}
