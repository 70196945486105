.brand-img {
    figure{
        height: 90px;background-color: #FFF;border-radius: 10px;box-shadow: 0 0px 15px rgba(0, 0, 0, 0.15);display: flex;justify-content:center;align-items:center;
        img{
            width: 100%;
            height: auto;
        }
    }
  
}   

