@import '../../../assets/Scss/variable';

.address-form {
    .topheading {display: flex;justify-content: space-between;
        h5 {margin-bottom: 0;}
        h6 {font-size: 12px;color: $grayColor;margin-top: auto;margin-bottom: 0;}
    }

    .office-address {
            .new-address{display: flex;justify-content: space-between;
                h5{font-size: 20px !important;font-weight: 600;line-height: 28px;}
                h6{font-size: 14px;color: #676666;margin-top: auto;margin-bottom: 0;}
            }

            form {
                margin-bottom: 2rem;
                .form-row {
                    list-style: none;
                    display: flex;
                    padding: 0;
                    justify-content: space-between;
                    align-items: center;

                    .form-group {
                        margin-bottom: 1rem;
                        width: 32%;
                        label {text-transform: capitalize;font-size: 13px !important;font-weight: 600;display: block;font-family: $basefont;color: #3F3F3F !important;line-height: 23px;}

                        .form-control {
                            border-radius: 5px;
                            border: 1px solid #B6B5B5;
                            height: 40px;
                            // width: 300px;
                            margin-right: 5px;padding-left:10px;
                            font-family: "Montserrat", sans-serif;
                            font-size: 0.75rem;

                            &::placeholder {
                                font-size: 0.75rem;
                                color: #676666;
                            }

                            &:disabled {
                                background-color: #f9f9f9;
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        p {
                            &.form-error {
                                color: red;
                                font-size: 0.8rem;
                                font-weight: 700;
                                padding-top: 0.6rem;
                            }
                        }

                        .form-select {
                            border-radius: 5px;
                            border: 1px solid #B6B5B5;
                            height: 40px;
                            font-size: 0.75rem;
                            color: #676666;
                            // width: 300px;
                            background-image: url('../../../assets/images/caret-down-solid.svg');
                            background-size: 8px;
                            background-position: top 12px right 15px;
                            font-family: 'Montserrat';
                            appearance: none;

                            &::placeholder {
                                font-size: 0.75rem;
                                color: #7F7F7F;
                            }

                        }
                        &.state,&.city{
                            .error-message{line-height: 1.5;}
                        }
                    }
                    &:last-child{margin-bottom: 1.5rem;}
                }

                .save-address{margin-right: 10px;}
            }
         
           .address-review {
                margin-bottom: 2rem;
                .addresses {
                    display: flex;
                    margin-bottom: 0.5rem;
                    flex-wrap: wrap;
                    // justify-content: space-between;
                    .address-box {
                        border-radius: 5px;
                        border: 1px solid $cardcolor;
                        padding: 1rem;
                        width: 31%;
                        margin-bottom: 1rem;
                        margin-right: 1rem;

                        // strong:first-child{display: block;}

                        h4{font-size: 15px;font-weight: bold;line-height: 25px;margin-bottom: 0.25rem;}

                        h6{font-size: 15px;font-weight: 700;line-height: 25px;}

                        p{font-size: 15px;font-weight: 400;line-height: 25px;margin-bottom: 1rem;word-wrap: break-word;word-break: break-word;}

                        .address-span{line-height: 25px;
                            span{
                                color: $linkcolor;
                                font-weight: 600;
                                cursor: pointer; 
                                &:first-child{margin-right: 0.5rem;} 
                                &.or{color: #E8E5E5;}
                                &:last-child{margin-left: 0.5rem;}
                        }
                        }
                    }    
                }           
           } 

    }
}