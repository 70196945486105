@import '../../../assets/Scss/variable';
@import '../../../assets/Scss/mixing';

.e-cart-list {
    padding: 0;
    float: right;
    margin-top: 1rem;
    margin-bottom: 0;

    .home-video{
        position: relative;padding-right:0;margin-right:20px;
        // &:after{
        //     content:'';position: absolute;width: 1px;height: 35px;background-color: #B4B4B4;right: 0;top:50%;transform: translateY(-50%);
        // }
        .tryathome{ 
            // display: block;
            line-height: normal;padding-right: 20px;
            svg {
                height: 22px;
            }
            .icon-text{line-height: 26px;}
        }
        .video-call{
            svg {
                height: 22px;
                &.videoIcn {
                    font-size: 22px;
                }
            } 
        }
    }
    .cart-wishlist{padding-right: 0;
        li{
            &:last-child{
                padding-right: 0;
                padding-left: 10px;
            }
        }
        // >div{display: none;border: 1px solid transparent;}
    }

    li {
        position: relative;
        list-style-type: none;
        padding-right: 20px;
        @include navbar-li;text-align:center;

        

       
        .cart-product{
            a{
                font-size: 28px;
                span.cart-counter{
                    left:18px;
                }
            }
        }


        a {
            color: inherit;display:flex;justify-content:space-between;flex-direction:column;align-items:center;height:50px;line-height:35px;
            svg{
                height: 22px;
                color: $maincolor;
                &.homeIcn {
                    font-size: 18px;
                }
                // &.videoIcn {
                //     font-size: 22px;
                // }
                &.wishlistIcn {
                    font-size: 25px;
                }
                &.cartIcn {
                    font-size: 25px;
                }
                &.userIcn {
                    font-size: 40px;
                    // margin-bottom: 5px;
                }
            }
        }

        .after-login {.userIcn {margin-bottom: 5px;}}

        button.video-call,button.tryathome {
            border: 0;
            background-color: #fff;
            padding-right: 20px;
        }

        span.icon-text{
            font-size:$font12;font-weight: 400;display: block;color:$textcolor;line-height: 30px;
        }
        span.cart-counter {
            @include flexitem;
            width: 20px;
            height: 20px;
            background: $texthover;
            border-radius: 50px;
            position: absolute;
            left: 27px;
            top: -7px;
            @include font(12px, bold, $whitecolor);
        }

        &.after-login {
            display: none;
            border: 1px solid transparent;

            & a {
                padding-right: 9px;
            }
        }
        &.currency_drop {position: relative;flex-direction:column;
            li {
                &:before {content: '';border-left: 0.5px solid #b4b4b4;position: absolute;left:-10px;height:35px;top:50%;transform: translateY(-50%);}
                ul {width: 160px;
                    display: flex;flex-direction: column;justify-content:flex-start;
                    li {padding:0 0 0 10px;width: 100%;
                        &::before{display: none;}
                        > span {width: 100%;
                           .ReactFlagsSelect-module_selectFlag__2q5gC {padding-right: 10px;width: 30px;background: none;}
                        }
                    }
                }
            }
        }
    }
}